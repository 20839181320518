import React from 'react';
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';

import TodaysPracticesSingle from './TodaysPracticesSingle';
import { useTranslation } from 'react-i18next';

function TodaysPractices(exercise, exerciseID) {

    const { t } = useTranslation();

    return (
        <>
            <div className="todaysPracticesTitleContainer">
                <p className="todaysPracticesTitle">{t('TODAYS_PRAC')}</p>
            </div>
            <Grid container spacing={2}>
                {exercise && exercise.exercise.map((data, i) => (
                    data.type !== "questionBank" &&
                    <TodaysPracticesSingle key={i} exercise={data} exerciseID={exercise.exerciseID[i]}/>
                ))}
            </Grid>
            <div className="todaysPracticesBottom"></div>
        </>
    );
}

export default TodaysPractices;
