import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {makeStyles, withStyles} from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../localStorage/Language';
import {styled} from "@mui/material/styles";

export const LoginTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '100px',
                width: '100%',
            },
        },
    },
})(TextField);

export const LoginTextFieldLong = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '30px',
                width: '100%',
                height: '280px',
            },
        },
    },
})(TextField);

export const LoginTextFieldSingleDigit = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '10px',
                width: '4vw',
                height: '6vw',
                borderColor: '#F6C840',
            },
        },
    },
})(TextField);

export const LoginButton = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '100px',
                width: '100%',
            },
        },
    },
})(Button);

export const SendButton = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '100px',
                width: '100%',
            },
        },
    },
})(Button);

export const ConfirmButton = () => {
    const { t } = useTranslation();

    return (
        <Button2 disableRipple>
            {t('CONFIRM')}
        </Button2>
    );
};



// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Button2 = styled(Button)(({ theme }) => ({

    backgroundColor: '#FFE351',
    color: 'black',
    width: '9vw',
    borderRadius: '0.8vw',
    fontSize: '1.2vw',
    fontFamily: 'Poppins',
    fontWeight: '500',
    boxShadow: '0px 3px 2px #aaa',
    textTransform: 'none',
    padding: "0vw",
    marginTop: "1vw",
    marginLeft: "7vw",
    '&:hover': {
        backgroundColor: '#FFE351',
        color: 'black',
    },



    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFE351',
        color: 'black',
        width: '50vw',
        borderRadius: '0.8vw',
        fontSize: '18px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow: '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "0vw",
        // marginTop: "1vw",
        // marginLeft: "7vw",
        '&:hover': {
            backgroundColor: '#FFE351',
            color: 'black',
        },
    },
}));
