import React from 'react';
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";

import '../../App.css';
import './_styles.css';
import '../../translation'

import {useNavigate} from 'react-router-dom';

import questionBankPastTenseNoWordSVG from '../../assets/exercise/questionBankPastTenseNoWord.svg';
import questionBankPastTensePresentTenseNoWordSVG
    from '../../assets/exercise/questionBankPastTensePresentTenseNoWord.svg';
import questionBankPresentContinuousTenseNoWordSVG
    from '../../assets/exercise/questionBankPresentContinuousTenseNoWord.svg';
import questionBankPresentTensePresentContinuousTenseNoWordSVG
    from '../../assets/exercise/questionBankPresentTensePresentContinuousTenseNoWord.svg';
import questionBankPrepositionNoWordSVG from '../../assets/exercise/questionBankPrepositionNoWord.svg';
import questionBankQuestionWordNoWordSVG from '../../assets/exercise/questionBankQuestionWordNoWord.svg';

function ImprovementTopicBox({
                                 data,
                                 toBeImprovedLevel1,
                                 toBeImprovedLevel2
                             }) {
    let navigate = useNavigate();
    const {t} = useTranslation();
    return (
        <>
            {
                (toBeImprovedLevel1.accuracy >= 0 &&
                    (Math.round(toBeImprovedLevel1.accuracy * 100 < 80)) ||
                    (toBeImprovedLevel2.accuracy >= 0 && Math.round(toBeImprovedLevel2.accuracy * 100 < 60))) &&
                <Grid item xs={12}>
                    <div className="improvementTopicBoxContainer">
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={1} align="center">
                                <img className="topicBoxCover" src={
                                    data &&
                                    data.topicKey == "pastTense" ? questionBankPastTenseNoWordSVG :
                                        data.topicKey == "pastTensePresentTense" ? questionBankPastTensePresentTenseNoWordSVG :
                                            data.topicKey == "presentContinuousTense" ? questionBankPresentContinuousTenseNoWordSVG :
                                                data.topicKey == "presentTensePresentContinuousTense" ? questionBankPresentTensePresentContinuousTenseNoWordSVG :
                                                    data.topicKey == "preposition" ? questionBankPrepositionNoWordSVG :
                                                        data.topicKey == "questionWord" && questionBankQuestionWordNoWordSVG
                                } alt="topic cover"/>
                            </Grid>
                            <Grid item xs={4}><p className="resultTopicBoxText">{data.topic}</p></Grid>
                            <Grid item xs={3} align="center">
                                <p className="resultTopicBoxText">
                                    {
                                        Math.round(data.accuracy * 100) === 100 ?
                                            "Q"
                                            : Math.round(data.accuracy * 100) >= 90 ?
                                                "A"
                                                : Math.round(data.accuracy * 100) >= 80 ?
                                                    "B"
                                                    : Math.round(data.accuracy * 100) >= 60 ?
                                                        "C"
                                                        : "F"
                                    }
                                </p>
                            </Grid>
                            <Grid item xs={2} align="center">
                                <p
                                    className="resultTopicBoxText">
                                    {Math.round(data.accuracy * 100)}%
                                </p>
                            </Grid>
                            <Grid item xs={12} sm={2} align="center">
                                <div onClick={
                                    () => {
                                        navigate("/exercise", {state: {topic: data.topicKey}})
                                    }
                                } className="practiceNowContainer">
                                    <p className="practiceNowText">{t("PRACTICE_NOW")}</p>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            }
        </>
    );
}

export default ImprovementTopicBox;