import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth, db} from "../../firebase";
import {addDoc, collection, doc, getDoc, setDoc, updateDoc} from 'firebase/firestore';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";

import '../../App.css';
import './_styles.css';
import '../../translation'
import Menu from '../../components/menu/Menu';
import ForgotPassword from './ForgotPassword';
import {LoginButton, LoginFailedTextField, LoginTextField} from './_components';
import {getUser, setUser} from '../../localStorage/User';
import {redirect} from "../../functions/redirect"
import {useMediaQuery} from 'react-responsive'
import {Box, TextField} from "@mui/material";
import QualiflyIconForBooth from "../../assets/booth/QualiflyIconForBooth.svg";
import Screenshot2022 from "../../assets/booth/Screenshot2022-09-02at.png";
import QualiFlylogo_3 from "../../assets/booth/QualiFlylogo_3-02-03.png";
import Instagramlogo from "../../assets/booth/Instagramlogo.png";
import LKlogo from "../../assets/booth/LKlogo.png";
import image4 from "../../assets/booth/image4.png";
import Group308 from "../../assets/booth/Group308.png";
import {BoothButton333333, BoothButton333334} from "../login/Logout";
import Button from "@material-ui/core/Button";
import SearchWhiteSVG from "../../assets/dashboard/searchWhite.svg";
import SearchYellowSVG from "../../assets/dashboard/searchYellow.svg";
import {styled} from "@mui/material/styles";



const TextField1 = styled(TextField)(({ theme }) => ({

    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: '100px',
            width: 'calc(100% - 20px)',
        },
    },



    [theme.breakpoints.down('sm')]: {
        borderRadius: '100px',
        width: 'calc(100% - 2px)',
    },
}));
function Login() {
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const user = getUser();
    let navigate = useNavigate();
    useEffect(() => {
        // redirect(user, navigate);
        // eslint-disable-next-line
    }, [])

    const {t} = useTranslation();



    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState("");
    const [search3, setSearch3] = useState("");


    const location = useLocation();


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [forgotPasswordModalVisibility, setForgotPasswordModalVisibility] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showLoginFailedMsg, setShowLoginFailedMsg] = useState(false);

    const logInWithEmailAndPassword = async () => {
        let userID, userEmail;
        const emailToDB = [password + "@kfps4a.com", password + "@kfps4b.com", password + `@${email}.com`];
        const passwordToDB = email;
        let wrongFlag = false;
        for (let i = 0; i < 3; i++) {
            try {
                await signInWithEmailAndPassword(auth, emailToDB[i], passwordToDB)
                    .then((data) => {
                        userID = data.user.uid;
                        userEmail = data.user.email;
                        setEmail("");
                        setPassword("");
                    });
            } catch (err) {
                wrongFlag = true;
            }


            if (userID) {
                const userSnapshot = await getDoc(doc(db, 'User', userID));
                let today = new Date();
                today.setHours(0);
                today.setMinutes(0);
                today.setSeconds(0);
                if (!userSnapshot.exists()) { // to handle first login


                    await setDoc(doc(db, 'User', userID), {
                        email: userEmail,
                        firstLoginDate: new Date(),
                        loginDate: [today]
                    });

                    setUser(userID, {email: userEmail});
                    navigate("/welcome");

                } else {  // not first time trigger above code

                    if (userSnapshot.data().firstLoginDate === undefined) {
                        const userRef = doc(db, 'User', userID);
                        updateDoc(userRef, {
                            firstLoginDate: new Date()
                        });
                    }

                    setUser(userID, userSnapshot.data());


                    if (userSnapshot.data().finishColibrationTestFlag) {
                        navigate("/");    // go to home
                    } else if (userSnapshot.data().finishWelcomeFlag) {
                        navigate("/welcome", {state: {processInit: 8}});
                    } else {
                        navigate("/welcome");
                    }
                }
            }


            // no user found , next loop
        }
        setShowLoginFailedMsg(wrongFlag);
    };

    return <>
        {/*<Menu />*/}

        {/*<div>*/}
        {/*    <Grid container justify="center">*/}
        {/*        <Button color="primary" size="large" type="submit" variant="contained">*/}
        {/*            Sign Up*/}
        {/*        </Button>*/}
        {/*    </Grid>*/}
        {/*</div>*/}
        <div>

            <div className="loginBackgroundImage23333">
                <img src={QualiflyIconForBooth} alt="QualiflyIconForBooth" className="QualiflyIconForBooth"/>

            </div>

            <div className="margin1222define"/>
            <Grid container spacing={3}
                // alignItems="center"
                  justifyContent="center"
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign:"center"}}>

                    <img className="imageshiftright" src={QualiFlylogo_3}/>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign:"center"}}>
                    <div className="joinus">
                        Tell us more about you!
                    </div>

                </Grid>
                <Grid item xs={10} sx={{textAlign:"center"}}>
                    <div >
                        {/*<TextField*/}
                        {/*    style={{*/}
                        {/*        backgroundColor: "blue"*/}
                        {/*    }}*/}
                        {/*    InputProps={{*/}
                        {/*        style: {*/}
                        {/*            color: "red"*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <TextField1
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                            fullWidth
                            // onClick={() => (setFocusSearch(true))}
                            // onBlur={() => (setTimeout(() => {
                            //     setFocusSearch(false)
                            // }, 200))}
                            // className={search == "" ? 'searchTextField' : 'searchSuggestionTextField'}
                            autoComplete='off'
                            placeholder="Name"
                            InputProps={{
                                style: {

                                    fontSize: is_m?"18px":"1.2vw",  // this control font size of placeholder
                                    paddingLeft: "1.2vw",
                                    fontFamily: "Nunito",
                                    fontWeight: '600',
                                    minHeight: "4vw"
                                },
                                inputProps: {
                                    style: { textAlign: "center" },
                                }
                                // endAdornment: (
                                //     <InputAdornment position="end">
                                //         {search == "" ?
                                //             <div className="searchBtnContainer">
                                //                 <img className="searchWhite" src={SearchWhiteSVG} alt="search"/>
                                //             </div>
                                //             :
                                //             <div className="searchSuggestionBtnContainer">
                                //                 <img className="searchWhite" src={SearchYellowSVG} alt="search"/>
                                //             </div>
                                //         }
                                //     </InputAdornment>
                                // )
                            }}
                        />
                    </div>

                </Grid>
                <Grid item xs={10} sx={{textAlign:"center"}}>
                    <div >
                        {/*<TextField*/}
                        {/*    style={{*/}
                        {/*        backgroundColor: "blue"*/}
                        {/*    }}*/}
                        {/*    InputProps={{*/}
                        {/*        style: {*/}
                        {/*            color: "red"*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <TextField1
                            value={search2}
                            onChange={(e) => {
                                setSearch2(e.target.value)
                            }}
                            fullWidth
                            // onClick={() => (setFocusSearch(true))}
                            // onBlur={() => (setTimeout(() => {
                            //     setFocusSearch(false)
                            // }, 200))}
                            // className={search == "" ? 'searchTextField' : 'searchSuggestionTextField'}
                            autoComplete='off'
                            placeholder="Email"
                            InputProps={{
                                style: {

                                    fontSize: is_m?"18px":"1.2vw",  // this control font size of placeholder
                                    paddingLeft: "1.2vw",
                                    fontFamily: "Nunito",
                                    fontWeight: '600',
                                    minHeight: "4vw"
                                },
                                inputProps: {
                                    style: { textAlign: "center" },
                                }
                                // endAdornment: (
                                //     <InputAdornment position="end">
                                //         {search == "" ?
                                //             <div className="searchBtnContainer">
                                //                 <img className="searchWhite" src={SearchWhiteSVG} alt="search"/>
                                //             </div>
                                //             :
                                //             <div className="searchSuggestionBtnContainer">
                                //                 <img className="searchWhite" src={SearchYellowSVG} alt="search"/>
                                //             </div>
                                //         }
                                //     </InputAdornment>
                                // )
                            }}
                        />
                    </div>

                </Grid>
                <Grid item xs={10} sx={{textAlign:"center"}}>
                    <div >
                        {/*<TextField*/}
                        {/*    style={{*/}
                        {/*        backgroundColor: "blue"*/}
                        {/*    }}*/}
                        {/*    InputProps={{*/}
                        {/*        style: {*/}
                        {/*            color: "red"*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <TextField1
                            value={search3}
                            onChange={(e) => {
                                setSearch3(e.target.value)
                            }}
                            fullWidth
                            // onClick={() => (setFocusSearch(true))}
                            // onBlur={() => (setTimeout(() => {
                            //     setFocusSearch(false)
                            // }, 200))}
                            // className={search == "" ? 'searchTextField' : 'searchSuggestionTextField'}
                            autoComplete='off'
                            placeholder="Major"
                            InputProps={{
                                style: {

                                    fontSize: is_m?"18px":"1.2vw",  // this control font size of placeholder
                                    paddingLeft: "1.2vw",
                                    fontFamily: "Nunito",
                                    fontWeight: '600',
                                    minHeight: "4vw"
                                },
                                inputProps: {
                                    style: { textAlign: "center" },
                                }
                                // endAdornment: (
                                //     <InputAdornment position="end">
                                //         {search == "" ?
                                //             <div className="searchBtnContainer">
                                //                 <img className="searchWhite" src={SearchWhiteSVG} alt="search"/>
                                //             </div>
                                //             :
                                //             <div className="searchSuggestionBtnContainer">
                                //                 <img className="searchWhite" src={SearchYellowSVG} alt="search"/>
                                //             </div>
                                //         }
                                //     </InputAdornment>
                                // )
                            }}
                        />
                    </div>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign:"center"}}>
                    <div onClick={async ()=>{



                        const tmp = {
                            search:search,
                            search2:search2,
                            search3:search3,
                            date: new Date(),
                            otherinfo:location.state
                        };
                        await addDoc(collection(db, "Yourinfo5"), tmp);
                        console.log("location",location.state)
                        navigate("/res", {state: location.state})
                    }}>
                    <BoothButton333334></BoothButton333334>
                    </div>
                </Grid>

                {/*<Grid item xs={6} sx={{textAlign:"center"}}>*/}
                {/*    <div onClick={()=>{ window.location.replace('https://www.instagram.com/qualifly_hk/')}}>*/}
                {/*        <img src={Instagramlogo} style={{width:"20vw"}}/>*/}
                {/*        <div className="joinus3">*/}
                {/*            qualifly_hk*/}
                {/*        </div>*/}

                {/*    </div>*/}


                {/*</Grid>*/}
                {/*<Grid item xs={6} sx={{textAlign:"center"}}>*/}
                {/*    <div onClick={()=>{ window.location.replace('https://www.linkedin.com/company/qualiflyhk/?originalSubdomain=hk')}}>*/}
                {/*        <img src={LKlogo} style={{width:"20vw"}}/>*/}
                {/*        <div className="joinus3">*/}
                {/*            QualiFly*/}
                {/*        </div>*/}

                {/*    </div>*/}


                {/*</Grid>*/}

                {/*<Grid item xs={8} sx={{textAlign:"center"}}>*/}
                {/*    <div className="joinus3">*/}
                {/*        A one-stop platform for personalised, convenient and effective revision.*/}
                {/*    </div>*/}

                {/*</Grid>*/}
                {/*<Grid item xs={12} sx={{textAlign:"center"}}>*/}

                {/*    <img src={Group308} style={{width:"80vw"}}/>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={5} sx={{textAlign:"center"}}>*/}
                {/*    <div className="joinus4">*/}
                {/*        Supported by:*/}
                {/*    </div>*/}
                {/*</Grid>*/}

                {/*<Grid item xs={6} sx={{textAlign:"center"}}>*/}
                {/*    <img src={image4} style={{width:"50vw"}}/>*/}
                {/*</Grid>*/}


            </Grid>
            {/*<Box*/}
            {/*    m={1}*/}
            {/*    // Direction="row"*/}
            {/*    display="flex"*/}
            {/*    justifyContent="center"*/}
            {/*    alignItems="center"*/}
            {/*    minHeight="100vh"*/}
            {/*    // sx={{}}*/}
            {/*>*/}

            {/*    <div className="margin10define">*/}

            {/*        <BoothButton333333></BoothButton333333></div>*/}

            {/*    /!*<Button variant="contained" color="primary" sx={{ height: 40 }}>*!/*/}
            {/*    /!*    Primary*!/*/}
            {/*    /!*</Button>*!/*/}
            {/*</Box>*/}
            {/*<div className="responsiveContainer">*/}

            {/*    <Grid container justify="center">*/}

            {/*            <BoothButton333333></BoothButton333333>*/}

            {/*    </Grid>*/}
            {/*    /!*<div className="imgconta" onClick={()=>{}}>*!/*/}
            {/*    /!*    <Box textAlign='center'>*!/*/}
            {/*    /!*        <BoothButton333333></BoothButton333333></Box>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<div className="imgconta" onClick={()=>{}}>*!/*/}
            {/*    /!*    <Box textAlign='center'>*!/*/}
            {/*    /!*        <BoothButton333333></BoothButton333333></Box>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*</div>*/}
        </div>
    </>;
}

export default Login;
