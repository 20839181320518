import React from "react";
import PropTypes from "prop-types";

import '../../App.css';
import './_styles.css';

const LearningVideoEmbed = ({ embedId }) => (
  <div className="learningVideoEmbed">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}?ref=0&rel=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

LearningVideoEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default LearningVideoEmbed;