import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';
import questionBankBannerSVG from '../../assets/exercise/questionBankBanner.svg';
import questionBankBanner2SVG from '../../assets/exercise/questionBankBanner2.svg';
import questionBankPastTenseSVG from '../../assets/exercise/questionBankPastTense.svg';
import questionBankPastTensePresentTenseSVG from '../../assets/exercise/questionBankPastTensePresentTense.svg';
import questionBankPresentContinuousTenseSVG from '../../assets/exercise/questionBankPresentContinuousTense.svg';
import questionBankPresentTensePresentContinuousTenseSVG
    from '../../assets/exercise/questionBankPresentTensePresentContinuousTense.svg';
import questionBankPrepositionSVG from '../../assets/exercise/questionBankPreposition.svg';
import questionBankQuestionWordSVG from '../../assets/exercise/questionBankQuestionWord.svg';


import ggPACT from '../../assets/exercise/questionBankPACT.svg';
import ggPPT from '../../assets/exercise/GGPPT.svg';
import ggBIxTI from '../../assets/exercise/ggBIxTI.svg';
import ggBIxGER from '../../assets/exercise/ggBIxGER.svg';
import ggART from '../../assets/exercise/ggART.svg';
import ggCOM from '../../assets/exercise/ggCOM.svg';
import ggCONJ from '../../assets/exercise/ggCONJ.svg';





import QuestionBankModal from '../../pages/exercise/QuestionBankModal';
import {useTranslation} from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useMediaQuery} from "react-responsive";

function QuestionBank(topicInput) {

    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        speed: 500
    };
    const {t} = useTranslation();

    useEffect(() => {
        if (topicInput.topicInput != "" && topicInput.topicInput != undefined) {
            setQuestionBankModalVisibility(true);
            setActiveTopic(topicInput.topicInput);
        }
    }, [topicInput])

    const [questionBankModalVisibility, setQuestionBankModalVisibility] = useState();
    const [activeTopic, setActiveTopic] = useState();
    const handleChooseQuestionBank = (topic) => {
        setQuestionBankModalVisibility(true);
        setActiveTopic(topic);
    }

    const newtopicstring = ["PACT_1", "PPT_1", "BIxTI_1", "", "BIxGER_1", "ART_1", "COM_1", "CONJ_1"]

    const mapping3= [
        ["PACT_1",ggPACT,"Past Continuous Tense"],
        ["PPT_1",ggPPT,"Past Perfect Tense"],
        ["BIxTI_1",ggBIxTI,"xx"],
        ["BIxGER_1",ggBIxGER,"xx"],
        ["ART_1",ggART,"xx"],
        ["COM_1",ggCOM,"xx"],
        ["CONJ_1",ggCONJ,"xx"],

    ]
    return <>


        <div className="exerciseTitleContainer">
            <p className="todaysPracticesTitle">{t('QUESTION_BANK')}</p>
        </div>

        {!is_m ? <>
            <div className="todaysPracticesBottom"></div>
            {/*<div>*/}

            {/*    {newtopicstring.map((item, i) => {*/}
            {/*        // console.log("wtf")*/}
            {/*        return <div key={i} onClick={() => {*/}
            {/*            handleChooseQuestionBank(item)*/}

            {/*        }}>{item}</div>*/}
            {/*    })}*/}
            {/*</div>*/}

            <img className="questionBankBanner" src={questionBankBannerSVG} alt="question bank banner"/>


            <div className="questionBankBookContainer">
                <div className="questionBankBookContainerInner">
                    <Grid justifyContent="center" container spacing={2}>
                        <Grid align="center" item xs={2}></Grid>
                        <Grid align="center" item xs={2}>
                            <img onClick={() => {
                                handleChooseQuestionBank("pastTense")
                            }} className="questionBankBook" src={questionBankPastTenseSVG} alt="past tense"/>
                        </Grid>
                        <Grid align="center" item xs={2}>
                            <img onClick={() => {
                                handleChooseQuestionBank("pastTensePresentTense")
                            }} className="questionBankBook" src={questionBankPastTensePresentTenseSVG}
                                 alt="past tense"/>
                        </Grid>
                        <Grid align="center" item xs={2}>
                            <img onClick={() => {
                                handleChooseQuestionBank("presentContinuousTense")
                            }} className="questionBankBook" src={questionBankPresentContinuousTenseSVG}
                                 alt="past tense"/>
                        </Grid>
                        <Grid align="center" item xs={2}>
                            <img onClick={() => {
                                handleChooseQuestionBank("presentTensePresentContinuousTense")
                            }} className="questionBankBook" src={questionBankPresentTensePresentContinuousTenseSVG}
                                 alt="past tense"/>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <img className="questionBankBanner" src={questionBankBanner2SVG} alt="question bank banner"/>
            <div className="questionBankBookContainer">
                <div className="questionBankBookContainerInner">
                    <Grid justifyContent="center" container spacing={2}>
                        <Grid align="center" item xs={2}></Grid>
                        <Grid align="center" item xs={2}>
                            <img onClick={() => {
                                handleChooseQuestionBank("preposition")
                            }} className="questionBankBook" src={questionBankPrepositionSVG} alt="past tense"/>
                        </Grid>
                        <Grid align="center" item xs={2}>
                            <img onClick={() => {
                                handleChooseQuestionBank("questionWord")
                            }} className="questionBankBook" src={questionBankQuestionWordSVG}
                                 alt="past tense"/>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <img className="questionBankBanner" src={questionBankBanner2SVG} alt="question bank banner"/>
            <div className="questionBankBookContainer">
                <div className="questionBankBookContainerInner">
                    <Grid justifyContent="center" container spacing={2}>
                        {mapping3.slice(0,4).map((item, i) => {
                            // console.log("wtf")
                            return <Grid align="center" item xs={2} >
                                <img
                                    key={i} onClick={() => {
                                    handleChooseQuestionBank(item[0])

                                }}
                                    className="questionBankBook" src={item[1]} alt={item[2]}

                                />



                            </Grid>
                        })}

                        {/*<Grid align="center" item xs={2}></Grid>*/}
                        {/*<Grid align="center" item xs={2}>*/}
                        {/*    <img onClick={() => {*/}
                        {/*        handleChooseQuestionBank("preposition")*/}
                        {/*    }} className="questionBankBook" src={ggPACT} alt="past tense"/>*/}
                        {/*</Grid>*/}
                        {/*<Grid align="center" item xs={2}>*/}
                        {/*    <img onClick={() => {*/}
                        {/*        handleChooseQuestionBank("questionWord")*/}
                        {/*    }} className="questionBankBook" src={questionBankQuestionWordSVG}*/}
                        {/*         alt="past tense"/>*/}
                        {/*</Grid>*/}
                    </Grid>
                </div>
            </div>
            <img className="questionBankBanner" src={questionBankBanner2SVG} alt="question bank banner"/>
            <div className="questionBankBookContainer">
                <div className="questionBankBookContainerInner">
                    <Grid justifyContent="center" container spacing={2}>
                        {mapping3.slice(4,7).map((item, i) => {
                            // console.log("wtf")
                            return <Grid align="center" item xs={2} >
                                <img
                                    key={i} onClick={() => {
                                    handleChooseQuestionBank(item[0])

                                }}
                                    className="questionBankBook" src={item[1]} alt={item[2]}

                                />



                            </Grid>
                        })}

                        {/*<Grid align="center" item xs={2}></Grid>*/}
                        {/*<Grid align="center" item xs={2}>*/}
                        {/*    <img onClick={() => {*/}
                        {/*        handleChooseQuestionBank("preposition")*/}
                        {/*    }} className="questionBankBook" src={ggPACT} alt="past tense"/>*/}
                        {/*</Grid>*/}
                        {/*<Grid align="center" item xs={2}>*/}
                        {/*    <img onClick={() => {*/}
                        {/*        handleChooseQuestionBank("questionWord")*/}
                        {/*    }} className="questionBankBook" src={questionBankQuestionWordSVG}*/}
                        {/*         alt="past tense"/>*/}
                        {/*</Grid>*/}
                    </Grid>
                </div>
            </div>

        </> : <>
            <div>

                <Slider {...settings}>
                    <div>
                        <img onClick={() => {
                            handleChooseQuestionBank("pastTense")
                        }} className="questionBankBook" src={questionBankPastTenseSVG} alt="past tense"/>
                    </div>
                    <div>
                        <img onClick={() => {
                            handleChooseQuestionBank("pastTensePresentTense")
                        }} className="questionBankBook" src={questionBankPastTensePresentTenseSVG}
                             alt="past tense"/>
                    </div>
                    <div>
                        <img onClick={() => {
                            handleChooseQuestionBank("presentContinuousTense")
                        }} className="questionBankBook" src={questionBankPresentContinuousTenseSVG}
                             alt="past tense"/>
                    </div>
                    <div>
                        <img onClick={() => {
                            handleChooseQuestionBank("presentTensePresentContinuousTense")
                        }} className="questionBankBook" src={questionBankPresentTensePresentContinuousTenseSVG}
                             alt="past tense"/>
                    </div>
                    <div>
                        <img onClick={() => {
                            handleChooseQuestionBank("preposition")
                        }} className="questionBankBook" src={questionBankPrepositionSVG} alt="past tense"/>
                    </div>
                    <div>
                        <img onClick={() => {
                            handleChooseQuestionBank("presentTensePresentContinuousTense")
                        }} className="questionBankBook" src={questionBankPresentTensePresentContinuousTenseSVG}
                             alt="past tense"/>
                    </div>
                    {mapping3.map((item, i) => {
                        // console.log("wtf")
                        return <div>
                            <img
                                key={i} onClick={() => {
                                handleChooseQuestionBank(item[0])

                            }}
                                className="questionBankBook" src={item[1]} alt={item[2]}

                            />



                        </div>
                    })}

                </Slider>
            </div>
        </>}


        <QuestionBankModal questionBankModalVisibility={questionBankModalVisibility}
                           setQuestionBankModalVisibility={setQuestionBankModalVisibility}
                           activeTopic={activeTopic}
        />


    </>;
}

export default QuestionBank;
