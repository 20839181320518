import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import { LoginTextField, LoginTextFieldSingleDigit, SendButton } from './_components';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import '../../App.css';
import './_styles.css';
import closeSVG from '../../assets/general/close.svg';
import tickSVG from '../../assets/login/tick.svg';

function ForgotPassword({
    forgotPasswordModalVisibility,
    setForgotPasswordModalVisibility
}) {
    let navigate = useNavigate();
    const [process, setProcess] = useState("inputEmail");
    const verificationCode = [null, null, null, null, null];

    // to send password reset code email
    const auth = getAuth();
    const sendPasswordResetEmailBtn = (email) => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setProcess("inputVerificationCode");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <Modal
                isOpen={forgotPasswordModalVisibility}
                className="forgotPasswordModalContainer"
                ariaHideApp={false}
                style={{ overlay: {backgroundColor:'rgba(0, 0, 0, 0.3)', backdropFilter: "blur(6px)", WebkitBackdropFilter: "blur(6px)", zIndex: 1000000 }}}
            >
                <div className="forgotPasswordBackgroundImage">
                    <img onClick={() => {
                        setForgotPasswordModalVisibility(false);
                        setProcess("inputEmail")
                    }} className="forgotPasswordClose" src={closeSVG} alt="close" />
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <p className="forgotPasswordText">Forgot password?</p>
                            <div className="forgotPasswordInputContainer">
                                {process === "inputEmail" ?
                                    <>
                                        <p className="forgotPasswordInputLabel1">Please enter your student email</p>
                                        <LoginTextField
                                            className="forgotPasswordInput"
                                            InputProps={{
                                                style: {
                                                    fontSize: 18,
                                                    paddingLeft: 16,
                                                    fontFamily: "Nunito",
                                                    fontWeight: '600'
                                                }
                                            }}
                                        />
                                        <p className="forgotPasswordInputLabel2">Forgot your student email? Please <span
                                            onClick={() => {
                                                navigate('/contactUs')
                                            }} className="forgotPasswordContactUs">Contact Us</span></p>
                                        <SendButton onClick={() => {
                                            sendPasswordResetEmailBtn("");
                                        }} variant="contained" className="sendButton">Submit</SendButton>
                                    </>
                                    : process === "inputVerificationCode" ?
                                        <>
                                            <p className="forgotPasswordInputLabel1">Please enter the verification
                                                code.</p>
                                            <div style={{ flexDiection: 'row' }}>
                                                {verificationCode.map((_, i) => (
                                                    <LoginTextFieldSingleDigit key={i} inputProps={{
                                                        style: {
                                                            fontSize: '2.6vw',
                                                            textAlign: 'center',
                                                            marginLeft: "-1.8vw"
                                                        }
                                                    }} className="forgotPasswordInputSingleDigit" />
                                                ))}
                                            </div>
                                            <p className="forgotPasswordInputLabel2">Havn't received the verification
                                                email yet? <span onClick={() => {
                                                    setProcess("inputNewPassword")
                                                }} className="forgotPasswordContactUs">Send again</span></p>
                                        </>
                                        : process === "inputNewPassword" ?
                                            <>
                                                <div className="margin20"></div>
                                                <LoginTextField placeholder="New Password"
                                                    className="forgotPasswordInput" />
                                                <div className="margin10"></div>
                                                <LoginTextField placeholder="Confirm New Password"
                                                    className="forgotPasswordInput" />
                                                <div className="margin20"></div>
                                                <SendButton onClick={() => {
                                                    setProcess("successRenew")
                                                }} variant="contained" className="sendButton">Submit</SendButton>
                                            </>
                                            : process === "successRenew" &&
                                            <>
                                                <img className="forgotPasswordTick" src={tickSVG} alt="tick" />
                                                <p className="forgotPasswordSuccessText">Password has been reset!</p>
                                                <SendButton onClick={() => {
                                                    setForgotPasswordModalVisibility(false);
                                                    setProcess("inputEmail")
                                                }} variant="contained" className="sendButton">Back to home</SendButton>
                                            </>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </>
    );
}

export default ForgotPassword;
