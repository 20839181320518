import Button from '@mui/material/Button';
import {makeStyles} from '@mui/styles';
import arrowWhiteSVG from '../../assets/exercise/arrowWhite.svg';
import { getLanguage } from '../../localStorage/Language';
import { useTranslation } from 'react-i18next';
import '../../translation'
import {styled} from "@mui/material/styles";
import {useMediaQuery} from "react-responsive";

// export const ConfirmButton = () => {
//     const { t } = useTranslation();
//     const useStyles = makeStyles({
//         button: {
//             backgroundColor: '#FFE351',
//             color: 'black',
//             width: '11vw',
//             borderRadius: '0.8vw',
//             fontSize: '1.2vw',
//             fontFamily: 'Poppins',
//             fontWeight: '500',
//             boxShadow: '0px 3px 2px #aaa',
//             textTransform: 'none',
//             padding: "0.4vw",
//             '&:hover': {
//                 backgroundColor: '#FFE351',
//                 color: 'black',
//             },
//         }
//     })
//     const classes = useStyles()
//     return (
//         <Button disableRipple className={classes.button}>
//             {t("CONFIRM")}
//         </Button>
//     );
// };
const ConfirmButton28 = styled(Button)(({theme})=>({

    backgroundColor: '#FFE351',
    color: 'black',
    width: '11vw',
    borderRadius: '0.8vw',
    fontSize: '1.2vw',
    fontFamily: 'Poppins',
    fontWeight: '500',
    boxShadow: '0px 3px 2px #aaa',
    textTransform: 'none',
    padding: "0.4vw",
    '&:hover': {
        backgroundColor: '#FFE351',
        color: 'black',
    },

    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFE351',
        color: 'black',
        width: '25vw',
        borderRadius: '0.8vw',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow: '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "0.4vw",
        '&:hover': {
            backgroundColor: '#FFE351',
            color: 'black',
        },

    }

}))
export const ConfirmButton = () => {
    const { t } = useTranslation();

    return (
        <ConfirmButton28 disableRipple>
            {t("CONFIRM")}
        </ConfirmButton28>
    );
};
export const ConfirmButton2 = () => {
    const { t } = useTranslation();

    return (
        <ConfirmButton28 disableRipple>
            Next
        </ConfirmButton28>
    );
};

// export const ResumeButton = () => {
//     const l = getLanguage();
//     const { t } = useTranslation();
//     const useStyles = makeStyles({
//         button: {
//             backgroundColor: '#FFE351',
//             color: 'black',
//             width: '13vw',
//             borderRadius: '0.8vw',
//             fontSize: '1.1vw',
//             fontFamily: 'Poppins',
//             fontWeight: '500',
//             boxShadow: '0px 3px 2px #aaa',
//             textTransform: 'none',
//             padding: "0.4vw",
//             '&:hover': {
//                 backgroundColor: '#FFE351',
//                 color: 'black',
//             },
//         }
//     })
//     const classes = useStyles()
//     return (
//         <Button disableRipple className={classes.button}>
//             {t("RESUME")}
//         </Button>
//     );
// };
const ResumeButton28 = styled(Button)(({theme})=>({

    backgroundColor: '#FFE351',
    color: 'black',
    width: '13vw',
    borderRadius: '0.8vw',
    fontSize: '1.1vw',
    fontFamily: 'Poppins',
    fontWeight: '500',
    boxShadow: '0px 3px 2px #aaa',
    textTransform: 'none',
    padding: "0.4vw",
    '&:hover': {
        backgroundColor: '#FFE351',
        color: 'black',
    },

    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFE351',
        color: 'black',
        width: '30vw',
        borderRadius: '0.8vw',

        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow: '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "0.4vw",
        '&:hover': {
            backgroundColor: '#FFE351',
            color: 'black',
        },

    }

}))

export const ResumeButton = () => {
    const l = getLanguage();
    const { t } = useTranslation();
    return (
        <ResumeButton28 disableRipple>
            {t("RESUME")}
        </ResumeButton28>
    );
};


export const ViewMyResultsButton = () => {
    const { t } = useTranslation();
    const useStyles = makeStyles({
        button: {
            backgroundColor: '#FFE351',
            color: 'black',
            width: '18vw',
            borderRadius: '0.8vw',
            fontSize: '1.1vw',
            fontFamily: 'Poppins',
            fontWeight: '500',
            boxShadow: '0px 3px 2px #aaa',
            textTransform: 'none',
            padding: "0.4vw",
            '&:hover': {
                backgroundColor: '#FFE351',
                color: 'black',
            },
        }
    })
    const classes = useStyles()
    return (
        <Button disableRipple className={classes.button}>
            {t("VIEW_MY_RESULTS")}
        </Button>
    );
};


const ResumeButton281 = styled(Button)(({theme})=>({

    backgroundColor: '#4BC4EF',
    color: 'white',
    width: '18vw',
    borderRadius: '10000px',
    fontSize: '1.1vw',
    fontFamily: 'Nunito',
    fontWeight: '800',
    textTransform: 'none',
    padding: "0.4vw",
    float: 'right',
    marginTop: '-4vw',
    '&:hover': {
        backgroundColor: '#4BC4EF',
        color: 'white',
    },

    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#4BC4EF',
        color: 'white',
        width: 'auto',
        borderRadius: '0px',
        fontSize: '16px',
        fontFamily: 'Nunito',
        fontWeight: '800',
        textTransform: 'none',
        padding: "0.4vw",
        float: 'right',
        marginTop: '-4vw',
        '&:hover': {
            backgroundColor: '#4BC4EF',
            color: 'white',
        },
    }

}))


export const ViewResultSummaryBtn = () => {
    const { t } = useTranslation();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })

    // const useStyles = makeStyles({
    //     button: {
    //         backgroundColor: '#4BC4EF',
    //         color: 'white',
    //         width: '18vw',
    //         borderRadius: '10000px',
    //         fontSize: '1.1vw',
    //         fontFamily: 'Nunito',
    //         fontWeight: '800',
    //         textTransform: 'none',
    //         padding: "0.4vw",
    //         float: 'right',
    //         marginTop: '-4vw',
    //         '&:hover': {
    //             backgroundColor: '#4BC4EF',
    //             color: 'white',
    //         },
    //     }
    // })
    // const classes = useStyles()
    return (
        <ResumeButton281 disableRipple >
            {t("VIEW_RESULT_SUMMARY")}


            {is_m?     <img
                style={{
                    // width: '0.7vw',
                    // marginLeft: '0.6vw'
                }} src={arrowWhiteSVG} alt="arrow"/>:     <img
                style={{
                    width: '0.7vw',
                    marginLeft: '0.6vw'
                }} src={arrowWhiteSVG} alt="arrow"/>}

        </ResumeButton281>
    );
};


const ResumeButton2811 = styled(Button)(({theme})=>({

    backgroundColor: '#FFE351',
    color: 'black',
    width: '11vw',
    borderRadius: '0.8vw',
    fontSize: '1.2vw',
    fontFamily: 'Poppins',
    fontWeight: '500',
    boxShadow: '0px 3px 2px #aaa',
    textTransform: 'none',
    padding: "0.4vw",
    '&:hover': {
        backgroundColor: '#FFE351',
        color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFE351',
        color: 'black',
        width: '11vw',
        borderRadius: '0.8vw',
        fontSize: '18px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow: '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "0.4vw",
        '&:hover': {
            backgroundColor: '#FFE351',
            color: 'black',
        },
    }

}))


export const NextButton = () => {

    return (
        <ResumeButton2811 disableRipple>
            Next
        </ResumeButton2811>
    );
};



const ResumeButton28111 = styled(Button)(({theme})=>({

    backgroundColor: '#FFE351',
    color: 'black',
    width: '11vw',
    borderRadius: '0.8vw',
    fontSize: '1.2vw',
    fontFamily: 'Poppins',
    fontWeight: '500',
    boxShadow: '0px 3px 2px #aaa',
    textTransform: 'none',
    padding: "0.4vw",
    '&:hover': {
        backgroundColor: '#FFE351',
        color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFE351',
        color: 'black',
        width: '11vw',
        borderRadius: '0.8vw',
        fontSize: '18px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow: '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "0.4vw",
        '&:hover': {
            backgroundColor: '#FFE351',
            color: 'black',
        },
    }

}))
export const GotItButton = () => {

    return (
        <ResumeButton28111 disableRipple>
            Got it
        </ResumeButton28111>
    );
};

// export const LogoutNoButton = () => {
//     const { t } = useTranslation();
//     const useStyles = makeStyles({
//         button: {
//             backgroundColor: '#FFE351',
//             color: 'black',
//             minWidth: '18vw',
//             borderRadius: '0.8vw',
//             fontSize: '1.1vw',
//             fontFamily: 'Poppins',
//             fontWeight: '500',
//             boxShadow: '0px 3px 2px #aaa',
//             textTransform: 'none',
//             padding: "0.4vw",
//             '&:hover': {
//                 backgroundColor: '#FFE351',
//                 color: 'black',
//             },
//         }
//     })
//     const classes = useStyles()
//     return (
//         <Button disableRipple className={classes.button}>
//             {t("CONFIRM_LEAVE_NO")}
//         </Button>
//     );
// };
// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const LogoutNoButton28 = styled(Button)(({theme})=>({

    backgroundColor: '#FFE351',
    color: 'black',
    minWidth: '18vw',
    borderRadius: '0.8vw',
    fontSize: '1.1vw',
    fontFamily: 'Poppins',
    fontWeight: '500',
    boxShadow: '0px 3px 2px #aaa',
    textTransform: 'none',
    padding: "0.4vw",
    '&:hover': {
        backgroundColor: '#FFE351',
        color: 'black',
    },

    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFE351',
        color: 'black',
        minWidth: '18vw',
        width: '30vw',
        borderRadius: '0.8vw',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow: '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "0.4vw",
        '&:hover': {
            backgroundColor: '#FFE351',
            color: 'black',
        },


    }

}))
export const LogoutNoButton = () => {
    const { t } = useTranslation();

    return (
        <LogoutNoButton28 disableRipple>
            {t("CONFIRM_LEAVE_NO")}
        </LogoutNoButton28>
    );
};



//
// export const LogoutYesButton = () => {
//     const { t } = useTranslation();
//     const useStyles = makeStyles({
//         button: {
//             backgroundColor: '#AEF4F4',
//             color: 'black',
//             minWidth: '18vw',
//             borderRadius: '0.8vw',
//             fontSize: '1.1vw',
//             fontFamily: 'Poppins',
//             fontWeight: '500',
//             boxShadow: '0px 3px 2px #aaa',
//             textTransform: 'none',
//             padding: "0.4vw",
//             '&:hover': {
//                 backgroundColor: '#AEF4F4',
//                 color: 'black',
//             },
//         }
//     })
//     const classes = useStyles()
//     return (
//         <Button disableRipple className={classes.button}>
//             {t("CONFIRM_LEAVE_YES")}
//         </Button>
//     );
// };

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const LogoutYesButton28 = styled(Button)(({theme})=>({

    backgroundColor: '#AEF4F4',
    color: 'black',
    minWidth: '18vw',
    borderRadius: '0.8vw',
    fontSize: '1.1vw',
    fontFamily: 'Poppins',
    fontWeight: '500',
    boxShadow: '0px 3px 2px #aaa',
    textTransform: 'none',
    padding: "0.4vw",
    '&:hover': {
        backgroundColor: '#AEF4F4',
        color: 'black',
    },

    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#AEF4F4',
        color: 'black',
        minWidth: '18vw',
        width: '30vw',
        borderRadius: '0.8vw',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow: '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "0.4vw",
        '&:hover': {
            backgroundColor: '#AEF4F4',
            color: 'black',
        },


    }

}))

export const LogoutYesButton = () => {
    const { t } = useTranslation();

    return (
        <LogoutYesButton28 disableRipple>
            {t("CONFIRM_LEAVE_YES")}
        </LogoutYesButton28>
    );
};

export const BackToHomeButton = () => {

    return (
        <LogoutNoButton28 disableRipple>
            Back to Home
        </LogoutNoButton28>
    );
};


const LogoutNoButton281 = styled(Button)(({theme})=>({

    backgroundColor: '#FFE351',
    color: 'black',
    width: '18vw',
    borderRadius: '0.8vw',
    fontSize: '1.1vw',
    fontFamily: 'Poppins',
    fontWeight: '500',
    boxShadow: '0px 2px 1px #aaa',
    textTransform: 'none',
    padding: "0.4vw",
    '&:hover': {
        backgroundColor: '#FFE351',
        color: 'black',
    },

    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFE351',
        color: 'black',
        width:   'auto',
        borderRadius: '0.8vw',
        fontSize: '15px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow: '0px 2px 1px #aaa',
        textTransform: 'none',
        padding: "0px 10px",
        '&:hover': {
            backgroundColor: '#FFE351',
            color: 'black',
        },


    }

}))

export const SimilarQuestionButton = () => {
    const { t } = useTranslation();
    return (
        // <Button disableRipple className={classes.button}>
        //     {t("PRACTISE_SIMILAR_QUESTION")}
        // </Button>

    <LogoutNoButton281>
        {t("PRACTISE_SIMILAR_QUESTION")}
    </LogoutNoButton281>
    );
};
