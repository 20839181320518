import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase";
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import '../../App.css';
import './_styles.css';
import '../../translation'
import Menu from '../../components/menu/Menu';
import ForgotPassword from './ForgotPassword';
import { LoginButton, LoginFailedTextField, LoginTextField } from './_components';
import { getUser, setUser } from '../../localStorage/User';
import { redirect } from "../../functions/redirect"
import { useMediaQuery } from 'react-responsive'
import {Box} from "@mui/material";
function Login({ rememberMeFlag, setRememberMeFlag }) {
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const user = getUser();
    let navigate = useNavigate();
    useEffect(() => {
        redirect(user, navigate);
        // eslint-disable-next-line
    }, [])

    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [forgotPasswordModalVisibility, setForgotPasswordModalVisibility] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showLoginFailedMsg, setShowLoginFailedMsg] = useState(false);

    const logInWithEmailAndPassword = async () => {
        let userID, userEmail;
        const emailToDB = [password + "@kfps4a.com", password + "@kfps4b.com", password+`@${email}.com`];
        const passwordToDB = email;

        console.log("loggin in with", emailToDB[2],passwordToDB)
        let wrongFlag = false;
        for(let i=0; i<3; i++){
            try {
                await signInWithEmailAndPassword(auth, emailToDB[i], passwordToDB)
                    .then((data) => {
                        userID = data.user.uid;
                        userEmail = data.user.email;
                        setEmail("");
                        setPassword("");
                    });
            } catch (err) {
                wrongFlag = true;
            }



            if (userID) {
                const userSnapshot = await getDoc(doc(db, 'User', userID));
                let today = new Date();
                today.setHours(0);
                today.setMinutes(0);
                today.setSeconds(0);
                if (!userSnapshot.exists()) { // to handle first login


                    await setDoc(doc(db, 'User', userID), {
                        email: userEmail,
                        firstLoginDate: new Date(),
                        loginDate: [today]
                    });

                    setUser(userID, { email: userEmail });
                    navigate("/welcome");

                } else {  // not first time trigger above code

                    if (userSnapshot.data().firstLoginDate === undefined) {
                        const userRef = doc(db, 'User', userID);
                        updateDoc(userRef, {
                            firstLoginDate: new Date()
                        });
                    }

                    setUser(userID, userSnapshot.data());


                    if (userSnapshot.data().finishColibrationTestFlag) {
                        navigate("/");    // go to home
                    } else if (userSnapshot.data().finishWelcomeFlag) {  
                        navigate("/welcome", { state: { processInit: 8 } });
                    } else {
                        navigate("/welcome");
                    }
                }
            }


            // no user found , next loop
        }
        setShowLoginFailedMsg(wrongFlag);
    };

    return <>
        <Menu />
        <ForgotPassword
            forgotPasswordModalVisibility={forgotPasswordModalVisibility}
            setForgotPasswordModalVisibility={setForgotPasswordModalVisibility}
        />
        <div className="loginBackgroundImage">
            <div className="responsiveContainer">
                <Grid container spacing={is_m? 0:2}>
                    <Grid item xs={is_m? 12:5}>

                        {is_m&&<><div className="margin100"></div><div className="margin100"></div><div className="margin100"></div><div className="margin100"></div></> }

                        {!is_m&&<p className="welcomeBack">{t('WELCOME')}</p>}
                        <p className="loginInputLabel">{t('USERNAME')}</p>
                        {!showLoginFailedMsg ?
                            <LoginTextField autoComplete='off' value={email} onChange={(e) => {
                                setEmail(e.target.value)
                            }} fullWidth className='loginTextField'
                                placeholder="danielwong"
                                InputProps={{
                                    style: {
                                        fontSize: 18,
                                        paddingLeft: 16,
                                        fontFamily: "Nunito",
                                        fontWeight: '600'
                                    }
                                }}
                            />
                            :
                            <LoginFailedTextField autoComplete='off' value={email} onChange={(e) => {
                                setEmail(e.target.value)
                            }} fullWidth className='loginTextField'
                                placeholder="daniel123@gmail.com"
                                InputProps={{
                                    style: {
                                        fontSize: 18,
                                        paddingLeft: 16,
                                        fontFamily: "Nunito",
                                        fontWeight: '600'
                                    }
                                }}
                            />
                        }
                        <div className="margin10"></div>
                        <p className="loginInputLabel">{t('PASSWORD')}</p>
                        {!showLoginFailedMsg ?
                            <LoginTextField autoComplete='off' value={password} onChange={(e) => {
                                setPassword(e.target.value)
                            }} fullWidth type={showPassword ? "text" : "password"} className='loginTextField'
                                placeholder="• • • • • •"
                                InputProps={{
                                    style: {
                                        fontSize: 18,
                                        paddingLeft: 16,
                                        fontFamily: "Nunito",
                                        fontWeight: '600'
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setShowPassword(!showPassword)
                                                }}
                                                size="large">
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            :
                            <LoginFailedTextField autoComplete='off' value={password} onChange={(e) => {
                                setPassword(e.target.value)
                            }} fullWidth type={showPassword ? "text" : "password"} className='loginTextField'
                                placeholder="• • • • • •"
                                InputProps={{
                                    style: {
                                        fontSize: 18,
                                        paddingLeft: 16,
                                        fontFamily: "Nunito",
                                        fontWeight: '600'
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    setShowPassword(!showPassword)
                                                }}
                                                size="large">
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        }
                        <p className="loginFailedMsg"
                            style={{ display: showLoginFailedMsg ? 'inline-block' : 'none' }}>{t('INCORRECT_USERNAME_OR_PASSWORD')}</p>
                        <div className="rememberMe">
                            <RadioGroup value={rememberMeFlag} className="rememberMeRadio">
                                <FormControlLabel value={true}
                                    onClick={() => { setRememberMeFlag(!rememberMeFlag) }}
                                    control={<Radio />}
                                    label=""
                                />
                            </RadioGroup>
                            <p className="rememberMeLabel">{t('REMEMBER_ME')}</p>
                            {/* <p onClick={() => {setForgotPasswordModalVisibility(true)}} className="forgotPassword">Forgot password?</p> */}
                        </div>
                        {is_m?<Box textAlign='center'><LoginButton style={{ borderRadius: 100  }} size="large" onClick={() => {
                            logInWithEmailAndPassword()
                        }} variant="contained">{t('LOGIN')}</LoginButton> </Box>  :<LoginButton style={{ borderRadius: 50, margin:0, display: "block" }} onClick={() => {
                            logInWithEmailAndPassword()
                        }} variant="contained">{t('LOGIN')}</LoginButton> }

                    </Grid>
                </Grid>
            </div>
        </div>
    </>;
}

export default Login;
