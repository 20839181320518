import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {db} from "../../firebase";
import {addDoc, collection, doc, getDocs, query, updateDoc, where, orderBy, getDoc} from 'firebase/firestore';
import {useStopwatch} from 'react-timer-hook';

import '../../App.css';
import './_styles.css';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import Progress from '../../pages/exercise/Progress';
import MultipleChoice from '../../pages/exercise/MultipleChoice';
import FillInTheBlank from '../../pages/exercise/FillInTheBlank';
import FillInMultipleBlank from '../../pages/exercise/FillInMultipleBlank';

import arrowLeftSVG from '../../assets/exercise/arrowLeft.svg';
import arrowRightSVG from '../../assets/exercise/arrowRight.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import {getUser} from '../../localStorage/User';

import Pause from '../../pages/exercise/Pause';
import FinishExercise from '../../pages/exercise/FinishExercise';
import VideoSuggestion from '../../pages/video/VideoSuggestion';
import {useMediaQuery} from "react-responsive";
import {NUMB} from '../../firebase';
import {BackButton2333, BoothButton333331, BoothButton333333, NextButton2333} from "../login/Logout";

function Practice() {

    const {
        seconds,
        minutes,
        hours,
        start,
        pause,
    } = useStopwatch({autoStart: true});
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })


    //timer
    const [countUpTime, setCountUpTime] = useState(0);

    //
    const [saveDraftFlag, setSaveDraftFlag] = useState(false);

    // random
    const [correctRandomIndex, setCorrectRandomIndex] = useState(1);

    // random
    const [wrongRandomIndex, setWrongRandomIndex] = useState(1);

    useEffect(() => {
        setCorrectRandomIndex(Math.floor(Math.random() * 2) + 1);
        setWrongRandomIndex(Math.floor(Math.random() * 3) + 1);
    }, []);

    useEffect(() => {
        let initialTime = location.state.exercise ?
            location.state.exercise.countUpTime :
            0
        setCountUpTime(initialTime + hours * 3600 + minutes * 60 + seconds);
    }, [seconds])


    // pause
    const [pauseModalVisibility, setPauseModalVisibility] = useState(false);

    const user = getUser();
    let navigate = useNavigate();
    useEffect(() => {
        (user.id === "" || user.id === null || user.id === undefined) && navigate("/login"); // to redirect to home page if user has logged-in
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getExercise();
    }, [])

    const location = useLocation();

    const exerciseID = location.state.exerciseID;
    // console.log("exerciseID: ", exerciseID);

    // dont load from database, but pass from other apge
    const [exerciseDetail, setExerciseDetail] = useState();
    // console.log("exerciseDetail: ", exerciseDetail);


    // array of type of each question
    const [questionType, setQuestionType] = useState();

    // seems no use
    const [questionAnswer, setQuestionAnswer] = useState();


    // seems no use
    const [questionTopic, setQuestionTopic] = useState();

    // for record answer, array?
    const [userAnswer, setUserAnswer] = useState();

    //
    const [remark, setRemark] = useState();

    //
    const [progress, setProgress] = useState(-1); /// the question index that user confirmed answer

    //
    const [endFlag, setEndFlag] = useState(false);

    // to determine if question has been confirmed by user
    const [answeredFlagArray, setAnsweredFlagArray] = useState();

    //
    const [correctAnsweredFlagArray, setCorrectAnsweredFlagArray] = useState();
    // console.log("flag arr", correctAnsweredFlagArray)

    //
    const [timeSpentArray, setTimeSpentArray] = useState();
    // console.log("time arr", timeSpentArray)

    useEffect(() => {
        getUserInfo();
    }, [])

    // to get user information
    const [userInfo, setUserInfo] = useState();
    const getUserInfo = async () => {
        if (user.id !== "" && user.id !== null && user.id !== undefined) { // if localstorage have user id
            const userSnapshot = await getDoc(doc(db, 'User', user.id)); // get user info from firestore
            setUserInfo(userSnapshot.data()); // set user info to state
        }
    }

    // to add question id into user's answered question
    const updateSubmitedQuestion = async (questionID) => {
        // console.log("questionID", questionID)
        // console.log()
        let userInfoLocal;
        if (user.id !== "" && user.id !== null && user.id !== undefined) { // if localstorage have user id
            const userSnapshot = await getDoc(doc(db, 'User', user.id)); // get user info from firestore
            userInfoLocal = userSnapshot.data(); // set user info to state
        }
        if (userInfoLocal.submitedQuestion === undefined ||
            userInfoLocal.submitedQuestion.length === 0) { // if user has no submited question
            const userRef = doc(db, 'User', user.id); // get user reference
            updateDoc(userRef, {submitedQuestion: [questionID]}); // add question id into user's submited question
        } else { // if user has submited question before
            const userRef = doc(db, 'User', user.id);
            const tmp = userInfoLocal.submitedQuestion;
            if (!tmp.includes(questionID)) {
                updateDoc(userRef, {submitedQuestion: tmp.concat([questionID])});
            }
        }
    }


    const getExercise = async () => {

        setExerciseDetail(location.state.exercise);
        setCurrectQuestionID(location.state.exercise.question[0]); // initial the first question

        const arrLength = location.state.exercise.question.length; // number of question
        // to initalise the answer and remark array


        location.state.exercise.progress !== undefined // if some progress has been saved
        && setProgress(location.state.exercise.progress);

        location.state.exercise.answer !== undefined ?
            setUserAnswer(location.state.exercise.answer) :
            setUserAnswer(Array.from({length: arrLength}).fill(""));

        location.state.exercise.remark !== undefined ?
            setRemark(location.state.exercise.remark) :
            setRemark(Array.from({length: arrLength}).fill({
                bookmark: false,
                dontKnow: false,
            }));

        setAnsweredFlagArray(Array.from({length: arrLength}).fill(false));

        if (location.state.exercise.correctAnsweredFlagArray === undefined) {
            setCorrectAnsweredFlagArray(Array.from({length: arrLength}).fill("notAnswered"));
        } else {
            setCorrectAnsweredFlagArray(location.state.exercise.correctAnsweredFlagArray);
        }

        if (location.state.exercise.correctAnsweredFlagArray === undefined) {
            setTimeSpentArray(Array.from({length: arrLength}).fill("notAnswered"));
        } else {
            setTimeSpentArray(location.state.exercise.timeSpentArray);
        }

        let tmpQuestion = location.state.exercise.question;

        // to get the question type of the question list
        let tmpArray = [];
        let tmpArrayAnswer = [];
        let tmpArrayTopic = [];
        for (let i = 0; i < tmpQuestion.length; i++) {
            const docRef = doc(db, 'Question', tmpQuestion[i]);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                tmpArray.push(docSnap.data().type);
                tmpArrayAnswer.push(docSnap.data().answer)
                tmpArrayTopic.push(docSnap.data().topic)
            }
        }
        setQuestionType(tmpArray);
        setQuestionAnswer(tmpArrayAnswer);
        setQuestionTopic(tmpArrayTopic);
    }

    const [mode, setMode] = useState("question");
    const [continuousCorrectCount, setContinuousCorrectCount] = useState(0);
    const [continuousWrongCount, setContinuousWrongCount] = useState(0);
    const [lastQuestionTime, setLastQuestionTime] = useState(location.state.exercise && location.state.exercise.lastQuestionTime ? location.state.exercise.lastQuestionTime : 0);

    // to handle answer submission
    const handleSubmitAnswer = async (type, correctFlag) => {

        if (type == "saveDraft") {
            setSaveDraftFlag(true);
        }

        // to count the continuous correct or wrong count
        if (correctFlag) {
            setContinuousCorrectCount(continuousCorrectCount + 1);
            setContinuousWrongCount(0)
        } else {
            setContinuousCorrectCount(0);
            setContinuousWrongCount(continuousWrongCount + 1)
        }

        // to pause the timer if all questions have been answered
        if (!correctAnsweredFlagArray.includes("notAnswered")) {
            setEndFlag(true);
            pause();
        }

        // to check if all questions have answered by user
        const userRef = doc(db, 'Exercise', exerciseID);
        updateDoc(userRef, {
            answer: userAnswer,
            remark: remark,
            countUpTime: countUpTime,
            progress: type === "saveDraft" ? progress : progress + 1,
            correctAnsweredFlagArray: correctAnsweredFlagArray,
        });
        // to add the wrong question if user answer incorrectly in Today's Practice
        if (exerciseDetail.type !== "answerReviewExercise" && exerciseDetail.type !== "calibrationTest" && !correctFlag) {
            if (user.id !== "" && user.id !== null && user.id !== undefined) {
                const userSnapshot = await getDoc(doc(db, 'User', user.id));
                let tmpWrongQuestion = []
                if (userSnapshot.data().wrongQuestion !== undefined) {
                    tmpWrongQuestion = userSnapshot.data().wrongQuestion;
                }
                tmpWrongQuestion.push(exerciseDetail.question[currentQuestionNum - 1]);
                const userRef = doc(db, 'User', user.id);
                updateDoc(userRef, {wrongQuestion: tmpWrongQuestion})
            }
        }
        // to delete the wrong question if user answer correctly in Answer Review Exercise
        if (exerciseDetail.type === "answerReviewExercise" && correctFlag) {
            if (user.id !== "" && user.id !== null && user.id !== undefined) {
                const userSnapshot = await getDoc(doc(db, 'User', user.id));
                let tmpWrongQuestion = userSnapshot.data().wrongQuestion;
                const index = tmpWrongQuestion.indexOf(exerciseDetail.question[currentQuestionNum - 1]);
                if (index > -1) {
                    tmpWrongQuestion.splice(index, 1); // to remove the correct qusetion ID
                }
                const userRef = doc(db, 'User', user.id);
                updateDoc(userRef, {wrongQuestion: tmpWrongQuestion})
            }
        }
        // to calculate accuracy of the exercise for each confirm action
        if (exerciseDetail.question.length == 5) { // for level 1 and 2 question
            let correctAnswer = 0;
            for (let i = 0; i <= progress + 1; i++) {
                const questionSnapshot = await getDoc(doc(db, 'Question', exerciseDetail.question[i]));
                questionSnapshot.data().answer === userAnswer[i] && (correctAnswer += 1);
            }
            const userRef = doc(db, 'Exercise', exerciseID);
            updateDoc(userRef, {accuracy: correctAnswer / (progress + 2)});
        } else if (exerciseDetail.question.length == 1) {  // for level 3 question
            const questionSnapshot = await getDoc(doc(db, 'Question', exerciseDetail.question[0]));
            let correctAnswer = 0;
            let answeredAnswerCount = 0;
            for (let i = 0; i < questionSnapshot.data().answer.length; i++) {
                questionSnapshot.data().answer[i] === JSON.parse(userAnswer[0])[i] && JSON.parse(userAnswer[0])[i] !== "" && (correctAnswer += 1);
                JSON.parse(userAnswer[0])[i] !== "" && (answeredAnswerCount += 1);
            }
            const userRef = doc(db, 'Exercise', exerciseID);
            updateDoc(userRef, {accuracy: correctAnswer / answeredAnswerCount});
        }
        // to store the time spent for each question
        if (exerciseDetail.type !== "calibrationTest") {
            let tmp = timeSpentArray;
            tmp[currentQuestionNum - 1] = countUpTime - lastQuestionTime;
            const userRef = doc(db, 'Exercise', exerciseID);
            updateDoc(userRef, {
                timeSpentArray: tmp,
                lastQuestionTime: countUpTime,
            });
            setTimeSpentArray(tmp);
            setLastQuestionTime(countUpTime);
        }
    }

    // to handel display practice answer
    const [currentQuestionNum, setCurrectQuestionNum] = useState(1);
    const [currentQuestionID, setCurrectQuestionID] = useState();
    const nextQuestion = async () => {
        setSaveDraftFlag(false);
        if (currentQuestionNum === exerciseDetail.question.length
            // last question
        ) {
            if (NUMB == 2) { // runway flow

                // calculate accuracy
                // let accAllandEachTopic = {};
                //
                // let typemap35 = {
                //     "K4Q0mJZ0YttdqXaAh3jP": "campus",
                //     "KwYa7NjutvYYk7It5GLZ": "campus",
                //     "LaPqkbjtQSkZgWx5R9eD": "campus",
                //     "7AvtasVNDojkzIX7pGq4": "Myth",
                //     "eYUDAFpIdsSKmg1o63cD": "Myth",
                //     "yVxQPz9LOf2rwYXhD06B": "Myth",
                //     "ak6VBKtwwsLxvJtdNrS7": "Academic",
                //     "kh1WUacNQ0k3F2py1flY": "Academic",
                //     "xvdRRE3wnODG9vpS0F6w": "Academic",
                // }

                let runwayMyth1Total = 0;
                let runwayMyth1CorrectTotal = 0;
                let runwayAcademic1Total = 0;
                let runwayAcademic1CorrectTotal = 0;
                let runwayCampus1Total = 0;
                let runwayCampus1CorrectTotal = 0;
                for (let i = 0; i < exerciseDetail.question.length; i++) {


                    if (questionTopic[i] === "runwayGeography1") {

                        runwayMyth1Total += 1;  // total question
                        questionAnswer[i] === userAnswer[i] && (runwayMyth1CorrectTotal += 1);
                    }
                    if (questionTopic[i] === "runwayTransport1") {
                        runwayAcademic1Total += 1;   // total question
                        questionAnswer[i] === userAnswer[i] && (runwayAcademic1CorrectTotal += 1);
                    }
                    if (questionTopic[i] === "runwayPopculture 1") {
                        runwayCampus1Total += 1; // total question
                        questionAnswer[i] === userAnswer[i] && (runwayCampus1CorrectTotal += 1);
                    }
                    // if (questionTopic[i] === "questionWord") {
                    //     questionWordTotal += 1;// total question
                    //     questionAnswer[i] === userAnswer[i] && (questionWordCorrectTotal += 1);
                    // }
                }
                let overallacc = (runwayMyth1CorrectTotal + runwayAcademic1CorrectTotal + runwayCampus1CorrectTotal) / 9;


                let accuracy123 = {
                    "runwayMyth1": runwayMyth1CorrectTotal / runwayMyth1Total,
                    "runwayAcademic1": runwayAcademic1CorrectTotal / runwayAcademic1Total,
                    "runwayCampus1": runwayCampus1CorrectTotal / runwayCampus1Total,
                    "overallacc": overallacc,
                }
                console.log("ttttttttttt", accuracy123);
                // navigate to accuracy page with accuracy props
                navigate('/accuracy', {
                        state: {
                            acc8: accuracy123,
                            timeused5: countUpTime,
                            exerciseID: exerciseID,
                            exerciseDetail: exerciseDetail
                        }
                    }
                )


            } else {   // normal flow
                if (exerciseDetail.type != "calibrationTest" &&
                    exerciseDetail.type != "answerReviewExercise" &&
                    exerciseDetail.type != "questionBank"
                    // today practice
                ) {

                    // to determine if video suggestion is needed
                    let tmpAccuracyHistory = [];


                    // past same user same type exercise
                    const q = query(collection(db, "Exercise"),
                        where("user", "==", user.id),
                        where("type", "==", exerciseDetail.type)
                    );
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        if (doc.data().accuracy !== undefined && doc.data().accuracy >= 0) {
                            tmpAccuracyHistory.push(doc.data());
                        }

                        // to sort by date descending order
                        tmpAccuracyHistory.sort(function (a, b) {
                            return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
                        });
                    });

                    // to calculate user average accuracy
                    let userAverageAccuracy = 0;
                    for (let i = 0; i < tmpAccuracyHistory.length; i++) {
                        userAverageAccuracy += tmpAccuracyHistory[i].accuracy;
                    }
                    userAverageAccuracy = userAverageAccuracy / tmpAccuracyHistory.length;

                    // to calculate overall average accuracy
                    let userTotal = 0;
                    let userOverallAverageAccuracy = 0;
                    const qUser = query(collection(db, "User"));
                    const querySnapshotUser = await getDocs(qUser);
                    querySnapshotUser.forEach((doc) => {
                        if (doc.data().overallAccuracy !== undefined && doc.data().overallAccuracy >= 0) {
                            userTotal += 1;
                            userOverallAverageAccuracy += doc.data().overallAccuracy;
                        }
                    });
                    userOverallAverageAccuracy = userOverallAverageAccuracy / userTotal;

                    // to determine if video suggestion is needed
                    let showSuggestedVideoFlag = false;
                    if (tmpAccuracyHistory[0].accuracy < 0.6 && (tmpAccuracyHistory[0].level === "1" || tmpAccuracyHistory[0].level === "2")) {
                        showSuggestedVideoFlag = true;
                    }
                    if (userAverageAccuracy <= 0.7) {
                        showSuggestedVideoFlag = true;
                    }
                    if (tmpAccuracyHistory[0].accuracy <= userOverallAverageAccuracy - 0.2) {
                        showSuggestedVideoFlag = true;
                    }
                    if ((tmpAccuracyHistory.length >= 3 && tmpAccuracyHistory[0].accuracy + tmpAccuracyHistory[1].accuracy + tmpAccuracyHistory[2].accuracy) / 3 < userAverageAccuracy) {
                        showSuggestedVideoFlag = true;
                    }
                    if (tmpAccuracyHistory[0].accuracy >= 0.8) {
                        showSuggestedVideoFlag = false;
                    }
                    if (showSuggestedVideoFlag // if this flag is set to true above
                        &&
                        (exerciseDetail.type === "pastTense" ||
                            exerciseDetail.type == "presentContinuousTense") // only these two tense have video

                    ) {
                        setSuggestedVideoModalVisibility(true)
                    } else {
                        setFinishExerciseModalVisibility(true)
                    }
                } else {
                    setFinishExerciseModalVisibility(true)
                }

            }
        } else {
            setCurrectQuestionID(exerciseDetail.question[currentQuestionNum]);
            setCurrectQuestionNum(currentQuestionNum + 1);
            setMode("question");
        }
    }
    const prevQuestion = () => {
        setSaveDraftFlag(false);
        setCurrectQuestionID(exerciseDetail.question[currentQuestionNum - 2]);
        setCurrectQuestionNum(currentQuestionNum - 1);
    }
    const toQuestion = (index) => {
        setSaveDraftFlag(false);
        setCurrectQuestionID(exerciseDetail.question[index]);
        setCurrectQuestionNum(index + 1);
    }

    const [finishExerciseModalVisibility, setFinishExerciseModalVisibility] = useState(false);
    const [suggestedVideoModalVisibility, setSuggestedVideoModalVisibility] = useState(false);

    // console.log("correctAnsweredFlagArray", correctAnsweredFlagArray);

    // const cccccccc = () => {
    //
    //     document.getElementById("menuContainer").style.backgroundColor = "#FFDB8E";
    // }
    //
    // const cccccccc2 = () => {
    //
    //     document.getElementById("menuContainer").style.backgroundColor = "white";
    // }
    // useEffect(() => {
    //     // cccccccc()
    //     return cccccccc2
    //
    //
    // },[])
    return <>

        {/*Question!!!!!*/}
        {/*should the slide down belong to menu or not */}
        {/*the slide down contain exactly the stuff original in the left progress bar and timer*/}
        {/*approach better be the menu is transparent and the slide down doesn't belongg to menu*/}
        {/*Answer!!!!!!!,
        just use the progress component*/}


        <Menu/>


        {exerciseDetail && exerciseDetail.type !== "calibrationTest" && NUMB != 2 &&
            <SideMenu/>
        }

        {/*tenary is used here only because in mobile view we dont need grid container for the progress and main content */}
        {/*it is weird to use container while the progress is actually fixed position*/}
        {is_m ?
            <div className="exerciseBackgroundImage"
                 style={{
                     marginLeft: exerciseDetail && exerciseDetail.type !== "calibrationTest" && !is_m ?
                         85 : 0
                 }}>


                <div className="calibrationTestSideMenuContainer">
                    {exerciseDetail &&
                        <Progress
                            endFlag={endFlag}
                            correctAnsweredFlagArray={correctAnsweredFlagArray}
                            answeredFlagArray={answeredFlagArray}
                            mode={mode}
                            countUpTime={countUpTime} progress={progress} toQuestion={toQuestion}
                            pauseModalVisibility={pauseModalVisibility}
                            setPauseModalVisibility={setPauseModalVisibility} start={start} pause={pause}
                            handleSubmitAnswer={handleSubmitAnswer}
                            type={exerciseDetail && exerciseDetail.type == "questionBank" ? "questionBank" : exerciseDetail.type == "answerReviewExercise" ? "answerReviewExercise" : "todaysPractices"}
                            questionTotal={exerciseDetail.question.length}
                            currentQuestionNum={currentQuestionNum - 1} userAnswer={userAnswer}
                            remark={remark} saveDraftFlag={saveDraftFlag}/>
                    }
                </div>

                {/*add some margin to the main content*/}
                <div className="menuHeight30"/>
                <div className="calibrationTestQuestionContainer">
                    <>
                        <div className="claibrationTestBottomMargin"></div>
                        <Grid

                              alignItems="center"
                              justifyContent="center"
                              style={{
                                  minHeight: 'calc(100vh - 10vw - 60px'
                        }}
                              container spacing={0}>

                            <Grid align="center" item xs={10}>
                                {exerciseDetail && questionType && questionType[currentQuestionNum - 1] === "multipleChoice" &&
                                    <MultipleChoice answeredFlagArray={answeredFlagArray}
                                                    updateSubmitedQuestion={updateSubmitedQuestion}
                                                    correctAnsweredFlagArray={correctAnsweredFlagArray}
                                                    setCorrectAnsweredFlagArray={setCorrectAnsweredFlagArray}
                                                    setAnsweredFlagArray={setAnsweredFlagArray}
                                                    exerciseDetail={exerciseDetail}
                                                    handleSubmitAnswer={handleSubmitAnswer} progress={progress}
                                                    setProgress={setProgress}
                                                    type={exerciseDetail && exerciseDetail.type == "questionBank" ? "questionBank" : exerciseDetail.type == "answerReviewExercise" ? "answerReviewExercise" : "todaysPractices"}
                                                    questionKey={currentQuestionNum - 1}
                                                    currentQuestionNum={currentQuestionNum}
                                                    questionID={currentQuestionID} userAnswer={userAnswer}
                                                    mode={mode} setMode={setMode} setUserAnswer={setUserAnswer}
                                                    remark={remark} setRemark={setRemark} confirmBtn={false}
                                                    continuousCorrectCount={continuousCorrectCount}
                                                    continuousWrongCount={continuousWrongCount}
                                                    correctRandomIndex={correctRandomIndex}
                                                    wrongRandomIndex={wrongRandomIndex}
                                                    pause={pause}
                                                    setSaveDraftFlag={setSaveDraftFlag}/>}
                                {exerciseDetail && questionType && questionType[currentQuestionNum - 1] === "fillInTheBlank" &&
                                    <FillInTheBlank answeredFlagArray={answeredFlagArray}
                                                    updateSubmitedQuestion={updateSubmitedQuestion}
                                                    correctAnsweredFlagArray={correctAnsweredFlagArray}
                                                    setCorrectAnsweredFlagArray={setCorrectAnsweredFlagArray}
                                                    setAnsweredFlagArray={setAnsweredFlagArray}
                                                    exerciseDetail={exerciseDetail}
                                                    handleSubmitAnswer={handleSubmitAnswer} progress={progress}
                                                    setProgress={setProgress}
                                                    type={exerciseDetail && exerciseDetail.type == "questionBank" ? "questionBank" : exerciseDetail.type == "answerReviewExercise" ? "answerReviewExercise" : "todaysPractices"}
                                                    questionKey={currentQuestionNum - 1}
                                                    currentQuestionNum={currentQuestionNum}
                                                    questionID={currentQuestionID} userAnswer={userAnswer}
                                                    mode={mode} setMode={setMode} setUserAnswer={setUserAnswer}
                                                    remark={remark} setRemark={setRemark} confirmBtn={false}
                                                    continuousCorrectCount={continuousCorrectCount}
                                                    continuousWrongCount={continuousWrongCount}
                                                    correctRandomIndex={correctRandomIndex}
                                                    wrongRandomIndex={wrongRandomIndex}
                                                    pause={pause}
                                                    setSaveDraftFlag={setSaveDraftFlag}/>}
                                {exerciseDetail && questionType && questionType[currentQuestionNum - 1] === "fillInMultipleBlank" &&
                                    <FillInMultipleBlank answeredFlagArray={answeredFlagArray}
                                                         updateSubmitedQuestion={updateSubmitedQuestion}
                                                         correctAnsweredFlagArray={correctAnsweredFlagArray}
                                                         setCorrectAnsweredFlagArray={setCorrectAnsweredFlagArray}
                                                         setAnsweredFlagArray={setAnsweredFlagArray}
                                                         exerciseDetail={exerciseDetail}
                                                         handleSubmitAnswer={handleSubmitAnswer}
                                                         progress={progress} setProgress={setProgress}
                                                         type={exerciseDetail && exerciseDetail.type == "questionBank" ? "questionBank" : exerciseDetail.type == "answerReviewExercise" ? "answerReviewExercise" : "todaysPractices"}
                                                         questionKey={currentQuestionNum - 1}
                                                         currentQuestionNum={currentQuestionNum}
                                                         questionID={currentQuestionID} userAnswer={userAnswer}
                                                         mode={mode} setMode={setMode}
                                                         setUserAnswer={setUserAnswer} remark={remark}
                                                         setRemark={setRemark} confirmBtn={false}
                                                         continuousCorrectCount={continuousCorrectCount}
                                                         continuousWrongCount={continuousWrongCount}
                                                         pause={pause}
                                                         setSaveDraftFlag={setSaveDraftFlag}/>}
                            </Grid>
                            <Grid align="center" item xs={12}>
                              <div className="menuHeight30"></div>
                            </Grid>
                            <Grid align="center" item xs={5}>
                                {currentQuestionNum !== 1 &&
                                    <div onClick={() => prevQuestion()}>

                                    <BackButton2333 text8="Back"></BackButton2333>
                                    </div>
                                    //
                                    // <img onClick={() => prevQuestion()} className="questionArrowAnswer"
                                    //      src={arrowLeftSVG} alt="left arrow"/>
                                }
                            </Grid>
                            <Grid align="center" item xs={5}>
                                {exerciseDetail &&
                                    (progress >= currentQuestionNum - 1 ||
                                        exerciseDetail.type == "questionBank") &&
                                    <div onClick={() => nextQuestion()}>

                                    <NextButton2333 text8="Next"></NextButton2333>
                                    </div>
                                    // <img onClick={() => nextQuestion()} className="questionArrowAnswer"
                                    //      src={arrowRightSVG} alt="right arrow"/>
                                }
                            </Grid>
                        </Grid>
                        <div className="claibrationTestBottomMargin"></div>
                    </>
                </div>

            </div> : <div className="exerciseBackgroundImage"
                          style={{
                              marginLeft: exerciseDetail && exerciseDetail.type !== "calibrationTest" && !is_m ?
                                  85 : 0
                          }}>
                <Grid container spacing={0}

                    // alignItems="center"
                      justifyContent="center">

                    {/*progress part*/}
                    <Grid item xs={12} sm={3}>
                        <div className="calibrationTestSideMenuContainer">
                            {exerciseDetail &&
                                <Progress
                                    endFlag={endFlag}
                                    correctAnsweredFlagArray={correctAnsweredFlagArray}
                                    answeredFlagArray={answeredFlagArray}
                                    mode={mode}
                                    countUpTime={countUpTime} progress={progress} toQuestion={toQuestion}
                                    pauseModalVisibility={pauseModalVisibility}
                                    setPauseModalVisibility={setPauseModalVisibility} start={start} pause={pause}
                                    handleSubmitAnswer={handleSubmitAnswer}
                                    type={exerciseDetail && exerciseDetail.type == "questionBank" ? "questionBank" : exerciseDetail.type == "answerReviewExercise" ? "answerReviewExercise" : "todaysPractices"}
                                    questionTotal={exerciseDetail.question.length}
                                    currentQuestionNum={currentQuestionNum - 1} userAnswer={userAnswer}
                                    remark={remark} saveDraftFlag={saveDraftFlag}/>
                            }
                        </div>
                    </Grid>


                    {/*Question part*/}
                    <Grid item xs={12} sm={9}>
                        <div className="calibrationTestQuestionContainer">
                            <>
                                <div className="claibrationTestBottomMargin"></div>
                                <Grid justifyContent="center"
                                      alignItems="center"
                                      justifyContent="center"
                                      style={{minHeight: 'calc(100vh - 10vw - 60px'}}
                                      container spacing={0}>
                                    <Grid align="center" item xs={1}>
                                        {currentQuestionNum !== 1 &&
                                            <img onClick={() => prevQuestion()} className="questionArrowAnswer"
                                                 src={arrowLeftSVG} alt="left arrow"/>
                                        }
                                    </Grid>
                                    <Grid align="center" item xs={10}>
                                        {exerciseDetail && questionType && questionType[currentQuestionNum - 1] === "multipleChoice" &&
                                            <MultipleChoice answeredFlagArray={answeredFlagArray}
                                                            updateSubmitedQuestion={updateSubmitedQuestion}
                                                            correctAnsweredFlagArray={correctAnsweredFlagArray}
                                                            setCorrectAnsweredFlagArray={setCorrectAnsweredFlagArray}
                                                            setAnsweredFlagArray={setAnsweredFlagArray}
                                                            exerciseDetail={exerciseDetail}
                                                            handleSubmitAnswer={handleSubmitAnswer} progress={progress}
                                                            setProgress={setProgress}
                                                            type={exerciseDetail && exerciseDetail.type == "questionBank" ? "questionBank" : exerciseDetail.type == "answerReviewExercise" ? "answerReviewExercise" : "todaysPractices"}
                                                            questionKey={currentQuestionNum - 1}
                                                            currentQuestionNum={currentQuestionNum}
                                                            questionID={currentQuestionID} userAnswer={userAnswer}
                                                            mode={mode} setMode={setMode} setUserAnswer={setUserAnswer}
                                                            remark={remark} setRemark={setRemark} confirmBtn={false}
                                                            continuousCorrectCount={continuousCorrectCount}
                                                            continuousWrongCount={continuousWrongCount}
                                                            correctRandomIndex={correctRandomIndex}
                                                            wrongRandomIndex={wrongRandomIndex}
                                                            pause={pause}
                                                            setSaveDraftFlag={setSaveDraftFlag}/>}
                                        {exerciseDetail && questionType && questionType[currentQuestionNum - 1] === "fillInTheBlank" &&
                                            <FillInTheBlank answeredFlagArray={answeredFlagArray}
                                                            updateSubmitedQuestion={updateSubmitedQuestion}
                                                            correctAnsweredFlagArray={correctAnsweredFlagArray}
                                                            setCorrectAnsweredFlagArray={setCorrectAnsweredFlagArray}
                                                            setAnsweredFlagArray={setAnsweredFlagArray}
                                                            exerciseDetail={exerciseDetail}
                                                            handleSubmitAnswer={handleSubmitAnswer} progress={progress}
                                                            setProgress={setProgress}
                                                            type={exerciseDetail && exerciseDetail.type == "questionBank" ? "questionBank" : exerciseDetail.type == "answerReviewExercise" ? "answerReviewExercise" : "todaysPractices"}
                                                            questionKey={currentQuestionNum - 1}
                                                            currentQuestionNum={currentQuestionNum}
                                                            questionID={currentQuestionID} userAnswer={userAnswer}
                                                            mode={mode} setMode={setMode} setUserAnswer={setUserAnswer}
                                                            remark={remark} setRemark={setRemark} confirmBtn={false}
                                                            continuousCorrectCount={continuousCorrectCount}
                                                            continuousWrongCount={continuousWrongCount}
                                                            correctRandomIndex={correctRandomIndex}
                                                            wrongRandomIndex={wrongRandomIndex}
                                                            pause={pause}
                                                            setSaveDraftFlag={setSaveDraftFlag}/>}
                                        {exerciseDetail && questionType && questionType[currentQuestionNum - 1] === "fillInMultipleBlank" &&
                                            <FillInMultipleBlank answeredFlagArray={answeredFlagArray}
                                                                 updateSubmitedQuestion={updateSubmitedQuestion}
                                                                 correctAnsweredFlagArray={correctAnsweredFlagArray}
                                                                 setCorrectAnsweredFlagArray={setCorrectAnsweredFlagArray}
                                                                 setAnsweredFlagArray={setAnsweredFlagArray}
                                                                 exerciseDetail={exerciseDetail}
                                                                 handleSubmitAnswer={handleSubmitAnswer}
                                                                 progress={progress} setProgress={setProgress}
                                                                 type={exerciseDetail && exerciseDetail.type == "questionBank" ? "questionBank" : exerciseDetail.type == "answerReviewExercise" ? "answerReviewExercise" : "todaysPractices"}
                                                                 questionKey={currentQuestionNum - 1}
                                                                 currentQuestionNum={currentQuestionNum}
                                                                 questionID={currentQuestionID} userAnswer={userAnswer}
                                                                 mode={mode} setMode={setMode}
                                                                 setUserAnswer={setUserAnswer} remark={remark}
                                                                 setRemark={setRemark} confirmBtn={false}
                                                                 continuousCorrectCount={continuousCorrectCount}
                                                                 continuousWrongCount={continuousWrongCount}
                                                                 pause={pause}
                                                                 setSaveDraftFlag={setSaveDraftFlag}/>}
                                    </Grid>
                                    <Grid align="center" item xs={1}>
                                        {exerciseDetail && (progress >= currentQuestionNum - 1 || exerciseDetail.type == "questionBank") &&
                                            <img onClick={() => nextQuestion()} className="questionArrowAnswer"
                                                 src={arrowRightSVG} alt="right arrow"/>
                                        }
                                    </Grid>
                                </Grid>
                                <div className="claibrationTestBottomMargin"></div>
                            </>
                        </div>
                    </Grid>
                </Grid>
            </div>}


        <Pause pauseModalVisibility={pauseModalVisibility} setPauseModalVisibility={setPauseModalVisibility}
               countUpTime={countUpTime} resume={start}/>

        <FinishExercise finsihExerciseModalVisibility={finishExerciseModalVisibility}
                        setFinishExerciseModalVisibility={setFinishExerciseModalVisibility}/>
        {exerciseDetail && <VideoSuggestion practiceTopic={exerciseDetail.type}
                                            suggestedVideoModalVisibility={suggestedVideoModalVisibility}
                                            setSuggestedVideoModalVisibility={setSuggestedVideoModalVisibility}/>}
    </>;
}

export default Practice;
