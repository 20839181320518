import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {db} from "../../firebase";
import {addDoc, collection, doc, getDoc, getDocs, query, where} from 'firebase/firestore';

import '../../App.css';
import './_styles.css';

import {ConfirmButton, SimilarQuestionButton} from './_components';
import correctSVG from '../../assets/general/correct.svg';
import incorrectSVG from '../../assets/general/incorrect.svg';
import correctElaborationSVG from '../../assets/exercise/correctElaboration.svg';
import LearningVideoEmbed from '../../pages/video/LearningVideoEmbed';

import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {getLanguage} from '../../localStorage/Language';

import {getUser} from '../../localStorage/User';
import {useMediaQuery} from "react-responsive";

function FillInMultipleBlank({
                                 updateSubmitedQuestion,
                                 answeredFlagArray,
                                 setCorrectAnsweredFlagArray,
                                 correctAnsweredFlagArray,
                                 setAnsweredFlagArray,
                                 exerciseDetail,
                                 handleSubmitAnswer,
                                 setProgress,
                                 progress,
                                 type,
                                 showEmptyMsg,
                                 questionKey,
                                 questionID,
                                 currentQuestionNum,
                                 confirmBtn = true,
                                 userAnswer,
                                 setUserAnswer,
                                 setMode,
                                 mode = "question",
                                 remark,
                                 setRemark,
                                 pause,
                                 setSaveDraftFlag
                             }) {

    const {t} = useTranslation();
    const l = getLanguage();
    const user = getUser();
    let navigate = useNavigate();

    const [value, setValue] = useState("");
    const [forceUpdate, setForceUpdate] = useState(1);

    function shuffle(a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }

    useEffect(() => {
            questionID && getQuestion(questionID);
            if (type !== "calibrationTest" && type !== undefined) {
                // setValue(exerciseDetail.answer && JSON.parse(exerciseDetail.answer[questionKey]));
                setValue(userAnswer[questionKey]);
                if (type === "todaysPractices") {
                    progress >= currentQuestionNum - 1 ?  //
                        (setMode("answer")) :
                        (setMode("question"));
                }
                (type === "questionBank" || type === "answerReviewExercise") // for these 2,
                // there is no save draft, so directly question mode
                && setMode("question");
            }
            setForceUpdate(forceUpdate + 1);
        },
        [questionID]   // i guess this only change once?
    )

    useEffect(() => {
            if (type === "todaysPractices") {
                progress >= currentQuestionNum - 1 ? (setMode("answer")) : (setMode("question"));
            }
        },
        [progress]  // when moving the progress
    )

    const [questionDetail, setQuestionDetail] = useState();
    const getQuestion = async (id) => {
        const questionSnapshot = await getDoc(doc(db, 'Question', id));
        if (questionSnapshot.exists()) {
            setQuestionDetail(questionSnapshot.data()); // this will trigger a new useEffect in this file
            // to initialise the answer array for fill in multiple blank type only
            if (userAnswer[questionKey] == '') {
                let tmp = userAnswer;


                // we edit this question element in the array , to be all blanks
                tmp[currentQuestionNum - 1] = JSON.stringify(Array.from({length: questionSnapshot.data().question.length - 1}).fill(""));

                setUserAnswer(tmp);
            }
        }
    }

    const [randomOption, setRandomOption] = useState();

    useEffect(() => {
        if (questionDetail) {
            let tmpOption = shuffle(questionDetail.option);
            setRandomOption(tmpOption);
        }
    }, [questionDetail])


    // for each blank
    const handleSelectAnswer = (answer, i) => {
        setSaveDraftFlag(false); // unset the flag
        let tmp = JSON.parse(userAnswer[questionKey]);
        tmp[i] = answer;

        // what is being done here????
        let tmpUserAnswer = userAnswer;
        tmpUserAnswer[questionKey] = JSON.stringify(tmp);
        setUserAnswer(tmpUserAnswer);
        setValue(userAnswer[questionKey]);
    }

    const handleConfirmAnswer = () => {
        updateSubmitedQuestion(questionID);
        let tmp = answeredFlagArray;
        tmp[questionKey] = true;
        setAnsweredFlagArray(tmp);
        setProgress(progress + 1);
        setMode("answer");
        let correctFlag = true;
        (value == "" || value == null) && (correctFlag = false);
        try {
            let tmpUserAnswer = JSON.parse(value)
            for (let i = 0; i < questionDetail.answer.length; i++) {
                questionDetail.answer[i] !== tmpUserAnswer[i] && (correctFlag = false);
            }
        } catch {

        } finally {
            let tmpCorrect = correctAnsweredFlagArray;
            tmpCorrect[questionKey] = correctFlag ? "correct" : "incorrect";
            setCorrectAnsweredFlagArray(tmpCorrect);
            handleSubmitAnswer("default", correctFlag);
            progress === currentQuestionNum - 1 && pause();
        }

    }
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const toQuestionBank = async () => {
        // to generate question bank practice
        const qQuestion = query(collection(db, "Question"), where("topic", "==", questionDetail.topic), where("level", "==", (questionDetail.level).toString()));
        const querySnapshotQuestion = await getDocs(qQuestion);
        let tmpQuestionArray = []
        querySnapshotQuestion.forEach((doc) => {
            tmpQuestionArray.push(doc.id);
        });
        const tmpQuestionBankExercise = {
            date: new Date(),
            type: "questionBank",
            user: user.id,
            question: tmpQuestionArray,
            countUpTime: 0,
        };
        const docRefQuestionBankExercise = await addDoc(collection(db, "Exercise"), tmpQuestionBankExercise);
        navigate("/practice", {state: {exercise: tmpQuestionBankExercise, exerciseID: docRefQuestionBankExercise.id}});
        window.location.reload(false);
    }

    return (
        <>
            {(type === "answerReviewExercise" && (mode == "answer" || answeredFlagArray[currentQuestionNum - 1])) &&
                <div className='similarQuestionContainer'>
                    <div onClick={toQuestionBank}>
                        <SimilarQuestionButton />
                    </div>
                </div>
            }
            <div key={forceUpdate}>
                {questionDetail && questionDetail.withOptionFlag &&
                    <div className="fillInMultipleBlankOptionContainer">
                        {randomOption && randomOption.map((data, i) => (
                            <span key={i} className="fillInMultipleBlankOption">{data}</span>
                        ))}
                    </div>
                }
                <div className="fillInMultipleBlankContainer">
                    <p className="fillInMultipleBlankIntroduction">
                        {
                            questionDetail && questionDetail.withOptionFlag && questionDetail.useOnceFlag ?
                                t("LEVEL_3_WITH_OPTION_NO_REPEAT")
                                : questionDetail && questionDetail.withOptionFlag && !questionDetail.useOnceFlag ?
                                    t("LEVEL_3_WITH_OPTION_WITH_REPEAT")
                                    : questionDetail && !questionDetail.withOptionFlag &&
                                    t('LEVEL_3_WITHOUT_OPTION')

                        }
                    </p>
                    <div className="fillInMultipleBlankQuestionContainer">
                        {questionDetail && userAnswer && questionDetail.question.map((data, i) => (
                            <span key={i}>
                                <p className="fillInMultipleBlankQuestion">{data}</p>
                                {i !== questionDetail.question.length - 1 &&
                                    <>
                                        <p className="fillInMultipleBlankQuestion"> ({i + 1})</p>
                                        {(mode != "answer" &&
                                            !((type === "questionBank" || type === "answerReviewExercise") &&
                                                answeredFlagArray[currentQuestionNum - 1])) ?
                                            <TextField
                                                autoComplete='off'
                                                defaultValue={value && JSON.parse(value)[i]}
                                                onChange={(e) => {
                                                    handleSelectAnswer(e.target.value, i)
                                                }}
                                                className="fillInMultipleBlankInputTextField"
                                                InputProps={{
                                                    inputProps: {style: {textAlign: "center"}},
                                                    style: {
                                                        fontSize: is_m ? "12px" : "1.4vw",
                                                        fontFamily: 'Poppins',

                                                        ...(!is_m) && {height: "1.4vw"},
                                                        color: "#366FD8"
                                                    },
                                                }}
                                                variant="standard"
                                            />
                                            :
                                            <>
                                                <TextField
                                                    autoComplete='off'
                                                    disabled
                                                    className="fillInMultipleBlankInputTextField"
                                                    value={value && JSON.parse(value)[i]}
                                                    sx={{
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "#356fd8",
                                                        },
                                                    }}
                                                    InputProps={{
                                                        inputProps: {style: {textAlign: "center"}},
                                                        style: {
                                                            fontSize: is_m ? "12px" : "1.4vw",
                                                            fontFamily: 'Poppins',
                                                            ...(!is_m) && {height: "1.4vw"},
                                                            color: "#366FD8"
                                                        },
                                                        endAdornment: (
                                                            (mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) &&
                                                            <InputAdornment
                                                                className="fillInTheBlankTextFieldInputAdornment"
                                                                position="end">
                                                                {questionDetail && value && questionDetail.answer[i] == JSON.parse(value)[i] ?
                                                                    <img className="fillInMultipleBlankCorrect"
                                                                         src={correctSVG} alt="correct"/>
                                                                    :
                                                                    <img className="fillInMultipleBlankCorrect"
                                                                         src={incorrectSVG} alt="incorrect"/>
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                />
                                                {questionDetail && (value === "" || questionDetail.answer[i] !== JSON.parse(value)[i]) &&
                                                    <div
                                                        className="fillInMultipleBlankAnswer">({questionDetail.answer[i]})</div>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </span>
                        ))}
                    </div>
                </div>
                {(mode != "answer" &&
                    !((type === "questionBank" || type === "answerReviewExercise") &&
                        answeredFlagArray[currentQuestionNum - 1])) ?
                    <></>
                    :
                    <>

                        {is_m?

                            <>
                                <div className="answerElaborationContainerLevel3">
                                {questionDetail && <LearningVideoEmbed embedId={questionDetail.video}/>}
                                </div>
                            </> :      <>
                            <img className="answerContainerImg"
                                                 src={require(l === "en" ? '../../assets/exercise/answerBannerLevel3.png' : '../../assets/exercise/answerBannerLevel3ZH-HK.png')}
                                                 alt="answer container"/>
                            <Grid container spacing={0}>
                            <Grid item xs={7}>
                            <div className="answerElaborationContainerLevel3">
                        {questionDetail && <LearningVideoEmbed embedId={questionDetail.video}/>}
                            </div>
                            </Grid>
                            </Grid>
                        </>

                        }

                    </>
                }
                {((confirmBtn && mode != "answer") || true) && (!(progress >= currentQuestionNum - 1) || correctAnsweredFlagArray[questionKey] === "notAnswered") &&
                    <div onClick={() => {
                        if (type !== "calibrationTest") {
                            handleConfirmAnswer();
                        }
                    }} className="questionCenterButtonContainer">
                        <ConfirmButton/>
                    </div>
                }
            </div>
        </>
    );
}

export default FillInMultipleBlank;
