import React from 'react';
import Grid from "@mui/material/Grid";
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";

import '../../App.css';
import './_styles.css';
import closeSVG from '../../assets/general/close.svg';
import tickSVG from '../../assets/login/tick.svg';

import '../../translation'
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../localStorage/Language';

import { AccountSettingsBackToHomeButton } from './_components';

function AccountSettingsUpdated({
    updatedModalVisibility,
    setUpdatedModalVisibility,
}) {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const l = getLanguage();
    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={updatedModalVisibility}
                className="accountSettingsUpdatedModalContainer"
                style={{ overlay: { backgroundColor: 'rgba(0, 0, 0, 0.3)', backdropFilter: "blur(6px)", WebkitBackdropFilter: "blur(6px)", zIndex: 1000000 } }}
            >
                <div className="accountSettingsUpdatedBackgroundImage">
                    {/* <img onClick={() => {
                        setUpdatedModalVisibility(false);
                    }} className="accountSettingsUpdatedClose" src={closeSVG} alt="close" /> */}
                    <div className="accountSettingsTopMargin"></div>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <div className="accountSettingsUpdatedInputContainer">
                                <img className="accountSettingsUpdatedTick" src={tickSVG} alt="tick" />
                                <p className="accountSettingsUpdatedText">{t("UDPATED_NAME")}</p>
                                <div className="accountSettingsDisplayInlineBlock" onClick={() => {
                                    setUpdatedModalVisibility(false);
                                    navigate('/')
                                }}><AccountSettingsBackToHomeButton variant="contained" className="sendButton"></AccountSettingsBackToHomeButton></div>

                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </>
    );
}

export default AccountSettingsUpdated;
