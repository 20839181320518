import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {db} from "../../firebase";
import {addDoc, collection, doc, getDoc, getDocs, query, where} from 'firebase/firestore';

import '../../App.css';
import './_styles.css';

import {ConfirmButton, SimilarQuestionButton} from './_components';
import Remark from './Remark';
import penSVG from '../../assets/exercise/pen.svg';
import correctSVG from '../../assets/general/correct.svg';
import incorrectSVG from '../../assets/general/incorrect.svg';
import correctElaborationSVG from '../../assets/exercise/correctElaboration.svg';
import starYellowSVG from '../../assets/exercise/starYellow.svg';
import starGreySVG from '../../assets/exercise/starGrey.svg';
import bookmarkedSVG from '../../assets/exercise/bookmarked.svg';

import '../../translation'
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {getLanguage} from '../../localStorage/Language';

import {getUser} from '../../localStorage/User';
import {useMediaQuery} from "react-responsive";
import {Box} from "@mui/material";

function FillInTheBlank({
                            updateSubmitedQuestion,
                            answeredFlagArray,
                            setCorrectAnsweredFlagArray,
                            correctAnsweredFlagArray,
                            setAnsweredFlagArray,
                            exerciseDetail,
                            handleSubmitAnswer,
                            setProgress,
                            progress,
                            type,
                            showEmptyMsg,
                            questionKey,
                            questionID,
                            currentQuestionNum,
                            confirmBtn = true,
                            userAnswer,
                            setUserAnswer,
                            setMode,
                            mode = "question",
                            remark,
                            setRemark,
                            pause,
                            continuousCorrectCount,
                            continuousWrongCount,
                            correctRandomIndex,
                            wrongRandomIndex,
                            setSaveDraftFlag,
                        }) {

    const {t} = useTranslation();
    const l = getLanguage();
    const user = getUser();
    let navigate = useNavigate();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(true)



    useEffect(() => {
        setLoading(true);
        questionID && getQuestion(questionID);
        if (type !== "calibrationTest" && type !== undefined) {
            setValue(exerciseDetail.answer ? exerciseDetail.answer[questionKey] : "");
            if (type === "todaysPractices") {
                progress >= currentQuestionNum - 1 ? (setMode("answer")) : (setMode("question"));
            }
            (type === "questionBank" || type === "answerReviewExercise") && setMode("question");
        }
    }, [questionID])

    useEffect(() => {
        if (type === "todaysPractices") {
            progress >= currentQuestionNum - 1 ? (setMode("answer")) : (setMode("question"));
        }
    }, [progress])

    const [questionDetail, setQuestionDetail] = useState();
    const getQuestion = async (id) => {
        const questionSnapshot = await getDoc(doc(db, 'Question', id));
        questionSnapshot.exists() && setQuestionDetail(questionSnapshot.data());
        setLoading(false);
    }

    const handleSelectAnswer = (answer) => {
        let tmp = userAnswer;
        tmp[questionKey] = answer;
        setUserAnswer(tmp);
        setValue(userAnswer[questionKey]);
    }

    const [forceUpdate, setForceUpdate] = useState(1); // to force update DOM when updated remark

    const handleConfirmAnswer = () => {
        updateSubmitedQuestion(questionID);
        setSaveDraftFlag(false);
        let tmp = answeredFlagArray;
        tmp[questionKey] = true;
        setAnsweredFlagArray(tmp);
        let tmpCorrect = correctAnsweredFlagArray;
        tmpCorrect[questionKey] = (questionDetail.answer == value) ? "correct" : "incorrect";
        setCorrectAnsweredFlagArray(tmpCorrect);
        setProgress(progress + 1);
        setMode("answer");
        handleSubmitAnswer("default", questionDetail.answer == value);
    }

    const toQuestionBank = async () => {
        // to generate question bank practice
        const qQuestion = query(collection(db, "Question"), where("topic", "==", questionDetail.topic), where("level", "==", (questionDetail.level).toString()));
        const querySnapshotQuestion = await getDocs(qQuestion);
        let tmpQuestionArray = []
        querySnapshotQuestion.forEach((doc) => {
            tmpQuestionArray.push(doc.id);
        });
        const tmpQuestionBankExercise = {
            date: new Date(),
            type: "questionBank",
            user: user.id,
            question: tmpQuestionArray,
            countUpTime: 0,
        };
        const docRefQuestionBankExercise = await addDoc(collection(db, "Exercise"), tmpQuestionBankExercise);
        navigate("/practice", {state: {exercise: tmpQuestionBankExercise, exerciseID: docRefQuestionBankExercise.id}});
        window.location.reload(false);
    }
    let iiiiiiiii=questionDetail?.imagecloudinary?questionDetail.imagecloudinary:questionDetail?.image
    return (
        <>
            {(type === "answerReviewExercise" && (mode == "answer" || answeredFlagArray[currentQuestionNum - 1])) &&
                <div className='similarQuestionContainer'>
                    <div onClick={toQuestionBank}>
                        <SimilarQuestionButton />
                    </div>
                </div>
            }
            <div className="multipleChoiceContainer">
                <div className="multipleChoiceTopContainer">
                    <div>
                        <p className="multipleChoiceQuestionNum">
                            {l === "en" ?
                                "Question " + currentQuestionNum
                                :
                                "第 " + currentQuestionNum + " 題"
                            }
                        </p>
                        {showEmptyMsg && (userAnswer[currentQuestionNum - 1] == '' || userAnswer[currentQuestionNum - 1] == undefined) && currentQuestionNum - 1 &&
                            <p className="multipleChoiceEmptyMsg">You have not attempted this question yet.</p>
                        }
                        {mode == "answer" &&
                            <>
                                {questionDetail &&
                                    <>
                                        <p className="multipleChoiceTopicText">
                                            {
                                                questionDetail.topic === "pastTense" ? "Past Tense" :
                                                    questionDetail.topic === "pastTensePresentTense" ? "Past Tense + Present Tense" :
                                                        questionDetail.topic === "presentContinuousTense" ? "Present Continuous Tense" :
                                                            questionDetail.topic === "presentTensePresentContinuousTense" ? "Present Tense + Present Continuous Tense" :
                                                                questionDetail.topic === "preposition" ? "Prepositions" :
                                                                    questionDetail.topic === "questionWord" && "Question Words"
                                            }
                                        </p>
                                        <div className="multipleChoiceDifficult">
                                            <p className="multipleChoiceDifficultText">{t("DIFFICULTY")} :</p>
                                            <img className="multipleChoiceDifficultStar" src={starYellowSVG}
                                                 alt="star"/>
                                            <img className="multipleChoiceDifficultStar" src={starYellowSVG}
                                                 alt="star"/>
                                            <img className="multipleChoiceDifficultStar" src={starGreySVG} alt="star"/>
                                        </div>
                                    </>
                                }
                            </>
                        }
                        <p className="fillInTheBlankIntroduction">{t("FILL_IN_THE_BLANK_INSTRUCTION")}</p>
                    </div>
                    {(mode == "question" && !((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) &&
                        <Remark questionKey={questionKey} remark={remark} setRemark={setRemark}
                                forceUpdate={forceUpdate} setForceUpdate={setForceUpdate} setValue={setValue}/>
                    }
                </div>
                <Grid container spacing={is_m?2:0}>
                    <Grid item xs={12} sm={7}>
                        <div className="fillInTheBlankQuestionContainer">
                            {questionDetail && questionDetail.question.map((data, i) => (
                                <span key={i}>
                                    <p className="multipleChoiceQuestion">{data}</p>
                                    {i !== questionDetail.question.length - 1 &&
                                        <span className="multipleChoiceQuestionUnderline">______________</span>
                                    }
                                </span>
                            ))}
                            <div className="fillInTheBlankInputTextFieldContainer">
                                {(mode != "answer" && !((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) ?
                                    <TextField
                                        autoComplete='off'
                                        value={value}
                                        disabled={remark && remark[questionKey].dontKnow}
                                        onChange={(e) => {
                                            handleSelectAnswer(e.target.value)
                                        }}
                                        className="fillInTheBlankInputTextField"
                                        InputProps={{
                                            style: {
                                                fontSize: is_m?"12px":"1.4vw",
                                                fontFamily: "Poppins",
                                                fontWeight: '400',
                                                color: "#366FD8"
                                            },
                                            startAdornment: (
                                                <InputAdornment className="fillInTheBlankTextFieldInputAdornment"
                                                                position="start">
                                                    <img className="fillInTheBlankPen" src={penSVG} alt="pen"/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                    :
                                    <TextField
                                        autoComplete='off'
                                        disabled
                                        className="fillInTheBlankInputTextField"
                                        value={userAnswer[currentQuestionNum - 1]}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#356fd8",
                                            },
                                        }}
                                        InputProps={{
                                            style: {
                                                fontSize: is_m?"12px":"1.4vw",
                                                fontFamily: "Poppins",
                                                fontWeight: '400',
                                                color: "#366FD8"
                                            },
                                            startAdornment: (
                                                <InputAdornment className="fillInTheBlankTextFieldInputAdornment"
                                                                position="start">
                                                    <img className="fillInTheBlankPen" src={penSVG} alt="pen"/>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                (mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) &&
                                                <InputAdornment className="fillInTheBlankTextFieldInputAdornment"
                                                                position="end">
                                                    {!loading && questionDetail && questionDetail.answer === userAnswer[currentQuestionNum - 1] ?
                                                        <img className="fillInTheBlankCorrect" src={correctSVG}
                                                             alt="correct"/>
                                                        : !loading && questionDetail && questionDetail.answer !== userAnswer[currentQuestionNum - 1] &&
                                                        <img className="fillInTheBlankCorrect" src={incorrectSVG}
                                                             alt="incorrect"/>
                                                    }
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        {questionDetail && <div className="questionImageExampleContainer"
                                                style={{backgroundImage: "url(" + iiiiiiiii + ")",
                                                    ...(is_m) && {height: "20vh"}, // in mobile this line need to be added .... wtf
                                                }}/>}
                    </Grid>
                </Grid>
            </div>
            {(mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) &&
                <>
                    {is_m ?
                        <></>
                        :


                        continuousCorrectCount >= 3 && l === "en" && correctRandomIndex === 1 ?
                        <img className="answerContainerImg"
                             src={require('../../assets/exercise/answerBannerCorrect1.png')} alt="answer container"/>
                        : continuousCorrectCount >= 3 && l === "en" && correctRandomIndex === 2 ?
                            <img className="answerContainerImg"
                                 src={require('../../assets/exercise/answerBannerCorrect2.png')}
                                 alt="answer container"/>
                            : continuousWrongCount >= 3 && l === "en" && wrongRandomIndex === 1 ?
                                <img className="answerContainerImg"
                                     src={require('../../assets/exercise/answerBannerWrong1.png')}
                                     alt="answer container"/>
                                : continuousWrongCount >= 3 && l === "en" && wrongRandomIndex === 2 ?
                                    <img className="answerContainerImg"
                                         src={require('../../assets/exercise/answerBannerWrong2.png')}
                                         alt="answer container"/>
                                    : continuousWrongCount >= 3 && l === "en" && wrongRandomIndex === 3 ?
                                        <img className="answerContainerImg"
                                             src={require('../../assets/exercise/answerBannerWrong3.png')}
                                             alt="answer container"/>
                                        : continuousCorrectCount >= 3 && l !== "en" && correctRandomIndex === 1 ?
                                            <img className="answerContainerImg"
                                                 src={require('../../assets/exercise/answerBannerCorrect1ZH-HK.png')}
                                                 alt="answer container"/>
                                            : continuousCorrectCount >= 3 && l !== "en" && correctRandomIndex === 2 ?
                                                <img className="answerContainerImg"
                                                     src={require('../../assets/exercise/answerBannerCorrect2ZH-HK.png')}
                                                     alt="answer container"/>
                                                : continuousWrongCount >= 3 && l !== "en" && wrongRandomIndex === 1 ?
                                                    <img className="answerContainerImg"
                                                         src={require('../../assets/exercise/answerBannerWrong1ZH-HK.png')}
                                                         alt="answer container"/>
                                                    : continuousWrongCount >= 3 && l !== "en" && wrongRandomIndex === 2 ?
                                                        <img className="answerContainerImg"
                                                             src={require('../../assets/exercise/answerBannerWrong2ZH-HK.png')}
                                                             alt="answer container"/>
                                                        : continuousWrongCount >= 3 && l !== "en" && wrongRandomIndex === 3 ?
                                                            <img className="answerContainerImg"
                                                                 src={require('../../assets/exercise/answerBannerWrong3ZH-HK.png')}
                                                                 alt="answer container"/>
                                                            :
                                                            <img className="answerContainerImg"
                                                                 src={require('../../assets/exercise/answerBanner.png')}
                                                                 alt="answer container"/>
                    }


                    {is_m?
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Box style={
                                    {
                                        backgroundImage: `url(${require('../../assets/Group423.png')})`,

                                        // background-size: `auto 100%`;
                                        // height:'300px',
                                        // marginTop: 20,
                                        // backgroundPosition: 'center',

                                        // borderRadius: '50px',
                                        backgroundSize: '100% 100%',
                                        backgroundRepeat: 'no-repeat'
                                    }
                                } sx={{
                                    // position: '',
                                    // width:`100%`, flex :1
                                    // height: ``,
                                    // flex: 1,

                                    // height:200,
                                    // minHeight: '200px',
                                    // width: 370.4,
                                    // height: 171.48,
                                }}>


                                <div className="answerElaborationContainer">
                                    <p className="answerElaborationTitle">{t("CORRECT_ANSWER")}: <span
                                        className="answerElaborationAnswerTextUnderline">{questionDetail && questionDetail.answer}</span>
                                    </p>
                                    <img className="correctElaborationImg" src={correctElaborationSVG} alt="correct"/>
                                    <p className="answerElaborationDetail">{t("EXPLANATION")}:<br/>{questionDetail && questionDetail.elaboration}
                                    </p>
                                </div>
                                </Box>
                            </Grid>
                        </Grid>


                        :
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <div className="answerElaborationContainer">
                                <p className="answerElaborationTitle">{t("CORRECT_ANSWER")}: <span
                                    className="answerElaborationAnswerTextUnderline">{questionDetail && questionDetail.answer}</span>
                                </p>
                                <img className="correctElaborationImg" src={correctElaborationSVG} alt="correct"/>
                                <p className="answerElaborationDetail">{t("EXPLANATION")}:<br/>{questionDetail && questionDetail.elaboration}
                                </p>
                            </div>
                        </Grid>
                    </Grid>}
                </>
            }
            {
                ((confirmBtn && mode != "answer") || ((value !== "" || (remark && remark[questionKey].dontKnow)) && type !== "calibrationTest")) &&
                (!(progress >= currentQuestionNum - 1) || correctAnsweredFlagArray[questionKey] === "notAnswered") &&
                <div onClick={() => {
                    if (type !== "calibrationTest") {
                        handleConfirmAnswer();
                    }
                }} className="questionCenterButtonContainer">
                    <ConfirmButton/>
                </div>
            }
        </>
    );
}

export default FillInTheBlank;
