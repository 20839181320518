import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: {
                    //WELCOME
                    "HELLO_NAME": "Hello My Name is",
                    "TYPE_HERE": "Please type here",
                    "TRY_CONVERSATION": "Try to have a conversation with him",
                    "NICE_MEET": "Nice to meet you too, Dr. QualiFly!",
                    "WHO_ARE_YOU": "Who are you?",
                    "WILL_IT_BE_DIFFICULT": "Will it be difficult...?",
                    "I_M_READY": "Okay! I am ready!",
                    "CLICK_TO_CONTINUE": "Click anywhere to continue",
                    // MENU
                    "LOGOUT": "Log out",
                    // SIDE MENU
                    "MENU": "Menu",
                    "HOME": "Home",
                    "RESULT": "Result Slip",
                    "ANS_REVIEW": "Mistakes Review",
                    "LEARNING_VID": "Learning Videos",
                    "EXERCISES": "Exercises",
                    "ACC_SETTING": "Account Settings",
                    "CONTACT_US": "Contact Us",
                    // LOG IN
                    "WELCOME": "WELCOME!",
                    "EMAIL": "Email",
                    "USERNAME": "Username",
                    "PASSWORD": "Password",
                    "INCORRECT_USERNAME_OR_PASSWORD": "Incorrect email or password",
                    "REMEMBER_ME": "Remember me",
                    "LOGIN": "Log In",
                    // DASHBOARD
                    "HELLO1": "Hi ",
                    "WELCOME_BACK1": ", welcome to QualiFly!",
                    "HELLO2": "Hi ",
                    "WELCOME_BACK2": ", how have you been? It's never too late to start!",
                    "HELLO3": "Hi ",
                    "WELCOME_BACK3": ", I am happy to see you again!",
                    "HELLO4": "Hi ",
                    "WELCOME_BACK4": ", how is your day?",
                    "HELLO5": "Hi ",
                    "WELCOME_BACK5": ", welcome back to QualiFly!",
                    "TODAYS_THEME": "Today's themes are",
                    "AND": "and",
                    "SEARCH_EXERCISE": "Search exercises",
                    "QUESTION_OF_THE_DAY": "Question of the Day",
                    "PLEASE_CHOOSE_THE_CORRECT_ANS": "Please choose the correct answer.",
                    "TODAYS_PRAC": "Today's Practices",
                    "START": "Start",
                    "RESUME": "Resume",
                    // PROGRESS
                    "PAUSE": "Pause",
                    "PAUSED": "Paused",
                    "SAVE_DRAFT": "Save Draft",
                    "SAVED": "Saved",
                    "PROGRESS": "Progress",
                    "VIEW_RESULT_SUMMARY": "View results summary",
                    // PAUSE
                    "YOU_VE_PAUSED": "You've paused!",
                    "TIME_REMAIN": "Time Remaining",
                    "TIME_SPENT": "Time Spent",
                    // RESULT
                    "PERFORMANCE_TREND": "Performance Trend",
                    "PERFORMANCE_LEVEL": "Performance Level",
                    "MASTERED_TOPICS": "Mastered Topics",
                    "TOPIC": "Topic",
                    "OVERALL_GRADE": "Overall Grade",
                    "ACCURACY": "Accuracy",
                    "NO_MASTERED": "You don’t have any mastered topics yet. Practise more!",
                    "TOPIC_IMPROVED": "Topics To Be Improved",
                    "NO_IMPROVED": "You don't have any topics to be improved yet. Keep up the good work!",
                    "PRACTICE_NOW": "Practise Now",
                    // ANSWER REVIEW
                    "RANK": "Rank",
                    "QUESTIONS_LEFT": "No. of questions left for review",
                    "REVIEW_NOW": "Review Now",
                    "DIFFICULTY": "Difficulty",
                    // BUTTONS
                    "RETURN_BUTTON": "Return",
                    // QUESTION BANK
                    "QUESTION_BANK": "Question Bank",
                    // CONTACT US
                    "CONTACT_US_MODAL": "CONTACT US!",
                    "CONTACT_US_EMAIL": "Email",
                    "CONTACT_US_TEXTBOX": "Please enter your question and we will reply to you as soon as possible.",
                    "SEND": "Send",
                    "INCORRECT_INPUT": "Incorrect email or content",
                    // SETTING
                    "SETTING": "Account Settings",
                    "CHANGE_NAME": "Change Name",
                    "NEW_NAME": "New Name",
                    "PLEASE_TYPE": "Please Type",
                    "CONFIRM": "Confirm",
                    // QUESTION BANK
                    "EASY": "Easy",
                    "NORMAL": "Normal",
                    "HARD": "Hard",
                    "ATTEMPT_NUM": "Attempted number of questions",
                    "SKIP_ATTEMPT": "Skip attempted questions",
                    "CHOOSE_LEVEL": "Please choose the level",
                    "FILL_IN_THE_BLANK_INSTRUCTION": "Fill in the blank with a suitable word.",
                    "CORRECT_ANSWER": "Correct answer",
                    "EXPLANATION": "Explanation",
                    // QUESTION OF THE DAY
                    // eslint-disable-next-line no-dupe-keys
                    // "QUESTION_OF_THE_DAY": "Question of the Day",
                    // LOG OUT
                    "LOGOUT_CONFIRM_MSG": "Are you sure you want to log out?",
                    "LOGOUT_NO": "No, let me practise",
                    "LOGOUT_YES": "Yes, log me out",
                    // REMARK
                    "I_WANT_TO_TRY": "I want to try",
                    "I_DONT_KNOW": "I don't know",
                    "BOOKMARK": "Bookmark",
                    "BOOKMARKED": "Bookmarked",
                    // MODAL
                    "CONFIRM_LEAVE": "Are you sure you want to leave?",
                    "CONFIRM_LEAVE_NO": "No, bring me back",
                    "CONFIRM_LEAVE_YES": "Yes, I want to leave",
                    "TIMES_UP": "Time's up!",
                    "VIEW_MY_RESULTS": "View my Results",
                    "FORCE_GO_BACK_TITLE": "Are you sure you have checked all answers?",
                    "UDPATED_NAME": "Your name has been updated.",
                    "UPDATED_NAME_BTN": "Back to home",
                    "SENT_MSG": "Message has been sent.",
                    "TODAYS_VIDEO": "Today's Video",
                    // VIDEO
                    "FINISHED_PRACTICE": "You have finished today’s practice.",
                    "BACK_TO_HOME": "Back to home",
                    // EXERCISE
                    "LEVEL_3_WITH_OPTION_NO_REPEAT": "Fill in the blanks with a suitable word in the correct form from the box above. Each verb can only be used once.",
                    "LEVEL_3_WITH_OPTION_WITH_REPEAT": "Fill in the blanks with a suitable word in the correct form from the box above. Each verb can be used more than once.",
                    "LEVEL_3_WITHOUT_OPTION": "Fill in the blanks with a suitable word in the correct form.",
                    "PRACTISE_SIMILAR_QUESTION": "Practise similar questions",
                }
            },
            "zh-HK": {
                translation: {
                    //WELCOME
                    "HELLO_NAME": "你好! 我的名字是：",
                    "TYPE_HERE": "請輸入你的名字",
                    "TRY_CONVERSATION": "試試和牠對話吧！",
                    "NICE_MEET": "Dr. Qualifly，我也很高興認識你！",
                    "WHO_ARE_YOU": "你是誰？",
                    "WILL_IT_BE_DIFFICULT": "這個練習會很難嗎......？",
                    "I_M_READY": "好的，我準備好了！",
                    "CLICK_TO_CONTINUE": "點擊任何地方以繼續",
                    // MENU
                    "LOGOUT": "登出",
                    // SIDE MENU
                    "MENU": "目錄",
                    "HOME": "主頁",
                    "RESULT": "成績表",
                    "ANS_REVIEW": "錯題簿",
                    "LEARNING_VID": "學習影片",
                    "EXERCISES": "課題練習",
                    "ACC_SETTING": "用戶設定",
                    "CONTACT_US": "聯絡我們",
                    // LOG IN
                    "WELCOME": "歡迎！",
                    "EMAIL": "電郵",
                    "USERNAME": "帳戶",
                    "PASSWORD": "密碼",
                    "INCORRECT_USERNAME_OR_PASSWORD": "電郵或密碼錯誤",
                    "REMEMBER_ME": "記住我",
                    "LOGIN": "登入",
                    // DASHBOARD
                    "HELLO1": "你好，",
                    "WELCOME_BACK1": "！歡迎回來QualiFly。",
                    "HELLO2": "",
                    "WELCOME_BACK2": "，你近日好嗎？在學習的路上，有心不怕遲！",
                    "HELLO3": "你好，",
                    "WELCOME_BACK3": "！很高興再次見到你。",
                    "HELLO4": "",
                    "WELCOME_BACK4": "，你今天過得好嗎？",
                    "HELLO5": "你好，",
                    "WELCOME_BACK5": "！歡迎回來QualiFly。",
                    "TODAYS_THEME": "今天的練習主題是",
                    "AND": "和",
                    "SEARCH_EXERCISE": "搜尋練習",
                    "QUESTION_OF_THE_DAY": "是日挑戰題",
                    "PLEASE_CHOOSE_THE_CORRECT_ANS": "請選擇正確的答案。",
                    "TODAYS_PRAC": "是日練習",
                    "START": "開始練習",
                    "RESUME": "繼續練習",
                    // PROGRESS
                    "PAUSE": "暫停練習",
                    "PAUSED": "已暫停",
                    "SAVE_DRAFT": "保存為草稿",
                    "VIEW_RESULT_SUMMARY": "查看成績總結",
                    "PROGRESS": "進度",
                    // PAUSE
                    "YOU_VE_PAUSED": "練習已暫停！",
                    "TIME_REMAIN": "剩餘時間",
                    "TIME_SPENT": "已使用時間",
                    // RESULT
                    "PERFORMANCE_TREND": "學習表現趨勢",
                    "PERFORMANCE_LEVEL": "能力指標",
                    "MASTERED_TOPICS": "已掌握的課題",
                    "TOPIC": "課題",
                    "OVERALL_GRADE": "總成績",
                    "ACCURACY": "準確度",
                    "NO_MASTERED": "你還未有已掌握的課題，繼續努力！",
                    "TOPIC_IMPROVED": "有待進步的課題",
                    "NO_IMPROVED": "你還未有待進步的課題，繼續努力！",
                    "PRACTICE_NOW": "立即練習",
                    // ANSWER REVIEW
                    "RANK": "排名",
                    "QUESTIONS_LEFT": "錯誤題目數目",
                    "REVIEW_NOW": "立刻重溫",
                    "DIFFICULTY": "難度",
                    // BUTTONS
                    "RETURN_BUTTON": "返回",
                    // QUESTION BANK
                    "QUESTION_BANK": "題目庫",
                    // CONTACT US
                    "CONTACT_US_MODAL": "聯絡我們",
                    "CONTACT_US_EMAIL": "電郵地址",
                    "CONTACT_US_TEXTBOX": "請寫下任何疑問，我們會儘快回覆您。",
                    "SEND": "傳送",
                    "INCORRECT_INPUT": "電郵或內容錯誤",
                    // SETTING
                    "SETTING": "用戶設定",
                    "CHANGE_NAME": "更改名稱",
                    "NEW_NAME": "新名稱",
                    "PLEASE_TYPE": "請輸入新名稱",
                    "CONFIRM": "確定",
                    // QUESTION BANK
                    "EASY": "容易",
                    "NORMAL": "普通",
                    "HARD": "困難",
                    "ATTEMPT_NUM": "已嘗試題目",
                    "SKIP_ATTEMPT": "跳過已嘗試題目",
                    "CHOOSE_LEVEL": "選擇難度",
                    "FILL_IN_THE_BLANK_INSTRUCTION": "在橫線上填上適當的答案。",
                    "CORRECT_ANSWER": "正確答案",
                    "EXPLANATION": "解釋",
                    // QUESTION OF THE DAY
                    // eslint-disable-next-line no-dupe-keys
                    // "QUESTION_OF_THE_DAY": "是日挑戰題",
                    // LOG OUT
                    "LOGOUT_CONFIRM_MSG": "你確定要登出嗎？",
                    "LOGOUT_NO": "否，我要繼續練習",
                    "LOGOUT_YES": "是，我要登出",
                    // REMARK
                    "I_WANT_TO_TRY": "我想嘗試",
                    "I_DONT_KNOW": "我不懂這道題",
                    "BOOKMARK": "保存題目",
                    "BOOKMARKED": "已保存題目",
                    // MODAL
                    "CONFIRM_LEAVE": "你確定要離開練習嗎？",
                    "CONFIRM_LEAVE_NO": "不，我要繼續練習",
                    "CONFIRM_LEAVE_YES": "對，我要離開",
                    "TIMES_UP": "時間到！",
                    "VIEW_MY_RESULTS": "查看成績",
                    "FORCE_GO_BACK_TITLE": "你檢查了所有的答案了嗎？",
                    "UDPATED_NAME": "你的名稱已更新。",
                    "UPDATED_NAME_BTN": "返回主頁",
                    "SENT_MSG": "訊息已被傳送。",
                    "TODAYS_VIDEO": "是日影片",
                    // VIDEO
                    "FINISHED_PRACTICE": "你完成今天的練習了！",
                    "BACK_TO_HOME": "回到主頁",
                    // EXERCISE
                    "LEVEL_3_WITH_OPTION_NO_REPEAT": "從以上的選項中選出合適的字詞，再用正確的文法把答案填在橫線上。選項不可以被重複使用。",
                    "LEVEL_3_WITH_OPTION_WITH_REPEAT": "從以上的選項中選出合適的字詞，再用正確的文法把答案填在橫線上。選項可以被重複使用。",
                    "LEVEL_3_WITHOUT_OPTION": "以正確的文法在橫線上填上適當的答案。",
                    "PRACTISE_SIMILAR_QUESTION": "練習類似題目",
                }
            },
        },
        lng: "en",
    });
