import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { useNavigate } from 'react-router-dom';
import { db } from "../../firebase";
import { doc, updateDoc } from 'firebase/firestore';

import '../../App.css';
import './_styles.css';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import AccountSettingUpdated from './AccountSettingUpdated';
import { AccountSettingsTextField, AccountSettingsConfirmButton } from './_components';
import { getUser } from '../../localStorage/User';
import { SendButton } from './_components';
import '../../translation'
import { useTranslation } from "react-i18next";
import {useMediaQuery} from "react-responsive";

function AccountSetting() {

    const { t } = useTranslation();
    const [updatedModalVisibility, setUpdatedModalVisibility] = useState(false)
    const [userName, setUserName] = useState("")
    const user = getUser();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    let navigate = useNavigate();
    useEffect(() => {
        (user.id === "" || user.id === null || user.id === undefined) && navigate("/login");
        // eslint-disable-next-line
    }, []);

    function titleCase(str) {
        var splitStr = str.split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    const updateUserName = () => {
        if (userName !== "") {
            setUpdatedModalVisibility(true);
            const userRefUser = doc(db, 'User', user.id);
            updateDoc(userRefUser, { nickname: titleCase(userName) });
        }
    }

    return (
        <>
            <Menu />
            <SideMenu />
            <div className="contactUsBackgroundImage">
                <div className="responsiveContainer">
                    <Grid container spacing={2}>
                        {!is_m&&                       <> <Grid item xs={6}></Grid>
                            <Grid item xs={2}></Grid></>}

                        <Grid item xs={12} sm={4} align="end">
                            <p className="accountSettingsText">{t("SETTING")}</p>
                            <p className="accountSettingsTypeText">{t("CHANGE_NAME")}</p>
                            <div className="newNameTextContainer">
                                <p className="newNameText">{t("NEW_NAME")}</p>
                                <AccountSettingsTextField fullWidth
                                    className='loginTextField'
                                    autoComplete='off'
                                    placeholder={t("PLEASE_TYPE")}
                                    inputProps={{
                                        style: {
                                            fontSize: 18,
                                            paddingLeft: 28,
                                            fontFamily: "Nunito",
                                            fontWeight: '600'
                                        }
                                    }}
                                    onChange={(e) => {
                                        setUserName(e.target.value)
                                    }} />
                            </div>
                            <div onClick={() => { updateUserName(); }} className="confirmButtonContainer">
                                <SendButton variant="contained" className="sendButton">{t("CONFIRM")}</SendButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <AccountSettingUpdated updatedModalVisibility={updatedModalVisibility} setUpdatedModalVisibility={setUpdatedModalVisibility} />
        </>
    );
}

export default AccountSetting;
