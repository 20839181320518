import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';
import '../../translation'

import logoSVG from '../../assets/general/logo.svg';
import logoSVGtest from '../../assets/general/logo_test.svg';
import moreSVG from '../../assets/menu/More.svg';
import moreSVG1 from '../../assets/image 77.png';
import logoutSVG from '../../assets/menu/logout.svg';
import languageSVG from '../../assets/menu/language.svg';
import Language from './Language';
import {getUser} from '../../localStorage/User';
import Logout from '../../pages/login/Logout';
import ConfirmLeave from '../../pages/exercise/ConfirmLeave';
import {t} from 'i18next';
import {NUMB} from "../../firebase.js";
import {useMediaQuery} from "react-responsive";
import {useLocation} from "react-router";

function Menu() {

    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const user = getUser();
    let navigate = useNavigate();
    const [logoutModalVisibility, setLogoutModalVisibility] = useState(false);
    const [confirmLeaveModalVisibility, setConfirmLeaveModalVisibility] = useState(false);

    const location = useLocation();
    // console.log(location.pathname);

    return (
        <>

            {is_m ?
                <div className="menuContainer" style={{
                    backgroundColor: (location.pathname === "/practice" || location.pathname === "/calibrationTest") ? "#FFDB8E" : "#FFFFFF",
                }}>

                    <div className="menuRight">
                        <Grid container alignItems="center"
                              justifyContent="center" spacing={2} className="menuLanguageContainer">
                            <Grid item xs={3}>
                                <img className="menuLanguage" src={languageSVG} alt="home"/>
                            </Grid>
                            <Grid item xs={9}>
                                <Language/>
                            </Grid>
                        </Grid>

                        {/*{(user.id !== "" && user.id !== null && user.id !== undefined) &&*/}
                        {/*    <div className="logoutContainer" onClick={() => {*/}
                        {/*        setLogoutModalVisibility(true)*/}
                        {/*    }}>*/}
                        {/*        <img className="menuLogout" src={logoutSVG} alt="logout"/>*/}
                        {/*        <p className="logoutText">{t('LOGOUT')}</p>*/}
                        {/*    </div>*/}
                        {/*}*/}


                        {NUMB != 3 && <img
                            onClick={
                                () => {

                                    if (NUMB != 2) {
                                        window.location.pathname !== "/calibrationTest" && window.location.pathname === "/practice"
                                            ?
                                            setConfirmLeaveModalVisibility(true)
                                            : window.location.pathname !== "/calibrationTest" &&
                                            navigate('/')
                                    }
                                }


                            }

                            className="menuLogo" src={NUMB == 0 ? logoSVGtest : logoSVG}
                            alt="QualiFly logo"
                        />}
                    </div>
                </div>
                :

                <div className="menuContainer">


                    {NUMB != 3 && <img onClick={() => {
                        window.location.pathname !== "/calibrationTest" && window.location.pathname === "/practice" ?
                            setConfirmLeaveModalVisibility(true)
                            : window.location.pathname !== "/calibrationTest" &&
                            navigate('/')
                    }} className="menuLogo" src={NUMB == 0 ? logoSVGtest : logoSVG} alt="QualiFly logo"/>

                    }
                    {NUMB == 3 &&
                        <div style={{
                            // border:"4px",
                            // display:"flex",
                            // justifyContent:"center"
                        }}
                        className="menuLogo1"
                        >


                            <img src={moreSVG1}/>
                            <span

                                className="menuLogo2"
                            >Roo</span>
                            <span

                                className="menuLogo3"
                            >Online</span>
                        </div>
                    }

                    <div className="menuRight">
                        <Grid container alignItems="center"
                              justifyContent="center" spacing={2} className="menuLanguageContainer">
                            <Grid item xs={3}>
                                <img className="menuLanguage" src={languageSVG} alt="home"/>
                            </Grid>
                            <Grid item xs={9}>
                                <Language/>
                            </Grid>
                        </Grid>

                        {(user.id !== "" && user.id !== null && user.id !== undefined && NUMB != 2) &&
                            <div className="logoutContainer" onClick={() => {
                                setLogoutModalVisibility(true)
                            }}>
                                <img className="menuLogout" src={logoutSVG} alt="logout"/>
                                <p className="logoutText">{t('LOGOUT')}</p>
                            </div>
                        }
                    </div>
                </div>}


            <div className="menuHeight60"/>


            {NUMB != 3 &&
                <Logout logoutModalVisibility={logoutModalVisibility}
                        setLogoutModalVisibility={setLogoutModalVisibility}/>
            }
            {NUMB != 3 &&
                <ConfirmLeave confirmLeaveModalVisibility={confirmLeaveModalVisibility}
                              setConfirmLeaveModalVisibility={setConfirmLeaveModalVisibility}/>
            }
        </>
    );
}

export default Menu;
