import Button from '@mui/material/Button';
import {makeStyles} from '@mui/styles';
import '../../translation'
import { useTranslation, Translation } from 'react-i18next';
import { getLanguage } from '../../localStorage/Language';

import vectorSVG from '../../assets/video/vector.svg';

export const LearningVideoPlayerReturnButton = () => {
    const useStyles = makeStyles({
        button: {
            backgroundColor: '#4BC4EF',
            color: '#FFFFFF',
            width: '11vw',
            borderRadius: '3vw',
            fontSize: '1vw',
            fontFamily: 'Nunito',
            fontWeight: '800',
            letterSpacing: '0.1vw',
            textTransform: 'none',
            marginTop: '1.3vw',
            '&:hover': {
                backgroundColor: '#4BC4EF',
                color: '#FFFFFF',
            },
        }
    })
    const classes = useStyles()
    return (
        <Button disableRipple className={classes.button}>
            <img style={{width: '0.8vw', marginRight: '0.5vw'}} src={vectorSVG} alt="<"></img>
            <Translation>
                {
                    (t, { i18n }) => <>{t('RETURN_BUTTON')}</>
                }
            </Translation>
        </Button>
    );
};
export const VideoSuggestionBackToHomeButton = () => {

    const { t } = useTranslation();
    const l = getLanguage();

    const useStyles = makeStyles({
        button: {
            backgroundColor: '#FFE351',
            color: '#000000',
            width: '11vw',
            borderRadius: '0.8vw',
            fontSize: '1vw',
            fontFamily: 'Poppins',
            fontWeight: '500',
            boxShadow: '0px 3px 2px #aaa',
            textTransform: 'none',
            marginTop: '1.3vw',
            '&:hover': {
                backgroundColor: '#FFE351',
                color: '#000000',
            },
        }
    })
    const classes = useStyles()
    return (
        <Button disableRipple className={classes.button}>
            {t("BACK_TO_HOME")}
        </Button>
    );
};
