import React from 'react';
import {styled} from '@mui/material/styles';
import Modal from 'react-modal';
import {useNavigate} from "react-router-dom";

import '../../App.css';
import './_styles.css';

import '../../translation'
import {useTranslation} from 'react-i18next';
import {getLanguage} from '../../localStorage/Language';

import {useMediaQuery} from "react-responsive";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";


// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const LogoutNoButton2 = styled(Button)(({theme})=>({

    backgroundColor: '#FFE351',
    color: 'black',
    width: '13vw',
    borderRadius: '0.8vw',
    fontSize: '1.1vw',
    fontFamily: 'Poppins',
    fontWeight: '500',
    boxShadow: '0px 3px 2px #aaa',
    textTransform: 'none',
    padding: "0.4vw",
    '&:hover': {
        backgroundColor: '#FFE351',
        color: 'black',
    },

    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFE351',
        color: 'black',
        width: '30vw',
        borderRadius: '1.2vw',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow: '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "0.4vw",
        '&:hover': {
            backgroundColor: '#FFE351',
            color: 'black',
        },


    }

}))

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const LogoutNoButton3 = styled(Button)(({theme})=>({

    backgroundColor: '#FFE351',
    color: 'black',
    // width: '13vw',
    borderRadius: '0.8vw',
    fontSize: '1.1vw',
    fontFamily: 'Poppins',
    fontWeight: '500',
    boxShadow: '0px 3px 2px #aaa',
    textTransform: 'none',
    padding: "0.4vw",
    '&:hover': {
        backgroundColor: '#FFE351',
        color: 'black',
    },



    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFE351',
        color: 'black',
        // width: '30vw',
        borderRadius: '1.2vw',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow: '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "10px",
        '&:hover': {
            backgroundColor: '#FFE351',
            color: 'black',
        },


    }

}))

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const LogoutYesButton2 = styled(Button)(({theme})=>({

    backgroundColor: '#AEF4F4',
    color: 'black',
    width: '13vw',
    borderRadius: '0.8vw',
    fontSize: '1.1vw',
    fontFamily: 'Poppins',
    fontWeight: '500',
    boxShadow: '0px 3px 2px #aaa',
    textTransform: 'none',
    padding: "0.4vw",
    '&:hover': {
        backgroundColor: '#AEF4F4',
        color: 'black',
    },

    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#AEF4F4',
        color: 'black',
        width: '30vw',
        borderRadius: '0.8vw',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow: '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "0.4vw",
        '&:hover': {
            backgroundColor: '#AEF4F4',
            color: 'black',
        },


    }

}))



// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const BackButton233 = styled(Button)(({theme})=>({

    // backgroundColor: '#AEF4F4',
    // color: 'black',
    // width: '13vw',
    // borderRadius: '0.8vw',
    // fontSize: '1.1vw',
    // fontFamily: 'Poppins',
    // fontWeight: '500',
    // boxShadow: '0px 3px 2px #aaa',
    // textTransform: 'none',
    // padding: "0.4vw",
    // '&:hover': {
    //     backgroundColor: '#AEF4F4',
    //     color: 'black',
    // },

    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFFFFF',
        color: 'black',
        width: '30vw',
        borderRadius: '0.8vw',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow:
            '2px 4px 4px rgba(0, 0, 0, 0.25)',
            // '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "0.4vw",
        // '&:hover': {
        //     backgroundColor: '#AEF4F4',
        //     color: 'black',
        // },


    }

}))
export const BackButton2333 = () => {
    const {t} = useTranslation();

    return (
        <BackButton233 disableRipple>
            Back
        </BackButton233>
    );
};


// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const NextButton233 = styled(Button)(({theme})=>({

    // backgroundColor: '#AEF4F4',
    // color: 'black',
    // width: '13vw',
    // borderRadius: '0.8vw',
    // fontSize: '1.1vw',
    // fontFamily: 'Poppins',
    // fontWeight: '500',
    // boxShadow: '0px 3px 2px #aaa',
    // textTransform: 'none',
    // padding: "0.4vw",
    // '&:hover': {
    //     backgroundColor: '#AEF4F4',
    //     color: 'black',
    // },

    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFE351',
        color: 'black',
        width: '30vw',
        borderRadius: '0.8vw',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        boxShadow:
            '2px 4px 4px rgba(0, 0, 0, 0.25)',
        // '0px 3px 2px #aaa',
        textTransform: 'none',
        padding: "0.4vw",
        // '&:hover': {
        //     backgroundColor: '#AEF4F4',
        //     color: 'black',
        // },


    }

}))
export const NextButton2333 = () => {
    const {t} = useTranslation();

    return (
        <NextButton233 disableRipple>
            Next
        </NextButton233>
    );
};




export const LogoutNoButton = () => {
    const {t} = useTranslation();

    return (
        <LogoutNoButton2 disableRipple>
            {t("LOGOUT_NO")}
        </LogoutNoButton2>
    );
};


export const BoothButton333333 = ({text8}) => {
    const {t} = useTranslation();

    return (
        <LogoutNoButton2 disableRipple>
            {text8||"Start"}
        </LogoutNoButton2>
    );
};


export const BoothButton333334 = () => {
    const {t} = useTranslation();

    return (
        <LogoutNoButton3 disableRipple>
            Check my UST student type!
        </LogoutNoButton3>
    );
};

export const BoothButton333335 = () => {
    const {t} = useTranslation();

    return (
        <LogoutNoButton3 disableRipple>
            {/*I want to participate in lucky draw for $100 coupons!*/}
            Result Analysis
        </LogoutNoButton3>
    );
};

export const BoothButton333339 = () => {
    const {t} = useTranslation();

    return (
        <LogoutNoButton3 disableRipple>
            Next
        </LogoutNoButton3>
    );
};

export const BoothButton333331 = () => {
    const {t} = useTranslation();

    return (
        <LogoutNoButton3 disableRipple>
            {/*Join our team*/}
            Our Website
        </LogoutNoButton3>
    );
};


export const LogoutYesButton = () => {
    const {t} = useTranslation();

    return (
        <LogoutYesButton2 disableRipple>
            {t("LOGOUT_YES")}
        </LogoutYesButton2>
    );
};

function Logout({
                    logoutModalVisibility,
                    setLogoutModalVisibility,
                }) {
    let navigate = useNavigate();
    const {t} = useTranslation();
    const l = getLanguage();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    return (
        (
            <div>
                <Modal
                    ariaHideApp={false}
                    isOpen={logoutModalVisibility}
                    className="logoutModalContainer"
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            backdropFilter: "blur(6px)",
                            WebkitBackdropFilter: "blur(6px)",
                            zIndex: 1000000
                        }
                    }}
                >
                    <div className="logoutBackgroundImage">
                        <p className="logoutConfirmText">{t("LOGOUT_CONFIRM_MSG")}</p>
                        <img className="logoutContent" src={require(
                            l === "en" ?
                                '../../assets/login/logoutContent.png'
                                :
                                '../../assets/login/logoutContent_zh-HK.png'
                        )}
                             alt="logout confirm"/>
                        <div className="logoutButtonContainer">
                            <div className="logoutDisplayInlineBlock" onClick={() => {
                                setLogoutModalVisibility(false)
                            }}>
                                <LogoutNoButton/>
                            </div>
                            <div className="logoutButtonMargin">

                            </div>
                            <div className="logoutDisplayInlineBlock" onClick={() => {
                                localStorage.removeItem("User");
                                navigate('/login')
                            }}>
                                <LogoutYesButton/>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    );
}

export default Logout;
