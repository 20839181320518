import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { db } from "../../firebase";
import { doc, getDoc } from 'firebase/firestore';
import { useTranslation } from "react-i18next";
import { getLanguage } from '../../localStorage/Language';

import '../../App.css';
import './_styles.css';
import '../../translation'

import daySVG from '../../assets/exercise/day.svg';
import dayZHHKSVG from '../../assets/exercise/dayZH-HK.svg';
import { getUser } from '../../localStorage/User';

function Day() {

  const { t } = useTranslation();
  const l = getLanguage();

  const user = getUser();
  const [day, setDay] = useState();
  useEffect(() => {
    getDay()
  }, [])
  const getDay = async () => {
    if (user.id !== "" && user.id !== null && user.id !== undefined) {
      const userSnapshot = await getDoc(doc(db, 'User', user.id));
      let userInfo = userSnapshot.data();
      let compareDate = new Date();
      compareDate.setHours(0);
      compareDate.setMinutes(0);
      compareDate.setSeconds(0);
      if(userInfo.loginDate){
        let count = 1;
        for(let i = userInfo.loginDate.length - 2; i>=0; i--){
          if(Math.round((compareDate - userInfo.loginDate[i].toDate()) / (1000 * 60 * 60 * 24)) === 1){
            compareDate = userInfo.loginDate[i].toDate();
            count += 1;
          }else{
            break;
          }
        }
        setDay(count);
      }
    }
  }

  return (
    <>
      <div className="dayContainer">
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={8} align="center">
            <img className="dayCover" src={l === "en" ? daySVG : dayZHHKSVG} alt="day cover"/>
          </Grid>
          <Grid item xs={4} align="center">
            <p className="dayUpperText">{day}</p>
            <p className="weekAndDayLowerText">{day > 1? "Days" : "Day"}</p>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Day;