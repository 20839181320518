import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {db} from "../../firebase";
import {doc, getDoc} from 'firebase/firestore';

import '../../App.css';
import './_styles.css';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import TodaysPractices from '../../pages/dashboard/TodaysPractices';
import QuestionBank from '../../pages/exercise/QuestionBank';
import {getExercise} from "../../functions/getExercise";
import {getUser} from '../../localStorage/User';

function Exercise() {

    const user = getUser();
    const [topic, setTopic] = useState();
    const location = useLocation();
    useEffect(() => {
        location.state && setTopic(location.state.topic);
    }, [])

    useEffect(() => {
        getUserInfo();
    }, [])

    // to get user information
    const [userInfo, setUserInfo] = useState();
    const getUserInfo = async () => {
        if (user.id !== "" && user.id !== null && user.id !== undefined) {
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            setUserInfo(userSnapshot.data());
        }
    }

    // to get today's exercise or generate today's exercise if not created
    useEffect(() => {
        getExercise(userInfo, user.id, setExercise, setExerciseID, setQuestionOfTheDay, setQuestionOfTheDayID);
    }, [userInfo])
    const [exercise, setExercise] = useState();
    const [exerciseID, setExerciseID] = useState();
    const [questionOfTheDay, setQuestionOfTheDay] = useState();
    const [questionOfTheDayID, setQuestionOfTheDayID] = useState();

    return (
        <>
            <Menu/>
            <SideMenu/>
            <div className="exerciseResponsiveContainer">
                {exercise && exerciseID && exercise.length > 0 && exerciseID.length > 0 &&
                    <TodaysPractices exercise={exercise} exerciseID={exerciseID}/>}



                {/*mobile way*/}
                {/*https://codesandbox.io/s/sxk1z?file=/src/index.js:83-100*/}

                {/*no library method*/}
                {/*https://stackoverflow.com/questions/66308478/render-grid-items-in-one-row-reactjs-and-material-ui*/}

                {/*possible one!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}
                {/*https://bit.cloud/akiran/react-slick/slider*/}
                {/*https://stackoverflow.com/questions/42000555/slick-carousel-want-center-slider-bigger-than-others*/}
                {/*this!!!!*/}
                {/*https://react-slick.neostack.com/docs/example/center-mode*/}
                

                {/*research*/}
                {/*https://stackoverflow.com/questions/71509819/can-i-show-some-part-of-the-next-slide-in-carousel-is-it-feasible-react-web*/}
                <QuestionBank topicInput={topic}/>
            </div>
        </>
    );
}

export default Exercise;
