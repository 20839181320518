import {db, istensetopic, lgg} from "../firebase";
import {addDoc, collection, doc, getDocs, query, updateDoc, where, getDoc} from 'firebase/firestore';

export const getExercise = async (userInfo, userID, setExercise, setExerciseID, setQuestionOfTheDay, setQuestionOfTheDayID) => {
    if (userInfo) {





        // ////////////////DEBUG
        // let tmpActiveTenseTopic1="presentContinuousTense"
        // const qQuestionActiveTenseTopic3 = query(collection(db, "Question"),
        //     where("topic", "==", tmpActiveTenseTopic1),
        //     where("level", "==", "1")
        // );
        //
        // const querySnapshot3 = await getDocs(qQuestionActiveTenseTopic3);
        // querySnapshot3.forEach((doc) => {
        //     // doc.data() is never undefined for query doc snapshots
        //     console.log(doc.id, " => ", doc.data());
        // });
        //
        // return
        // // const querySnapshotQuestionActiveTenseTopic = await getDocs(qQuestionActiveTenseTopic3);
        // // let tmpTenseTopicQuestionArray = [];
        // // let tmpAllTenseTopicQuestionArray = [];
        // // querySnapshotQuestionActiveTenseTopic.forEach((doc) => {
        // //     tmpAllTenseTopicQuestionArray.push(doc.id);
        // //     lgg(`qqq ${doc.id}`)
        // //     if (userInfo.answeredQuestion === undefined ||
        // //         !userInfo.answeredQuestion.includes(doc.id))// if user hasn't already answered this question
        // //     {
        // //         lgg("qqq2")
        // //         tmpTenseTopicQuestionArray.push(doc.id)
        // //     }
        // // });
        // // return 0;
        // /////////////////////END







        // to record login record when generate exercise
        let todayLogin = new Date();
        todayLogin.setHours(0);
        todayLogin.setMinutes(0);
        todayLogin.setSeconds(0);
        const userSnapshot = await getDoc(doc(db, 'User', userID));
        // to record user login date
        if (userSnapshot.data().loginDate === undefined)// first login
        {


            const userRef = doc(db, 'User', userID);
            await updateDoc(userRef, {
                loginDate: [todayLogin]
            });
        } else // non first login
        {
            if (Math.round(
                    (userSnapshot.data().loginDate[userSnapshot.data().loginDate.length - 1].toDate() - todayLogin)
                    / (1000 * 60 * 60 * 24))
                !== 0
            ) {
                let tmp = userSnapshot.data().loginDate
                tmp.push(todayLogin);
                const userRef = doc(db, 'User', userID);
                await updateDoc(userRef, {
                    loginDate: tmp
                });
            }
        }


        // to define variable for user information
        let tmpActiveTenseTopic = userInfo.activeTenseTopic;
        let tmpActiveTenseTopicLevel = userInfo.activeTenseTopicLevel;
        let tmpActiveNonTenseTopic = userInfo.activeNonTenseTopic;
        let tmpActiveNonTenseTopicLevel = userInfo.activeNonTenseTopicLevel;

        // to record the prev level for future use
        let tmpUserTenseTopicLevel = userInfo.topicLevel;
        const prevTenseLevel = tmpUserTenseTopicLevel[tmpActiveTenseTopic];

        var today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        const q = query(collection(db, "Exercise"), where("user", "==", userID));
        // all excercises of the user


        const querySnapshot = await getDocs(q);

        ////////////////////////////////////////////////// to check if exercise is created
        let createdTodaysPracticeFlag = false;
        let exerciseCount = 0;
        let tmpExercise = [];
        let tmpExerciseID = [];
        let tmpQuestionOfTheDay = [];
        let tmpQuestionOfTheDayID = [];
        let tenseTopicCreatedFlag = false;
        let nonTenseTopicCreatedFlag = false;
        querySnapshot.forEach((doc) => { // get created exercise  and  set flag for created
            if (doc.data().type != "calibrationTest" &&
                doc.data().type != "answerReviewExercise"
                // question bank or today practice


                // todo:
                // should question bank be here???????????????????
            ) {
                let exerciseDate = doc.data().date.toDate();
                exerciseDate.setHours(0);
                exerciseDate.setMinutes(0);
                exerciseDate.setSeconds(0);
                if (exerciseDate.toDateString() == today.toDateString()
                    // if exercise is created today
                ) {


                    lgg("found one exercise  today")
                    exerciseCount += 1;

                    lgg("exerciseCount", exerciseCount)
                    createdTodaysPracticeFlag = true;


                    if (doc.data().type === "todaysQuestion"
                        // question of the day
                    ) {
                        lgg("note : this belongs to question of the day")
                        tmpQuestionOfTheDay.push(doc.data());
                        tmpQuestionOfTheDayID.push(doc.id);
                    } else {
                        lgg("note : this belongs to today practice")
                        // today practice
                        tmpExercise.push(doc.data());
                        tmpExerciseID.push(doc.id);
                        if (
                            // EDIT: generalize
                            !istensetopic(doc.data().type)
                            // doc.data().type === "preposition" ||
                            // doc.data().type === "questionWord"

                            // belong to non tense topic
                        ) {
                            nonTenseTopicCreatedFlag = true
                        } else {
                            tenseTopicCreatedFlag = true;
                        }
                    }
                }
            }
        });


        lgg("0 update level or update topic check")
        // to update when exercise is not created
        if (exerciseCount < 3
            // if something shall be created
        ) {

            lgg("01 update level ")
            // 01 update the level

            const qExercise = query(collection(db, "Exercise"), where("user", "==", userID));

            lgg("get all excercises of the user ")
            // get all excercises of the user ( NOTE not only today!   is all)

            const querySnapshotExercise = await getDocs(qExercise);
            let tmpTenseTopicExerciseUpdateLevel = [];
            let tmpNonTenseTopicExerciseUpdateLevel = [];
            querySnapshotExercise.forEach((doc) => {
                if (
                    // and accuracy is defined    , what does this imply, sth is answer
                    doc.data().accuracy !== undefined &&
                    doc.data().accuracy >= 0 &&

                    // if the exercise is not calibration test, answer review exercise, question bank
                    doc.data().type != "calibrationTest" &&
                    doc.data().type != "answerReviewExercise" &&
                    doc.data().type != "questionBank"


                ) {
                    if (

                        doc.data().type == "pastTense" ||
                        doc.data().type == "pastTensePresentTense" ||
                        doc.data().type == "presentContinuousTense" ||
                        doc.data().type == "presentTensePresentContinuousTense"||

                        // EDIT002: add 2 tense
                        doc.data().type == "PACT_1" ||
                        doc.data().type == "PPT_1"
                    ) {// add to 4 tense topic array
                        tmpTenseTopicExerciseUpdateLevel.push(doc.data());
                    } else {// add to non tense topic array
                        tmpNonTenseTopicExerciseUpdateLevel.push(doc.data());
                    }
                }
            });

            lgg("001 to update user level for tense topic")
            // 001 to update user level for tense topic

            // sort for easier calculation
            tmpTenseTopicExerciseUpdateLevel.sort(function (a, b) {
                return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
            });
            if (tmpTenseTopicExerciseUpdateLevel.length > 1) {


                // to update user level for tense topic


                if (
                    // accuracy is above 0.8

                    tmpTenseTopicExerciseUpdateLevel[0].accuracy >= 0.8 &&
                    tmpTenseTopicExerciseUpdateLevel[1].accuracy >= 0.8 &&

                    // last two exercise is same tense topic
                    tmpTenseTopicExerciseUpdateLevel[0].type === tmpTenseTopicExerciseUpdateLevel[1].type &&
                    tmpTenseTopicExerciseUpdateLevel[0].type === tmpActiveTenseTopic &&
                    tmpTenseTopicExerciseUpdateLevel[1].type === tmpActiveTenseTopic

                ) {
                    // to increase level

                    let tmpUserTopicLevel = userInfo.topicLevel; // a dict
                    const tmpPrevLevel = userInfo.activeTenseTopicLevel; // an integer ,  only for storing for level change event!!!!

                    lgg(`original level ${parseInt(userInfo.activeTenseTopicLevel)}  ${tmpActiveTenseTopic}`)


                    // todo : check whether the level exists


                    tmpActiveTenseTopicLevel = Math.min(3,
                        parseInt(userInfo.activeTenseTopicLevel) + 1
                    ).toString()
                    lgg(`+1, now level is ${tmpActiveTenseTopicLevel}`)
                    tmpUserTopicLevel[tmpActiveTenseTopic] = tmpActiveTenseTopicLevel;


                    const userRef = doc(db, 'User', userID);
                    await updateDoc(userRef, {
                        activeTenseTopicLevel: tmpActiveTenseTopicLevel,
                        topicLevel: tmpUserTopicLevel
                    });


                    // to submit the tense update record to DB
                    const tmpUpdateHistory = {
                        date: new Date(),
                        topic: tmpActiveTenseTopic,
                        user: userID,
                        levelTo: tmpActiveTenseTopicLevel,
                        levelFrom: tmpPrevLevel,
                    };

                    tmpActiveTenseTopicLevel != tmpPrevLevel  // 2 integer comparsion
                    && await addDoc(collection(db, "UpdateLevelHistory"), tmpUpdateHistory);

                    let g1=tmpActiveTenseTopic
                    let g2=tmpUpdateHistory
                    if(tmpActiveTenseTopicLevel != tmpPrevLevel){

                        const userSnapshot = await getDoc(doc(db, 'User', userID));

                        if (userSnapshot.data().levelhistory === undefined) {

                            // let tt2=userSnapshot.data().topicLevel

                            // for all topic, init
                            // {"t1":[],"t2":[],"t3":[],"t4":[]}
                            let p9={}
                            p9[g1]=[g2  ]

                            const userRef = doc(db, 'User', userID);
                            updateDoc(userRef, {
                                levelhistory: p9
                            });

                        } else {

                            let p9 = userSnapshot.data().levelhistory

                            if (p9[g1] === undefined) {
                                p9[g1] = []
                            }

                            p9[g1].push(g2);
                            const userRef = doc(db, 'User', userID);
                            updateDoc(userRef, {
                                levelhistory: p9
                            });
                        }
                    }



                }else {
                    lgg("no level + for tense topic")
                }


                // to decrease level
                if (tmpTenseTopicExerciseUpdateLevel[0].type === tmpTenseTopicExerciseUpdateLevel[1].type &&
                    tmpTenseTopicExerciseUpdateLevel[0].accuracy < 0.6 &&
                    tmpTenseTopicExerciseUpdateLevel[1].accuracy < 0.6
                ) {


                    // decrease level condition
                    let tmpUserTopicLevel = userInfo.topicLevel;
                    const tmpPrevLevel = userInfo.activeTenseTopicLevel;
                    tmpActiveTenseTopicLevel = Math.max(1,
                        parseInt(userInfo.activeTenseTopicLevel) - 1).toString()
                    tmpUserTopicLevel[tmpActiveTenseTopic] = tmpActiveTenseTopicLevel

                    const userRef = doc(db, 'User', userID);
                    await updateDoc(userRef, {
                        activeTenseTopicLevel: tmpActiveTenseTopicLevel,
                        topicLevel: tmpUserTopicLevel
                    });


                    // to submit the tense update record to DB
                    const tmpUpdateHistory = {
                        date: new Date(),
                        topic: tmpActiveTenseTopic,
                        user: userID,
                        levelTo: tmpActiveTenseTopicLevel,
                        levelFrom: tmpPrevLevel,
                    };
                    tmpActiveTenseTopicLevel != tmpPrevLevel && await addDoc(collection(db, "UpdateLevelHistory"), tmpUpdateHistory);

                    let g1=tmpActiveTenseTopic
                    let g2=tmpUpdateHistory
                    if(tmpActiveTenseTopicLevel != tmpPrevLevel){
                        const userSnapshot = await getDoc(doc(db, 'User', userID));
                        if (userSnapshot.data().levelhistory === undefined) {
                            // let tt2=userSnapshot.data().topicLevel
                            // for all topic, init
                            // {"t1":[],"t2":[],"t3":[],"t4":[]}
                            let p9={}
                            p9[g1]=[g2  ]
                            const userRef = doc(db, 'User', userID);
                            updateDoc(userRef, {
                                levelhistory: p9
                            });

                        } else {
                            let p9 = userSnapshot.data().levelhistory
                            if (p9[g1] === undefined) {
                                p9[g1] = []
                            }
                            p9[g1].push(g2);
                            const userRef = doc(db, 'User', userID);
                            updateDoc(userRef, {
                                levelhistory: p9
                            });
                        }
                    }


                }else {
                    lgg("no level - for tense topic")
                }
            } else {
                lgg("001 end, not enough tense topic exercise")
            }


            // 002 to update user level for non tense topic

            // sort for easier calculation
            tmpNonTenseTopicExerciseUpdateLevel.sort(function (a, b) {
                return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
            });
            if (tmpNonTenseTopicExerciseUpdateLevel.length > 1) {
                // to increase level
                if (tmpNonTenseTopicExerciseUpdateLevel[0].type === tmpNonTenseTopicExerciseUpdateLevel[1].type &&
                    tmpNonTenseTopicExerciseUpdateLevel[0].accuracy >= 0.8 &&
                    tmpNonTenseTopicExerciseUpdateLevel[1].accuracy >= 0.8 &&
                    tmpNonTenseTopicExerciseUpdateLevel[0].type === tmpActiveNonTenseTopic &&
                    tmpNonTenseTopicExerciseUpdateLevel[1].type === tmpActiveNonTenseTopic
                ) {




                    let tmpUserTopicLevel = userInfo.topicLevel;
                    const tmpPrevLevel = userInfo.activeNonTenseTopicLevel;

                    // todo : check whether the level exists


                    tmpActiveNonTenseTopicLevel = Math.min(2, parseInt(userInfo.activeNonTenseTopicLevel) + 1).toString()
                    tmpUserTopicLevel[tmpActiveNonTenseTopic] = tmpActiveNonTenseTopicLevel;
                    const userRef = doc(db, 'User', userID);
                    await updateDoc(userRef, {
                        activeNonTenseTopicLevel: tmpActiveNonTenseTopicLevel,
                        topicLevel: tmpUserTopicLevel
                    });

                    ///////////////////////////// to submit the non-tense update record to DB
                    const tmpUpdateHistory = {
                        date: new Date(),
                        topic: tmpActiveNonTenseTopic,
                        user: userID,
                        levelTo: tmpActiveNonTenseTopicLevel,
                        levelFrom: tmpPrevLevel,
                    };
                    tmpActiveNonTenseTopicLevel != tmpPrevLevel && await addDoc(collection(db, "UpdateLevelHistory"), tmpUpdateHistory);


                    let g1=tmpActiveNonTenseTopic
                    let g2=tmpUpdateHistory
                    if(tmpActiveNonTenseTopicLevel != tmpPrevLevel){
                        const userSnapshot = await getDoc(doc(db, 'User', userID));
                        if (userSnapshot.data().levelhistory === undefined) {
                            // let tt2=userSnapshot.data().topicLevel
                            // for all topic, init
                            // {"t1":[],"t2":[],"t3":[],"t4":[]}
                            let p9={}
                            p9[g1]=[g2  ]
                            const userRef = doc(db, 'User', userID);
                            updateDoc(userRef, {
                                levelhistory: p9
                            });

                        } else {
                            let p9 = userSnapshot.data().levelhistory
                            if (p9[g1] === undefined) {
                                p9[g1] = []
                            }
                            p9[g1].push(g2);
                            const userRef = doc(db, 'User', userID);
                            updateDoc(userRef, {
                                levelhistory: p9
                            });
                        }
                    }



                }
                // to decrease level
                if (tmpNonTenseTopicExerciseUpdateLevel[0].type === tmpNonTenseTopicExerciseUpdateLevel[1].type &&
                    tmpNonTenseTopicExerciseUpdateLevel[0].accuracy < 0.6 &&
                    tmpNonTenseTopicExerciseUpdateLevel[1].accuracy < 0.6
                ) {

                    let tmpUserTopicLevel = userInfo.topicLevel;
                    const tmpPrevLevel = userInfo.activeNonTenseTopicLevel;
                    tmpActiveNonTenseTopicLevel = Math.max(1, parseInt(userInfo.activeNonTenseTopicLevel) - 1).toString()
                    tmpUserTopicLevel[tmpActiveNonTenseTopic] = tmpActiveNonTenseTopicLevel
                    const userRef = doc(db, 'User', userID);
                    updateDoc(userRef, {
                        activeNonTenseTopicLevel: tmpActiveNonTenseTopicLevel,
                        topicLevel: tmpUserTopicLevel
                    });

                    ///////////////////////////// to submit the non-tense update record to DB
                    const tmpUpdateHistory = {
                        date: new Date(),
                        topic: tmpActiveNonTenseTopic,
                        user: userID,
                        levelTo: tmpActiveNonTenseTopicLevel,
                        levelFrom: tmpPrevLevel,
                    };
                    tmpActiveNonTenseTopicLevel != tmpPrevLevel && await addDoc(collection(db, "UpdateLevelHistory"), tmpUpdateHistory);


                    let g1=tmpActiveNonTenseTopic
                    let g2=tmpUpdateHistory
                    if(tmpActiveNonTenseTopicLevel != tmpPrevLevel){
                        const userSnapshot = await getDoc(doc(db, 'User', userID));
                        if (userSnapshot.data().levelhistory === undefined) {
                            // let tt2=userSnapshot.data().topicLevel
                            // for all topic, init
                            // {"t1":[],"t2":[],"t3":[],"t4":[]}
                            let p9={}
                            p9[g1]=[g2  ]
                            const userRef = doc(db, 'User', userID);
                            updateDoc(userRef, {
                                levelhistory: p9
                            });

                        } else {
                            let p9 = userSnapshot.data().levelhistory
                            if (p9[g1] === undefined) {
                                p9[g1] = []
                            }
                            p9[g1].push(g2);
                            const userRef = doc(db, 'User', userID);
                            updateDoc(userRef, {
                                levelhistory: p9
                            });
                        }
                    }

                }
            } else {
                lgg("002 end, not enough nontense topic exercise, no level update")
            }


            lgg("02 update TOPIC ")

            // 02 UPDATE TOPIC


            lgg("001 update user topic for tense topic")
            // 001 to update user topic for tense topic
            lgg("0001 rule check")
            lgg("00001 rule 1")
            ////////////  00001 rule 1
            let changeTopipcRule1Flag = true;
            if (tmpTenseTopicExerciseUpdateLevel.length >= 5) {
                for (let i = 0; i < 5; i++) {
                    if (tmpTenseTopicExerciseUpdateLevel[i].type !== tmpActiveTenseTopic) {  // at least one day different topic as today
                        changeTopipcRule1Flag = false
                    }
                    if (tmpTenseTopicExerciseUpdateLevel[i].level !== tmpActiveTenseTopicLevel) {  //  at least one day different level as today
                        changeTopipcRule1Flag = false
                    }
                }

                if (changeTopipcRule1Flag) {

                    lgg("00001 rule 1 ok")
                } else {
                    lgg("00001 rule 1 failed")
                }
            } else { // not even 5 days history
                changeTopipcRule1Flag = false


                lgg("00001 rule 1 failed")
            }

            lgg("00002 rule 2")
            ////////////  00002 rule 2
            let changeTopipcRule2Flag = true;
            if (tmpTenseTopicExerciseUpdateLevel.length >= 2 && tmpActiveTenseTopicLevel === "3" && prevTenseLevel === "3") { // level 3 for latest 2 day
                for (let i = 0; i < 2; i++) {
                    if (tmpTenseTopicExerciseUpdateLevel[i].accuracy && tmpTenseTopicExerciseUpdateLevel[i].accuracy < 0.8) { // compare [0,1] [1,2]
                        changeTopipcRule2Flag = false
                    }
                }

                if (changeTopipcRule2Flag) {
                    lgg("00002 rule 2 ok")
                } else {
                    lgg("00002 rule 2 failed")
                }
            } else {
                changeTopipcRule2Flag = false
                lgg("00002 rule 2 failed")
            }


            lgg("00003 rule 3")
            ////////////  00003 rule 3
            let changeTopipcRule3Flag = true;
            if (tmpTenseTopicExerciseUpdateLevel.length >= 15) {
                for (let i = 0; i < 5; i++) {
                    if (tmpTenseTopicExerciseUpdateLevel[i].type !== tmpActiveTenseTopic) { // at least one day not today tpoic
                        changeTopipcRule3Flag = false
                    }
                }
                if (changeTopipcRule3Flag) {
                    lgg("00003 rule 3 ok")
                } else {
                    lgg("00003 rule 3 failed")
                }
            } else {
                changeTopipcRule3Flag = false
                lgg("00003 rule 3 failed")
            }



            lgg("0002 seeing if at least one rule passed")
            //////////// update tense active topic and set to reasonable level
            if (changeTopipcRule1Flag || changeTopipcRule2Flag || changeTopipcRule3Flag) {
                lgg("0002 at least one rule passed")




                const userSnapshot = await getDoc(doc(db, 'User', userID));
                let p56Flag = false;
                if (userSnapshot.data().formm == "p5"||userSnapshot.data().formm == "p6" ) {
                    console.log("p5 or p6 pass")
                    p56Flag = true

                }
                ;

                if (p56Flag){
                    // pastTensePresentTense: 0,
                    //     preposition: 0,
                    //     presentContinuousTense: 0,
                    //     presentTensePresentContinuousTense: 0,
                    if (userSnapshot.data().formmp5_path == "below40"){


                        // P.4 PRCT -->  PAT --> PRTxPRCT --> PRTxPAT --> P.5 (PACT --> PPT)

                        const userRef = doc(db, 'User', userID);

                        if (tmpActiveTenseTopic === "presentContinuousTense") {

                            let to1="pastTense"


                            let tmpLevel;
                            if (

                                // if not initialized
                                userInfo.topicLevel[to1] === 0 ||
                                userInfo.topicLevel[to1] === "0"


                            ) {
                                tmpLevel = "1";
                            } else {   // last time recorded level
                                tmpLevel = userInfo.topicLevel[to1];
                            }
                            await updateDoc(userRef, {
                                activeTenseTopic: to1,
                                activeTenseTopicLevel: tmpLevel,
                            });
                        }


                        if (tmpActiveTenseTopic === "pastTense") {

                            let to1="presentTensePresentContinuousTense"


                            let tmpLevel;
                            if (

                                // if not initialized
                                userInfo.topicLevel[to1] === 0 ||
                                userInfo.topicLevel[to1] === "0"


                            ) {
                                tmpLevel = "1";
                            } else {   // last time recorded level
                                tmpLevel = userInfo.topicLevel[to1];
                            }
                            await updateDoc(userRef, {
                                activeTenseTopic: to1,
                                activeTenseTopicLevel: tmpLevel,
                            });
                        }

                        if (tmpActiveTenseTopic === "presentTensePresentContinuousTense") {

                            let to1="pastTensePresentTense"


                            let tmpLevel;
                            if (

                                // if not initialized
                                userInfo.topicLevel[to1] === 0 ||
                                userInfo.topicLevel[to1] === "0"


                            ) {
                                tmpLevel = "1";
                            } else {   // last time recorded level
                                tmpLevel = userInfo.topicLevel[to1];
                            }
                            await updateDoc(userRef, {
                                activeTenseTopic: to1,
                                activeTenseTopicLevel: tmpLevel,
                            });
                        }

                        if (tmpActiveTenseTopic === "pastTensePresentTense") {

                            let to1="PACT_1"


                            let tmpLevel;
                            if (

                                // if not initialized
                                userInfo.topicLevel[to1] === 0 ||
                                userInfo.topicLevel[to1] === "0"


                            ) {
                                tmpLevel = "1";
                            } else {   // last time recorded level
                                tmpLevel = userInfo.topicLevel[to1];
                            }
                            await updateDoc(userRef, {
                                activeTenseTopic: to1,
                                activeTenseTopicLevel: tmpLevel,
                            });
                        }

                        if (tmpActiveTenseTopic === "PACT_1") {

                            let to1="PPT_1"


                            let tmpLevel;
                            if (

                                // if not initialized
                                userInfo.topicLevel[to1] === 0 ||
                                userInfo.topicLevel[to1] === "0"


                            ) {
                                tmpLevel = "1";
                            } else {   // last time recorded level
                                tmpLevel = userInfo.topicLevel[to1];
                            }
                            await updateDoc(userRef, {
                                activeTenseTopic: to1,
                                activeTenseTopicLevel: tmpLevel,
                            });
                        }

                        if (tmpActiveTenseTopic === "PPT_1") {

                            let to1="presentContinuousTense"


                            let tmpLevel;
                            if (

                                // if not initialized
                                userInfo.topicLevel[to1] === 0 ||
                                userInfo.topicLevel[to1] === "0"


                            ) {
                                tmpLevel = "1";
                            } else {   // last time recorded level
                                tmpLevel = userInfo.topicLevel[to1];
                            }
                            await updateDoc(userRef, {
                                activeTenseTopic: to1,
                                activeTenseTopicLevel: tmpLevel,
                            });
                        }

                    }
                    else if (userSnapshot.data().formmp5_path == "!below40")
                    {

                        // PACT --> PPT --> p4 (PRTxPRCT, PRTxPAT)
                        const userRef = doc(db, 'User', userID);
                        if (tmpActiveTenseTopic === "PACT_1") {

                            let to1="PPT_1"


                            let tmpLevel;
                            if (

                                // if not initialized
                                userInfo.topicLevel[to1] === 0 ||
                                userInfo.topicLevel[to1] === "0"


                            ) {
                                tmpLevel = "1";
                            } else {   // last time recorded level
                                tmpLevel = userInfo.topicLevel[to1];
                            }
                            await updateDoc(userRef, {
                                activeTenseTopic: to1,
                                activeTenseTopicLevel: tmpLevel,
                            });
                        }

                        if (tmpActiveTenseTopic === "PPT_1") {

                            let to1="presentTensePresentContinuousTense"


                            let tmpLevel;
                            if (

                                // if not initialized
                                userInfo.topicLevel[to1] === 0 ||
                                userInfo.topicLevel[to1] === "0"


                            ) {
                                tmpLevel = "1";
                            } else {   // last time recorded level
                                tmpLevel = userInfo.topicLevel[to1];
                            }
                            await updateDoc(userRef, {
                                activeTenseTopic: to1,
                                activeTenseTopicLevel: tmpLevel,
                            });
                        }

                        if (tmpActiveTenseTopic === "presentTensePresentContinuousTense") {

                            let to1="pastTensePresentTense"


                            let tmpLevel;
                            if (

                                // if not initialized
                                userInfo.topicLevel[to1] === 0 ||
                                userInfo.topicLevel[to1] === "0"


                            ) {
                                tmpLevel = "1";
                            } else {   // last time recorded level
                                tmpLevel = userInfo.topicLevel[to1];
                            }
                            await updateDoc(userRef, {
                                activeTenseTopic: to1,
                                activeTenseTopicLevel: tmpLevel,
                            });
                        }


                        if (tmpActiveTenseTopic === "pastTensePresentTense") {

                            let to1="PACT_1"


                            let tmpLevel;
                            if (

                                // if not initialized
                                userInfo.topicLevel[to1] === 0 ||
                                userInfo.topicLevel[to1] === "0"


                            ) {
                                tmpLevel = "1";
                            } else {   // last time recorded level
                                tmpLevel = userInfo.topicLevel[to1];
                            }
                            await updateDoc(userRef, {
                                activeTenseTopic: to1,
                                activeTenseTopicLevel: tmpLevel,
                            });
                        }

                    }

                }
                else
                {
                    const userRef = doc(db, 'User', userID);
                    if (tmpActiveTenseTopic === "pastTense") {
                        let tmpLevel;
                        if (

                            // if not initialized
                            userInfo.topicLevel["pastTensePresentTense"] === 0 ||
                            userInfo.topicLevel["pastTensePresentTense"] === "0"


                        ) {
                            tmpLevel = "1";
                        } else {   // last time recorded level
                            tmpLevel = userInfo.topicLevel["pastTensePresentTense"];
                        }
                        await updateDoc(userRef, {
                            activeTenseTopic: "pastTensePresentTense",
                            activeTenseTopicLevel: tmpLevel,
                        });
                    }

                    if (tmpActiveTenseTopic === "pastTensePresentTense") {
                        let tmpLevel;
                        if (


                            userInfo.topicLevel["presentContinuousTense"] === 0 ||
                            userInfo.topicLevel["presentContinuousTense"] === "0") {
                            tmpLevel = "1";
                        } else {
                            tmpLevel = userInfo.topicLevel["presentContinuousTense"];
                        }
                        await updateDoc(userRef, {
                            activeTenseTopic: "presentContinuousTense",
                            activeTenseTopicLevel: tmpLevel,
                        });
                    }

                    if (tmpActiveTenseTopic === "presentContinuousTense") {
                        let tmpLevel;
                        if (userInfo.topicLevel["presentTensePresentContinuousTense"] === 0 ||
                            userInfo.topicLevel["presentTensePresentContinuousTense"] === "0") {
                            tmpLevel = "1";
                        } else {
                            tmpLevel = userInfo.topicLevel["presentTensePresentContinuousTense"];
                        }
                        await updateDoc(userRef, {
                            activeTenseTopic: "presentTensePresentContinuousTense",
                            activeTenseTopicLevel: tmpLevel,
                        });
                    }

                    if (tmpActiveTenseTopic === "presentTensePresentContinuousTense") {
                        let tmpLevel;
                        if (userInfo.topicLevel["pastTense"] === 0 ||
                            userInfo.topicLevel["pastTense"] === "0") {
                            tmpLevel = "1";
                        } else {
                            tmpLevel = userInfo.topicLevel["pastTense"];
                        }
                        await updateDoc(userRef, {
                            activeTenseTopic: "pastTense",
                            activeTenseTopicLevel: tmpLevel,
                        });
                    }

                }


            } else {
                lgg("0002 no rule passed")
            }

            lgg("002 update user topic for  NON tense topic")
            // 002 to update user topic for NON tense topic
            // to update user topic for non tense topic
            let changeNonTopipcRule1Flag = true;
            if (tmpNonTenseTopicExerciseUpdateLevel.length >= 5) {
                for (let i = 0; i < 5; i++) {
                    if (tmpNonTenseTopicExerciseUpdateLevel[i].type !== tmpActiveNonTenseTopic) {
                        changeNonTopipcRule1Flag = false
                    }
                    if (tmpNonTenseTopicExerciseUpdateLevel[i].level !== tmpActiveNonTenseTopicLevel) {
                        changeNonTopipcRule1Flag = false
                    }
                }
            } else {
                changeNonTopipcRule1Flag = false
            }

            lgg(`changeNonTopipcRule1Flag is ${changeNonTopipcRule1Flag}`)

            let changeNonTopipcRule3Flag = true;
            if (tmpNonTenseTopicExerciseUpdateLevel.length >= 15) {
                for (let i = 0; i < 5; i++) {
                    if (tmpNonTenseTopicExerciseUpdateLevel[i].topic !== tmpActiveNonTenseTopic) {
                        changeNonTopipcRule3Flag = false
                    }
                }
            } else {
                changeNonTopipcRule3Flag = false
            }

            lgg(`changeNonTopipcRule3Flag is ${changeNonTopipcRule3Flag}`)




            if (changeNonTopipcRule1Flag || changeNonTopipcRule3Flag) {



                //////////////////////////////////////////////////////////// 1

                const userSnapshot = await getDoc(doc(db, 'User', userID));
                let p56Flag = false;
                if (userSnapshot.data().formm == "p5"||userSnapshot.data().formm == "p6" ) {
                    console.log("p5 or p6 pass")
                    p56Flag = true

                }
                ;

                if (p56Flag){
                    // pastTensePresentTense: 0,
                    //     preposition: 0,
                    //     presentContinuousTense: 0,
                    //     presentTensePresentContinuousTense: 0,
                    const userRef = doc(db, 'User', userID);



                    if (tmpActiveNonTenseTopic === "BIxTI_1") {
                        let to1="BIxGER_1"

                        let tmpLevel;
                        if (userInfo.topicLevel[to1] === 0 || userInfo.topicLevel[to1] === "0") {
                            tmpLevel = "1";
                        } else {
                            tmpLevel = userInfo.topicLevel[to1];
                        }
                        await updateDoc(userRef, {
                            activeNonTenseTopic: to1,
                            activeNonTenseTopicLevel: tmpLevel,
                        });
                    }


                    if (tmpActiveNonTenseTopic === "BIxGER_1") {
                        let to1="ART_1"

                        let tmpLevel;
                        if (userInfo.topicLevel[to1] === 0 || userInfo.topicLevel[to1] === "0") {
                            tmpLevel = "1";
                        } else {
                            tmpLevel = userInfo.topicLevel[to1];
                        }
                        await updateDoc(userRef, {
                            activeNonTenseTopic: to1,
                            activeNonTenseTopicLevel: tmpLevel,
                        });
                    }

                    if (tmpActiveNonTenseTopic === "ART_1") {
                        let to1="COM_1"

                        let tmpLevel;
                        if (userInfo.topicLevel[to1] === 0 || userInfo.topicLevel[to1] === "0") {
                            tmpLevel = "1";
                        } else {
                            tmpLevel = userInfo.topicLevel[to1];
                        }
                        await updateDoc(userRef, {
                            activeNonTenseTopic: to1,
                            activeNonTenseTopicLevel: tmpLevel,
                        });
                    }

                    if (tmpActiveNonTenseTopic === "COM_1") {
                        let to1="CONJ_1"

                        let tmpLevel;
                        if (userInfo.topicLevel[to1] === 0 || userInfo.topicLevel[to1] === "0") {
                            tmpLevel = "1";
                        } else {
                            tmpLevel = userInfo.topicLevel[to1];
                        }
                        await updateDoc(userRef, {
                            activeNonTenseTopic: to1,
                            activeNonTenseTopicLevel: tmpLevel,
                        });
                    }

                    if (tmpActiveNonTenseTopic === "CONJ_1") {
                        let to1="BIxTI_1"

                        let tmpLevel;
                        if (userInfo.topicLevel[to1] === 0 || userInfo.topicLevel[to1] === "0") {
                            tmpLevel = "1";
                        } else {
                            tmpLevel = userInfo.topicLevel[to1];
                        }
                        await updateDoc(userRef, {
                            activeNonTenseTopic: to1,
                            activeNonTenseTopicLevel: tmpLevel,
                        });
                    }

                }
                else
                {
                    const userRef = doc(db, 'User', userID);
                    if (tmpActiveNonTenseTopic === "preposition") {
                        let tmpLevel;
                        if (userInfo.topicLevel["questionWord"] === 0 || userInfo.topicLevel["questionWord"] === "0") {
                            tmpLevel = "1";
                        } else {
                            tmpLevel = userInfo.topicLevel["questionWord"];
                        }
                        updateDoc(userRef, {
                            activeNonTenseTopic: "questionWord",
                            activeNonTenseTopicLevel: tmpLevel,
                        });
                    }
                    ;
                    if (tmpActiveNonTenseTopic === "questionWord") {
                        let tmpLevel;
                        if (userInfo.topicLevel["preposition"] === 0 || userInfo.topicLevel["preposition"] === "0") {
                            tmpLevel = "1";
                        } else {
                            tmpLevel = userInfo.topicLevel["preposition"];
                        }
                        updateDoc(userRef, {
                            activeNonTenseTopic: "preposition",
                            activeNonTenseTopicLevel: tmpLevel,
                        });
                    }

                }





                /////////////////////////////////////////////////////////////1 end















            }else{
                lgg("no flag pass, no update on non tense topic")
            }
            lgg(" 01 end")

        } else {
            lgg(" 01 end, as exerciseCount >=3 , no need to update level and topic")
        }


        lgg("2 set exercise")
        //SET EXCERCISE

        lgg("01       set exercise or  'create and set' ")
        //to set exercise if created already
        if (exerciseCount >= 3) {
            lgg("01 set exercise directly, as exerciseCount >=3")

            tmpQuestionOfTheDay.length > 0 && setQuestionOfTheDay(tmpQuestionOfTheDay); // to set exercise if exist
            tmpQuestionOfTheDayID.length > 0 && setQuestionOfTheDayID(tmpQuestionOfTheDayID); // to set exercise id if exist
            tmpExercise.length > 0 && setExercise(tmpExercise); // to set exercise if exist
            tmpExerciseID.length > 0 && setExerciseID(tmpExerciseID); // to set exercise id if exist

        }

        //to create exercise if not created
        else {
            lgg("01 'create and set'")
            let docRefAddTenseTopicExercise, docRefAddNonTenseTopicExercise, tmpTenseTopicExercise,
                tmpNonTenseTopicExercise;
            let addAnsweredQuestionArray = []
            let deleteAnsweredQuestionArray = []

            lgg("001 'creating qday'")
            // create question of the day
            if (tmpQuestionOfTheDay.length === 0) { // if today not exist  question of the day
                // to create question of the day
                const qQuestionOfTheDay = query(collection(db, "Question"),
                    where("level", "==", "1")
                );
                const querySnapshotQuestionOfTheDay = await getDocs(qQuestionOfTheDay);
                let tmpQuestionOfTheDayArray = []
                querySnapshotQuestionOfTheDay.forEach((doc) => {
                    tmpQuestionOfTheDayArray.push(doc.id)
                });
                // to create question of the day
                const tmpTodaysQuestionExercise = {
                    date: new Date(),
                    type: "todaysQuestion",
                    user: userID,
                    question: [tmpQuestionOfTheDayArray[Math.floor(Math.random() * tmpQuestionOfTheDayArray.length)]],
                };
                const docRefAddQuestionOfTheDayExercise = await addDoc(collection(db, "Exercise"), tmpTodaysQuestionExercise);
                tmpQuestionOfTheDay.push(tmpTodaysQuestionExercise)
                tmpQuestionOfTheDayID.push(docRefAddQuestionOfTheDayExercise.id)
            }


            lgg("002 'set qday'")
            setQuestionOfTheDay(tmpQuestionOfTheDay);
            setQuestionOfTheDayID(tmpQuestionOfTheDayID);


            lgg("003 'check if need to creating today exercise, create if needed'")
            // create tense topic exercise
            if (!tenseTopicCreatedFlag) {  // if not yet created tense topic today

                lgg("0001 'creating today exercise tense'")

                // lgg(`tmpActiveTenseTopic ${tmpActiveTenseTopic} ${tmpActiveTenseTopicLevel}`)
                // to create today's practice exercise - tense topic






                const qQuestionActiveTenseTopic = query(collection(db, "Question"),
                    where("topic", "==", tmpActiveTenseTopic),
                    where("level", "==", tmpActiveTenseTopicLevel)
                );



                const querySnapshotQuestionActiveTenseTopic = await getDocs(qQuestionActiveTenseTopic);
                let tmpTenseTopicQuestionArray = [];
                let tmpAllTenseTopicQuestionArray = [];
                querySnapshotQuestionActiveTenseTopic.forEach((doc) => {
                    tmpAllTenseTopicQuestionArray.push(doc.id);
                    // lgg(`qqq ${doc.id}`)
                    if (userInfo.answeredQuestion === undefined ||
                        !userInfo.answeredQuestion.includes(doc.id))// if user hasn't already answered this question
                    {
                        // lgg("qqq2")
                        tmpTenseTopicQuestionArray.push(doc.id)
                    }
                });

                lgg(`00001 enough question check ${ tmpTenseTopicQuestionArray.length}`)
                if (
                    tmpTenseTopicQuestionArray.length >= 5 ||
                    (tmpTenseTopicQuestionArray.length >= 1 && tmpActiveTenseTopicLevel == '3')
                    // still get enough remaining questions
                ) {
                    lgg("000001 enough")

                    // to create tense topic today's question practice


                    // shuffled it randomly
                    let tmpTenseTopicQuestionArrayShuffled = tmpTenseTopicQuestionArray
                        .map(value => ({value, sort: Math.random()}))
                        .sort((a, b) => a.sort - b.sort)
                        .map(({value}) => value)


                    tmpTenseTopicExercise = {
                        date: new Date(),  // date
                        type: tmpActiveTenseTopic, //
                        user: userID,
                        question: tmpActiveTenseTopicLevel == "3" ?  // just take 1 for level 3
                            tmpTenseTopicQuestionArrayShuffled.slice(0, 1) :
                            tmpTenseTopicQuestionArrayShuffled.slice(0, 5),
                        countUpTime: 0,
                        level: tmpActiveTenseTopicLevel// an string representing an integer
                    };


                    docRefAddTenseTopicExercise = await addDoc(collection(db, "Exercise"), tmpTenseTopicExercise);
                    tmpExercise.push(tmpTenseTopicExercise)
                    tmpExerciseID.push(docRefAddTenseTopicExercise.id);
                    addAnsweredQuestionArray = addAnsweredQuestionArray.concat(tmpActiveTenseTopicLevel == "3" ? tmpTenseTopicQuestionArrayShuffled.slice(0, 1) : tmpTenseTopicQuestionArrayShuffled.slice(0, 5));
                } else { // if not enough question

                    lgg("000001 not enough")

                    // to handle user answered all questions case
                    // to delete the answered question of particular topic and level
                    deleteAnsweredQuestionArray = deleteAnsweredQuestionArray.concat(tmpAllTenseTopicQuestionArray);
                    // to create tense topic today's question practice


                    let tmpTenseTopicQuestionArrayShuffled = tmpAllTenseTopicQuestionArray
                        .map(value => ({value, sort: Math.random()}))
                        .sort((a, b) => a.sort - b.sort)
                        .map(({value}) => value)

                    tmpTenseTopicExercise = {
                        date: new Date(),
                        type: tmpActiveTenseTopic,
                        user: userID,
                        question: tmpActiveTenseTopicLevel == "3" ? tmpTenseTopicQuestionArrayShuffled.slice(0, 1) : tmpTenseTopicQuestionArrayShuffled.slice(0, 5),
                        countUpTime: 0,
                        level: tmpActiveTenseTopicLevel
                    };

                    docRefAddTenseTopicExercise = await addDoc(collection(db, "Exercise"), tmpTenseTopicExercise);
                    tmpExercise.push(tmpTenseTopicExercise)
                    tmpExerciseID.push(docRefAddTenseTopicExercise.id);
                    addAnsweredQuestionArray = addAnsweredQuestionArray.concat(tmpActiveTenseTopicLevel == "3" ? tmpTenseTopicQuestionArrayShuffled.slice(0, 1) : tmpTenseTopicQuestionArrayShuffled.slice(0, 5));
                }
            } else {
                lgg("0001 'no need create today exercise tense'")
            }

            if (!nonTenseTopicCreatedFlag) {
                lgg("0002 'create today exercise non tense'")
                // to create today's practice exercise - non tense topic
                const qQuestionActiveNonTenseTopic = query(collection(db, "Question"),
                    where("topic", "==", tmpActiveNonTenseTopic),
                    where("level", "==", tmpActiveNonTenseTopicLevel)
                );
                const querySnapshotQuestionActiveNonTenseTopic = await getDocs(qQuestionActiveNonTenseTopic);
                let tmpNonTenseTopicQuestionArray = []
                let tmpAllNonTenseTopicQuestionArray = [];
                querySnapshotQuestionActiveNonTenseTopic.forEach((doc) => {
                    tmpAllNonTenseTopicQuestionArray.push(doc.id);
                    if (userInfo.answeredQuestion === undefined ||
                        !userInfo.answeredQuestion.includes(doc.id)) { // if user hasn't already answered this question
                        tmpNonTenseTopicQuestionArray.push(doc.id)
                    }
                });
                lgg("00001 enough question check and then create")
                if (tmpNonTenseTopicQuestionArray.length >= 5) {  // still get enough remaining questions
                    lgg("000001 enough")

                    // to create non-tense topic today's question practice
                    let tmpNonTenseTopicQuestionArrayShuffled = tmpNonTenseTopicQuestionArray
                        .map(value => ({value, sort: Math.random()}))
                        .sort((a, b) => a.sort - b.sort)
                        .map(({value}) => value)
                    tmpNonTenseTopicExercise = {
                        date: new Date(),
                        type: tmpActiveNonTenseTopic,
                        user: userID,
                        question:  // EDIT002: allow level 3
                            tmpActiveNonTenseTopicLevel == "3" ? tmpNonTenseTopicQuestionArrayShuffled.slice(0, 1) : tmpNonTenseTopicQuestionArrayShuffled.slice(0, 5),
                        // tmpNonTenseTopicQuestionArrayShuffled.slice(0, 5),
                        countUpTime: 0,
                        level: tmpActiveNonTenseTopicLevel
                    };
                    docRefAddNonTenseTopicExercise = await addDoc(collection(db, "Exercise"), tmpNonTenseTopicExercise);


                    lgg("000002 created")
                    tmpExercise.push(tmpNonTenseTopicExercise)
                    tmpExerciseID.push(docRefAddNonTenseTopicExercise.id);
                    addAnsweredQuestionArray = addAnsweredQuestionArray.concat(tmpNonTenseTopicQuestionArrayShuffled.slice(0, 5));
                } else { // if not enough question
                    lgg("000001 not enough")
                    // to handle user answered all questions case
                    // to delete the answered question of particular topic and level
                    deleteAnsweredQuestionArray = deleteAnsweredQuestionArray.concat(tmpAllNonTenseTopicQuestionArray);
                    // to create tense topic today's question practice
                    let tmpNonTenseTopicQuestionArrayShuffled = tmpAllNonTenseTopicQuestionArray
                        .map(value => ({value, sort: Math.random()}))
                        .sort((a, b) => a.sort - b.sort)
                        .map(({value}) => value)

                    tmpNonTenseTopicExercise = {
                        date: new Date(),
                        type: tmpActiveNonTenseTopic,
                        user: userID,
                        question: tmpNonTenseTopicQuestionArrayShuffled.slice(0, 5),
                        countUpTime: 0,
                        level: tmpActiveNonTenseTopicLevel
                    };

                    docRefAddNonTenseTopicExercise = await addDoc(collection(db, "Exercise"), tmpNonTenseTopicExercise);
                    lgg("000002 created")

                    tmpExercise.push(tmpNonTenseTopicExercise)
                    tmpExerciseID.push(docRefAddNonTenseTopicExercise.id);

                    addAnsweredQuestionArray = addAnsweredQuestionArray.concat(tmpNonTenseTopicQuestionArrayShuffled.slice(0, 5));
                }
            } else {
                lgg("0002 no need 'create today exercise non tense'")
            }

            lgg("004 'set 2 exercises' ")
            setExercise(tmpExercise);
            setExerciseID(tmpExerciseID);


            // addAnsweredQuestionArray is the question that just got distributed to them~
            updateAnsweredQuestion(userInfo, userID, addAnsweredQuestionArray, deleteAnsweredQuestionArray);
        }
        lgg("2 end ")


    }else{
        lgg("no user error")
    }
}

// to add question id into user's answered question
const updateAnsweredQuestion = async (userInfo, userID, addAnsweredQuestionArray, deleteAnsweredQuestionArray) => {
    if (
        userInfo.answeredQuestion === undefined ||
        userInfo.answeredQuestion.length === 0
    ) {

        lgg("005 no answeredQuestion 'question that just got distributed to them regarded as answered' ")
        const userRef = doc(db, 'User', userID);
        await updateDoc(userRef, {answeredQuestion: addAnsweredQuestionArray});
    } else {
        lgg("005 have answeredQuestion")
        const userRef = doc(db, 'User', userID);
        const tmp = userInfo.answeredQuestion;
        let tmpAnsweredQuestionArray = []

        for (let i = 0; i < tmp.length; i++) {
            if (!deleteAnsweredQuestionArray.includes(tmp[i])) {      // if the deleted array doesnt include this user already answer question
                tmpAnsweredQuestionArray.push(tmp[i]);      // also later add this question to the user's answered question
            }
        }
        await updateDoc(userRef, {answeredQuestion: tmpAnsweredQuestionArray.concat(addAnsweredQuestionArray)});
    }
}
