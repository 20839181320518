import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import Modal from 'react-modal';
import {db} from "../../firebase";
import {doc, updateDoc} from 'firebase/firestore';

import '../../App.css';
import './_styles.css';
import '../../translation'

import {useTranslation} from "react-i18next";

import closeSVG from '../../assets/general/close.svg';
import correctSVG from '../../assets/general/correct.svg';
import incorrectSVG from '../../assets/general/incorrect.svg';
import {useMediaQuery} from "react-responsive";
import {Box} from "@mui/material";

function TodaysQuestionAnswer({
                                  todaysQuestionAnswerVisibility,
                                  setTodaysQuestionAnswerVisibility,
                                  questionDetail,
                                  answerIndex,
                                  option,
                                  numbering,
                                  questionOfTheDayID,
                                  questionOfTheDay,
                                  userAnswer,
                                  setUserAnswer,
                                  mode,
                                  setMode,
                                  handleSelectAnswer
                              }) {

    const {t} = useTranslation();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    return (
        <>
            <Modal
                isOpen={todaysQuestionAnswerVisibility}
                className="todaysQuestionAnswerModalContainer"
                ariaHideApp={false}
                style={
                    {
                        overlay:
                            {
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                backdropFilter: "blur(6px)",
                                WebkitBackdropFilter: "blur(6px)",
                                zIndex: 1000000
                            }
                    }
                }
            >

                {is_m ?


                    <>
                        <Box style={
                            {
                                backgroundImage: `url(${require('../../assets/questionOfday.png')})`,

                                // background-size: `auto 100%`;
                                // height:'300px',
                                // marginTop: 20,
                                // backgroundPosition: 'center',

                                borderRadius: '10px',
                                backgroundSize: '100% 100%',
                                backgroundRepeat: 'no-repeat'
                            }
                        }
                             sx={{
                                 // position: '',
                                 // width:`100%`, flex :1
                                 // height: ``,
                                 // flex: 1,

                                 // height:200,
                                 // minHeight: '200px',
                                 // width: 370.4,
                                 // height: 171.48,
                             }}>
                            <img onClick={() => {
                                setTodaysQuestionAnswerVisibility(false)
                            }} className="todaysQuestionAnswerClose" src={closeSVG} alt="close"/>
                            <Grid container spacing={0}>
                                <Grid item xs={1}></Grid>

                                <Grid item xs={10} className="todaysQuestionAnswerContainer">
                                    <p className="questionOfTheDayTitle">{t("QUESTION_OF_THE_DAY")}</p>
                                    <div className="margin3vw"></div>
                                    {mode == "question" ?
                                        <p className="questionOfTheDayModalQuestion">
                                            {questionDetail && questionDetail.question.map((data, i) => (
                                                <span key={i}>
                                            <span>{data}</span>
                                                    {i !== questionDetail.question.length - 1 &&
                                                        <span
                                                            className="multipleChoiceQuestionUnderline">______________</span>
                                                    }
                                        </span>
                                            ))}
                                        </p>
                                        :
                                        <p className="questionOfTheDayModalQuestion">
                                            {questionDetail && questionDetail.question.map((data, i) => (
                                                <span key={i}>
                                            <span>{data}</span>
                                                    {i !== questionDetail.question.length - 1 &&
                                                        <span
                                                            className="questionOfTheDayQuestionAnswer">{" " + questionDetail.answer + " "}</span>
                                                    }
                                        </span>
                                            ))}
                                        </p>
                                    }
                                    <p className="questionOfTheDayModalType">({
                                        questionDetail && questionDetail.topic === "pastTense" ? "Past Tense" :
                                            questionDetail && questionDetail.topic === "pastTensePresentTense" ? "Past Tense × Present Tense" :
                                                questionDetail && questionDetail.topic === "presentContinuousTense" ? "Present Continuous Tense" :
                                                    questionDetail && questionDetail.topic === "presentTensePresentContinuousTense" ? "Present Tense × Present Continuous Tense" :
                                                        questionDetail && questionDetail.topic === "preposition" ? "Prepositions" :
                                                            questionDetail && questionDetail.topic === "questionWord" && "Question Words"
                                    })</p>
                                    <Grid container spacing={1}>
                                        {option && option.map((data, i) => (
                                            <Grid key={i} item xs={5}>
                                                <div
                                                    onClick={() => {
                                                        handleSelectAnswer(data)
                                                    }}
                                                    className="multipleChoicesContainer"
                                                    style={{
                                                        backgroundColor:
                                                            (data === option[answerIndex] && mode === "answer") ? '#BDFFB2'
                                                                : (data === userAnswer && mode === "answer") ? '#F8A5A5'
                                                                    : 'white'
                                                    }}
                                                >
                                            <span
                                                className="questionOfTheDayModalAnswer">{numbering[i] + ".\xa0\xa0" + data}</span>
                                                    {data === option[answerIndex] && mode === "answer" ?
                                                        <img className="todaysQuestionAnswerCorrect" src={correctSVG}
                                                             alt="correct"/>
                                                        : data === userAnswer && mode === "answer" &&
                                                        <img className="todaysQuestionAnswerCorrect" src={incorrectSVG}
                                                             alt="incorrect"/>
                                                    }
                                                </div>
                                            </Grid>
                                        ))}
                                        {/* {multopleChoicesAnswer.map((data, i) => (
                <Grid key={i} item xs={5}>
                  <div className="multipleChoicesContainer" style={{ backgroundColor: i===0 ? '#BDFFB2' : 'white' }}>
                    <span className="multipleChoicesAnswer">{data}</span>
                    {i === 0 &&
                      <img className="todaysQuestionAnswerCorrect" src={ correctSVG } alt="correct" />
                    }
                  </div>
                </Grid>
              ))} */}
                                    </Grid>
                                    <div className="menuHeight30"></div>
                                </Grid>
                                {/*<Grid item xs={3}>*/}
                                {/*    <img style={{marginTop: 50}} className="questionOfTheDayFrame"*/}
                                {/*         src={require('../../assets/QualiFlylogo.png')}/>*/}

                                {/*</Grid>*/}
                            </Grid>

                        </Box>
                    </> :


                    <div className="todaysQuestionAnswerBackgroundImage">
                        <img onClick={() => {
                            setTodaysQuestionAnswerVisibility(false)
                        }} className="todaysQuestionAnswerClose" src={closeSVG} alt="close"/>
                        <Grid container spacing={0}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={5} className="todaysQuestionAnswerContainer">
                                <p className="questionOfTheDayTitle">{t("QUESTION_OF_THE_DAY")}</p>
                                <div className="margin3vw"></div>
                                {mode == "question" ?
                                    <p className="questionOfTheDayModalQuestion">
                                        {questionDetail && questionDetail.question.map((data, i) => (
                                            <span key={i}>
                                            <span>{data}</span>
                                                {i !== questionDetail.question.length - 1 &&
                                                    <span
                                                        className="multipleChoiceQuestionUnderline">______________</span>
                                                }
                                        </span>
                                        ))}
                                    </p>
                                    :
                                    <p className="questionOfTheDayModalQuestion">
                                        {questionDetail && questionDetail.question.map((data, i) => (
                                            <span key={i}>
                                            <span>{data}</span>
                                                {i !== questionDetail.question.length - 1 &&
                                                    <span
                                                        className="questionOfTheDayQuestionAnswer">{" " + questionDetail.answer + " "}</span>
                                                }
                                        </span>
                                        ))}
                                    </p>
                                }
                                <p className="questionOfTheDayModalType">({
                                    questionDetail && questionDetail.topic === "pastTense" ? "Past Tense" :
                                        questionDetail && questionDetail.topic === "pastTensePresentTense" ? "Past Tense × Present Tense" :
                                            questionDetail && questionDetail.topic === "presentContinuousTense" ? "Present Continuous Tense" :
                                                questionDetail && questionDetail.topic === "presentTensePresentContinuousTense" ? "Present Tense × Present Continuous Tense" :
                                                    questionDetail && questionDetail.topic === "preposition" ? "Prepositions" :
                                                        questionDetail && questionDetail.topic === "questionWord" && "Question Words"
                                })</p>
                                <Grid container spacing={1}>
                                    {option && option.map((data, i) => (
                                        <Grid key={i} item xs={5}>
                                            <div
                                                onClick={() => {
                                                    handleSelectAnswer(data)
                                                }}
                                                className="multipleChoicesContainer"
                                                style={{
                                                    backgroundColor:
                                                        (data === option[answerIndex] && mode === "answer") ? '#BDFFB2'
                                                            : (data === userAnswer && mode === "answer") ? '#F8A5A5'
                                                                : 'white'
                                                }}
                                            >
                                            <span
                                                className="questionOfTheDayModalAnswer">{numbering[i] + ".\xa0\xa0" + data}</span>
                                                {data === option[answerIndex] && mode === "answer" ?
                                                    <img className="todaysQuestionAnswerCorrect" src={correctSVG}
                                                         alt="correct"/>
                                                    : data === userAnswer && mode === "answer" &&
                                                    <img className="todaysQuestionAnswerCorrect" src={incorrectSVG}
                                                         alt="incorrect"/>
                                                }
                                            </div>
                                        </Grid>
                                    ))}
                                    {/* {multopleChoicesAnswer.map((data, i) => (
                <Grid key={i} item xs={5}>
                  <div className="multipleChoicesContainer" style={{ backgroundColor: i===0 ? '#BDFFB2' : 'white' }}>
                    <span className="multipleChoicesAnswer">{data}</span>
                    {i === 0 &&
                      <img className="todaysQuestionAnswerCorrect" src={ correctSVG } alt="correct" />
                    }
                  </div>
                </Grid>
              ))} */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>}

            </Modal>
        </>
    );
}

export default TodaysQuestionAnswer;
