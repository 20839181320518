// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {
    query,
    collection,
    where,
    documentId,
    getDocs
} from "firebase/firestore";



import {getAuth} from "firebase/auth";
import {getAnalytics} from "firebase/analytics";
import {useEffect, useState} from "react";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries




// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
apiKey: "AIzaSyBqOM7cAv8CyGOgeFgKKeS_upEJC7Rdxo8",
authDomain: "qualifly-dev.firebaseapp.com",
projectId: "qualifly-dev",
storageBucket: "qualifly-dev.appspot.com",
messagingSenderId: "974976770838",
appId: "1:974976770838:web:cf51ea5db81a04e592a637",
measurementId: "G-4SNBBMY9JG"
};


const firebaseConfig2 = {     // which point to Qualify                       // Test
    apiKey: "AIzaSyA7prM4PHvJwud0VzOctFO66Z_OSwuwKfE",
    authDomain: "qualifly-c4787.firebaseapp.com",
    projectId: "qualifly-c4787",
    storageBucket: "qualifly-c4787.appspot.com",
    messagingSenderId: "117747969654",
    appId: "1:117747969654:web:2fedac27f7748e81e6f957",
    measurementId: "G-GJ9V8KW8GF"
};



const firebaseConfig3 = {      // demo

    apiKey: "AIzaSyBlUAauhXIDFZItuOlZ8F1fBUYCrMwARs4",
    authDomain: "qualiflyhkdemo.firebaseapp.com",
    projectId: "qualiflyhkdemo",
    storageBucket: "qualiflyhkdemo.appspot.com",
    messagingSenderId: "26251277194",
    appId: "1:26251277194:web:78adefff58c7e8f5b34e73",
    measurementId: "G-X6QNN1J752"
}

// Initialize Firebase
export const NUMB=1
let mode;
let app;
if (NUMB==0){
    console.log("running in  DEV          test ")
    mode="DEV"
    app = initializeApp(firebaseConfig2);
}else if (NUMB==1){
    console.log("running in  PROD ")
    mode="Prod"
    app = initializeApp(firebaseConfig);
}else if (NUMB==2){
    console.log("running in runway demo ")
    mode="demo"
    app = initializeApp(firebaseConfig3);

}else if (NUMB==3){
    console.log("running in  DEV          test   ora ")
    mode="DEV"
    app = initializeApp(firebaseConfig2);
}



// lgg("testingGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG ")
// lgg(ggPACT)

export function istensetopic  (topic)  {


    let p63={
        pastTense: 1,
        pastTensePresentTense: 1,
        preposition: 0,
        presentContinuousTense: 1,
        presentTensePresentContinuousTense: 1,
        questionWord: 0,

        PACT_1:1,
        PPT_1:1,
        BIxTI_1:0,
        BIxGER_1:0,
        ART_1:0,
        COM_1:0,
        CONJ_1:0
    }

    if (!(topic in p63)){
        lgg("fatal error")
    }

    return p63[topic]


}


export function lgg(q,y=0) {
    if (y==0){
        console.log(q)
    }else {
        console.log(q,y)

    }
    // console.log(q,y)
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


export const auth = getAuth();
export const db = getFirestore(app);



export const pp22=async (Collect,Idlist)=>{
    const q = query(
        collection(db, Collect),
        where(documentId(), "in",
            Idlist
        ),
    );

    const productsDocsSnap = await getDocs(q);
    return productsDocsSnap
}







// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

export const DEBUGGGGGG=1
// export const Mainserver = "https://9423-183-178-101-99.ngrok-free.app"
export const Mainserver = "https://tttt33ttqfororalpractice3.azurewebsites.net/"
export const LPP="tell me what you would like to learn about in English? I'm here to assist you with whatever you need to improve your English skills.I'm sorry, I didn't understand your response. Can you please ask me a question or tell me what you would like to learn about in English? I'm here to assist you with whatever you need to improve your English skills.I'm sorry, I didn't understand your response. Can you please ask me a question or tell me what you would like to learn about in English? I'm here to assist you with whatever you need to improve your English skills.tell me what you would like to learn about in English? I'm here to assist you with whatever you need to improve your English skills.I'm sorry, I didn't understand your response. Can you please ask me a question or tell me what you would like to learn about in English? I'm here to assist you with whatever you need to improve your English skills.I'm sorry, I didn't understand your response. Can you please ask me a question or tell me what you would like to learn about in English? I'm here to assist you with whatever you need to improve your English skills.tell me what you would like to learn about in English? I'm here to assist you with whatever you need to improve your English skills.I'm sorry, I didn't understand your response. Can you please ask me a question or tell me what you would like to learn about in English? I'm here to assist you with whatever you need to improve your English skills.I'm sorry, I didn't understand your response. Can you please ask me a question or tell me what you would like to learn about in English? I'm here to assist you with whatever you need to improve your English skills."
