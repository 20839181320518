import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {db} from "../../firebase";
import {addDoc, collection, doc, getDoc, query, updateDoc, where} from 'firebase/firestore';


// these css import could be omit I think
import '../../App.css';
import './_styles.css';


import '../../translation'

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';

import {LoginFailedTextFieldLong, LoginTextField, LoginTextFieldLong, SendButton} from './_components';
import ContactUsMessageSent from './ContactUsMessageSent';
import {useTranslation} from "react-i18next";
import {getUser, setUser} from '../../localStorage/User';
import {useMediaQuery} from "react-responsive";

function ContactUs() {

    const user = getUser();
    const {t} = useTranslation();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    useEffect(() => {
        getUserInfo();
    }, [])

    // to get user information
    const [userInfo, setUserInfo] = useState();
    const getUserInfo = async () => {
        if (user.id !== "" && user.id !== null && user.id !== undefined) {
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            setUserInfo(userSnapshot.data());
        }
    }

    const [messageSentModalVisibility, setMessageSentModalVisibility] = useState(false);

    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const submitContent = async () => {
        var re = /\S+@\S+\.\S+/;
        const validateEmail = re.test(email);
        if (validateEmail && email !== "" && content !== "") {
            await addDoc(collection(db, "ContactUs"), {
                email: email,
                content: content,
                date: new Date(),
                userEmail: userInfo.email
            });
            setMessageSentModalVisibility(true);
        } else {
            setShowErrorMsg(true);
        }
    }

    return (
        <>
            <Menu/>
            <SideMenu/>
            <div className="contactUsBackgroundImage">
                <div className="responsiveContainer">


                    <Grid container spacing={0}>
                        {!is_m && <Grid item xs={6}></Grid>}
                        <Grid item xs={12} sm={6}>
                            <p className="contactUsText">{t("CONTACT_US_MODAL")}</p>
                            <div className="menuHeight60"/>
                            <div className="menuHeight30"/>

                            <div className="menuHeight30"/>
                            <div className="menuHeight30"/>
                            <p className="contactUsEmailText">{t("CONTACT_US_EMAIL")}</p>
                            {!showErrorMsg ?
                                <LoginTextFieldLong
                                    fullWidth autoComplete='off' className='loginTextField'
                                    placeholder="daniel123@gmail.com"
                                    inputProps={{
                                        style: {
                                            fontSize: 18,
                                            paddingLeft: 28,
                                            fontFamily: "Nunito",
                                            fontWeight: '600'
                                        },
                                    }}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                    value={email}
                                />
                                :
                                <LoginFailedTextFieldLong
                                    fullWidth autoComplete='off' className='loginTextField'
                                    placeholder="daniel123@gmail.com"
                                    inputProps={{
                                        style: {
                                            fontSize: 18,
                                            paddingLeft: 28,
                                            fontFamily: "Nunito",
                                            fontWeight: '600'
                                        },
                                    }}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                    value={email}
                                />
                            }
                            <div className="margin20"></div>
                            {!showErrorMsg ?
                                <LoginTextFieldLong
                                    fullWidth multiline className='loginTextFieldLong'
                                    rows={10}
                                    placeholder={t("CONTACT_US_TEXTBOX")}
                                    inputProps={{
                                        style: {
                                            fontSize: 18,
                                            paddingLeft: 12,
                                            marginTop: 5,
                                            fontFamily: "Nunito",
                                            fontWeight: '600'
                                        },
                                    }}
                                    onChange={(e) => {
                                        setContent(e.target.value)
                                    }}
                                    value={content}
                                />
                                :
                                <LoginFailedTextFieldLong
                                    fullWidth multiline className='loginTextFieldLong'
                                    rows={10}
                                    placeholder={t("CONTACT_US_TEXTBOX")}
                                    inputProps={{
                                        style: {
                                            fontSize: 18,
                                            paddingLeft: 12,
                                            marginTop: 5,
                                            fontFamily: "Nunito",
                                            fontWeight: '600'
                                        },
                                    }}
                                    onChange={(e) => {
                                        setContent(e.target.value)
                                    }}
                                    value={content}
                                />
                            }

                            <div onClick={() => {
                                submitContent();
                            }} className="sendButtonContainer">
                                <p className="loginFailedMsgRight"
                                   style={{display: showErrorMsg ? 'inline-block' : 'none'}}>{t('INCORRECT_INPUT')}</p>
                                <SendButton variant="contained" className="sendButton">{t("SEND")}</SendButton>
                            </div>
                        </Grid>
                    </Grid>
                </div>

            </div>
            <ContactUsMessageSent messageSentModalVisibility={messageSentModalVisibility}
                                  setMessageSentModalVisibility={setMessageSentModalVisibility}/>
        </>
    );
}

export default ContactUs;
