import {db, lgg, pp22} from "../firebase";
import {doc, getDoc} from 'firebase/firestore';

export const getWrongAnswer = async (userInfo, setWrongQuestionIDByTopic) => {
    if (userInfo) { // TO-DO handle no question answered

        const topic = ["pastTense", "pastTensePresentTense", "presentContinuousTense", "presentTensePresentContinuousTense", "preposition", "questionWord"];
        const topicDisplay = ["Past Tense", "Past Tense Present Tense", "Present Continuous Tense", "Present Tense Present Continuous Tense", "Prepositions", "Question Words"];

        // to get the question type of each wrong question
        let tmpWrongQuestionIDByTopic = [];
        for(let i = 0; i<topic.length; i++){
            tmpWrongQuestionIDByTopic.push([]);
        }
        lgg("tmpWrongQuestionIDByTopicccccc",tmpWrongQuestionIDByTopic)

        if(userInfo.wrongQuestion){

            let mmm=3
            lgg(userInfo.wrongQuestion.length,"userInfo.wrongQuestion.lengthhhhhhhhhh")
            if (mmm===1) {
                for (let i = 0; i < userInfo.wrongQuestion.length; i++) {

                    lgg("Doing for loop.", i)

                    const questionSnapshot = await getDoc(doc(db, 'Question', userInfo.wrongQuestion[i]));
                    if (questionSnapshot.exists()) {

                        if (0){
                            for(let j = 0; j<topic.length; j++){
                                if (questionSnapshot.data().topic === topic[j]) {
                                    tmpWrongQuestionIDByTopic[j].push(questionSnapshot.id)

                                }
                            }

                        }else{
                            let ppppppppp = questionSnapshot.data().topic
                            let index = topic.indexOf(ppppppppp)
                            if (index !== -1) {
                                tmpWrongQuestionIDByTopic[index].push(questionSnapshot.id)

                            }else{
                                lgg("fatal error",ppppppppp )
                            }

                        }





                    }
                }
            } else if (mmm===2) {
                // const questionRefs = userInfo.wrongQuestion.map(q => doc(db, 'Question', q));
                // const questionSnapshots = await getAll(questionRefs);
                //
                // for (let i = 0; i < questionSnapshots.length; i++) {
                //     const questionSnapshot = questionSnapshots[i];
                //     if (questionSnapshot.exists()) {
                //         let ppppppppp = questionSnapshot.data().topic;
                //         let index = topic.indexOf(ppppppppp);
                //         if (index !== -1) {
                //             tmpWrongQuestionIDByTopic[index].push(questionSnapshot.id);
                //         }
                //     }
                // }
            } else if (mmm===3) {
                const batchSize = 10;
                const batches = [];
                for (let i = 0; i < userInfo.wrongQuestion.length; i += batchSize) {
                    // lgg("qqqqqqqqqq")
                    // lgg(i, i + batchSize)

                    const batch = userInfo.wrongQuestion.slice(i, i + batchSize);
                    batches.push(batch);
                    // lgg("batchhhhhh", batch)
                    // lgg(batches[batches.length-1].length)
                }

                for (let i = 0; i < batches.length; i++) {
                    lgg("Doing batch.", i);

                    // const questionSnapshots = await getDocs(query(collection(db, 'Question'), where('__name__', 'in', batches[i])));
                    const questionSnapshots = await pp22('Question', batches[i])


                    questionSnapshots.forEach((questionSnapshot) => {
                        let ppppppppp = questionSnapshot.data().topic;
                        let index = topic.indexOf(ppppppppp);
                        if (index !== -1) {
                            tmpWrongQuestionIDByTopic[index].push(questionSnapshot.id);
                        }
                    });
                }



            }



        }


        lgg("tmpWrongQuestionIDByTopicccccc2",tmpWrongQuestionIDByTopic)

        let tmp = [];
        for(let i=0; i<tmpWrongQuestionIDByTopic.length; i++){


            tmp.push({
                cover: topic[i] + ".png",
                topic: topicDisplay[i],
                topicKey: topic[i],
                wrongQuestionID: tmpWrongQuestionIDByTopic[i],
            })
        }
        lgg("tmp1111111",tmp)
        tmp.sort(function (a, b) {
            return a.wrongQuestionID.length > b.wrongQuestionID.length ? -1 : a.wrongQuestionID.length < b.wrongQuestionID.length ? 1 : 0;
        });
        lgg("tmp222222222",tmp)

        setWrongQuestionIDByTopic(tmp);
    }
}
