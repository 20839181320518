
import {Line} from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';

const plugin = {
  id: 'background',
  beforeDraw: (chart, args, opts) => {
    if (!opts.color) {
      return;
    }

    const {
      ctx,
      chartArea
    } = chart;

    ctx.fillStyle = opts.color;
    ctx.fillRect(chartArea.left, chartArea.top, chartArea.width, chartArea.height)
  }
}

Chart.register(...registerables, plugin);

export const LineChart = ({ title, chartData }) => {
  return (
    <div>
      <Line
        data={chartData}
        options={{
          scales: {
            x: {
              offset: true,
              grid: {
                color: '#3497CE',
                borderDash: [1,2],
                drawTicks: false,
                drawBorder: false,
              },
              ticks: {
                color: '#000000',
                font: {
                    size: 14,
                }
              },
            },
            y: {
              min: 0,
              max: 100,
              offset: true,
              grid: {
                color: function(context) {
                  if (context.tick.value === 0 || context.tick.value === 100 ) {
                    return '#3497CE';
                  } 
                  return '#3497CE';
                },
                tickColor: '#CAC7C7',
                borderDash: [1,2],
                drawBorder: false,
              },
              ticks: {
                color: '#CAC7C7',
                stepSize: 20,
              },
            }
          },
          plugins: {
            title: {
              display: true,
              text: title,
              position: 'left',
              color: '#3497CE',
            },
            legend: {
              display: false,
            },
            background: {
              color: '#EDFDFF'
            }
          }
        }}
      />
    </div>
  );
};