import React, {useEffect, useState} from 'react';
import {LineChart} from "../../components/LineChart";

import './_styles.css';

import arrowLeftSVG from '../../assets/performance/buttonLeft.svg';
import arrowRightSVG from '../../assets/performance/buttonRight.svg';
import { useTranslation } from 'react-i18next';
import {useMediaQuery} from "react-responsive";

function PerformanceTrend({weeklyAccuracy}) {

  const [chartData, setCharData] = useState();
  const [currectEndWeek, setCurrentEndWeek] = useState();
  const { t } = useTranslation();
  const is_m = useMediaQuery({
    query: '(max-width: 600px)'
  })
  useEffect(()=>{
    weeklyAccuracy && initialSetWeek();
  }, [weeklyAccuracy])

  const initialSetWeek = () => {
    let finalData = [];
    let finalLabels = [];
    // console.log(weeklyAccuracy)
    if(weeklyAccuracy.length <= 6){
      finalLabels = [['Week', '1'], ['Week', '2'], ['Week', '3'], ['Week', '4'], ['Week', '5'], ['Week', '6'],]
      finalData = weeklyAccuracy
    }else{
      for(let i=weeklyAccuracy.length - 6; i<weeklyAccuracy.length; i++){
        finalLabels.push(['Week',(i+1).toString()]);
        finalData.push(weeklyAccuracy[i]);
      }
    }
    setCharData({
      labels: finalLabels,
      datasets: [
        {
          label: 'Weekly Average Score',
          data: finalData,
          backgroundColor: '#F6C840',
          borderColor: '#F6C840',
          pointBorderWidth: 12,
        }
      ]
    });
    setCurrentEndWeek(Math.max(6,weeklyAccuracy.length));
  }

  const changeWeek = (endWeek) => {
    let finalData = [];
    let finalLabels = [];
    for(let i=endWeek - 6; i<endWeek; i++){
      finalLabels.push(['Week',(i+1).toString()]);
      finalData.push(weeklyAccuracy[i]);
    }
    setCharData({
      labels: finalLabels,
      datasets: [
        {
          label: 'Weekly Average Score',
          data: finalData,
          backgroundColor: '#F6C840',
          borderColor: '#F6C840',
          pointBorderWidth: 12,
        }
      ]
    });
    setCurrentEndWeek(endWeek);
  }


  return (
    <>
      <div className="resultTitleContainer" style={{
        backgroundColor: '#97FF8E',
        marginLeft: is_m?'0px' :'30px',
      }}>
        <p className="resultTitle">{t('PERFORMANCE_TREND')}</p>
      </div>
      <div className="performanceTrendContainer">
        <div className="performanceTrendButtonContainer">
          {weeklyAccuracy && weeklyAccuracy.length > 6 && currectEndWeek != 6 &&
            <img onClick={()=>{changeWeek(currectEndWeek - 1)}} className="performanceTrendButtonLeft" src={arrowLeftSVG} alt="left button"/>
          }
        </div>
        <div className="performanceTrendTable">
          {chartData && <LineChart title = {"Average Score"} chartData={chartData} />}
        </div>
        <div className="performanceTrendButtonContainer">
          {weeklyAccuracy && weeklyAccuracy.length > 6 && currectEndWeek != weeklyAccuracy.length &&
            <img onClick={()=>{changeWeek(currectEndWeek + 1)}} className="performanceTrendButtonRight" src={arrowRightSVG} alt="right button"/>
          }
        </div>
      </div>
    </>
  );
}

export default PerformanceTrend;
