import React from 'react';
import Modal from 'react-modal';

import '../../App.css';
import './_styles.css';

import { ResumeButton } from './_components';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../localStorage/Language';

function Pause({
    pauseModalVisibility,
    setPauseModalVisibility,
    remainingTime = 0,
    countUpTime = 0,
    resume,
}) {

    const { t } = useTranslation();
    const l = getLanguage();

    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={pauseModalVisibility}
                className="pauseModalContainer"
                style={{ overlay: {backgroundColor:'rgba(0, 0, 0, 0.3)', backdropFilter: "blur(6px)", WebkitBackdropFilter: "blur(6px)", zIndex: 1000000 }}}
            >
                <div className="pauseBackgroundImage">
                    <p className="pauseConfirmText">{t('YOU_VE_PAUSED')}</p>
                    <img className="pauseContent" src={
                        l === 'en' ?
                        require('../../assets/exercise/pauseContent.png')
                        :
                        require('../../assets/exercise/pauseContentZH-HK.png')
                    } alt="resume" />
                    {remainingTime > 0 ?
                        <p className="pauseTimeSpentText">{t('TIME_REMAIN')}: {new Date(remainingTime).toISOString().substr(11, 8)}</p>
                        :
                        <p className="pauseTimeSpentText">{t('TIME_SPENT')}: {new Date(countUpTime * 1000).toISOString().substr(11, 8)}</p>
                    }
                    <div onClick={() => {
                        setPauseModalVisibility(false);
                        resume();
                    }} className="pauseButtonContainer">
                        <ResumeButton />
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Pause;
