import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {db} from "../../firebase";
import {doc, getDoc} from 'firebase/firestore';

import '../../App.css';
import './_styles.css';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import AnswerReviewTopicBox from './AnswerReviewTopicBox';
import {getUser} from '../../localStorage/User';
import {getWrongAnswer} from "../../functions/getWrongAnswer"
import {useTranslation} from 'react-i18next';
import {Box} from "@mui/material";
import QualiFlylogo_3 from "../../assets/booth/QualiFlylogo_3-02-03.png";
import Highlighter from "react-highlight-words";
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {returnFocus} from "react-modal/lib/helpers/focusManager";

var _ = require('lodash');
let GG = "You put on a heavy, white spacesuit. It will protect you from asteroids and the cold outer space. You cannot walk smoothly wearing the spacesuit so you can only move slowly in space.  \n" +
    "~" +
    "\n" +
    "  \n" +
    "\n" +
    "Your team are a part of a project. The team has a dozen experts who will go to Mars to explore it, build power plants, houses and grow food. You are starting a Mars colony.  \n" +
    "~" +

    "\n" +
    "  \n" +
    "\n" +
    "Sam speaks happily, “I cannot wait to start a new life on Mars! It will be fun!”  \n" +
    "~" +
    "\n" +
    "Steven told Sam, “We should keep fit using the sports equipment on the ship and eat healthily. I will need to do a health check with you all before you set off to space.” Bob says, “I will build the solar panels and electricity generator there.”  \n" +
    "~" +
    "\n" +
    "  \n" +
    "\n" +
    "The countdown is starting. Are you ready?  "

let GG3 = "You put on a heavy, white spacesuit. It will protect you from asteroids and the cold outer space. You cannot walk smoothly wearing the spacesuit so you can only walk slowly in space."


let dd = {
    "asteroids": ["Asteroids (Noun)   小行星 ", "The asteroids are large rocks that go around the sun like planets.", require('../../assets/asteroid 1.png')],
    "spacesuit": ["Spacesuit (Noun)   太空服", "The astronaut has his spacesuit on to protect his body when he is outside the spacecraft.", require('../../assets/spacesuit 1.png')],
    "experts": ["Experts (Noun)   專家/ 行家 ", "My brother is an expert at cooking.", require('../../assets/experts 1.png')],
    "dozen": ["Dozen (Noun)   十二個/ 一打 ", "Could you get me half a dozen (i.e. six) eggs when you go to the supermarket?", require('../../assets/Dozen 1.png')],
    "colony": ["Colony (Noun)   殖民地", "Hong Kong was a colony of the British Empire in the past.", require('../../assets/Dozen 1.png')],
    "sports equipment": ["Sports equipment (Noun)   運動器材", "The coach bought some sports equipment for training the athletes", require('../../assets/sports equipment 1.png')],
    "solar panels": ["Solar panels (Noun)   太陽能板", "The solar panels that are installed on the roof of our school to capture energy from the sun.", require('../../assets/solar panels 1.png')],
    "electricity": ["Electricity (Noun)   電力", "We need electricity to power televisions, light bulbs, heaters, and computers.", require('../../assets/electricity 1.png')],
}


let QP = [

    ["What is an asteroid? ", "A flying rock in space",
        "A type of alien", "A kind of food for astronauts", "A piece of space garbage  "],
    ["What about the space suit is not correct?", "It is easy to move in the space suit.",
        "It can keep you warm",
        "It can protect you from danger",
        "It is white.  "],
    [" What does paragraph 2 tell us? ", "What you will do with your teammates on mars.  ",
        "You and your team don’t like the project.  ",
        "You may see aliens on mars.",
        "What food you are eating on mars.  "],
    ["How many teammates do you have?", "11  ",
        "4  ",
        "8  ",
        "12  "],
    ["Sam is feeling ___. ", "Excited  ",
        "Scared  ",
        "Angry  ",
        "Sad  "],
    ["What is Steven’s job?", "Doctor ",
        "Chef  ",
        "Teacher  ",
        "Engineer  "],
    ["What is Bob building?", "A power plant   ",
        "A farm  ",
        "Houses",
        "A spaceship"],
]


const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const BootstrapTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

const HtmlTooltip =
    styled(
        (
            {className, ...props}
        ) => (

            <Tooltip {...props} classes={{popper: className}}/>

        )
    )(
        ({theme}) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: '#FFDB8E',
                    color: 'rgba(0, 0, 0, 0.87)',
                    // maxWidth: 220,
                    minWidth: 600,
                    padding: 80,
                    // fontSize: theme.typography.pxToRem(12),
                    border: '1px solid #dadde9',
                },

            }
        )
    );

function AnswerReview() {

    const user = getUser();
    const {t} = useTranslation();
    const [highlight, setHighlight] = useState(0);
    useEffect(() => {
        getUserInfo();
    }, [])

    // to get user information
    const [userInfo, setUserInfo] = useState();
    const getUserInfo = async () => {
        if (user.id !== "" && user.id !== null && user.id !== undefined) {
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            setUserInfo(userSnapshot.data());
        }
    }

    const [wrongQuestionIDByTopic, setWrongQuestionIDByTopic] = useState();
    useEffect(() => {
        getWrongAnswer(userInfo, setWrongQuestionIDByTopic);
    }, [userInfo])


    const TT = (
        i1 = "Spacesuit (Noun)   太空衣 ",
        i2 = require('../../assets/asteroid 1.png'),
        i3 = "The asteroids are large rocks that go around the sun like planets.",
        i4 = "asteroid") => {
        return <HtmlTooltip

            arrow
            title={
                <div className="questionOfTheDayFrame12">

                    <div className="questionOfTheDayFrame10">{i1}</div>
                    <div className="menuHeight30"></div>
                    <Grid container spacing={2} justifyContent="center">

                        <Grid item xs={6}>
                            <img
                                // className="questionOfTheDayFrame-2"
                                src={i2}
                                alt="frame"/>

                        </Grid>
                        <Grid item xs={6}>
                            <div>


                                <div className="questionOfTheDayFrame11">{i3}

                                </div>
                                <div
                                    // className="questionOfTheDayFrame13"
                                >

                                    <img
                                        className="questionOfTheDayFrame14"
                                        // className="questionOfTheDayFrame-2"
                                        src={require('../../assets/Vector.png')}
                                        alt="frame"/>
                                    <img
                                        className="questionOfTheDayFrame14"

                                        // className="questionOfTheDayFrame-2"
                                        src={require('../../assets/Vector2.png')}
                                        alt="frame"/>
                                </div>
                            </div>
                        </Grid>
                    </Grid>


                    {/*<Typography color="inherit">Tooltip with HTML</Typography>*/}


                    {/*<em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}*/}
                    {/*{"It's very engaging. Right?"}*/}
                </div>
            }
        >
        <span
            className="questionOfTheDayFrame4"
        >{i4}</span>
        </HtmlTooltip>
    }
    const Highlighted = (
        {
            text = GG,

            // range to highlight


            from = 24,
            to = 24,

            dictionary = dd,

            highlightornot = true,
        }
    ) => {
        console.log("highlightornot", highlightornot)
        let words_check = Object.keys(dictionary)


        // divided text into array with the space
        const words = text.split(/(\s+)/);


        let buffer = []
        let QQQ;
        for (let i = 0; i < words.length; i++) {

            if(words[i] == 'solar'){

                console.log(i, words[i])
            }

            // a possibly highlighted word that have tooltip


            //for j in words_check:
            let index1 = "";
            let index2 = "";
            for (let j = 0; j < words_check.length; j++) {

                if(words[i] == 'solar'){

                    console.log(",,,,",words_check[j],words_check[j].split(" "))
                }
                // if words[i] is substring of words_check[j] using substring method
                if (words_check[j].split(" ").includes(words[i])) {
                    if(words[i] == 'solar'){

                        console.log("aaaaaaaaaaaaaaaaaaa")
                    }

                    //if dictionary have the word
                    if (
                        1
                        // dictionary[words[i]] !== undefined
                    ) {

                        console.log(words_check[j], words[i])
                        index1 = words_check[j];
                        index2=j;
                        break
                    }

                }

            }

            if (words[i] == '~') {
                buffer.push(<p></p>)

            }
            // a possibly highlighted space
            else if (words[i] === ' ') {

                // if inside hightlight range and highlightornot=1
                if (i >= from && i <= to && highlightornot === true) {
                    buffer.push(<span className="questionOfTheDayFrame66">{' '}</span>);
                } else {
                    buffer.push(<span>{' '}</span>);
                }


            }

            // if words[i] is one of the item in dictionary
            else if (index1) {

                // console.log("index1",index1,dictionary[words[i]][0])
                // console.log("words[i]", words[i])
                // if inside hightlight range and highlightornot=1

                console.log("11")


                let px = TT(
                    dictionary[index1][0],
                    dictionary[index1][2],
                    dictionary[index1][1],
                    index1
                )
                if (i >= from && i <= to && highlightornot === true) {
                    QQQ = <span className="questionOfTheDayFrame66"> {px}</span>
                } else {
                    QQQ = px
                }


                buffer.push(QQQ);
            }






            // a possibly highlighted word that have no tooltip
            else if (words[i] !== ' ') {

                // if inside hightlight range and highlightornot=1
                if (i >= from && i <= to && highlightornot === true) {
                    buffer.push(<span className="questionOfTheDayFrame66">{words[i]}</span>);
                } else {
                    buffer.push(<span>{words[i]}</span>);
                }
            } else {
                console.log("else", words[i])
            }


        }


        return (
            <span className="questionOfTheDayFrame4">

                {
                    buffer
                }

                {/*{buffer}*/}

            </span>
        )
    }


    return <>
        <Menu/>
        <SideMenu/>
        <div className="responsiveContainer">


            {/*<div className="questionOfTheDayFrame3"*/}
            {/*>*/}
            {/*    Do you want to live in Mars?*/}

            {/*</div>*/}

            {/*<img className="questionOfTheDayFrame" src={require('../../assets/Group 1132.png')}*/}
            {/*     alt="frame"/>*/}
            {/*<img className="questionOfTheDayFrame" src={require('../../assets/image 116.png')}*/}
            {/*     alt="frame"/>*/}


            {/*<Box style={*/}
            {/*    {*/}
            {/*        backgroundImage: `url(${require('../../assets/image 49.png')})`,*/}

            {/*        // background-size: `auto 100%`;*/}
            {/*        // height:'300px',*/}
            {/*        // marginTop: 20,*/}
            {/*        // backgroundPosition: 'center',*/}

            {/*        borderRadius: '50px',*/}
            {/*        backgroundSize: 'cover',*/}
            {/*        backgroundRepeat: 'no-repeat'*/}
            {/*    }*/}
            {/*}*/}
            {/*     sx={{*/}
            {/*         // position: '',*/}
            {/*         // width:`100%`, flex :1*/}
            {/*         // height: ``,*/}
            {/*         // flex: 1,*/}

            {/*         // height:200,*/}
            {/*         // minHeight: '200px',*/}
            {/*         // width: 370.4,*/}
            {/*         // height: 171.48,*/}
            {/*     }}></Box>*/}

            {/*<div className="resultTitleContainer" style={{*/}
            {/*    backgroundColor: '#FFDB70',*/}
            {/*    marginLeft: '30px',*/}
            {/*    marginBottom: '50px'*/}
            {/*}}>*/}
            {/*    <p className="resultTitle">{t('ANS_REVIEW')}</p>*/}
            {/*</div>*/}


            <Grid container spacing={2} justifyContent="center">

                {/*header*/}
                <Grid item xs={6}>

                    <div className="questionOfTheDayFrame5">

                        <div className="questionOfTheDayFrame2"
                        >
                            Teams for Mars

                        </div>

                        {/*<div*/}
                        {/*    // style={{whiteSpace: "pre-wrap"}}*/}
                        {/*    className="questionOfTheDayFrame4"*/}
                        {/*>*/}
                        {/*    <span className="questionOfTheDayFrame66">ttttttttt</span>*/}
                        {/*    <span className="questionOfTheDayFrame66"> </span>*/}
                        {/*    <span className="questionOfTheDayFrame66"> {TT()}</span>*/}
                        {/*    <span className="questionOfTheDayFrame66"> </span>*/}
                        {/*    <span>ttttttttt</span>*/}
                        {/*    <span>{GG1}</span>*/}
                        {/*</div>*/}

                        {/*<Highlighter*/}
                        {/*    highlightClassName="questionOfTheDayFrame6"*/}
                        {/*    searchWords={["and", "or", "the"]}*/}
                        {/*    autoEscape={true}*/}
                        {/*    textToHighlight="The dog is chasing the cat. Or perhaps they're just playing?"*/}
                        {/*/>*/}
                        <Highlighted text={GG} highlightornot={highlight}/>
                    </div>

                    {/*<div className="answerReviewTopicTitleBoxContainer">*/}
                    {/*    /!*<Grid container spacing={2} alignItems="center">*!/*/}
                    {/*    /!*    <Grid item xs={1} align="center"><p className="answerReviewLebal">{t('RANK')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={6} align="center"><p className="answerReviewLebal">{t('TOPIC')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={3} align="center"><p className="answerReviewLebal">{t('QUESTIONS_LEFT')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={2} align="center"></Grid>*!/*/}
                    {/*    /!*</Grid>*!/*/}

                    {/*</div>*/}
                </Grid>
                {/*<Grid item xs={2}></Grid>*/}
                <Grid item xs={6}>

                    <div className="questionOfTheDayFrame9">
                        <img
                            className="questionOfTheDayFrame-2"
                            src={require('../../assets/image 82.png')}
                            alt="frame"/>
                        <img
                            className="questionOfTheDayFrame-1"
                            src={require('../../assets/image 58.png')}
                            alt="frame"/>
                        <div>
                            <span
                                className="questionOfTheDayFrame7"
                            >Q1. What is an asteroid?
                            </span>
                            <img
                                onClick={() => {
                                    setHighlight(!highlight)
                                    console.log(highlight)
                                }}
                                className="questionOfTheDayFrame-3"
                                src={require('../../assets/image 67.png')}
                                alt="frame"/>
                        </div>


                        <div
                            className="questionOfTheDayFrame8"
                        >A. A flying rock in space
                        </div>

                        <div
                            className="questionOfTheDayFrame8"
                        >B. A type of alien
                        </div>
                        <div
                            className="questionOfTheDayFrame8"
                        >C. A kind of food for astronauts
                        </div>
                        <div
                            className="questionOfTheDayFrame8"
                        >D. A piece of space garbage
                        </div>
                    </div>
                </Grid>


            </Grid>
            {/*<div className="answerReviewBottom"></div>*/}
        </div>
    </>;
}

export default AnswerReview;
