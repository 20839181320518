import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {db} from "../../firebase";
import {doc, getDoc} from 'firebase/firestore';

import '../../App.css';
import './_styles.css';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import AnswerReviewTopicBox from './AnswerReviewTopicBox';
import {getUser} from '../../localStorage/User';
import {getWrongAnswer} from "../../functions/getWrongAnswer"
import {useTranslation} from 'react-i18next';
import {Box} from "@mui/material";
import QualiFlylogo_3 from "../../assets/booth/QualiFlylogo_3-02-03.png";
import Highlighter from "react-highlight-words";
import {useNavigate} from "react-router-dom";

var _ = require('lodash');

function AnswerReview() {

    const user = getUser();
    const {t} = useTranslation();

    useEffect(() => {
        getUserInfo();
    }, [])

    // to get user information
    const [userInfo, setUserInfo] = useState();
    const getUserInfo = async () => {
        if (user.id !== "" && user.id !== null && user.id !== undefined) {
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            setUserInfo(userSnapshot.data());
        }
    }
    let navigate = useNavigate();
    const [wrongQuestionIDByTopic, setWrongQuestionIDByTopic] = useState();
    useEffect(() => {
        getWrongAnswer(userInfo, setWrongQuestionIDByTopic);
    }, [userInfo])

    const Highlighted = (
        {
            text = '',
            highlight = '',
            loc,
        }
    ) => {

        if (!highlight.trim()) {
            return <span>{text}</span>
        }

        const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
        const parts = text.split(regex)
        return (
            <span>

                {
                    parts.filter(part => part).map(
                        (part, i) => (
                            regex.test(part) ?
                                <mark key={i}>{part}</mark> :
                                <span key={i}>{part}</span>
                        )
                    )
                }

            </span>
        )
    }


    return <>
        <Menu/>
        <SideMenu/>
        <div className="responsiveContainer">


            {/*<div className="questionOfTheDayFrame3"*/}
            {/*>*/}
            {/*    Do you want to live in Mars?*/}

            {/*</div>*/}

            {/*<img className="questionOfTheDayFrame" src={require('../../assets/Group 1132.png')}*/}
            {/*     alt="frame"/>*/}
            {/*<img className="questionOfTheDayFrame" src={require('../../assets/image 116.png')}*/}
            {/*     alt="frame"/>*/}


            {/*<Box style={*/}
            {/*    {*/}
            {/*        backgroundImage: `url(${require('../../assets/image 49.png')})`,*/}

            {/*        // background-size: `auto 100%`;*/}
            {/*        // height:'300px',*/}
            {/*        // marginTop: 20,*/}
            {/*        // backgroundPosition: 'center',*/}

            {/*        borderRadius: '50px',*/}
            {/*        backgroundSize: 'cover',*/}
            {/*        backgroundRepeat: 'no-repeat'*/}
            {/*    }*/}
            {/*}*/}
            {/*     sx={{*/}
            {/*         // position: '',*/}
            {/*         // width:`100%`, flex :1*/}
            {/*         // height: ``,*/}
            {/*         // flex: 1,*/}

            {/*         // height:200,*/}
            {/*         // minHeight: '200px',*/}
            {/*         // width: 370.4,*/}
            {/*         // height: 171.48,*/}
            {/*     }}></Box>*/}

            {/*<div className="resultTitleContainer" style={{*/}
            {/*    backgroundColor: '#FFDB70',*/}
            {/*    marginLeft: '30px',*/}
            {/*    marginBottom: '50px'*/}
            {/*}}>*/}
            {/*    <p className="resultTitle">{t('ANS_REVIEW')}</p>*/}
            {/*</div>*/}


            <Grid container spacing={2} justifyContent="center">

                {/*header*/}
                <Grid item xs={7}>

                    <div className="questionOfTheDayFrame5">

                        <div className="questionOfTheDayFrame2"
                        >
                            Teams for Mars

                        </div>
                        <div  className="questionOfTheDayFrame4">
                            You put on a heavy, white spacesuit. It will protect you from asteroids and the cold outer
                            space. You cannot walk smoothly wearing the spacesuit so you can only walk slowly in space.
<br/><br/>
                            Your team are a part of a project. The team has dozen experts who will go to Mars to explore
                            it, build power plants, houses and grow food. You are starting a Mars colony.
                            <br/><br/>
                            Sam speaks happily, “I cannot wait to start a new life on Mars! It will be fun!”
                            <br/><br/>
                            Steven told Sam, “We should keep fit using the sports equipment on the ship and eat
                            healthily. I will need to do a health check with you all before you set off to space.” Bob
                            says, “I will build the solar panels and electricity generator there.”
                            <br/><br/>
                            The countdown is starting. Are you ready?
                        </div>

                        {/*<Highlighter*/}
                        {/*    highlightClassName="questionOfTheDayFrame6"*/}
                        {/*    searchWords={["and", "or", "the"]}*/}
                        {/*    autoEscape={true}*/}
                        {/*    textToHighlight="The dog is chasing the cat. Or perhaps they're just playing?"*/}
                        {/*/>*/}
                        {/*<Highlighted text="the quick brown fox jumps over the lazy dog" highlight="fox"/>*/}
                    </div>

                    {/*<div className="answerReviewTopicTitleBoxContainer">*/}
                    {/*    /!*<Grid container spacing={2} alignItems="center">*!/*/}
                    {/*    /!*    <Grid item xs={1} align="center"><p className="answerReviewLebal">{t('RANK')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={6} align="center"><p className="answerReviewLebal">{t('TOPIC')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={3} align="center"><p className="answerReviewLebal">{t('QUESTIONS_LEFT')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={2} align="center"></Grid>*!/*/}
                    {/*    /!*</Grid>*!/*/}

                    {/*</div>*/}
                </Grid>
                <Grid item xs={5}>
                    <div>

                        <img
                            // className="questionOfTheDayFrame"
                            src={require('../../assets/Group 1097.png')}
                            alt="frame"/></div>
                    <div className="menuHeight30"></div>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign: "center"}}>

                        {/*<img className="imageshiftright" src={QualiFlylogo_3}/>*/}
                        <img
                            onClick={() => {navigate("/read5")}}
                            // className="questionOfTheDayFrame"
                            src={require('../../assets/Group 1134.png')}
                            alt="frame"/>
                    </Grid>

                    {/*<div className="answerReviewTopicTitleBoxContainer">*/}
                    {/*    /!*<Grid container spacing={2} alignItems="center">*!/*/}
                    {/*    /!*    <Grid item xs={1} align="center"><p className="answerReviewLebal">{t('RANK')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={6} align="center"><p className="answerReviewLebal">{t('TOPIC')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={3} align="center"><p className="answerReviewLebal">{t('QUESTIONS_LEFT')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={2} align="center"></Grid>*!/*/}
                    {/*    /!*</Grid>*!/*/}

                    {/*</div>*/}
                </Grid>


            </Grid>
            {/*<div className="answerReviewBottom"></div>*/}
        </div>
    </>;
}

export default AnswerReview;
