
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Route} from "react-router-dom";
import useWindowDimensions from "./firebase";
import {Dialog, DialogTitle, List, ListItem, ListItemAvatar, Typography} from "@mui/material";
import {useMediaQuery} from "react-responsive";
// const theme = createTheme({
//     breakpoints: {
//         // Define custom breakpoint values.
//         // These will apply to Material-UI components that use responsive
//         // breakpoints, such as `Grid` and `Hidden`. You can also use the
//         // theme breakpoint functions `up`, `down`, and `between` to create
//         // media queries for these breakpoints
//         values: {
//             xs: 899,
//             sm: 1195,
//             md: 1196,
//             lg: 1197,
//             xl: 1200
//         }
//     }
// });
export function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
      <DialogTitle>如果你在使用手機，建議使用縱向模式。如果你在使用電腦，請嘗試拉長視窗高度，或縮小頁面。</DialogTitle>
            {/*<List sx={{ pt: 0 }}>*/}
            {/*    {emails.map((email) => (*/}
            {/*        <ListItem button onClick={() => handleListItemClick(email)} key={email}>*/}
            {/*            <ListItemAvatar>*/}
            {/*                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>*/}
            {/*                    <PersonIcon />*/}
            {/*                </Avatar>*/}
            {/*            </ListItemAvatar>*/}
            {/*            <ListItemText primary={email} />*/}
            {/*        </ListItem>*/}
            {/*    ))}*/}
            {/*    <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>*/}
            {/*        <ListItemAvatar>*/}
            {/*            <Avatar>*/}
            {/*                <AddIcon />*/}
            {/*            </Avatar>*/}
            {/*        </ListItemAvatar>*/}
            {/*        <ListItemText primary="Add account" />*/}
            {/*    </ListItem>*/}
            {/*</List>*/}
        </Dialog>
    );
}

// export default function SimpleDialogDemo() {
//     const [open, setOpen] = React.useState(false);
//     const [selectedValue, setSelectedValue] = React.useState(emails[1]);
//
//     const handleClickOpen = () => {
//         setOpen(true);
//     };
//
//     const handleClose = (value: string) => {
//         setOpen(false);
//         setSelectedValue(value);
//     };
//
//     return (
//         <div>
//             <Typography variant="subtitle1" component="div">
//                 Selected: {selectedValue}
//             </Typography>
//             <br />
//             <Button variant="outlined" onClick={handleClickOpen}>
//                 Open simple dialog
//             </Button>
//             <SimpleDialog
//                 selectedValue={selectedValue}
//                 open={open}
//                 onClose={handleClose}
//             />
//         </div>
//     );
// }


export const Route1 = (
    {Pp}
                       ) => {

    const { height, width } = useWindowDimensions();
    console.log("width", width, "height", height);

    //usemediaquery
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })

    let a=height<600 && !is_m;

    return(<>
        <SimpleDialog
            // selectedValue={selectedValue}
            open={a}
            // onClose={handleClose}
        />
        <Pp/>
    </>)


};
