import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';
import BestPerformanceTopicBox from './BestPerformanceTopicBox';
import TopicBox from './TopicBox';
import { useTranslation } from 'react-i18next';

function BestPerformance({ topciAccuracy, masteredTopic }) {

  const [nullFlag, setNullFlag] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if(topciAccuracy !== undefined){
      for (let i = 0; i < topciAccuracy.length; i++) {
        masteredTopic.includes(topciAccuracy[i].topicKey) && setNullFlag(false);
      }
    }
  }, [topciAccuracy])

  const noTopicText = t('NO_MASTERED');

  return <>
    <div className="resultTitleContainer" style={{ backgroundColor: '#FFDB70' }}>
      <p className="resultTitle">{t('MASTERED_TOPICS')}</p>
    </div>
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={1} align="center"></Grid>
      <Grid item xs={6} align="center"><p className="resultLabel">{t('TOPIC')}</p></Grid>
      <Grid item xs={3} align="center"><p className="resultLabel">{t('OVERALL_GRADE')}</p></Grid>
      <Grid item xs={2} align="center" sx={{ ml: -1 }}><p className="resultLabel">{t('ACCURACY')}</p></Grid>
      {(topciAccuracy && topciAccuracy.length) && topciAccuracy.map((data, i) => (
        <BestPerformanceTopicBox key={i} data={data} masteredTopic={masteredTopic} />
      ))
      }
      {nullFlag && <TopicBox className="bestPerformanceTopicBoxContainer" text={noTopicText} />}
    </Grid>
  </>;
}

export default BestPerformance;
