import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth, db} from "../../firebase";
import {doc, getDoc, setDoc, updateDoc} from 'firebase/firestore';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";

import '../../App.css';
import './_styles.css';
import '../../translation'
import Menu from '../../components/menu/Menu';
import ForgotPassword from './ForgotPassword';
import {LoginButton, LoginFailedTextField, LoginTextField} from './_components';
import {getUser, setUser} from '../../localStorage/User';
import {redirect} from "../../functions/redirect"
import {useMediaQuery} from 'react-responsive'
import {Box, TextField} from "@mui/material";
import QualiflyIconForBooth from "../../assets/booth/QualiflyIconForBooth.svg";
import Screenshot2022 from "../../assets/booth/Screenshot2022-09-02at.png";
import QualiFlylogo_3 from "../../assets/booth/QualiFlylogo_3-02-03.png";
import Instagramlogo from "../../assets/booth/Instagramlogo.png";
import LKlogo from "../../assets/booth/LKlogo.png";
import image4 from "../../assets/booth/image4.png";
import Group308 from "../../assets/booth/Group308.png";
import Group416 from "../../assets/booth/Group416.png";
import Group417 from "../../assets/booth/Group417.png";
import Group4171 from "../../assets/booth/Group417type1.png";
import Group4172 from "../../assets/booth/Group417type2.png";
import Group4173 from "../../assets/booth/Group417type3.png";
import Group4174 from "../../assets/booth/Group417type4.png";
import Group4175 from "../../assets/booth/Group417type5.png";
import Group227 from "../../assets/booth/Group 227.png";
import {BoothButton333333, BoothButton333334, BoothButton333335, BoothButton333339} from "../login/Logout";
import Button from "@material-ui/core/Button";
import SearchWhiteSVG from "../../assets/dashboard/searchWhite.svg";
import SearchYellowSVG from "../../assets/dashboard/searchYellow.svg";
import {styled} from "@mui/material/styles";

import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css'





const captions = {
    // columns
    // battery: 'Battery Capacity',
    // design: 'Design',
    // useful: 'Usefulness',
    // speed: 'Speed',
    // weight: 'Weight'
    Myths: 'Geography',
    campus : 'Transport',
    Academics: 'Popular Culture',
};






const TextField1 = styled(TextField)(({ theme }) => ({

    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: '100px',
            width: 'calc(100% - 20px)',
        },
    },



    [theme.breakpoints.down('sm')]: {
        borderRadius: '100px',
        width: 'calc(100% - 2px)',
    },
}));
function Login() {
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const user = getUser();
    let navigate = useNavigate();
    useEffect(() => {
        // redirect(user, navigate);
        // eslint-disable-next-line
    }, [])

    const {t} = useTranslation();


    const location = useLocation();

    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState("");
    const [search3, setSearch3] = useState("");
    console.log("location.state.runwayAcademic1",location.state.runwayAcademic1)

    const tt=(location.state.timeused5)/9
    let hi1=location.state.acc8.runwayMyth1
    let hi2=location.state.acc8.runwayCampus1
    let hi3=location.state.acc8.runwayAcademic1
    const gg35=location?.state?.acc8?.overallacc
    const gg353=gg35?Math.round(gg35 * 10000) / 100:0

    let mode09=Group4171;
       if
       (tt<8&&gg353>=80)
       {
           mode09=Group4171
       }else if
       (
           gg353<=80
       &&    gg353>=56
       ){
           mode09=Group4172

       }else if
       (tt>=8&&gg353>=80){
           mode09=Group4173

       }
       else if
       (tt<=4&&gg353<=56){
           mode09=Group4174

       }else if
       (tt>=4&&gg353<=56){
           mode09=Group4175

       }

    const data6 = [
        {
            data: {
                Myths: hi1,
                campus : hi2,
                Academics: hi3,
                // speed: 0.67,
                // weight: 0.8
            },
            meta: { color: 'orange' }
        },
        // {
        //     data: {
        //         battery: 0.6,
        //         design: .85,
        //         useful: 0.5,
        //         speed: 0.6,
        //         weight: 0.7
        //     },
        //     meta: { color: 'red' }
        // }
    ];


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [forgotPasswordModalVisibility, setForgotPasswordModalVisibility] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showLoginFailedMsg, setShowLoginFailedMsg] = useState(false);

    const logInWithEmailAndPassword = async () => {
        let userID, userEmail;
        const emailToDB = [password + "@kfps4a.com", password + "@kfps4b.com", password + `@${email}.com`];
        const passwordToDB = email;
        let wrongFlag = false;
        for (let i = 0; i < 3; i++) {
            try {
                await signInWithEmailAndPassword(auth, emailToDB[i], passwordToDB)
                    .then((data) => {
                        userID = data.user.uid;
                        userEmail = data.user.email;
                        setEmail("");
                        setPassword("");
                    });
            } catch (err) {
                wrongFlag = true;
            }


            if (userID) {
                const userSnapshot = await getDoc(doc(db, 'User', userID));
                let today = new Date();
                today.setHours(0);
                today.setMinutes(0);
                today.setSeconds(0);
                if (!userSnapshot.exists()) { // to handle first login


                    await setDoc(doc(db, 'User', userID), {
                        email: userEmail,
                        firstLoginDate: new Date(),
                        loginDate: [today]
                    });

                    setUser(userID, {email: userEmail});
                    navigate("/welcome");

                } else {  // not first time trigger above code

                    if (userSnapshot.data().firstLoginDate === undefined) {
                        const userRef = doc(db, 'User', userID);
                        updateDoc(userRef, {
                            firstLoginDate: new Date()
                        });
                    }

                    setUser(userID, userSnapshot.data());


                    if (userSnapshot.data().finishColibrationTestFlag) {
                        navigate("/");    // go to home
                    } else if (userSnapshot.data().finishWelcomeFlag) {
                        navigate("/welcome", {state: {processInit: 8}});
                    } else {
                        navigate("/welcome");
                    }
                }
            }


            // no user found , next loop
        }
        setShowLoginFailedMsg(wrongFlag);
    };
    const noSmoothing = points => {
        let d = 'M' + points[0][0].toFixed(4) + ',' + points[0][1].toFixed(4);
        for (let i = 1; i < points.length; i++) {
            d += 'L' + points[i][0].toFixed(4) + ',' + points[i][1].toFixed(4);
        }
        return d + 'z';
    };
    return <>
        {/*<Menu />*/}

        {/*<div>*/}
        {/*    <Grid container justify="center">*/}
        {/*        <Button color="primary" size="large" type="submit" variant="contained">*/}
        {/*            Sign Up*/}
        {/*        </Button>*/}
        {/*    </Grid>*/}
        {/*</div>*/}
        <div>

            <div className="loginBackgroundImage23333">
                <img src={QualiflyIconForBooth} alt="QualiflyIconForBooth" className="QualiflyIconForBooth"/>

            </div>

            <div className="margin1222define"/>
            <Grid container
                  // spacing={6}
                // alignItems="center"
                //   justifyContent="center"
            >
                {/*<Grid item xs={2} sm={12} md={12} lg={12} xl={12}   >*/}
                    <div className="joinus6">

                        Result Analysis
                    </div>
                {/*</Grid>*/}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign:"center"}}>

                    <img src={mode09}/>

                </Grid>




                {/*<Grid item xs={8} sm={12} md={12} lg={12} xl={12} sx={{textAlign:"center"}}>*/}
                    <div style={{
                        // textAlign:"center",
                        margin:"auto",
                        paddingTop:"20px",
                        paddingBottom:"20px",
                        // border:"1px solid red",
                    }}>

                    <RadarChart
                        captions={captions}
                        data={data6}
                        options={{ size: 200,
                            axes: true, // show axes?
                            scales: 3, // show scale circles?
                            captions: true, // show captions?
                            captionMargin: 10,
                            dots: false, // show dots?
                            zoomDistance: 1.2, // where on the axes are the captions?
                            setViewBox: (options) => `-${options.captionMargin} 0 ${options.size + options.captionMargin * 2} ${options.size}`, // custom viewBox ?
                            smoothing: noSmoothing, // shape smoothing function
                            axisProps: () => ({ className: 'axis' }),
                            scaleProps: () => ({ className: 'scale', fill: 'none' }),
                            shapeProps: () => ({ className: 'shape' }),
                            captionProps: () => ({
                                className: 'caption',
                                textAnchor: 'middle',
                                fontSize: 10,
                                fontFamily: 'sans-serif'
                            }),
                            dotProps: () => ({
                                className: 'dot',
                                mouseEnter: (dot) => { console.log(dot) },
                                mouseLeave: (dot) => { console.log(dot) }
                            }),
                            rotation: 0,
                            wrapCaptionAt: 15,
                            captionLineHeight: 20,}}
                        // size={"50vw"}
                    />
                    </div>
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign:"center"}}>*/}
                {/*    <div className="joinus">*/}
                {/*        Congratulations!*/}
                {/*    </div>*/}

                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign:"center"}}>*/}
                {/*    <div className="joinus4">*/}
                {/*        You have successfully achieved:*/}
                {/*    </div>*/}

                {/*</Grid>*/}

                {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign:"center"}}>*/}
                {/*    <div className="containtestt">*/}

                {/*        <img src={Group227}/>*/}
                {/*        <span className="texttest">27%</span>*/}
                {/*    </div>*/}


                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign:"center"}}>*/}
                {/*    <div className="joinus4">*/}
                {/*        Time used: 5:42*/}
                {/*    </div>*/}

                {/*</Grid>*/}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign:"center"}}>
                    <div onClick={()=>{console.log("haha")
                        navigate("/joinus")

                    }}>
                        <BoothButton333339></BoothButton333339>
                    </div>
                </Grid>

                {/*<Grid item xs={6} sx={{textAlign:"center"}}>*/}
                {/*    <div onClick={()=>{ window.location.replace('https://www.instagram.com/qualifly_hk/')}}>*/}
                {/*        <img src={Instagramlogo} style={{width:"20vw"}}/>*/}
                {/*        <div className="joinus3">*/}
                {/*            qualifly_hk*/}
                {/*        </div>*/}

                {/*    </div>*/}


                {/*</Grid>*/}
                {/*<Grid item xs={6} sx={{textAlign:"center"}}>*/}
                {/*    <div onClick={()=>{ window.location.replace('https://www.linkedin.com/company/qualiflyhk/?originalSubdomain=hk')}}>*/}
                {/*        <img src={LKlogo} style={{width:"20vw"}}/>*/}
                {/*        <div className="joinus3">*/}
                {/*            QualiFly*/}
                {/*        </div>*/}

                {/*    </div>*/}


                {/*</Grid>*/}

                {/*<Grid item xs={8} sx={{textAlign:"center"}}>*/}
                {/*    <div className="joinus3">*/}
                {/*        A one-stop platform for personalised, convenient and effective revision.*/}
                {/*    </div>*/}

                {/*</Grid>*/}
                {/*<Grid item xs={12} sx={{textAlign:"center"}}>*/}

                {/*    <img src={Group308} style={{width:"80vw"}}/>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={5} sx={{textAlign:"center"}}>*/}
                {/*    <div className="joinus4">*/}
                {/*        Supported by:*/}
                {/*    </div>*/}
                {/*</Grid>*/}

                {/*<Grid item xs={6} sx={{textAlign:"center"}}>*/}
                {/*    <img src={image4} style={{width:"50vw"}}/>*/}
                {/*</Grid>*/}


            </Grid>
            {/*<Box*/}
            {/*    m={1}*/}
            {/*    // Direction="row"*/}
            {/*    display="flex"*/}
            {/*    justifyContent="center"*/}
            {/*    alignItems="center"*/}
            {/*    minHeight="100vh"*/}
            {/*    // sx={{}}*/}
            {/*>*/}

            {/*    <div className="margin10define">*/}

            {/*        <BoothButton333333></BoothButton333333></div>*/}

            {/*    /!*<Button variant="contained" color="primary" sx={{ height: 40 }}>*!/*/}
            {/*    /!*    Primary*!/*/}
            {/*    /!*</Button>*!/*/}
            {/*</Box>*/}
            {/*<div className="responsiveContainer">*/}

            {/*    <Grid container justify="center">*/}

            {/*            <BoothButton333333></BoothButton333333>*/}

            {/*    </Grid>*/}
            {/*    /!*<div className="imgconta" onClick={()=>{}}>*!/*/}
            {/*    /!*    <Box textAlign='center'>*!/*/}
            {/*    /!*        <BoothButton333333></BoothButton333333></Box>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<div className="imgconta" onClick={()=>{}}>*!/*/}
            {/*    /!*    <Box textAlign='center'>*!/*/}
            {/*    /!*        <BoothButton333333></BoothButton333333></Box>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*</div>*/}
        </div>
    </>;
}

export default Login;
