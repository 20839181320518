import React from 'react';
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';

function TopicBox({
  className,
  text
}) {
  return (
    <>
      <Grid item xs={12}>
        <div className={className}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} align="center"><p className="resultTopicBoxText">{text}</p></Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
}

export default TopicBox;
