import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';
import '../../translation'

import useWindowDimensions, {db, lgg, Mainserver} from "../../firebase";
import {doc, getDoc} from 'firebase/firestore';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import QuestionOfTheDay from '../../pages/dashboard/QuestionOfTheDay';
import TodaysVideo from '../../pages/dashboard/TodaysVideo';
import TodaysPractices from '../../pages/dashboard/TodaysPractices';
import Search from '../../pages/dashboard/Search';
import {useNavigate} from 'react-router-dom';
import {getUser} from '../../localStorage/User';
import {redirect} from "../../functions/redirect"
import {getExercise} from "../../functions/getExercise"
import {useTranslation} from 'react-i18next'


import Carousel from 'react-material-ui-carousel'
import {useMediaQuery} from "react-responsive";
import {Dialog, DialogTitle, Paper} from "@mui/material";
import questionBankPastTenseNoWordSVG from "../../assets/exercise/questionBankPastTenseNoWord.svg";
import questionBankPastTensePresentTenseNoWordSVG
    from "../../assets/exercise/questionBankPastTensePresentTenseNoWord.svg";
import questionBankPresentContinuousTenseNoWordSVG
    from "../../assets/exercise/questionBankPresentContinuousTenseNoWord.svg";
import questionBankPresentTensePresentContinuousTenseNoWordSVG
    from "../../assets/exercise/questionBankPresentTensePresentContinuousTenseNoWord.svg";
import questionBankPrepositionNoWordSVG from "../../assets/exercise/questionBankPrepositionNoWord.svg";
import questionBankQuestionWordNoWordSVG from "../../assets/exercise/questionBankQuestionWordNoWord.svg";
import {mapping333} from "./TodaysPracticesSingle";

import Rectangle from '../../assets/Rectangle 398.png';
import Rectangle2 from '../../assets/Send.png';
import Rectangle3 from '../../assets/Voice.png';
import Rectangle4 from '../../assets/image 83.png';
import Rectangle5 from '../../assets/image 105.png';
import Rectangle6 from '../../assets/image 106.png';
import Rectangle7 from '../../assets/dice111.png';

import languageSVG from "../../assets/menu/language.svg";
import axios from "axios";
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    TypingIndicator
} from '@chatscope/chat-ui-kit-react';
import {styled} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchWhiteSVG from "../../assets/dashboard/searchWhite.svg";
import SearchYellowSVG from "../../assets/dashboard/searchYellow.svg";
import {makeStyles} from "@mui/styles";
import {MessageLeft, MessageRight} from "../../Message";
import {TextInput} from "../../TextInput";
import * as PropTypes from "prop-types";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import Stackgoogletonode from "./stackgoogletonode";


const API_KEY = "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";
// "Explain things like you would to a 10 year old learning how to code."
const systemMessage = { //  Explain things like you're talking to a software professional with 5 years of experience.
    "role": "system",
    // "content": "Please correct all my grammatical mistakes in the following conversation."
    "content": ""
}
const systemMessage2 = { //  Explain things like you're talking to a software professional with 5 years of experience.
    "role": "user",
    // "content": "Please correct all my grammatical mistakes in the following conversation."
    "content": "Your name is Roo. You should act like a teacher in the following conversation with me. I am a primary school student. Sometimes I might also ask you about some non-english learning Topic."
}
const systemMessage3 = { //  Explain things like you're talking to a software professional with 5 years of experience.
    "role": "assistant",
    // "content": "Please correct all my grammatical mistakes in the following conversation."
    "content": "Ok. I will try my best."
}


const systemMessage1 = { //  Explain things like you're talking to a software professional with 5 years of experience.
    "role": "system",
    // "content": "Please correct all my grammatical mistakes in the following conversation."
    "content": ""
}
const instanceAxios = axios.create({
    // baseURL: 'https://intense-shore-46981.herokuapp.com/api/',
    // baseURL: 'https://24ff-183-178-101-99.ap.ngrok.io/api',
    // baseURL: 'https://tttt33ttq.azurewebsites.net/api',
    baseURL: Mainserver + '/api',
    // baseURL:"https://9f2d-183-178-101-99.jp.ngrok.io/api"
    // baseURL: 'https://b0c5-156-146-45-209.ap.ngrok.io/api',

})
const TextField1 = styled(TextField)(({theme}) => ({

    // '& .MuiOutlinedInput-root': {
    //     '& fieldset': {
    //         borderRadius: '100px',
    //         width: 'calc(100% - 20px)',
    //     },
    // },
    //
    //
    // [theme.breakpoints.down('sm')]: {
    //     borderRadius: '100px',
    //     width: 'calc(100% - 2px)',
    // },

    "& .MuiInputBase-root": {
        height: "80px"
    },


    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
    },
    // '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    //     borderWidth: 1,
    // },
    // '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //     borderWidth: 1,
    // },

}));

const signupHandle = async (message) => {
    const body = {
        model: 'text-davinci-003',
        message: message,
        max_tokens: 1000,
        temperature: 0.5
    };
    try {
        const e = await instanceAxios.post('chat1', body)
        console.log("data", e.data)
        // const {token, expiresAt, userInfo} = data
        // setStorage(token, expiresAt, userInfo)
        // navigation.navigate('Home')
        // resetForm({})
        return e.data.data;
    } catch (error) {
        // console.log("error",error)

    }
}


function l(data, d) {
    console.log(data, d);
}


function SimpleDialog(props) {

    const {onClose, selectedValue, open} = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Loading</DialogTitle>
            {/*<List sx={{ pt: 0 }}>*/}
            {/*    {emails.map((email) => (*/}
            {/*        <ListItem button onClick={() => handleListItemClick(email)} key={email}>*/}
            {/*            <ListItemAvatar>*/}
            {/*                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>*/}
            {/*                    <PersonIcon />*/}
            {/*                </Avatar>*/}
            {/*            </ListItemAvatar>*/}
            {/*            <ListItemText primary={email} />*/}
            {/*        </ListItem>*/}
            {/*    ))}*/}
            {/*    <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>*/}
            {/*        <ListItemAvatar>*/}
            {/*            <Avatar>*/}
            {/*                <AddIcon />*/}
            {/*            </Avatar>*/}
            {/*        </ListItemAvatar>*/}
            {/*        <ListItemText primary="Add account" />*/}
            {/*    </ListItem>*/}
            {/*</List>*/}
        </Dialog>
    );
}

const useStyles = makeStyles((theme) =>
    ({
        paper: {
            width: "80vw",
            height: "80vh",
            maxWidth: "500px",
            maxHeight: "700px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative"
        },
        paper2: {
            width: "80vw",
            maxWidth: "500px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative"
        },
        container: {
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        messagesBody: {
            width: "calc( 100% - 20px )",
            margin: 10,
            overflowY: "scroll",
            height: "calc( 100% - 80px )"
        }
    })
);

function Sugges(props) {
    let AAAAAAA="I'm a primary school student. " +
        "I would like to chat with you. " +
        "Please give me 4 examples of what I can chat with you. " +
        "Your entire response must be in list format " +
        "So that I could parse your whole response. "
    let singlemessage1 = {
        message: AAAAAAA,
        direction: 'outgoing',
        sender: "user"
    }

    let AAAAAAA2='["Favorite subject in school", "Hobbies and interests", "Favorite books or movies", "Plans for the upcoming weekend"]'
    let singlemessage2 = {
        message:AAAAAAA2,
        sender:"ChatGPT"
    }

    let AAAAAAA3 ="Please give me another 4."
    let singlemessage3 = {
        message:AAAAAAA3,
        direction: 'outgoing',
        sender: "user"
    }
    let AAAAAAA4='["Favorite sports or games", "Favorite foods or snacks", "Dream vacation destination", "Favorite music or musicians"]'
    let singlemessage4 = {
        message:AAAAAAA4,
        sender:"ChatGPT"
    }
    let AAAAAAA5 ="Please give me another 4."
    let singlemessage5 = {
        message:AAAAAAA5,
        direction: 'outgoing',
        sender: "user"
    }

    let BBBBBB = [singlemessage1,singlemessage2,singlemessage3,singlemessage4,singlemessage5];
    let pp2 = props.suggest
    return <div
        className="dashboardBanner2"
    >
        {/*<div className="dashboardMargin"></div>*/}
        <div className="dashboardMargin05">
            {/*<div className="dashboardMargin1">Suggested Topics</div>*/}
            <div
                className="dashboardMargin1-1"
            >
                <div
                    className="dashboardMargin1-2"
                >
                {pp2.map((item, index) => {
                    return <div

                        onClick={
                            () => {
                                props.handleSend(item)

                            }
                        }
                        className="dashboardMargin2">
                        <div className="dashboardMargin3">
                            {item}
                        </div>
                    </div>

                })}
                </div>
                <div className="dashboardMargin2-1"
                     onClick={
                         async () => {


                             await props.processMessageToChatGPT(BBBBBB,2)

                         }
                     }
                >
                    <img className="dashboardMargin3-1" src={Rectangle7} alt="home"/>

                </div>
            </div>
        </div>
    </div>;
}


function Imagee2() {
    const [height, setHeight] = useState(0)
    const ref = useRef(null)
    console.log("heighttttt", height)
    useEffect(() => {
        setHeight(ref.current.clientHeight)
    })

    return (

        <div ref={ref} className="dashboardMargin134">


            {/*<img className="dashboardMargin135"  src={Rectangle} alt="home"/>*/}
        </div>
    );
}

function Imagee() {

    return <div className="dashboardMargin13"><Imagee2/></div>;

}

// </div>
function NewComponent9(props) {


    // console.log("propsssssssssssss", props)
    let scrollRef = props.scrollRef


    let messages = props.messages;
    return <div className="dashboardMargin125">


        <Imagee/>

        <div className="dashboardMargin189"
            // I miss this  line.

        >
            <div className="dashboardMargin19" ref={scrollRef}>

                {0 ? <>  {Array.from({length: 100}, (_, index) => index + 1).map((number) => (
                    <li key={number}>{number}</li>
                ))}</> : messages.map((message, index) => {

                    // console.log("message11111111111", message)

                    if (message.direction) {
                        return <MessageRight
                            message={message.message}
                            // timestamp="MM/DD 00:00"
                            // photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
                            displayName={"You"}
                            avatarDisp={true}
                        />
                    } else {
                        return <MessageLeft
                            message={message.message}
                            // timestamp="MM/DD 00:00"
                            // photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
                            displayName={"Roo"}
                            avatarDisp={true}
                        />
                    }
                })}


            </div>
        </div>

    </div>;
}

function Inputt(props) {

    const [isMicActive, setIsMicActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    return (
        <div className="dashboardMargin145">
            <div className="dashboardMargin146-0"></div>
            <div className="dashboardMargin146">
                <TextField1
                    value={props.value}
                    onChange={(e) => {
                        props.onChange(e.target.value)
                    }}

                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            console.log('Enter key pressed');
                            props.onClick()
                            // write your functionality here
                        }
                    }}

                    fullWidth
                    // onClick={() => (setFocusSearch(true))}
                    // onBlur={() => (setTimeout(() => {
                    //     setFocusSearch(false)
                    // }, 200))}
                    className={props.value == "" ?
                        "searchTextField1" :
                        "searchTextField1"
                        // 'searchSuggestionTextField'
                    }

                    disabled={props.isTyping}
                    // disabled={1}
                    autoComplete="off"
                    // placeholder={t('SEARCH_EXERCISE')}
                    placeholder={
                        isLoading ? "Loading..." :
                            isMicActive ? "Speak now. Press the mic to end the record." :
                                "Type your message here or click on the mic"
                    }
                    InputProps={{
                        style: {
                            // fontSize: props.m ? "18px" : "1.2vw",  // this control font size of placeholder
                            // fontSize: "18px",  // this control font size of placeholder
                            // paddingLeft: "1.2vw",
                            // fontFamily: "Nunito",
                            // fontWeight: "600",
                            // minHeight: "4vw"
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                {/*<div className="dashboardMargin18">*/}

                                <>

                                    {/*<img*/}
                                    {/*    className="dashboardMargin17"*/}
                                    {/*    src={Rectangle3} alt="search"*/}
                                    {/*    onClick={() => {*/}
                                    {/*        console.log("clicked")*/}
                                    {/*    }*/}
                                    {/*    }*/}
                                    {/*/>*/}
                                    <Stackgoogletonode
                                        onChange={props.onChange}
                                        setWords={props.setWords}

                                        isMicActive={isMicActive}
                                        setIsMicActive={setIsMicActive}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                    />

                                </>


                                {/*</div>*/}


                                {/*{search == "" ?*/}
                                {/*    <div className="searchBtnContainer">*/}
                                {/*        <img className="searchWhite" src={SearchWhiteSVG} alt="search"/>*/}
                                {/*    </div>*/}
                                {/*    :*/}
                                {/*    <div className="searchSuggestionBtnContainer">*/}
                                {/*        <img className="searchWhite" src={SearchYellowSVG} alt="search"/>*/}
                                {/*    </div>*/}
                                {/*}*/}
                            </InputAdornment>
                        )
                    }}
                />

            </div>


            <div className="dashboardMargin16" onClick={

                props.onClick}
            >

                <img className="dashboardMargin136" src={Rectangle2} alt="home"/>
            </div>
            <div className="dashboardMargin146-0"></div>

        </div>
    );
}

Inputt.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    m: PropTypes.bool,
    onClick: PropTypes.func
};

function Suggest2(props) {
    return (

        <div
            className="dashboardBanner3">

            {/*<div className="dashboardMargin10">*/}

            <div className="dashboardMargin11">
                Chat with ROO
            </div>

            <div className="dashboardMargin12">
                <NewComponent9 messages={props.messages}
                               scrollRef={props.scrollRef}
                />
                <Inputt value={props.value} onChange={props.onChange} m={props.m} onClick={props.onClick}
                        setWords={props.setWords}
                        scrollToBottom={props.scrollToBottom}
                        isTyping={props.isTyping}
                />
            </div>

            {/*</div>*/}


        </div>
    )
}

Suggest2.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    m: PropTypes.bool,
    onClick: PropTypes.func
};


let synthesizer;
// let inputText = "Ich möchte es auf deutsche Sprache setzen, weiß aber nicht wie!";
// let inputText = "speakTextAsync";
let player;
player = new SpeechSDK.SpeakerAudioDestination();
var audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player);
var speechConfig = SpeechSDK.SpeechConfig.fromSubscription("14c202f3a9bf4ab899552f5b76b3fba1", "eastus");


// speechConfig.speechSynthesisVoiceName = "Microsoft Server Speech Text to Speech Voice (de-DE, Hedda)";
// speechConfig.speechSynthesisLanguage = "de-DE";


synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);


function synthesizeSpeech(inputText) {
    player.pause()
    synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

    synthesizer.speakTextAsync(
        inputText,
        function (result) {
            // startSpeakTextAsyncButton.disabled = false;
            window.console.log("Result is as follow.", result);
            // synthesizer.close();
            console.log("synthesizer Closing now.")
            // synthesizer = undefined;
        });
    player.resume()
}

function Rightup(props) {
    console.log("props.wordsssssssssss", props.words)


    // for (let i = 0; i < words.length; i++) {
    //
    //     // Set a flag indicating whether they should be.
    //     let shouldReplace = false
    //
    //
    //     // console.log("words[i].Wordddddddddddddddd", words[i].Word)
    //     let phonemes = words[i].Phonemes
    //     // console.log("phonemessssssss", phonemes)
    //     for (let j = 0; j < phonemes.length; j++) {
    //         // console.log("phonemes[j].AccuracyScore",phonemes[j].Phoneme ,phonemes[j].AccuracyScore)
    //         if (phonemes[j].AccuracyScore < 100) {
    //             shouldReplace = true
    //         }
    //     }
    //
    //     if (shouldReplace){
    //         wordsToBeReplaced.push(words[i])
    //     }
    // }


    return <div
        className="dashboardBanner4">
        <div className="dashboardMargin4">Pronounciation</div>

        {props.words.map((
            item, index
        ) => (
            <div className="dashboardMargin6">
                <div className="dashboardMargin7">

                    <div
                        // className="dashboardMargin7"
                    >
                        {item.Word}
                    </div>
                    <div
                        // className="dashboardMargin7"
                    >

                        {item.Phonemes.map((item2, index2) => {

                            // If the score of this Phoneme is under 100, we show it in red.
                            if (item2.AccuracyScore < 100) {
                                return <span style={{color: "red"}}>{item2.Phoneme}</span>
                            }

                            return <span>{item2.Phoneme}</span>

                        })

                        }
                    </div>
                </div>


                <div
                    className="dashboardMargin75"
                    onClick={
                        () => {

                            synthesizeSpeech(item.Word)
                        }


                    }
                >
                    <img
                        // className="dashboardMargin13"
                        src={Rectangle4} alt="home"/>
                </div>
            </div>
        ))}


    </div>;
}

function Rightbelow(props) {


    let gr = props.grammar
    let pi = gr.filter((item) => {
        if (item.reason) {
            lgg("item.reason", item)

            return true
        } else {
            lgg("item.reason2", item)

            return false
        }
    })
    lgg("pi", pi)


    return <div
        className="dashboardBanner5" ref={props.scrollRef2}>

        <div className="dashboardMargin5">Grammar</div>
        {pi.map((item) => (
            <div className="dashboardMargin8">

                <div className="dashboardMargin9">

                    <div className="dashboardMargin91">
                        <div className="dashboardMargin92">
                            <img src={Rectangle5} alt="home"/>
                        </div>
                        <div>
                            {item.originalversion}
                        </div>

                    </div>

                    <div className="dashboardMargin91">
                        <div className="dashboardMargin92">
                            <img src={Rectangle6} alt="home"/>
                        </div>
                        <div>
                            {item.correctversion}
                        </div>

                    </div>
                    <br/>
                    <div>
                        {item.reason}
                    </div>


                    {/*當我們形容在某個地方時需要寫There are/ is*/}

                </div>

            </div>

        ))}

    </div>;
}

function Righthandside(props) {
    return <div
        className="dashboardMargin121"

    >
        <Rightup words={props.words}/>
        <div style={{height:10}}></div>
        <Rightbelow
            grammar={props.grammar}
            scrollRef2={props.scrollRef2}
        />
    </div>;
}

function Dashboard() {
    const classes = useStyles();
    const [search, setSearch] = useState("");
    const [words, setWords] = useState([]);
    const [grammar, setGrammar] = useState([]);
    const [suggest, setSuggest] = useState(["Today, I ... (what you did)", "How to find kangaroos", "Tell me a joke", "How to meet new friends in Australia"]);
    const [messages, setMessages] = useState([
        {
            message: "Hello",
            sentTime: "just now",
            sender: "ChatGPT"
        }
    ]);
    console.log("messagesssssssssssssssssss96", messages)




    const [isTyping, setIsTyping] = useState(false);

    const handleSend = async (message) => {


        setIsTyping(true);  // This line get moves upwards To try to prevent any case with double Click.

        if(isTyping){
            return;
        }
        if (!message.trim().length) {
            console.log('str is empty!');
            setIsTyping(false);
            return;
        }
        if (message === "" || message === null) {
            setIsTyping(false);
            return;

        }



        const newMessage = {
            message,
            direction: 'outgoing',
            sender: "user"
        };

        const newMessages = [...messages, newMessage];

        setMessages(newMessages);
        setSearch("");


        // Initial system message to determine ChatGPT functionality
        // How it responds, how it talks, etc.

        await processMessageToChatGPT(newMessages, 0);
        console.log("Finish normal flow.")


        let n = asking(message)
        // console.log("")
        let singlemessage = {
            message: n,
            direction: 'outgoing',
            sender: "user"
        }
        let checkgrammar = [singlemessage];
        l("Checking grammar is.", checkgrammar)

        console.log("Starting to ask grammar")
        await processMessageToChatGPT(checkgrammar, 1);


    };


    const asking = (a) => `

    Could you help me check the grammar correctness of this sentence?

"${a}"

your Whole response Must be a Json.

There should be a Property named correctness which is true or false.
That should a property name originalversion, which is the sentence I sent to you.
If the sentence is not grammatically correct, there should be two more Property.
One is reason and one is the correct version.

    `


    async function processMessageToChatGPT(chatMessages, mode = 0) { // messages is an array of messages
        // Format messages for chatGPT API
        // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
        // So we need to reformat

        let apiMessages = chatMessages.map((messageObject) => {
            let role = "";
            if (messageObject.sender === "ChatGPT") {
                role = "assistant";
            } else {
                role = "user";
            }
            return {role: role, content: messageObject.message}
        });
        l("The API message is now ", apiMessages)


        // Get the request body set up with the model we plan to use
        // and the messages which we formatted above. We add a system message in the front to'
        // determine how we want chatGPT to act.
        const apiRequestBody = {
            "model": "gpt-3.5-turbo",
            "messages": [
                systemMessage,  // The system message DEFINES the logic of our chatGPT
                ...apiMessages // The messages from our chat with ChatGPT
            ]
        }
        let body;
        if (mode == 1) {    // 1 is for grammar checking
            body = {
                model: 'text-davinci-003',
                message: [
                    systemMessage1,  // The system message DEFINES the logic of our chatGPT
                    ...apiMessages // The messages from our chat with ChatGPT
                ],
                max_tokens: 1000,
                temperature: 0.5
            };
        } else if (mode == 0) {
            body = {
                model: 'text-davinci-003',
                message: [
                    systemMessage,
                    systemMessage2,
                    systemMessage3,

                    ...apiMessages // The messages from our chat with ChatGPT
                ],
                max_tokens: 1000,
                temperature: 0.5
            };
        } else if (mode == 2) {
            body = {
                model: 'text-davinci-003',
                message: [
                    systemMessage1,  // The system message DEFINES the logic of our chatGPT
                    ...apiMessages // The messages from our chat with ChatGPT
                ],
                max_tokens: 1000,
                temperature: 0.5
            };

        }

        async function fetchCompletion(mode = 0) {
            try {
                l("apiRequestBody", apiRequestBody)
                let data;
                if (1) {

                    l("Stable version is now used.")
                    const e = await instanceAxios.post('chat1', body)
                    console.log("data", e.data)
                    // const {token, expiresAt, userInfo} = data
                    // setStorage(token, expiresAt, userInfo)
                    // navigation.navigate('Home')
                    // resetForm({})
                    data = e.data.data;
                    l("data", data)

                    if (mode == 0) {
                        synthesizeSpeech(data.text)
                        setMessages([...chatMessages, {
                            message: data.text,
                            sender: "ChatGPT"
                        }]);
                        setIsTyping(false);
                    } else if (mode == 1) {

                        try {
                            let qw = JSON.parse(data.text)
                            console.log("qwwwwwwwwwwww", qw)
                            setGrammar([...grammar, qw])
                        } catch (e) {
                        } finally {
                        }
                    } else if (mode == 2) {
                        try {
                            console.log("qwww1111111", data.text)
                            let qw = JSON.parse(data.text)

                            setSuggest(qw)
                            console.log("qwwwwwwwwwwww", qw)

                            // setGrammar([...grammar, qw])
                        } catch (e) {
                        } finally {
                        }
                    }


                } else {

                    // This part is for legacy reference. Do not use.
                    const response = await fetch("https://api.openai.com/v1/chat/completions", {
                        method: "POST",
                        headers: {
                            "Authorization": "Bearer " + API_KEY,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(apiRequestBody)
                    });
                    l("response", response)
                    data = await response.json();
                    l("data", data)
                    // {id: 'chatcmpl-6umo4Ww7StqvDsFFu6HCaIZUfiFD7', object: 'chat.completion', created: 1678992000, model: 'gpt-3.5-turbo-0301', usage: {…}, …}
                    console.log(data);
                    setMessages([...chatMessages, {
                        message: data.choices[0].message.content,
                        sender: "ChatGPT"
                    }]);
                    setIsTyping(false);
                }


            } catch (error) {
                console.error(error);
            }
        }

        await fetchCompletion(mode);


    }

    const [height, setHeight] = useState(0)
    const ref = useRef(null)
    const user = getUser();

    const scrollRef = useRef();
    const scrollRef2 = useRef();

    function scrollToBottom() {


        const element = scrollRef.current;
        element.scrollTop = element.scrollHeight - element.clientHeight;


        // element.scrollTo({
        //     top: element.scrollHeight - element.clientHeight,
        //     behavior: 'smooth'
        // });
    }

    function scrollToBottom2() {


        const element = scrollRef2.current;
        element.scrollTop = element.scrollHeight - element.clientHeight;


        // element.scrollTo({
        //     top: element.scrollHeight - element.clientHeight,
        //     behavior: 'smooth'
        // });
    }

    useLayoutEffect(() => {
        scrollToBottom();
    }, [messages])
    useLayoutEffect(() => {
        scrollToBottom2();
    }, [grammar])
    console.log("quesr", user.id)
    console.log("height", height)
    let navigate = useNavigate();
    const {t} = useTranslation();
    useEffect(() => {
        if (ref.current) {
            setHeight(ref.current.clientHeight)
        }
        // setHeight(ref.current.clientHeight)
    })
    useEffect(() => {
        // redirect(user, navigate);
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        getUserInfo();  // which set the UserInfo which    trigger the  getLoginStatus
    }, [])
    // to get user information
    const [userInfo, setUserInfo] = useState();
    const getUserInfo = async () => {
        if (user.id !== "" && user.id !== null && user.id !== undefined) {
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            setUserInfo(userSnapshot.data());
        }
    }
    const [loginStatus, setLoginStatus] = useState();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const randomIndex = Math.floor(Math.random() * 2) + 1;


    const SettingsT = {
        autoPlay: false,
        animation: "slide",
        indicators: true,
        duration: 500,
        navButtonsAlwaysVisible: false,
        navButtonsAlwaysInvisible: true,
        cycleNavigation: true,
        fullHeightHover: true,
        swipe: true
    }

    const msg = new SpeechSynthesisUtterance()

    const speechHandler = (msg) => {
        msg.text = msg
        window.speechSynthesis.speak(msg)
    }
    return (
        <>
            <div className="responsiveContainer7">


                {/*<SimpleDialog*/}
                {/*    // selectedValue={selectedValue}*/}
                {/*    open={!exerciseID}*/}
                {/*    // onClose={handleClose}*/}
                {/*/>*/}

                <Menu/>
                {/*<SideMenu/>*/}


                {/*{false && <div*/}
                {/*    className="responsiveContainer8"*/}
                {/*>*/}
                {/*    <div className="responsiveContainer9">*/}
                {/*        {0 ?*/}
                {/*            <div className="res2">*/}
                {/*                qqqqqqqqqqqqqq*/}
                {/*            </div>*/}
                {/*            :*/}
                {/*            <Sugges/>*/}
                {/*        }*/}


                {/*        <div className="dashboardBanner3">*/}


                {/*            /!*<div className="res4">*!/*/}


                {/*            /!*</div>*!/*/}
                {/*            <div className="dashboardMargin125">*/}

                {/*                /!*Image here.*!/*/}
                {/*                <div className="dashboardMargin189">*/}
                {/*                    <div className="dashboardMargin19" ref={scrollRef}>*/}

                {/*                        {Array.from({length: 1000}, (_, index) => index + 1).map((number) => (*/}
                {/*                            <li key={number}>{number}</li>*/}
                {/*                        ))}*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="res6" onClick={scrollToBottom}>qqqqqqqqqqqq</div>*/}
                {/*</div>*/}
                {/*}*/}

                {/*<div className="res6" onClick={scrollToBottom}>qqqqqqqqqqqq</div>*/}
                <div className="responsiveContainer8">

                    <div
                        className="responsiveContainer9"
                    >
                        <Sugges handleSend={handleSend} processMessageToChatGPT={processMessageToChatGPT}
                            suggest={suggest}
                        />


                        <Suggest2 setWords={setWords} words={words}
                                  messages={messages}
                                  value={search}
                                  onChange={(e) => {
                                      // setSearch(e.target.value)
                                      setSearch(e)
                                  }}
                                  m={is_m}
                                  onClick={() => {
                                      handleSend(search)
                                  }}
                                  scrollRef={scrollRef}
                                  scrollToBottom={scrollToBottom}
                                  isTyping={isTyping}
                        />
                    </div>

                    <Righthandside

                        words={words}
                        grammar={grammar}
                        scrollRef2={scrollRef2}
                    />

                    {/*<div onClick={*/}
                    {/*    ()=> {*/}
                    {/*        player.pause()*/}
                    {/*    }*/}
                    {/*}*/}
                    {/*>bbbbbbbbb</div>*/}

                    {/*<div onClick={*/}
                    {/*    ()=> {*/}
                    {/*        player.resume()*/}
                    {/*    }*/}
                    {/*}*/}
                    {/*>aaaaa</div>*/}

                    {/*<div onClick={*/}
                    {/*    ()=> {*/}
                    {/*        synthesizeSpeech("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq")*/}
                    {/*    }*/}
                    {/*}*/}
                    {/*>qqqqqqqq</div>*/}
                    {/*<div onClick={*/}
                    {/*    ()=> {*/}
                    {/*        // speechHandler("How are you doing today?")*/}
                    {/*        synthesizeSpeech("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww")*/}
                    {/*    }*/}
                    {/*}*/}
                    {/*>wwwwwwww</div>*/}
                </div>
            </div>


            <div className="notSupportScreenSizeImageContainer1">


                <div className="verticalCenter">
                    <img className="notSupportScreenSizeImage"
                         src={require('../../assets/general/notSupportScreenSizeBanner.png')} alt="not support"/>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
