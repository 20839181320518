
import React from 'react';
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';
import Week from './Week.js';
import Day from './Day.js';

function WeekAndDay() {
  return <>
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={6} align="center">
        <Week />
      </Grid>
      <Grid item xs={6} align="center">
        <Day />
      </Grid>
    </Grid>
  </>;
}

export default WeekAndDay;