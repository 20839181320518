

import React, { useEffect, useState } from 'react';

import '../../App.css';
import './_styles.css';

import '../../translation'
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../localStorage/Language';
import progressSVG from '../../assets/exercise/progress.svg';

import progressBookmarkSVG from '../../assets/exercise/progressBookmark.svg';
import progressBookmarkTransparentSVG from '../../assets/exercise/progressBookmarkTransparent.svg';
import Grid from "@mui/material/Grid";

function Progress({ topicTotal, topicCorrectTotal, toQuestion, questionAnswer, questionTotal, userAnswer, remark,qq321 }) {

    let mode3=qq321;
    const { t } = useTranslation();
    const l = getLanguage();

    const [progressQuestionNumArray, setProgressQuestionNumArray] = useState();
    useEffect(() => {
        let tmpArray = [];
        for (let i = 0; i < questionTotal; i++) {
            tmpArray.push(i);
        }
        setProgressQuestionNumArray(tmpArray);
    }, [])

    useEffect(() => {
        qq321 && calOverallAccuracy();
    }, [qq321]);
    const [overallAccuracy, setOverallAccuracy] = useState();
    const calOverallAccuracy = () => {

        if (!mode3){return}
        let ttt=0
        let ttt2=0
        if (mode3) {



            pp3 = Object.keys(mode3).map((i, index) => {
                ttt+=mode3[i].total;

                ttt2+=mode3[i].correct;
                // let per = mode3[i].correct / mode3[i].total;
                // let g1;
                // g1 = (per) == 1 ?
                //     "Q"
                //     :
                //     (per) >= 0.9 ?
                //         "A"
                //         :
                //         (per) >= 0.8 ?
                //             "B"
                //             :
                //             (per) >= 0.6 ?
                //                 "C"
                //                 :
                //                 "F";
                // return [trans3[i], g1, mode3[i].correct, mode3[i].total]
            })
            console.log('pp3', pp3)
        }

        // const tmp = (topicCorrectTotal.pastTense + topicCorrectTotal.presentContinuousTense + topicCorrectTotal.preposition + topicCorrectTotal.questionWord) / questionTotal;

        let tmp = ttt2 / ttt;
        setOverallAccuracy(tmp);



    }
    const trans3 = {
        "CONJ_1": "Conjunctions",
        "ART_1": "Article",
        "BIxGER_1": "Bare + Gerund",
        "BIxTI_1": "To + Bare",
        "COM_1": "Comparatives",
        "PACT_1": "Past Continuous Tense",
        "PPT_1": "Past Perfect Tense",
        "pastTense": "Past Tense",
        "preposition": "Preposition",
        "presentContinuousTense": "Present Continuous Tense",

        "questionWord": "Question Word",
    }


    let pp3;
    if (mode3) {
        pp3 = Object.keys(mode3).map((i, index) => {

            let per = mode3[i].correct / mode3[i].total;
            let g1;
            g1 = (per) == 1 ?
                "Q"
                :
                (per) >= 0.9 ?
                    "A"
                    :
                    (per) >= 0.8 ?
                        "B"
                        :
                        (per) >= 0.6 ?
                            "C"
                            :
                            "F";
            return [trans3[i], g1, mode3[i].correct, mode3[i].total]
        })
        console.log('pp3', pp3)
    }
    return (
        <>
            <div className="calibrationTestResultWhiteBackground"></div>
            <div className="progressContainer2">
                <div className="progressFloatRight">
                    <img className="calibrationTestProgressTopImage"
                        src={
                            l === "en" ?
                                require('../../assets/exercise/calibrationTestResultProgressTop.png')
                                :
                                require('../../assets/exercise/calibrationTestResultProgressTopZH-HK.png')
                        }
                        alt="introduction" />
                    <div className="calibrationTestResultGrade">
                        <p className="calibrationTestResultGradeText">
                            {overallAccuracy && overallAccuracy == 1 ? "Q"
                                : overallAccuracy >= 0.9 ? "A"
                                    : overallAccuracy >= 0.8 ? "B"
                                        : overallAccuracy >= 0.6 ? "C"
                                            : "F"
                            }
                        </p>
                    </div>
                    <div className="progressQuestionContainer2">
                        {progressQuestionNumArray && progressQuestionNumArray.map((data, i) => (
                            <span key={i}>
                                <div className="progressQuestionNumContainer">
                                    {i === 0 ?
                                        <div className="progressLineLeftEmpty"></div>
                                        : i % 5 === 0 ?
                                            <div className="progressLineLeftLong"></div>
                                            :
                                            <div className="progressLineLeft"></div>
                                    }
                                    <div
                                        key={i}
                                        onClick={() => { toQuestion(i) }}
                                        className="progressQuestionNum"
                                        style={{
                                            backgroundColor:
                                                (remark && remark[i].dontKnow) ? "#E5E5E5"
                                                    : (userAnswer && userAnswer[i] == questionAnswer[i]) ? '#C3FFB2'
                                                        : (userAnswer && userAnswer[i] !== questionAnswer[i]) && '#F8A5A5'
                                        }}
                                    >
                                        <div className="progressBookmarkContainer">
                                            {remark && remark[i].bookmark ?
                                                <img className="progressBookmark" src={progressBookmarkSVG} alt="clock" />
                                                :
                                                <img className="progressBookmark" src={progressBookmarkTransparentSVG} alt="clock" />
                                            }
                                        </div>
                                        <div className="progressQuestionNumText">{data + 1}</div>
                                    </div>

                                    {i === progressQuestionNumArray.length - 1 && i !== 4 ?
                                        <div className="progressLineRightEmpty"></div>
                                        : i % 5 === 4 ?
                                            <div className="progressLineRightLong"></div>
                                            :
                                            <div className="progressLineRight"></div>
                                    }

                                </div>
                            </span>
                        ))}
                    </div>
                    {topicCorrectTotal && topicTotal &&
                        <div className="calibartionTestResultByTopic">
                            <Grid container spacing={0}>



                                {pp3 && pp3.map((i, index) => {
                                    return <>

                                        <Grid item xs={10}>
                                            <p className="calibrationTestResultText">{i[0]}</p>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <p className="calibrationTestResultText">({i[2]}/{i[3]})</p>
                                        </Grid>
                                       </>

                                })
                                }


                                {/*<Grid item xs={10}>*/}
                                {/*    <p className="calibrationTestResultText">Past Tense</p>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={2}>*/}
                                {/*    <p className="calibrationTestResultText">({topicCorrectTotal.pastTense}/{topicTotal.pastTense})</p>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12}>*/}
                                {/*    <div className="calibrationTestResultDottedLine"></div>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={10}>*/}
                                {/*    <p className="calibrationTestResultText">Present Continuous Tense</p>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={2}>*/}
                                {/*    <p className="calibrationTestResultText">({topicCorrectTotal.presentContinuousTense}/{topicTotal.presentContinuousTense})</p>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12}>*/}
                                {/*    <div className="calibrationTestResultDottedLine"></div>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={10}>*/}
                                {/*    <p className="calibrationTestResultText">Prepositions</p>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={2}>*/}
                                {/*    <p className="calibrationTestResultText">({topicCorrectTotal.preposition}/{topicTotal.preposition})</p>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12}>*/}
                                {/*    <div className="calibrationTestResultDottedLine"></div>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={10}>*/}
                                {/*    <p className="calibrationTestResultText">Question Words</p>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={2}>*/}
                                {/*    <p className="calibrationTestResultText">({topicCorrectTotal.questionWord}/{topicTotal.questionWord})</p>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </div>
                    }
                    {/* </div> */}
                </div>
            </div>
        </>
    );
}

export default Progress;

