import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';

import finishWellDoneSVG from '../../assets/dashboard/finishWellDone.svg';
import {useTranslation} from 'react-i18next'

import questionBankPastTenseNoWordSVG from '../../assets/exercise/questionBankPastTenseNoWord.svg';
import questionBankPastTensePresentTenseNoWordSVG
    from '../../assets/exercise/questionBankPastTensePresentTenseNoWord.svg';
import questionBankPresentContinuousTenseNoWordSVG
    from '../../assets/exercise/questionBankPresentContinuousTenseNoWord.svg';
import questionBankPresentTensePresentContinuousTenseNoWordSVG
    from '../../assets/exercise/questionBankPresentTensePresentContinuousTenseNoWord.svg';
import questionBankPrepositionNoWordSVG from '../../assets/exercise/questionBankPrepositionNoWord.svg';
import questionBankQuestionWordNoWordSVG from '../../assets/exercise/questionBankQuestionWordNoWord.svg';
import {useMediaQuery} from "react-responsive";
import Box from "@mui/material/Box";


import {lgg} from "../../firebase";
import ggPACT from "../../assets/exercise/questionBankPACT.svg";
import ggPPT from "../../assets/exercise/GGPPT.svg";
import ggBIxTI from "../../assets/exercise/ggBIxTI.svg";
import ggBIxGER from "../../assets/exercise/ggBIxGER.svg";
import ggART from "../../assets/exercise/ggART.svg";
import ggCOM from "../../assets/exercise/ggCOM.svg";
import ggCONJ from "../../assets/exercise/ggCONJ.svg";


export const mapping333= [
    ["PACT_1",ggPACT,"Past Continuous Tense"],
    ["PPT_1",ggPPT,"Past Perfect Tense"],
    ["BIxTI_1",ggBIxTI,"To + Bare"],
    ["BIxGER_1",ggBIxGER,"Bare + Gerund"],
    ["ART_1",ggART,"Articles"],
    ["COM_1",ggCOM,"Comparatives"],
    ["CONJ_1",ggCONJ,"Conjunctions"],


]
function TodaysPracticesSingle({exercise, exerciseID}) {

    let navigate = useNavigate();
    const [progress, setProgress] = useState();
    useEffect(() => {
        const answerProgress = exercise.progress !== undefined ? exercise.progress + 1 : 0
        setProgress(answerProgress / exercise.question.length);
    }, [])
    const {t} = useTranslation();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })

    let mapping34= mapping333;
    lgg(`exercise.type, ${exercise.type}`)

    let q37;
    let q38
    if (mapping34.findIndex(e => e[0] ==  exercise.type)!=-1) {
        // lgg(typeof(mapping34.findIndex(e => e[0] == exercise.type)))
        q37 = mapping34[mapping34.findIndex(e => e[0] == exercise.type)][1]
        q38=mapping34[mapping34.findIndex(e => e[0] == exercise.type)][2]
        lgg(`q37 ${q37} ${q38}`)
        // lgg(`q37 ${typeof(q37)}
        // // ${Object.keys(q37)}
        // `)
    }else{
        lgg(`should be p4 topic ${exercise.type}`)
    }

    let q098=      exercise &&
    exercise.type == "pastTense" ?
        questionBankPastTenseNoWordSVG
        :
        exercise.type == "pastTensePresentTense" ?
            questionBankPastTensePresentTenseNoWordSVG
            :
            exercise.type == "presentContinuousTense" ?
                questionBankPresentContinuousTenseNoWordSVG
                :
                exercise.type == "presentTensePresentContinuousTense" ?
                    questionBankPresentTensePresentContinuousTenseNoWordSVG
                    :
                    exercise.type == "preposition" ?
                        questionBankPrepositionNoWordSVG
                        :
                        exercise.type == "questionWord" ?
                            questionBankQuestionWordNoWordSVG
                            :q37
    let q099=exercise &&
    exercise.type == "pastTense" ? "Past Tense" :
        exercise.type == "pastTensePresentTense" ? "Past Tense + Present Tense" :
            exercise.type == "presentContinuousTense" ? "Present Continuous Tense" :
                exercise.type == "presentTensePresentContinuousTense" ? "Present Tense + Present Continuous Tense" :
                    exercise.type == "preposition" ? "Prepositions" :
                        exercise.type == "questionWord" ? "Question Words":
                            q38

    return (
        <>
            <Grid item xs={12}>
                <div className="practiceContainer"
                     style={{backgroundColor: progress && progress === 1 ? "#F2F2F2" : "white"}}>
                    <Grid container spacing={2} alignItems="center">


                        {is_m ? <>

                                <Grid item xs={3}>
                                    {/* <img className="practiceCover" src={require(`../../assets/dashboard/${data.cover}`)} alt="practice cover"/> */}
                                    <img className="practiceCover"
                                         src={


                                       q098


                                         }
                                         alt="practice cover"/>
                                </Grid>
                                <Grid container item xs={9} onClick={() => {
                                    progress !== 1 && navigate("/practice", {
                                        state: {
                                            exercise: exercise,
                                            exerciseID: exerciseID
                                        }
                                    });
                                }}>
                                    <Grid item xs={12}>
                                        <p className="practiceTitle">
                                            {
                                                q099
                                            }
                                        </p>
                                    </Grid>


                                    <Grid item container xs={10}>

                                        <Grid item xs={8} sx={{

                                            marginTop: "8px"
                                        }}>
                                            <div className="practiceProgressContainer">


                                            </div>
                                            <div className="practiceProgress"
                                                 style={{
                                                     backgroundColor: progress === 0 ? '#f2f2f2' : progress === 1 ? '#50CC17' : '#FE9E0F',
                                                     width: "max( 2.2vw, calc( ( 100% - 0.4vw ) * " + progress + " ) )"
                                                 }}>


                                            </div>

                                            {/*100%*/}
                                        </Grid>
                                        <Grid item xs={2} sx={{


                                        }}>

                                        </Grid>
                                        <Grid item xs={2}>

                                            {/*100%*/}
                                            <div
                                                // style={{display:"inline-block"}}
                                                className="practiceProgressText">{progress && progress * 100}%
                                            </div>
                                        </Grid>

                                    </Grid>


                                    {/*THIS PERCENTAGE IS HARD TO ALIGN :(*/}
                                    {/*<Grid item xs={6}>*/}

                                    {/*    <p className="practiceProgressText">{progress && progress * 100}%*/}
                                    {/*        <span className="practiceProgressFinishedText">{progress === 1 && " Finished"}</span>*/}
                                    {/*    </p>*/}
                                    {/*</Grid>*/}


                                    {/*<Grid align="center" item xs={2}>*/}
                                    {/*    {progress && progress === 1 ?*/}
                                    {/*        <img className="practiceProgressFinishWellDoneSVG" src={finishWellDoneSVG}*/}
                                    {/*             alt="well done"/>*/}
                                    {/*        :*/}
                                    {/*        <div onClick={() => {*/}
                                    {/*            progress !== 1 && navigate("/practice", {*/}
                                    {/*                state: {*/}
                                    {/*                    exercise: exercise,*/}
                                    {/*                    exerciseID: exerciseID*/}
                                    {/*                }*/}
                                    {/*            });*/}
                                    {/*        }} className="practiceStatus"*/}
                                    {/*             style={{border: progress === 1 ? "2px solid #50CC17" : progress === 0 ? "0.1vw solid #54A2F0" : "0.1vw solid #EAD046"}}>*/}
                                    {/*            <p className="practiceStatusText">*/}
                                    {/*                {*/}
                                    {/*                    progress > 0 ? t('RESUME') : t('START')*/}
                                    {/*                }*/}
                                    {/*            </p>*/}

                                    {/*        </div>*/}
                                    {/*    }*/}
                                    {/*</Grid>*/}
                                </Grid>

                            </> :


                            <>
                                <Grid item xs={1}>
                                    {/* <img className="practiceCover" src={require(`../../assets/dashboard/${data.cover}`)} alt="practice cover"/> */}
                                    <img className="practiceCover"
                                         src={
                                             q098
                                         }
                                         alt="practice cover"/>
                                </Grid>
                                <Grid item xs={5}>
                                    <p className="practiceTitle">
                                        {q099
                                        }
                                    </p>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="practiceProgressText">{progress && progress * 100}% <span
                                        className="practiceProgressFinishedText">{progress === 1 && " Finished"}</span>
                                    </p>
                                    <div className="practiceProgressContainer"></div>
                                    <div className="practiceProgress" style={{
                                        backgroundColor: progress === 0 ? '#f2f2f2' : progress === 1 ? '#50CC17' : '#FE9E0F',
                                        width: "max( 2.2vw, calc( ( 100% - 0.4vw ) * " + progress + " ) )"
                                    }}></div>
                                </Grid>
                                <Grid align="center" item xs={2}>
                                    {progress && progress === 1 ?
                                        <img className="practiceProgressFinishWellDoneSVG" src={finishWellDoneSVG}
                                             alt="well done"/>
                                        :
                                        <div onClick={() => {
                                            progress !== 1 && navigate("/practice", {
                                                state: {
                                                    exercise: exercise,
                                                    exerciseID: exerciseID
                                                }
                                            });
                                        }} className="practiceStatus"
                                             style={{border: progress === 1 ? "2px solid #50CC17" : progress === 0 ? "0.1vw solid #54A2F0" : "0.1vw solid #EAD046"}}>
                                            <p className="practiceStatusText">
                                                {
                                                    progress > 0 ? t('RESUME') : t('START')
                                                }
                                            </p>

                                        </div>
                                    }
                                </Grid>
                            </>}

                    </Grid>
                </div>
            </Grid>
        </>
    );
}

export default TodaysPracticesSingle;
