import React, {useEffect, useRef, useState} from 'react';
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';
import '../../translation'

import useWindowDimensions, {db, lgg} from "../../firebase";
import {doc, getDoc} from 'firebase/firestore';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import QuestionOfTheDay from '../../pages/dashboard/QuestionOfTheDay';
import TodaysVideo from '../../pages/dashboard/TodaysVideo';
import TodaysPractices from '../../pages/dashboard/TodaysPractices';
import Search from '../../pages/dashboard/Search';
import {useNavigate} from 'react-router-dom';
import {getUser} from '../../localStorage/User';
import {redirect} from "../../functions/redirect"
import {getExercise} from "../../functions/getExercise"
import {useTranslation} from 'react-i18next'


import Carousel from 'react-material-ui-carousel'
import {useMediaQuery} from "react-responsive";
import {Dialog, DialogTitle, Paper} from "@mui/material";
import questionBankPastTenseNoWordSVG from "../../assets/exercise/questionBankPastTenseNoWord.svg";
import questionBankPastTensePresentTenseNoWordSVG
    from "../../assets/exercise/questionBankPastTensePresentTenseNoWord.svg";
import questionBankPresentContinuousTenseNoWordSVG
    from "../../assets/exercise/questionBankPresentContinuousTenseNoWord.svg";
import questionBankPresentTensePresentContinuousTenseNoWordSVG
    from "../../assets/exercise/questionBankPresentTensePresentContinuousTenseNoWord.svg";
import questionBankPrepositionNoWordSVG from "../../assets/exercise/questionBankPrepositionNoWord.svg";
import questionBankQuestionWordNoWordSVG from "../../assets/exercise/questionBankQuestionWordNoWord.svg";
import {mapping333} from "./TodaysPracticesSingle";


function SimpleDialog(props) {
    const {onClose, selectedValue, open} = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Loading</DialogTitle>
            {/*<List sx={{ pt: 0 }}>*/}
            {/*    {emails.map((email) => (*/}
            {/*        <ListItem button onClick={() => handleListItemClick(email)} key={email}>*/}
            {/*            <ListItemAvatar>*/}
            {/*                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>*/}
            {/*                    <PersonIcon />*/}
            {/*                </Avatar>*/}
            {/*            </ListItemAvatar>*/}
            {/*            <ListItemText primary={email} />*/}
            {/*        </ListItem>*/}
            {/*    ))}*/}
            {/*    <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>*/}
            {/*        <ListItemAvatar>*/}
            {/*            <Avatar>*/}
            {/*                <AddIcon />*/}
            {/*            </Avatar>*/}
            {/*        </ListItemAvatar>*/}
            {/*        <ListItemText primary="Add account" />*/}
            {/*    </ListItem>*/}
            {/*</List>*/}
        </Dialog>
    );
}

function Dashboard() {
    const [height, setHeight] = useState(0)
    const ref = useRef(null)
    const user = getUser();
    console.log("quesr", user.id)
    console.log("height", height)
    let navigate = useNavigate();
    const {t} = useTranslation();
    useEffect(() => {
        if (ref.current){
            setHeight(ref.current.clientHeight)
        }
        // setHeight(ref.current.clientHeight)
    })
    useEffect(() => {
        redirect(user, navigate);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getUserInfo();  // which set the UserInfo which    trigger the  getLoginStatus
    }, [])

    // to get user information
    const [userInfo, setUserInfo] = useState();
    const getUserInfo = async () => {
        if (user.id !== "" && user.id !== null && user.id !== undefined) {
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            setUserInfo(userSnapshot.data());
        }
    }

    // to get today's exercise or generate today's exercise if not created
    useEffect(() => {
        getExercise(userInfo, user.id, setExercise, setExerciseID, setQuestionOfTheDay, setQuestionOfTheDayID);
    }, [userInfo])
    const [exercise, setExercise] = useState();
    const [exerciseID, setExerciseID] = useState();
    const [questionOfTheDay, setQuestionOfTheDay] = useState();
    const [questionOfTheDayID, setQuestionOfTheDayID] = useState();

    // to get the bubble to be displayed
    useEffect(() => {
        getLoginStatus();
    }, [userInfo])


    // solely for determine the banner
    const [loginStatus, setLoginStatus] = useState();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const randomIndex = Math.floor(Math.random() * 2) + 1;


    const SettingsT = {
        autoPlay: false,
        animation: "slide",
        indicators: true,
        duration: 500,
        navButtonsAlwaysVisible: false,
        navButtonsAlwaysInvisible: true,
        cycleNavigation: true,
        fullHeightHover: true,
        swipe: true
    }

    const getLoginStatus = () => {
        if (userInfo) {
            let compareDate = new Date();
            compareDate.setHours(0);
            compareDate.setMinutes(0);
            compareDate.setSeconds(0);
            if (userInfo.loginDate) {
                if (Math.round((compareDate - userInfo.loginDate[0].toDate()) / (1000 * 60 * 60 * 24)) === 0) {
                    setLoginStatus("firstLoginIn");
                    return;
                }
            }
            if (userInfo.loginDate.length >= 2) {
                if (Math.round((compareDate - userInfo.loginDate[userInfo.loginDate.length - 2].toDate()) / (1000 * 60 * 60 * 24)) >= 4) {
                    setLoginStatus("returnBack");
                    return;
                }
            }
            setLoginStatus("default");
        }
    }

    let mapping34 = mapping333;
    // lgg(`exercise.type, ${exercise.type}`)

    let q88,q89;
    if (userInfo){
        // let q37;
        let q38
        if (mapping34.findIndex(e => e[0] == userInfo.activeTenseTopic) != -1) {
            // lgg(typeof(mapping34.findIndex(e => e[0] == exercise.type)))
            // q37 = mapping34[mapping34.findIndex(e => e[0] == exercise.type)][1]
            q38 = mapping34[mapping34.findIndex(e => e[0] == userInfo.activeTenseTopic)][2]
            // lgg(`q37 ${q37} ${q38}`)
            // lgg(`q37 ${typeof(q37)}
            // // ${Object.keys(q37)}
            // `)
        } else {
            // lgg(`should be p4 topic ${exercise.type}`)
        }

        let q388;
        if (mapping34.findIndex(e => e[0] == userInfo.activeNonTenseTopic) != -1) {
            // lgg(typeof(mapping34.findIndex(e => e[0] == exercise.type)))
            // q37 = mapping34[mapping34.findIndex(e => e[0] == exercise.type)][1]
            q388 = mapping34[mapping34.findIndex(e => e[0] == userInfo.activeNonTenseTopic)][2]
            // lgg(`q37 ${q37} ${q38}`)
            // lgg(`q37 ${typeof(q37)}
            // // ${Object.keys(q37)}
            // `)
        } else {
            // lgg(`should be p4 topic ${exercise.type}`)
        }


        // let q099=exercise &&
        // exercise.type == "pastTense" ? "Past Tense" :
        //     exercise.type == "pastTensePresentTense" ? "Past Tense + Present Tense" :
        //         exercise.type == "presentContinuousTense" ? "Present Continuous Tense" :
        //             exercise.type == "presentTensePresentContinuousTense" ? "Present Tense + Present Continuous Tense" :
        //                 exercise.type == "preposition" ? "Prepositions" :
        //                     exercise.type == "questionWord" ? "Question Words":
        //                         q38

        q88 = userInfo.activeTenseTopic == "pastTense" ? " Past Tense " :
            userInfo.activeTenseTopic == "presentContinuousTense" ? " Present Continuous Tense " :
                userInfo.activeTenseTopic == "pastTensePresentTense" ? " Past Tense & Present Tense " :
                    userInfo.activeTenseTopic == "presentTensePresentContinuousTense" ? " Present Tense & Present Continuous Tense " : q38

        q89 = userInfo.activeNonTenseTopic == "preposition" ? " Prepositions " :
            userInfo.activeNonTenseTopic == "questionWord" ? " Question Words " : q388


    }



    return (
        <>
            <SimpleDialog
                // selectedValue={selectedValue}
                open={!exerciseID}
                // onClose={handleClose}
            />
            <Menu/>
            <SideMenu/>
            <div className="responsiveContainer">
                <Grid container spacing={2}>

                    {/*the banner at the top*/}
                    <Grid item xs={12}>
                        <div
                            className="dashboardBanner"
                        >


                            <div className="dashboardMargin"></div>


                            <p className="dashboardBannerHelloText">
                                {loginStatus && userInfo &&
                                loginStatus === "firstLoginIn" ?
                                    userInfo && t('HELLO1') + " " + userInfo.nickname + t('WELCOME_BACK1')
                                    : loginStatus === "returnBack" && randomIndex === 1 ?
                                        userInfo && t('HELLO2') + " " + userInfo.nickname + t('WELCOME_BACK2')
                                        : loginStatus === "returnBack" && randomIndex === 2 ?
                                            userInfo && t('HELLO3') + " " + userInfo.nickname + t('WELCOME_BACK3')
                                            : randomIndex === 1 ?
                                                userInfo && t('HELLO4') + " " + userInfo.nickname + t('WELCOME_BACK4')
                                                :
                                                userInfo && t('HELLO5') + " " + userInfo.nickname + t('WELCOME_BACK5')
                                }
                            </p>
                            <p className="dashboardBannerThemeText">{t('TODAYS_THEME')}
                                {userInfo &&
                                    <>
                                        <span className="dashboardBannerTopicText">
                                            {
                                                q88
                                            }
                                        </span> {t('AND')}
                                        <span className="dashboardBannerTopicText">
                                            {
                                                q89
                                            }
                                        </span>
                                    </>
                                }
                                !
                            </p>
                            <div className="dashboardMargin"></div>
                        </div>
                    </Grid>


                    {/*search bar*/}
                    <Search/>

                    {is_m ?
                        <Carousel
                            className="sssssssssssssss"
                            // style={{marginTop:'100px'}}

                            {...SettingsT}

                        >
                            {/*Question of the Day*/}


                            {/*<Paper key={`test3-item`} elevation={10} style={{ height: 100 }} className="HeightItem">*/}
                            {/*    <h1> This item is <span style={{ color: 'cyan' }}>{100}px</span> high!</h1>*/}
                            {/*</Paper>*/}

                            {/*<Paper key={`test3-item`} elevation={10} style={{ height: 100 }} className="HeightItem">*/}
                            {/*    <h1> This item is <span style={{ color: 'cyan' }}>{100}px</span> high!</h1>*/}
                            {/*</Paper>*/}
                            <Grid item xs={12} sm={6} md={6}>
                                {questionOfTheDay && <QuestionOfTheDay questionOfTheDayID={questionOfTheDayID}
                                                                       questionOfTheDay={questionOfTheDay}/>}
                            </Grid>


                            {/*Today's Video*/}
                            <Grid item xs={12} sm={6} md={6}>
                                <TodaysVideo/>
                            </Grid>
                        </Carousel> :
                        <
                            Grid container
                              spacing={2}
                              item  // need for some empty space
                              alignItems="stretch"
                        >

                            <Grid item xs={12} sm={6} md={6}
                                  // style={{height:'1%'}}
                                  ref={ref}
                            >
                                {questionOfTheDay && <QuestionOfTheDay questionOfTheDayID={questionOfTheDayID}
                                                                       questionOfTheDay={questionOfTheDay}/>}
                            </Grid>


                            {/*Today's Video*/}
                            <Grid item xs={12} sm={6} md={6}
                                  // style={{height:'90%'}}
                            >

                                <img className="questionOfTheDayFrame0" src={require('../../assets/Group 1130.png')}

                                     onClick={() => {
                                         //navigate to route read
                                         navigate('/read')
                                     }
                                }
                                     alt="frame"/>
                                {/*<TodaysVideo/>*/}
                            </Grid>


                        </Grid>
                    }


                    {/*exercise area*/}
                    <Grid item xs={12}>
                        {exercise && exerciseID && exercise.length > 0 && exerciseID.length > 0 &&
                            <TodaysPractices exercise={exercise} exerciseID={exerciseID}/>}
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default Dashboard;
