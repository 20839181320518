import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';
import ImprovementTopicBox from './ImprovementTopicBox';
import TopicBox from './TopicBox';
import { useTranslation } from 'react-i18next';

function Improvements({ topciAccuracy, toBeImprovedLevel1, toBeImprovedLevel2 }) {

  const [nullFlag, setNullFlag] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if(topciAccuracy !== undefined){
      for (let i = 0; i < topciAccuracy.length; i++) {
        if(
            (toBeImprovedLevel1[i].accuracy >= 0 && Math.round(toBeImprovedLevel1[i].accuracy * 100 < 80 )) ||
            ( toBeImprovedLevel2[i].accuracy >= 0 && Math.round(toBeImprovedLevel2[i].accuracy * 100  < 60))
        ){
          setNullFlag(false);  // this DEFAULT TO true, which show no topic to improve,       unless if condition above meets
        }
      }
    }
  }, [topciAccuracy])

  const noTopicText = t('NO_IMPROVED');

  return <>
    <div className="resultTitleContainer" style={{ backgroundColor: '#FF9D79' }}>
      <p className="resultTitle">{t('TOPIC_IMPROVED')}</p>
    </div>
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={1} align="center"></Grid>
      <Grid item xs={4} align="center"><p className="resultLabel">{t('TOPIC')}</p></Grid>
      <Grid item xs={3} align="center"><p className="resultLabel">{t('OVERALL_GRADE')}</p></Grid>
      <Grid item xs={2} align="center" sx={{ ml: -1 }}><p className="resultLabel">{t('ACCURACY')}</p></Grid>
      <Grid item xs={2} align="center"></Grid>
      {(topciAccuracy && topciAccuracy.length) && topciAccuracy.map((data, i) => (
        <ImprovementTopicBox key={i} data={data} toBeImprovedLevel1={toBeImprovedLevel1[i]} toBeImprovedLevel2={toBeImprovedLevel2[i]} />
      ))}
      {nullFlag && <TopicBox className="improvementTopicBoxContainer" text={noTopicText} />}
    </Grid>
  </>;
}

export default Improvements;
