import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {useLocation, useNavigate} from "react-router-dom";

import '../../App.css';
import './_styles.css';

import Menu from '../../components/menu/Menu';
import {NextButton} from './_components';

function CalibrationTestResult() {

    let navigate = useNavigate();
    const location = useLocation();
    const [topicTotal, setTopicTotal] = useState();
    const [topicCorrectTotal, setTopicCorrectTotal] = useState();
    const [mode3, setMode3] = useState();
    useEffect(() => {
        location.state && setTopicTotal(location.state.topicTotal);
        location.state && setTopicCorrectTotal(location.state.topicCorrectTotal);
        location.state && setMode3(location.state.dict32);
        console.log("mode3", mode3);
    })

    const trans3 = {
        "CONJ_1": "Conjunctions",
        "ART_1": "Article",
        "BIxGER_1": "Bare + Gerund",
        "BIxTI_1": "To + Bare",
        "COM_1": "Comparatives",
        "PACT_1": "Past Continuous Tense",
        "PPT_1": "Past Perfect Tense",
        "pastTense": "Past Tense",
        "preposition": "Preposition",
        "presentContinuousTense": "Present Continuous Tense",

        "questionWord": "Question Word",
    }


    let pp3;
    if (mode3) {


        // percentage check tense

        pp3 = Object.keys(mode3).map((i, index) => {

            let per = mode3[i].correct / mode3[i].total;
            let g1;
            g1 = (per) == 1 ?
                "Q"
                :
                (per) >= 0.9 ?
                    "A"
                    :
                    (per) >= 0.8 ?
                        "B"
                        :
                        (per) >= 0.6 ?
                            "C"
                            :
                            "F";
            return [trans3[i], g1, mode3[i].correct, mode3[i].total]
        })
        // console.log('pp3', pp3)
    }

    return <>
        <Menu/>
        <div className="exerciseBackgroundImage2">
            <div className="calibrationTestResultContainer">
                {topicCorrectTotal && topicTotal &&
                    <div className="calibrationTestResultContainerInner">
                        <div className="calibrationTestResultTitleContainer">
                            <p className="calibrationTestResultTitle">Test Results</p>
                        </div>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={1} align="center"></Grid>
                            <Grid item xs={5} align="center"><p className="bestPerformanceLabel">Topic</p></Grid>
                            <Grid item xs={2} align="center"><p className="bestPerformanceLabel">Grade</p></Grid>
                            <Grid item xs={4} align="center"><p className="bestPerformanceLabel">Score</p></Grid>

                            {pp3 && pp3.map((i, index) => {
                                return <>
                                    <div className="calibrationTestResultSingleMargin"/>

                                    <Grid item xs={12}>
                                        <div className="calibrationTestResultSingleContainer">
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={1}>
                                                    <img className="practiceCover"
                                                         src={require(`../../assets/exercise/ererciseImageExample.png`)}
                                                         alt="practice cover"/>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <p className="practiceTitle">{i[0]}</p>
                                                </Grid>
                                                <Grid item xs={2} align="center">
                                                    <p className="practiceTitle">
                                                        {i[1]
                                                        }
                                                    </p>
                                                </Grid>
                                                <Grid item xs={4} align="center">
                                                    <p className="calibrationTestResultScore">{i[2]} / {i[3]}</p>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid></>

                            })
                            }

                            <div className="calibrationTestResultSingleMargin"/>

                            {/*<Grid item xs={12}>*/}
                            {/*    <div className="calibrationTestResultSingleContainer">*/}
                            {/*        <Grid container spacing={2} alignItems="center">*/}
                            {/*            <Grid item xs={1}>*/}
                            {/*                <img className="practiceCover"*/}
                            {/*                     src={require(`../../assets/exercise/ererciseImageExample.png`)}*/}
                            {/*                     alt="practice cover"/>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={5}>*/}
                            {/*                <p className="practiceTitle">Present Continuous Tense</p>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={2} align="center">*/}
                            {/*                <p className="practiceTitle">*/}
                            {/*                    {topicCorrectTotal.presentContinuousTense / topicTotal.presentContinuousTense == 1 ? "Q"*/}
                            {/*                        : topicCorrectTotal.presentContinuousTense / topicTotal.presentContinuousTense >= 0.9 ? "A"*/}
                            {/*                            : topicCorrectTotal.presentContinuousTense / topicTotal.presentContinuousTense >= 0.8 ? "B"*/}
                            {/*                                : topicCorrectTotal.presentContinuousTense / topicTotal.presentContinuousTense >= 0.6 ? "C"*/}
                            {/*                                    : "F"*/}
                            {/*                    }*/}
                            {/*                </p>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={4} align="center">*/}
                            {/*                <p className="calibrationTestResultScore">{topicCorrectTotal.presentContinuousTense} / {topicTotal.presentContinuousTense}</p>*/}
                            {/*            </Grid>*/}
                            {/*        </Grid>*/}
                            {/*    </div>*/}
                            {/*</Grid>*/}

                            {/*<div className="calibrationTestResultSingleMargin"/>*/}

                            {/*<Grid item xs={12}>*/}
                            {/*    <div className="calibrationTestResultSingleContainer">*/}
                            {/*        <Grid container spacing={2} alignItems="center">*/}
                            {/*            <Grid item xs={1}>*/}
                            {/*                <img className="practiceCover"*/}
                            {/*                     src={require(`../../assets/exercise/ererciseImageExample.png`)}*/}
                            {/*                     alt="practice cover"/>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={5}>*/}
                            {/*                <p className="practiceTitle">Prepositions</p>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={2} align="center">*/}
                            {/*                <p className="practiceTitle">*/}
                            {/*                    {topicCorrectTotal.preposition / topicTotal.preposition == 1 ? "Q"*/}
                            {/*                        : topicCorrectTotal.preposition / topicTotal.preposition >= 0.9 ? "A"*/}
                            {/*                            : topicCorrectTotal.preposition / topicTotal.preposition >= 0.8 ? "B"*/}
                            {/*                                : topicCorrectTotal.preposition / topicTotal.preposition >= 0.6 ? "C"*/}
                            {/*                                    : "F"*/}
                            {/*                    }*/}
                            {/*                </p>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={4} align="center">*/}
                            {/*                <p className="calibrationTestResultScore">{topicCorrectTotal.preposition} / {topicTotal.preposition}</p>*/}
                            {/*            </Grid>*/}
                            {/*        </Grid>*/}
                            {/*    </div>*/}
                            {/*</Grid>*/}

                            {/*<div className="calibrationTestResultSingleMargin"/>*/}

                            {/*<Grid item xs={12}>*/}
                            {/*    <div className="calibrationTestResultSingleContainer">*/}
                            {/*        <Grid container spacing={2} alignItems="center">*/}
                            {/*            <Grid item xs={1}>*/}
                            {/*                <img className="practiceCover"*/}
                            {/*                     src={require(`../../assets/exercise/ererciseImageExample.png`)}*/}
                            {/*                     alt="practice cover"/>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={5}>*/}
                            {/*                <p className="practiceTitle">Question Words</p>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={2} align="center">*/}
                            {/*                <p className="practiceTitle">*/}
                            {/*                    {topicCorrectTotal.questionWord / topicTotal.questionWord == 1 ? "Q"*/}
                            {/*                        : topicCorrectTotal.questionWord / topicTotal.questionWord >= 0.9 ? "A"*/}
                            {/*                            : topicCorrectTotal.questionWord / topicTotal.questionWord >= 0.8 ? "B"*/}
                            {/*                                : topicCorrectTotal.questionWord / topicTotal.questionWord >= 0.6 ? "C"*/}
                            {/*                                    : "F"*/}
                            {/*                    }*/}
                            {/*                </p>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={4} align="center">*/}
                            {/*                <p className="calibrationTestResultScore">{topicCorrectTotal.questionWord} / {topicTotal.questionWord}</p>*/}
                            {/*            </Grid>*/}
                            {/*        </Grid>*/}
                            {/*    </div>*/}
                            {/*</Grid>*/}

                            {/*<div className="calibrationTestResultSingleMargin"/>*/}

                        </Grid>
                    </div>
                }
            </div>
            <div className="calibrationTestResultNextBtn" onClick={() => {
                navigate('/calibrationTestVideo')
            }}>
                <NextButton/>
            </div>
        </div>
    </>;
}

export default CalibrationTestResult;
