import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {db} from "../../firebase";
import {addDoc, collection, doc, getDocs, query, updateDoc, where, orderBy, getDoc} from 'firebase/firestore';
import {useStopwatch} from 'react-timer-hook';

import '../../App.css';
import './_styles.css';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import Progress from '../../pages/exercise/Progress';
import MultipleChoice from '../../pages/exercise/MultipleChoice';
import FillInTheBlank from '../../pages/exercise/FillInTheBlank';
import FillInMultipleBlank from '../../pages/exercise/FillInMultipleBlank';

import arrowLeftSVG from '../../assets/exercise/arrowLeft.svg';
import arrowRightSVG from '../../assets/exercise/arrowRight.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import {getUser} from '../../localStorage/User';

import Pause from '../../pages/exercise/Pause';
import FinishExercise from '../../pages/exercise/FinishExercise';
import VideoSuggestion from '../../pages/video/VideoSuggestion';
import {useMediaQuery} from "react-responsive";
import {NUMB} from '../../firebase';
import {BackButton2333, BoothButton333331, BoothButton333333, NextButton2333} from "../login/Logout";
import MultipleChoice2 from "./MultipleChoice2";
import i2 from "../../assets/asteroid 1.png";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

export let QP = [

    ["What is an asteroid? ", "A flying rock in space",
        "A type of alien", "A kind of food for astronauts", "A piece of space garbage  "
        ,[24,24]
    ],
    ["What about the space suit is not correct?", "It is easy to move in the space suit.",
        "It can keep you warm.",
        "It can protect you from danger.",
        "It is white.  ",
        [
            36,64
        ]
    ],
    [" What does paragraph 2 tell us? ", "What you will do with your teammates on mars.  ",
        "You and your team don’t like the project.  ",
        "You may see aliens on mars.",
        "What food you are eating on mars.  ",
        [
            126,136
            ]
    ],
    ["How many teammates do you have?", "11  ",
        "4  ",
        "8  ",
        "12  ",
        [
            92,92
        ]
    ],
    ["Sam is feeling ___. ", "Excited  ",
        "Scared  ",
        "Angry  ",
        "Sad  ",
        [
            146,172
        ]
    ],
    ["What is Steven’s job?", "Doctor ",
        "Chef  ",
        "Teacher  ",
        "Engineer  ",
        [
            210,230
        ]
    ],
    ["What is Bob building?", "A power plant   ",
        "A farm  ",
        "Houses",
        "A spaceship",
        [
            248,266
        ]
    ],
]
const HtmlTooltip =
    styled(
        (
            {className, ...props}
        ) => (

            <Tooltip {...props} classes={{popper: className}}/>

        )
    )(
        ({theme}) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: '#FFDB8E',
                    color: 'rgba(0, 0, 0, 0.87)',
                    // maxWidth: 220,
                    minWidth: 600,
                    padding: 80,
                    // fontSize: theme.typography.pxToRem(12),
                    border: '1px solid #dadde9',
                },

            }
        )
    );
let GG = "You put on a heavy, white spacesuit. It will protect you from asteroids and the cold outer space. You cannot walk smoothly wearing the spacesuit so you can only move slowly in space.  \n" +
    "~" +
    "\n" +
    "  \n" +
    "\n" +
    "Your team are a part of a project. The team has a dozen experts who will go to Mars to explore it, build power plants, houses and grow food. You are starting a Mars colony.  \n" +
    "~" +

    "\n" +
    "  \n" +
    "\n" +
    "Sam speaks happily, “I cannot wait to start a new life on Mars! It will be fun!”  \n" +
    "~" +
    "\n" +
    "Steven told Sam, “We should keep fit using the sports equipment on the ship and eat healthily. I will need to do a health check with you all before you set off to space.” Bob says, “I will build the solar panels and electricity generator there.”  \n" +
    "~" +
    "\n" +
    "  \n" +
    "\n" +
    "The countdown is starting. Are you ready?  "

let dd = {
    "asteroids": ["Asteroids (Noun)   小行星 ", "The asteroids are large rocks that go around the sun like planets.", require('../../assets/asteroid 1.png')],
    "spacesuit": ["Spacesuit (Noun)   太空服", "The astronaut has his spacesuit on to protect his body when he is outside the spacecraft.", require('../../assets/spacesuit 1.png')],
    "experts": ["Experts (Noun)   專家/ 行家 ", "My brother is an expert at cooking.", require('../../assets/experts 1.png')],
    "dozen": ["Dozen (Noun)   十二個/ 一打 ", "Could you get me half a dozen (i.e. six) eggs when you go to the supermarket?", require('../../assets/Dozen 1.png')],
    "colony": ["Colony (Noun)   殖民地", "Hong Kong was a colony of the British Empire in the past.", require('../../assets/Dozen 1.png')],
    "sports equipment": ["Sports equipment (Noun)   運動器材", "The coach bought some sports equipment for training the athletes", require('../../assets/sports equipment 1.png')],
    "solar panels": ["Solar panels (Noun)   太陽能板", "The solar panels that are installed on the roof of our school to capture energy from the sun.", require('../../assets/solar panels 1.png')],
    "electricity": ["Electricity (Noun)   電力", "We need electricity to power televisions, light bulbs, heaters, and computers.", require('../../assets/electricity 1.png')],
}

function Practice() {

    // const {
    //     seconds,
    //     minutes,
    //     hours,
    //     start,
    //     pause,
    // } = useStopwatch({autoStart: true});
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })


    //timer
    const [countUpTime, setCountUpTime] = useState(0);

    //
    const [saveDraftFlag, setSaveDraftFlag] = useState(false);

    // random
    const [correctRandomIndex, setCorrectRandomIndex] = useState(1);

    // random
    const [wrongRandomIndex, setWrongRandomIndex] = useState(1);

    useEffect(() => {
        setCorrectRandomIndex(Math.floor(Math.random() * 2) + 1);
        setWrongRandomIndex(Math.floor(Math.random() * 3) + 1);
    }, []);

    // useEffect(() => {
    //     // let initialTime = location.state.exercise ?
    //     //     location.state.exercise.countUpTime :
    //     //     0
    //     // setCountUpTime(initialTime + hours * 3600 + minutes * 60 + seconds);
    // }, [seconds])


    // pause
    const [pauseModalVisibility, setPauseModalVisibility] = useState(false);

    const user = getUser();
    let navigate = useNavigate();
    useEffect(() => {
        (user.id === "" || user.id === null || user.id === undefined) && navigate("/login"); // to redirect to home page if user has logged-in
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getExercise();
    }, [])
    const [highlight, setHighlight] = useState(0);
    const location = useLocation();

    // const exerciseID = location.state.exerciseID;
    // console.log("exerciseID: ", exerciseID);

    // dont load from database, but pass from other apge
    // const [exerciseDetail, setExerciseDetail] = useState();
    // console.log("exerciseDetail: ", exerciseDetail);

    const TT = (
        i1 = "Spacesuit (Noun)   太空衣 ",
        i2 = require('../../assets/asteroid 1.png'),
        i3 = "The asteroids are large rocks that go around the sun like planets.",
        i4 = "asteroid") => {
        return <HtmlTooltip

            arrow
            title={
                <div className="questionOfTheDayFrame12">

                    <div className="questionOfTheDayFrame10">{i1}</div>
                    <div className="menuHeight30"></div>
                    <Grid container spacing={2} justifyContent="center">

                        <Grid item xs={6}>
                            <img
                                // className="questionOfTheDayFrame-2"
                                src={i2}
                                alt="frame"/>

                        </Grid>
                        <Grid item xs={6}>
                            <div>


                                <div className="questionOfTheDayFrame11">{i3}

                                </div>
                                <div
                                    // className="questionOfTheDayFrame13"
                                >

                                    <img
                                        className="questionOfTheDayFrame14"
                                        // className="questionOfTheDayFrame-2"
                                        src={require('../../assets/Vector.png')}
                                        alt="frame"/>
                                    <img
                                        className="questionOfTheDayFrame14"

                                        // className="questionOfTheDayFrame-2"
                                        src={require('../../assets/Vector2.png')}
                                        alt="frame"/>
                                </div>
                            </div>
                        </Grid>
                    </Grid>


                    {/*<Typography color="inherit">Tooltip with HTML</Typography>*/}


                    {/*<em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}*/}
                    {/*{"It's very engaging. Right?"}*/}
                </div>
            }
        >
        <span
            className="questionOfTheDayFrame4"
        >{i4}</span>
        </HtmlTooltip>
    }
    const Highlighted = (
        {
            text = GG,

            // range to highlight


            from ,
            to ,

            dictionary = dd,

            highlightornot = true,
        }
    ) => {
        console.log("highlightornot", highlightornot)
        let words_check = Object.keys(dictionary)


        // divided text into array with the space
        const words = text.split(/(\s+)/);


        let buffer = []
        let QQQ;
        for (let i = 0; i < words.length; i++) {

            // if (words[i] == 'asteroids') {
            //
            //     console.log("11111111",i, words[i])
            // }
            console.log("11111111",i, words[i])
            // a possibly highlighted word that have tooltip


            //for j in words_check:
            let index1 = "";
            let index2 = "";
            for (let j = 0; j < words_check.length; j++) {


                // if words[i] is substring of words_check[j] using substring method
                if (words_check[j].split(" ").includes(words[i])
                    ||
                    (words[i]=="colony."&&words_check[j]=="colony")
                ) {


                    //if dictionary have the word
                    if (
                        1
                        // dictionary[words[i]] !== undefined
                    ) {

                        // console.log(words_check[j], words[i])
                        index1 = words_check[j];

                        if(words[i]=="colony."&&words_check[j]=="colony"){
                            index2 = "colony."
                        }else{
                            index2 = words[i];
                        }


                        break
                    }

                }

            }

            if (words[i] == '~') {
                buffer.push(<p></p>)

            }
            // a possibly highlighted space
            else if (words[i] === ' ') {

                // if inside hightlight range and highlightornot=1
                if (i >= from && i <= to && highlightornot === true) {
                    buffer.push(<span className="questionOfTheDayFrame66">{' '}</span>);
                } else {
                    buffer.push(<span>{' '}</span>);
                }


            }

            // if words[i] is one of the item in dictionary
            else if (index1) {


                // console.log("111111111111111111111111111")
                let px

                px = TT(
                    dictionary[index1][0],
                    dictionary[index1][2],
                    dictionary[index1][1],
                    index2
                )
                // if (index1=="colony"){
                //     px = TT(
                //         dictionary[index1][0],
                //         dictionary[index1][2],
                //         dictionary[index1][1],
                //         "colony."
                //     )
                // }else{
                //     px = TT(
                //         dictionary[index1][0],
                //         dictionary[index1][2],
                //         dictionary[index1][1],
                //         index2
                //     )
                // }

                if (i >= from && i <= to && highlightornot === true) {
                    QQQ = <span className="questionOfTheDayFrame66"> {px}</span>
                } else {
                    QQQ = px
                }


                buffer.push(QQQ);
            }






            // a possibly highlighted word that have no tooltip
            else if (words[i] !== ' ') {

                // if inside hightlight range and highlightornot=1
                if (i >= from && i <= to && highlightornot === true) {
                    buffer.push(<span className="questionOfTheDayFrame66">{words[i]}</span>);
                } else {
                    buffer.push(<span>{words[i]}</span>);
                }
            } else {
                console.log("else", words[i])
            }


        }


        return (
            <span className="questionOfTheDayFrame4">

                {
                    buffer
                }

                {/*{buffer}*/}

            </span>
        )
    }


    // seems no use
    const [questionAnswer, setQuestionAnswer] = useState();


    // seems no use
    const [questionTopic, setQuestionTopic] = useState();

    // for record answer, array?
    const [userAnswer, setUserAnswer] = useState();

    //
    const [remark, setRemark] = useState();

    //
    const [progress, setProgress] = useState(-1); /// the question index that user confirmed answer

    //
    const [endFlag, setEndFlag] = useState(false);

    // to determine if question has been confirmed by user
    const [answeredFlagArray, setAnsweredFlagArray] = useState();

    //
    const [correctAnsweredFlagArray, setCorrectAnsweredFlagArray] = useState();
    // console.log("flag arr", correctAnsweredFlagArray)


    useEffect(() => {
        getUserInfo();
    }, [])

    // to get user information
    const [userInfo, setUserInfo] = useState();
    const getUserInfo = async () => {
        if (user.id !== "" && user.id !== null && user.id !== undefined) { // if localstorage have user id
            const userSnapshot = await getDoc(doc(db, 'User', user.id)); // get user info from firestore
            setUserInfo(userSnapshot.data()); // set user info to state
        }
    }

    // to add question id into user's answered question
    const updateSubmitedQuestion = async (questionID) => {
        // console.log("questionID", questionID)
        // console.log()

        // let userInfoLocal;
        // if (user.id !== "" && user.id !== null && user.id !== undefined) { // if localstorage have user id
        //     const userSnapshot = await getDoc(doc(db, 'User', user.id)); // get user info from firestore
        //     userInfoLocal = userSnapshot.data(); // set user info to state
        // }
        // if (userInfoLocal.submitedQuestion === undefined ||
        //     userInfoLocal.submitedQuestion.length === 0) { // if user has no submited question
        //     const userRef = doc(db, 'User', user.id); // get user reference
        //     updateDoc(userRef, {submitedQuestion: [questionID]}); // add question id into user's submited question
        // } else { // if user has submited question before
        //     const userRef = doc(db, 'User', user.id);
        //     const tmp = userInfoLocal.submitedQuestion;
        //     if (!tmp.includes(questionID)) {
        //         updateDoc(userRef, {submitedQuestion: tmp.concat([questionID])});
        //     }
        // }
    }


    const getExercise = async () => {
        console.log("getExerciseeeeeeeeeeeeeeeeeeeeeee")
        // setExerciseDetail(location.state.exercise);
        // setCurrectQuestionID(location.state.exercise.question[0]); // initial the first question


        // const arrLength = location.state.exercise.question.length; // number of question
        const arrLength = 7; // number of question


        // to initalise the answer and remark array


        // location.state.exercise.progress !== undefined // if some progress has been saved
        // && setProgress(location.state.exercise.progress);

        // location.state.exercise.answer !== undefined ?
        //     setUserAnswer(location.state.exercise.answer) :
        //     setUserAnswer(Array.from({length: arrLength}).fill(""));
        setUserAnswer(Array.from({length: arrLength}).fill(""));
        // location.state.exercise.remark !== undefined ?
        //     setRemark(location.state.exercise.remark) :
        //     setRemark(Array.from({length: arrLength}).fill({
        //         bookmark: false,
        //         dontKnow: false,
        //     }));

        setAnsweredFlagArray(Array.from({length: arrLength}).fill(false));

        // if (location.state.exercise.correctAnsweredFlagArray === undefined) {
        if (1) {
            setCorrectAnsweredFlagArray(Array.from({length: arrLength}).fill("notAnswered"));
        } else {
            setCorrectAnsweredFlagArray(location.state.exercise.correctAnsweredFlagArray);
        }


        let tmpQuestion = 7;

        // to get the question type of the question list
        let tmpArray = [];
        let tmpArrayAnswer = [];
        let tmpArrayTopic = [];
        for (let i = 0; i < tmpQuestion.length; i++) {
            // const docRef = doc(db, 'Question', tmpQuestion[i]);
            // const docSnap = await getDoc(docRef);

            if (1) {
                // tmpArray.push(docSnap.data().type);
                // tmpArrayAnswer.push(docSnap.data().answer)
                tmpArrayAnswer.push(QP[i][1])
                // tmpArrayTopic.push(docSnap.data().topic)
            }
        }
        // setQuestionType(tmpArray);
        setQuestionAnswer(tmpArrayAnswer);
        // setQuestionTopic(tmpArrayTopic);
    }

    const [mode, setMode] = useState("question");
    const [continuousCorrectCount, setContinuousCorrectCount] = useState(0);
    const [continuousWrongCount, setContinuousWrongCount] = useState(0);


    // to handel display practice answer
    const [currentQuestionNum, setCurrectQuestionNum] = useState(1);

    const nextQuestion = async () => {


        if (currentQuestionNum ===7) {
            //jump to result page
            // console.log("jump to result page")

            navigate("/read6")
        }else{
            setCurrectQuestionNum(currentQuestionNum + 1);
            setMode("question");
        }




    }
    const prevQuestion = () => {

        setCurrectQuestionNum(currentQuestionNum - 1);
    }
    const toQuestion = (index) => {

        setCurrectQuestionNum(index + 1);
    }


    return <>

        {/*Question!!!!!*/}
        {/*should the slide down belong to menu or not */}
        {/*the slide down contain exactly the stuff original in the left progress bar and timer*/}
        {/*approach better be the menu is transparent and the slide down doesn't belongg to menu*/}
        {/*Answer!!!!!!!,
        just use the progress component*/}


        <Menu/>


        <SideMenu/>


        {/*tenary is used here only because in mobile view we dont need grid container for the progress and main content */}
        {/*it is weird to use container while the progress is actually fixed position*/}


        <div className="responsiveContainer">
            {/*<div className="exerciseBackgroundImage"*/}
            {/*              style={{*/}
            {/*                  marginLeft: 1 ?*/}
            {/*                      85 : 0*/}
            {/*              }}>*/}
            <Grid container spacing={2} justifyContent="center">
                {/*/!*progress part*!/*/}
                {/*<Grid item xs={12} sm={3}>*/}
                {/*    <div className="calibrationTestSideMenuContainer">*/}
                {/*        {exerciseDetail &&*/}
                {/*            <Progress*/}
                {/*                endFlag={endFlag}*/}
                {/*                correctAnsweredFlagArray={correctAnsweredFlagArray}*/}
                {/*                answeredFlagArray={answeredFlagArray}*/}
                {/*                mode={mode}*/}
                {/*                countUpTime={countUpTime} progress={progress} toQuestion={toQuestion}*/}
                {/*                pauseModalVisibility={pauseModalVisibility}*/}
                {/*                setPauseModalVisibility={setPauseModalVisibility} start={start} pause={pause}*/}
                {/*                // handleSubmitAnswer={handleSubmitAnswer}*/}
                {/*                type={exerciseDetail && exerciseDetail.type == "questionBank" ? "questionBank" : exerciseDetail.type == "answerReviewExercise" ? "answerReviewExercise" : "todaysPractices"}*/}
                {/*                questionTotal={exerciseDetail.question.length}*/}
                {/*                currentQuestionNum={currentQuestionNum - 1} userAnswer={userAnswer}*/}
                {/*                remark={remark} saveDraftFlag={saveDraftFlag}/>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</Grid>*/}
                <Grid item xs={6}>

                    <div className="questionOfTheDayFrame5">

                        <div className="questionOfTheDayFrame2"
                        >
                            Teams for Mars

                        </div>

                        {/*<div*/}
                        {/*    // style={{whiteSpace: "pre-wrap"}}*/}
                        {/*    className="questionOfTheDayFrame4"*/}
                        {/*>*/}
                        {/*    <span className="questionOfTheDayFrame66">ttttttttt</span>*/}
                        {/*    <span className="questionOfTheDayFrame66"> </span>*/}
                        {/*    <span className="questionOfTheDayFrame66"> {TT()}</span>*/}
                        {/*    <span className="questionOfTheDayFrame66"> </span>*/}
                        {/*    <span>ttttttttt</span>*/}
                        {/*    <span>{GG1}</span>*/}
                        {/*</div>*/}

                        {/*<Highlighter*/}
                        {/*    highlightClassName="questionOfTheDayFrame6"*/}
                        {/*    searchWords={["and", "or", "the"]}*/}
                        {/*    autoEscape={true}*/}
                        {/*    textToHighlight="The dog is chasing the cat. Or perhaps they're just playing?"*/}
                        {/*/>*/}
                        <Highlighted text={GG} highlightornot={highlight} to={QP[currentQuestionNum-1][5][1]} from={QP[currentQuestionNum-1][5][0]}/>
                    </div>

                    {/*<div className="answerReviewTopicTitleBoxContainer">*/}
                    {/*    /!*<Grid container spacing={2} alignItems="center">*!/*/}
                    {/*    /!*    <Grid item xs={1} align="center"><p className="answerReviewLebal">{t('RANK')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={6} align="center"><p className="answerReviewLebal">{t('TOPIC')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={3} align="center"><p className="answerReviewLebal">{t('QUESTIONS_LEFT')}</p></Grid>*!/*/}
                    {/*    /!*    <Grid item xs={2} align="center"></Grid>*!/*/}
                    {/*    /!*</Grid>*!/*/}

                    {/*</div>*/}
                </Grid>

                {/*Question part*/}
                <Grid item xs={6}>
                    <div className="questionOfTheDayFrame9">
                        <img
                            className="questionOfTheDayFrame-2"
                            src={require('../../assets/image 82.png')}
                            alt="frame"/>
                        <img
                            className="questionOfTheDayFrame-1"
                            src={require('../../assets/image 58.png')}
                            alt="frame"/>

                            {/*<div className="claibrationTestBottomMargin"></div>*/}
                            {/*<Grid justifyContent="center"*/}
                            {/*      alignItems="center"*/}
                            {/*      justifyContent="center"*/}
                            {/*      style={{minHeight: 'calc(100vh - 10vw - 60px'}}*/}
                            {/*      container spacing={0}>*/}
                                {/*<Grid align="center" item xs={1}>*/}
                                {/*    {currentQuestionNum !== 1 &&*/}
                                {/*        <img onClick={() => prevQuestion()} className="questionArrowAnswer"*/}
                                {/*             src={arrowLeftSVG} alt="left arrow"/>*/}
                                {/*    }*/}
                                {/*</Grid>*/}

                                {/*<Grid align="center" item xs={10}>*/}
                                    {1 && userAnswer &&
                                        <MultipleChoice2 answeredFlagArray={answeredFlagArray}
                                                         updateSubmitedQuestion={updateSubmitedQuestion}
                                                         correctAnsweredFlagArray={correctAnsweredFlagArray}
                                                         setCorrectAnsweredFlagArray={setCorrectAnsweredFlagArray}
                                                         setAnsweredFlagArray={setAnsweredFlagArray}
                                            // exerciseDetail={exerciseDetail}
                                            // handleSubmitAnswer={handleSubmitAnswer}
                                                         progress={progress}
                                                         setProgress={setProgress}
                                            // type={exerciseDetail && exerciseDetail.type == "questionBank" ? "questionBank" : exerciseDetail.type == "answerReviewExercise" ? "answerReviewExercise" : "todaysPractices"}
                                                         questionKey={currentQuestionNum - 1}
                                                         currentQuestionNum={currentQuestionNum}
                                            // questionID={currentQuestionID}
                                                         userAnswer={userAnswer}
                                                         mode={mode} setMode={setMode} setUserAnswer={setUserAnswer}
                                                         remark={remark} setRemark={setRemark} confirmBtn={false}
                                                         continuousCorrectCount={continuousCorrectCount}
                                                         continuousWrongCount={continuousWrongCount}
                                                         correctRandomIndex={correctRandomIndex}
                                                         wrongRandomIndex={wrongRandomIndex}
                                            // pause={pause}
                                                         setSaveDraftFlag={setSaveDraftFlag}
                                                         nextQuestion={nextQuestion}


                                                         highlight={highlight}
                                                            setHighlight={setHighlight}


                                        />}

                                {/*</Grid>*/}

                                {/*<Grid align="center" item xs={1}>*/}

                                {/*    <img onClick={() => nextQuestion()} className="questionArrowAnswer"*/}
                                {/*         src={arrowRightSVG} alt="right arrow"/>*/}

                                {/*</Grid>*/}
                            {/*</Grid>*/}
                            {/*<div className="claibrationTestBottomMargin"></div>*/}

                    </div>
                </Grid>
            </Grid>
        </div>


    </>;
}

export default Practice;
