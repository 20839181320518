import React from 'react';
import Grid from "@mui/material/Grid";
import {db} from "../../firebase";
import {addDoc, collection, doc, getDocs, query, updateDoc, where} from 'firebase/firestore';

import '../../App.css';
import './_styles.css';
import '../../translation'

import {useNavigate} from 'react-router-dom';
import {getUser} from '../../localStorage/User';
import {useTranslation} from "react-i18next";

import questionBankPastTenseNoWordSVG from '../../assets/exercise/questionBankPastTenseNoWord.svg';
import questionBankPastTensePresentTenseNoWordSVG
    from '../../assets/exercise/questionBankPastTensePresentTenseNoWord.svg';
import questionBankPresentContinuousTenseNoWordSVG
    from '../../assets/exercise/questionBankPresentContinuousTenseNoWord.svg';
import questionBankPresentTensePresentContinuousTenseNoWordSVG
    from '../../assets/exercise/questionBankPresentTensePresentContinuousTenseNoWord.svg';
import questionBankPrepositionNoWordSVG from '../../assets/exercise/questionBankPrepositionNoWord.svg';
import questionBankQuestionWordNoWordSVG from '../../assets/exercise/questionBankQuestionWordNoWord.svg';
import {useMediaQuery} from "react-responsive";

function AnswerReviewTopicBox({
                                  index,
                                  data,
                              }) {

    const {t} = useTranslation();
    const user = getUser();
    let navigate = useNavigate();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const createAnswerReviewExercise = async (questionIDArray) => {

        const tmpAnswerReviewExercise = {
            date: new Date(),
            type: "answerReviewExercise",
            user: user.id,
            question: questionIDArray,
            countUpTime: 0,
        };
        const docRefAnswerReviewExercise = await addDoc(collection(db, "Exercise"), tmpAnswerReviewExercise);

        navigate("/practice", {
            state: {
                exercise: tmpAnswerReviewExercise,
                exerciseID: docRefAnswerReviewExercise.id
            }
        });
    }

    return (
        <>
            <Grid item xs={12}>
                <div className="answerReviewTopicBoxContainer">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={1} align="center"><p className="resultTopicBoxText">{index + 1}</p></Grid>
                        <Grid item xs={1} align="center">
                            <img className="topicBoxCover" src={
                                data &&
                                data.topicKey == "pastTense" ? questionBankPastTenseNoWordSVG :
                                    data.topicKey == "pastTensePresentTense" ? questionBankPastTensePresentTenseNoWordSVG :
                                        data.topicKey == "presentContinuousTense" ? questionBankPresentContinuousTenseNoWordSVG :
                                            data.topicKey == "presentTensePresentContinuousTense" ? questionBankPresentTensePresentContinuousTenseNoWordSVG :
                                                data.topicKey == "preposition" ? questionBankPrepositionNoWordSVG :
                                                    data.topicKey == "questionWord" && questionBankQuestionWordNoWordSVG
                            } alt="topic cover"/>
                        </Grid>
                        <Grid item xs={5}><p
                            className="answerReviewTopicBoxText">{data.topic == "Past Tense Present Tense" ? "Past Tense x Present Tense" : data.topic == "Present Tense Present Continuous Tense" ? "Present Tense x Present Continuous Tense" : data.topic}</p>
                        </Grid>
                        <Grid item xs={3} align="center"><p
                            className="answerReviewTopicBoxText">{data.wrongQuestionID.length} {data.wrongQuestionID.length > 1 || data.wrongQuestionID.length === 0 ? "questions" : "question"}</p>
                        </Grid>

                        {/*{is_m&& <Grid item xs={6} align="center"></Grid>}*/}
                        <Grid item xs={is_m?12:2} align="center">
                            <div
                                onClick={() => {
                                    data.wrongQuestionID.length > 0 && createAnswerReviewExercise(data.wrongQuestionID)
                                }}
                                className="reviewNowContainer"
                                style={{
                                    cursor: data.wrongQuestionID.length > 0 ? "pointer" : "not-allowed",
                                    backgroundColor: data.wrongQuestionID.length > 0 ? '#FFE351' : '#eee'
                                }}
                            >
                                <p className="reviewNowText">{t("REVIEW_NOW")}</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </>
    );
}

export default AnswerReviewTopicBox;