import React, {useEffect, useState} from 'react';

import '../../App.css';
import './_styles.css';

import clockSVG from '../../assets/exercise/clock.svg';
import pauseSVG from '../../assets/exercise/pause.svg';
import saveDraftSVG from '../../assets/exercise/saveDraft.svg';
import savedSVG from '../../assets/exercise/saved.svg';
import progressSVG from '../../assets/exercise/progress.svg';
import progressBookmarkSVG from '../../assets/exercise/progressBookmark.svg';
import progressBookmarkTransparentSVG from '../../assets/exercise/progressBookmarkTransparent.svg';
import {useTranslation} from 'react-i18next'
import {t} from 'i18next';
import {getLanguage} from '../../localStorage/Language';
import {useMediaQuery} from "react-responsive";
import Grid from "@mui/material/Grid";
import downArrowSVG from '../../assets/exercise/downArrowSVG.svg';
import upArrowSVG from '../../assets/exercise/upArrowSVG.svg';
import {NUMB} from "../../firebase"
function Progress({
                      endFlag,
                      correctAnsweredFlagArray,
                      answeredFlagArray,
                      mode,
                      countUpTime,
                      progress,
                      pauseModalVisibility,
                      setPauseModalVisibility,
                      remainingTime,
                      start,
                      pause,
                      resume,
                      handleSubmitAnswer,
                      scrollToQuestion,
                      toQuestion,
                      type,
                      questionTotal,
                      currentQuestionNum,
                      userAnswer,
                      remark,
                      saveDraftFlag
                  }) {
    // console.log("answeredFlagArray", answeredFlagArray);
    useEffect(() => {
        start();
    }, []);

    const mobileExpand = () => {
        if (isMobileExpanded) {
            setIsMobileExpanded(!isMobileExpanded)
            document.getElementById("progressFloatRight223").style.height = "0px";
            document.getElementById("progressFloatRight223").style.display = "none";
        } else {
            setIsMobileExpanded(!isMobileExpanded)
            document.getElementById("progressFloatRight223").style.height = "auto";
            document.getElementById("progressFloatRight223").style.display = "unset";
        }

    }

    const [isMobileExpanded, setIsMobileExpanded] = useState(false);
    const {t} = useTranslation();
    const l = getLanguage();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const [progressQuestionNumArray, setProgressQuestionNumArray] = useState();
    useEffect(() => {
        let tmpArray = [];
        for (let i = 0; i < questionTotal; i++) {
            tmpArray.push(i);
        }
        setProgressQuestionNumArray(tmpArray);
    }, [])
    const canSave= type !== "calibrationTest" && type !== "questionBank" && type !== "answerReviewExercise"
    return (
        <>
            {is_m ?

                <div className="progressContainer">
                    <div className="menuHeight60"/>
                    {/*option0*/}
                    {/*use border radius to shape the shape,    the arrow is an svg with onclick,  how to make it stick at the lowest part?*/}


                    {/*options1*/}
                    {/*the wrapper is absolute and the stuff inside is static,  */}
                    {/*click the button to change the height of part 1 or someway to let all the content display at its needed height  */}
                    {/*so part 2 will flow down in animation when part 1 expand*/}


                    {/*option2*/}
                    {/*the wrapper is absolute  and  part 2 is relative,   */}
                    {/*click the button to change the height of part 1 and change the top of part 2 */}


                    {/* part 1 content default hidden */}
                    <div className="progressFloatRight" id="progressFloatRight223"
                         style={{
                             height: "0px",  // inital height
                             // height: !isMobileExpanded ? "0%" : "auto",
                             // textAlign: isMobileExpanded ? "center" : "left",
                             // paddingLeft: isMobileExpanded ? 0 : 28,
                         }}
                    >


                        {/*the graph there */}
                        {type !== "questionBank" &&
                            type !== "answerReviewExercise" &&
                            <div className="progressTopImageContainer">
                                <img className="progressTopImage" src={
                                    l === "en" && type === "calibrationTest" && remainingTime <= 60000 ?
                                        require('../../assets/exercise/calibrationTestProgressTop5.png')
                                        : l === "en" && type === "calibrationTest" && remainingTime <= 300000 ?
                                            require('../../assets/exercise/calibrationTestProgressTop4.png')
                                            : l === "en" && type === "calibrationTest" && remainingTime <= 600000 ?
                                                require('../../assets/exercise/calibrationTestProgressTop3.png')
                                                : l === "en" && type === "calibrationTest" && remainingTime <= 900000 ?
                                                    require('../../assets/exercise/calibrationTestProgressTop2.png')
                                                    : l === "en" && type === "calibrationTest" ?
                                                        require('../../assets/exercise/calibrationTestProgressTop1.png')
                                                        : l !== "en" && type === "calibrationTest" && remainingTime <= 60000 ?
                                                            require('../../assets/exercise/calibrationTestProgressTop5ZH-HK.png')
                                                            : l !== "en" && type === "calibrationTest" && remainingTime <= 300000 ?
                                                                require('../../assets/exercise/calibrationTestProgressTop4ZH-HK.png')
                                                                : l !== "en" && type === "calibrationTest" && remainingTime <= 600000 ?
                                                                    require('../../assets/exercise/calibrationTestProgressTop3ZH-HK.png')
                                                                    : l !== "en" && type === "calibrationTest" && remainingTime <= 900000 ?
                                                                        require('../../assets/exercise/calibrationTestProgressTop2ZH-HK.png')
                                                                        : l !== "en" && type === "calibrationTest" ?
                                                                            require('../../assets/exercise/calibrationTestProgressTop1ZH-HK.png')
                                                                            : l === "en" ?
                                                                                require('../../assets/exercise/practiceProgressTop.png')
                                                                                :
                                                                                require('../../assets/exercise/practiceProgressTopZH-HK.png')
                                }
                                     alt="introduction"/>
                            </div>
                        }


                        {/*timer*/}
                        <div className="timerContainer">
                            {type === "calibrationTest" ?
                                <>
                                    <img className="progressClock" src={clockSVG} alt="clock"/>
                                    <p className="timerText">{new Date(remainingTime).toISOString().substr(14, 1)}</p>
                                    <p className="timerText">{new Date(remainingTime).toISOString().substr(15, 1)}</p>
                                    <p className="timerText">{new Date(remainingTime).toISOString().substr(16, 1)}</p>
                                    <p className="timerText">{new Date(remainingTime).toISOString().substr(17, 1)}</p>
                                    <p className="timerText">{new Date(remainingTime).toISOString().substr(18, 1)}</p>
                                </>
                                :
                                <>
                                    <img className="progressClockSmall" src={clockSVG} alt="clock"/>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(11, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(12, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(13, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(14, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(15, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(16, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(17, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(18, 1)}</p>
                                </>
                            }
                        </div>

                        {/*pause button*/}
                        <Grid container spacing={2}
                              // alignItems="center"
                              justifyContent="center"
                              direction="row"
                        >
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <div onClick={() => {
                                    !endFlag && setPauseModalVisibility(true);
                                    !endFlag && pause();
                                }} className="progressBtnContainer">
                                    <img className="progressPause" src={pauseSVG} alt="pause"/>
                                    <p className="progressPauseText">{!pauseModalVisibility ? t('PAUSE') : t('PAUSED')}</p>
                                </div>
                            </Grid>
                            {canSave&& NUMB!=2 &&
                            <Grid item xs={4}>
                                {/*save progress*/}
                                {canSave&&
                                    <div onClick={() => {
                                        handleSubmitAnswer("saveDraft")
                                    }} className="progressSaveBtnContainer"
                                         style={{backgroundColor: saveDraftFlag ? "#4BC4EF" : "white"}}>

                                        {saveDraftFlag ?
                                            <>
                                                <img className="progressSaveDraft" src={savedSVG} alt="save draft"/>
                                                <p className="progressSaveDraftTextWhite">{t('SAVED')}</p>
                                            </>
                                            :
                                            <>
                                                <img className="progressSaveDraft" src={saveDraftSVG} alt="save draft"/>
                                                <p className="progressSaveDraftText">{t('SAVE_DRAFT')}</p>
                                            </>
                                        }
                                    </div>
                                }</Grid>}
                            <Grid item xs={2}></Grid>
                        </Grid>


                        {/*progress*/}
                        {progressQuestionNumArray && progressQuestionNumArray.length > 0 &&
                            <div className="progressQuestionContainer">
                                <div className="progressQuestionTitleContainer">
                                    <img className="progressIcon" src={progressSVG} alt="progress"/>
                                    <p className="progressTitle">{t('PROGRESS')}</p>
                                </div>
                                <div className="progressQuestionNumContainerOuter">
                                    {
                                        progressQuestionNumArray &&
                                        progressQuestionNumArray.length > 0 &&
                                        progressQuestionNumArray.map((data, i) => (
                                            <span key={i}>
                                            <div className="progressQuestionNumContainer">
                                                {/*{i === 0 ?*/}
                                                {/*    <div className="progressLineLeftEmpty"></div>*/}
                                                {/*    : i % 5 === 0 ?*/}
                                                {/*        <div className="progressLineLeftLong"></div>*/}
                                                {/*        :*/}
                                                {/*        <div className="progressLineLeft"></div>*/}
                                                {/*}*/}
                                                <div
                                                    key={i}
                                                    onClick={() => {
                                                        type === "calibrationTest" ?
                                                            scrollToQuestion(data)
                                                            :
                                                            (progress >= i - 1 || type === "questionBank") && toQuestion(i)
                                                    }}
                                                    className="progressQuestionNum"
                                                    style={{
                                                        backgroundColor:
                                                            currentQuestionNum == i ? '#FF9F00' :
                                                                remark && remark[i].dontKnow ? '#E5E5E5' :
                                                                    (correctAnsweredFlagArray && correctAnsweredFlagArray[i] == "incorrect") ? '#F8A5A5' :
                                                                        (correctAnsweredFlagArray && correctAnsweredFlagArray[i] == "correct") ? '#C3FFB2' :
                                                                            userAnswer && userAnswer[i] != '' ? '#FFBC4C' :
                                                                                'white'
                                                    }}
                                                >
                                                    <div className="progressBookmarkContainer">
                                                        {remark && remark[i].bookmark ?
                                                            <img className="progressBookmark" src={progressBookmarkSVG}
                                                                 alt="clock"/>
                                                            :
                                                            <img className="progressBookmark"
                                                                 src={progressBookmarkTransparentSVG} alt="clock"/>
                                                        }
                                                    </div>
                                                    <div className="progressQuestionNumText">{data + 1}</div>
                                                </div>


                                                {/*{!is_m && (i === progressQuestionNumArray.length - 1 && i !== 4 ?*/}
                                                {/*    <div className="progressLineRightEmpty"></div>*/}
                                                {/*    : i % 5 === 4 ?*/}
                                                {/*        <div className="progressLineRightLong"></div>*/}
                                                {/*        :*/}
                                                {/*        <div className="progressLineRight"></div>)*/}
                                                {/*}*/}

                                        </div>
                                    </span>
                                        ))}
                                </div>
                            </div>
                        }


                    </div>
                    {/*a down arrow button*/}
                    <div className="ArrowContainer"

                         onClick={() => {
                             mobileExpand()
                         }}
                    >
                        {!isMobileExpanded ?
                            <img className="MobileprogressDownArrow11" src={downArrowSVG} alt="down arrow"/> :
                            <img className="MobileprogressDownArrow11" src={upArrowSVG} alt="down arrow"/>
                        }
                    </div>
                    {/* part 2 the clickable stuff, should this be inside the default hidden stuff? */}
                </div> :


                <div className="progressContainer">
                    <div className="progressFloatRight">


                        {/*the graph there */}
                        {type !== "questionBank" &&
                            type !== "answerReviewExercise" &&
                            <div className="progressTopImageContainer">
                                <img className="progressTopImage" src={
                                    l === "en" && type === "calibrationTest" && remainingTime <= 60000 ?
                                        require('../../assets/exercise/calibrationTestProgressTop5.png')
                                        : l === "en" && type === "calibrationTest" && remainingTime <= 300000 ?
                                            require('../../assets/exercise/calibrationTestProgressTop4.png')
                                            : l === "en" && type === "calibrationTest" && remainingTime <= 600000 ?
                                                require('../../assets/exercise/calibrationTestProgressTop3.png')
                                                : l === "en" && type === "calibrationTest" && remainingTime <= 900000 ?
                                                    require('../../assets/exercise/calibrationTestProgressTop2.png')
                                                    : l === "en" && type === "calibrationTest" ?
                                                        require('../../assets/exercise/calibrationTestProgressTop1.png')
                                                        : l !== "en" && type === "calibrationTest" && remainingTime <= 60000 ?
                                                            require('../../assets/exercise/calibrationTestProgressTop5ZH-HK.png')
                                                            : l !== "en" && type === "calibrationTest" && remainingTime <= 300000 ?
                                                                require('../../assets/exercise/calibrationTestProgressTop4ZH-HK.png')
                                                                : l !== "en" && type === "calibrationTest" && remainingTime <= 600000 ?
                                                                    require('../../assets/exercise/calibrationTestProgressTop3ZH-HK.png')
                                                                    : l !== "en" && type === "calibrationTest" && remainingTime <= 900000 ?
                                                                        require('../../assets/exercise/calibrationTestProgressTop2ZH-HK.png')
                                                                        : l !== "en" && type === "calibrationTest" ?
                                                                            require('../../assets/exercise/calibrationTestProgressTop1ZH-HK.png')
                                                                            : l === "en" ?
                                                                                require('../../assets/exercise/practiceProgressTop.png')
                                                                                :
                                                                                require('../../assets/exercise/practiceProgressTopZH-HK.png')
                                }
                                     alt="introduction"/>
                            </div>
                        }


                        {/*timer*/}
                        <div className="timerContainer">
                            {type === "calibrationTest" ?
                                <>
                                    <img className="progressClock" src={clockSVG} alt="clock"/>
                                    <p className="timerText">{new Date(remainingTime).toISOString().substr(14, 1)}</p>
                                    <p className="timerText">{new Date(remainingTime).toISOString().substr(15, 1)}</p>
                                    <p className="timerText">{new Date(remainingTime).toISOString().substr(16, 1)}</p>
                                    <p className="timerText">{new Date(remainingTime).toISOString().substr(17, 1)}</p>
                                    <p className="timerText">{new Date(remainingTime).toISOString().substr(18, 1)}</p>
                                </>
                                :
                                <>
                                    <img className="progressClockSmall" src={clockSVG} alt="clock"/>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(11, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(12, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(13, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(14, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(15, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(16, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(17, 1)}</p>
                                    <p className="timerTextSmall">{new Date(countUpTime * 1000).toISOString().substr(18, 1)}</p>
                                </>
                            }
                        </div>

                        {/*pause button*/}
                        <div onClick={() => {
                            !endFlag && setPauseModalVisibility(true);
                            !endFlag && pause();
                        }} className="progressBtnContainer">
                            <img className="progressPause" src={pauseSVG} alt="pause"/>
                            <p className="progressPauseText">{!pauseModalVisibility ? t('PAUSE') : t('PAUSED')}</p>
                        </div>

                        {/*save progress*/}
                        {canSave &&
                            <div onClick={() => {
                                handleSubmitAnswer("saveDraft")
                            }} className="progressSaveBtnContainer"
                                 style={{backgroundColor: saveDraftFlag ? "#4BC4EF" : "white"}}>

                                {saveDraftFlag ?
                                    <>
                                        <img className="progressSaveDraft" src={savedSVG} alt="save draft"/>
                                        <p className="progressSaveDraftTextWhite">{t('SAVED')}</p>
                                    </>
                                    :
                                    <>
                                        <img className="progressSaveDraft" src={saveDraftSVG} alt="save draft"/>
                                        <p className="progressSaveDraftText">{t('SAVE_DRAFT')}</p>
                                    </>
                                }
                            </div>
                        }


                        {/*progress*/}
                        {progressQuestionNumArray && progressQuestionNumArray.length > 0 &&
                            <div className="progressQuestionContainer">
                                <div className="progressQuestionTitleContainer">
                                    <img className="progressIcon" src={progressSVG} alt="progress"/>
                                    <p className="progressTitle">{t('PROGRESS')}</p>
                                </div>
                                <div className="progressQuestionNumContainerOuter">
                                    {progressQuestionNumArray && progressQuestionNumArray.length > 0 && progressQuestionNumArray.map((data, i) => (
                                        <span key={i}>
                                            <div className="progressQuestionNumContainer">




                                                {i === 0 ?
                                                    <div className="progressLineLeftEmpty"></div>
                                                    : i % 5 === 0 ?
                                                        <div className="progressLineLeftLong"></div>
                                                        :
                                                        <div className="progressLineLeft"></div>
                                                }
                                                <div
                                                    key={i}
                                                    onClick={() => {
                                                        type === "calibrationTest" ? scrollToQuestion(data) :
                                                            (progress >= i - 1 || type === "questionBank") && toQuestion(i)
                                                    }}
                                                    className="progressQuestionNum"
                                                    style={{
                                                        backgroundColor:
                                                            currentQuestionNum == i ? '#FF9F00' :
                                                                remark && remark[i].dontKnow ? '#E5E5E5' :
                                                                    (correctAnsweredFlagArray && correctAnsweredFlagArray[i] == "incorrect") ? '#F8A5A5' :
                                                                        (correctAnsweredFlagArray && correctAnsweredFlagArray[i] == "correct") ? '#C3FFB2' :
                                                                            userAnswer && userAnswer[i] != '' ? '#FFBC4C' :
                                                                                'white'
                                                    }}
                                                >
                                                    <div className="progressBookmarkContainer">
                                                        {remark && remark[i].bookmark ?
                                                            <img className="progressBookmark" src={progressBookmarkSVG}
                                                                 alt="clock"/>
                                                            :
                                                            <img className="progressBookmark"
                                                                 src={progressBookmarkTransparentSVG} alt="clock"/>
                                                        }
                                                    </div>
                                                    <div className="progressQuestionNumText">{data + 1}</div>
                                                </div>

                                                {
                                                    i === progressQuestionNumArray.length - 1 &&
                                                    i !== 4
                                                        ?
                                                        <div className="progressLineRightEmpty"></div>
                                                        :
                                                        i % 5 === 4 ?
                                                            <div className="progressLineRightLong"></div>
                                                            :
                                                            <div className="progressLineRight"></div>
                                                }

                                            </div>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>}
        </>
    );
}

export default Progress;
