import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, query, where } from 'firebase/firestore';

import '../../App.css';
import './_styles.css';
import LearningVideoEmbed from './LearningVideoEmbed';
import { VideoSuggestionBackToHomeButton } from './_components';

import '../../translation'
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../localStorage/Language';

import speakerSVG from '../../assets/video/speaker.svg';
import messageBoxSVG from '../../assets/video/messageBox.svg';


function VideoSuggestion({
    suggestedVideoModalVisibility,
    setSuggestedVideoModalVisibility,
    practiceTopic
}) {
    let navigate = useNavigate();

    const { t } = useTranslation();
    const l = getLanguage();

    const [embedId, setEmbedId] = useState();

    useEffect(() => {
        practiceTopic !== "" && getLearningVideo();
    }, [practiceTopic])

    const getLearningVideo = async () => {
        const q = query(collection(db, "Video"), where("topic", "==", practiceTopic));
        const querySnapshot = await getDocs(q);
        let tmpVideo = [];
        querySnapshot.forEach((doc) => {
            tmpVideo.push(doc.data());
        });
        if(tmpVideo[Math.floor(Math.random() * tmpVideo.length)]){
            setEmbedId(tmpVideo[Math.floor(Math.random() * tmpVideo.length)].embedId);
        }
    }
    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={suggestedVideoModalVisibility}
                className="videoSuggestionModalContainer"
                style={{ overlay: { backgroundColor: 'rgba(0, 0, 0, 0.3)', backdropFilter: "blur(6px)", WebkitBackdropFilter: "blur(6px)", zIndex: 1000000 } }}
            >
                <div className="videoSuggestionBackgroundImage">
                    <Grid container spacing={2}>
                        <Grid item xs={1}>
                            <img className="speakerImage" src={speakerSVG} alt="speaker" />
                        </Grid>
                        <Grid item xs={11} align="left">
                            <p className="videoSuggestionTopicText">{t("FINISHED_PRACTICE")}</p>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="flex-end">
                        <Grid item xs={1} />
                        <Grid item xs={7}>
                            {embedId && <LearningVideoEmbed embedId={embedId} />}
                            <div className="videoSuggestionReturnToHomeDisplayInlineBlock" onClick={() => {
                                navigate('/')
                                setSuggestedVideoModalVisibility(false);
                            }}><VideoSuggestionBackToHomeButton variant="contained" className="sendButton"></VideoSuggestionBackToHomeButton>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            {l === "en" && practiceTopic == "pastTense" && <img className="birdImage" src={require(`../../assets/video/birdTextPastTense.png`)} alt="bird" />}
                            {l === "en" && practiceTopic == "pastTensePresentTense" && <img className="birdImage" src={require(`../../assets/video/birdTextPastTensePresentTense.png`)} alt="bird" />}
                            {l === "en" && practiceTopic == "presentContinuousTense" && <img className="birdImage" src={require(`../../assets/video/birdTextPresentContinuousTense.png`)} alt="bird" />}
                            {l === "en" && practiceTopic == "presentTensePresentContinuousTense" && <img className="birdImage" src={require(`../../assets/video/birdTextPresentTensePresentContinuousTense.png`)} alt="bird" />}
                            {l === "en" && practiceTopic == "preposition" && <img className="birdImage" src={require(`../../assets/video/birdTextPreposition.png`)} alt="bird" />}
                            {l === "en" && practiceTopic == "questionWord" && <img className="birdImage" src={require(`../../assets/video/birdTextQuestionWord.png`)} alt="bird" />}
                            {l !== "en" && practiceTopic == "pastTense" && <img className="birdImage" src={require(`../../assets/video/birdTextPastTenseZH-HK.png`)} alt="bird" />}
                            {l !== "en" && practiceTopic == "pastTensePresentTense" && <img className="birdImage" src={require(`../../assets/video/birdTextPastTensePresentTenseZH-HK.png`)} alt="bird" />}
                            {l !== "en" && practiceTopic == "presentContinuousTense" && <img className="birdImage" src={require(`../../assets/video/birdTextPresentContinuousTenseZH-HK.png`)} alt="bird" />}
                            {l !== "en" && practiceTopic == "presentTensePresentContinuousTense" && <img className="birdImage" src={require(`../../assets/video/birdTextPresentTensePresentContinuousTenseZH-HK.png`)} alt="bird" />}
                            {l !== "en" && practiceTopic == "preposition" && <img className="birdImage" src={require(`../../assets/video/birdTextPrepositionZH-HK.png`)} alt="bird" />}
                            {l !== "en" && practiceTopic == "questionWord" && <img className="birdImage" src={require(`../../assets/video/birdTextQuestionWordZH-HK.png`)} alt="bird" />}
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </>
    );
}

export default VideoSuggestion;
