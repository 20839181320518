import {db} from "../firebase";
import {doc, getDoc} from "firebase/firestore";

export const redirect = async (user, navigate) => {
    if (window.location.pathname !== "/login") {      // if not path login
        if (user.id === "" || user.id === null || user.id === undefined) {  // no user
            navigate("/login");
        } else {  // some user is here
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            if (userSnapshot.data().finishColibrationTestFlag) {
                navigate("/");
            } else if (userSnapshot.data().finishWelcomeFlag) {
                navigate("/welcome", {state: {processInit: 8}});
            } else {
                navigate("/welcome");
            }
        }
    } else {
        if (user.id !== "" && user.id !== null && user.id !== undefined) {  // some user is here
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            if (userSnapshot.data().finishColibrationTestFlag) {
                navigate("/");
            } else if (userSnapshot.data().finishWelcomeFlag) {
                navigate("/welcome", {state: {processInit: 8}});
            } else {
                navigate("/welcome");
            }
        }
    }
}
