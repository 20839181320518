import React from 'react';

import '../../App.css';
import './_styles.css';
import '../../translation'

import dontKnowSVG from '../../assets/exercise/dontKnow.svg';
import bookmarkSVG from '../../assets/exercise/bookmark.svg';
import bookmarkedSVG from '../../assets/exercise/bookmarked.svg';

import { useTranslation } from 'react-i18next';
import {useMediaQuery} from "react-responsive";

function Remark({ userAnswer, setUserAnswer, questionKey, remark, setRemark, forceUpdate, setForceUpdate, setValue }) {

    const { t } = useTranslation();

    const handleDontKnowClick = () => {
        let tmp = remark;
        tmp[questionKey] = {
            bookmark: tmp[questionKey].bookmark,
            dontKnow: !tmp[questionKey].dontKnow,
        };
        setRemark(tmp);
        setForceUpdate(forceUpdate + 1);
        if (tmp[questionKey].dontKnow) {
            let tmp = userAnswer;
            tmp[questionKey] = "";
            setUserAnswer(tmp);
            setValue(userAnswer[questionKey]);
        }
    }
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const handleBookmarkClick = () => {
        let tmp = remark;
        tmp[questionKey] = {
            bookmark: !tmp[questionKey].bookmark,
            dontKnow: tmp[questionKey].dontKnow,
        };
        setRemark(tmp);
        setForceUpdate(forceUpdate + 1);
    }

    return (
        <>
            <div className="multipleChoiceTopRightContainer">
                <div onClick={() => {
                    handleDontKnowClick()
                }} className="multipleChoiceDontKnowContainer">
                    <img className="multipleChoiceDontKnowSVG" src={dontKnowSVG} alt="mark" />
                    <p className="multipleChoiceDontKnowText">{remark && forceUpdate && remark[questionKey].dontKnow ? t("I_WANT_TO_TRY") : t("I_DONT_KNOW")}</p>
                </div>
                {!is_m &&   <div onClick={() => {
                    handleBookmarkClick()
                }} className="multipleChoiceBookamrkContainer">
                    {remark && forceUpdate && !remark[questionKey].bookmark ?
                        <>
                            <img className="multipleChoiceBookmarkSVG" src={bookmarkSVG} alt="bookmark" />
                            <p className="multipleChoiceBookmarkText">{t("BOOKMARK")}</p>
                        </>
                        :
                        <>
                            <img className="multipleChoiceBookmarkSVG" src={bookmarkedSVG} alt="bookmark" />
                            <p className="multipleChoiceBookmarkText">{t("BOOKMARKED")}</p>
                        </>
                    }
                </div>}

            </div>
        </>
    );
}

export default Remark;
