
import React, { useEffect, useState } from 'react';
import { db } from "../../firebase";
import { addDoc, collection, doc, getDocs, query, updateDoc, where, getDoc } from 'firebase/firestore';
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { getLanguage } from '../../localStorage/Language';

import '../../App.css';
import './_styles.css';
import '../../translation'

import performanceLevelBarSVG from '../../assets/exercise/performanceLevelBar.svg';
import performanceLevelBarZHHKSVG from '../../assets/exercise/performanceLevelBarZH-HK.svg';
import performanceLevelIconSVG from '../../assets/exercise/performanceLevelIcon.svg';
import performanceLevelStudentIconSVG from '../../assets/exercise/performanceLevelStudentIcon.svg';
import { getUser } from '../../localStorage/User';
import { TwoMpOutlined } from '@mui/icons-material';
import {useMediaQuery} from "react-responsive";

function PerformanceLevel() {

  const user = getUser();
  const { t } = useTranslation();
  const l = getLanguage();

  useEffect(() => {
    getUserInfo();
  }, [])
  const is_m = useMediaQuery({
    query: '(max-width: 600px)'
  })
  // to get user information
  const [userInfo, setUserInfo] = useState();
  const getUserInfo = async () => {
    if (user.id !== "" && user.id !== null && user.id !== undefined) {
      const userSnapshot = await getDoc(doc(db, 'User', user.id));
      setUserInfo(userSnapshot.data());
    }
  }

  useEffect(() => {
    buildRanking();
  }, [userInfo])

  const [icons, setIcons] = useState();

  const buildRanking = async () => {

    let tmpLevel = 0;

    if (userInfo && userInfo.tier) {

      // to get the position of the performance level
      let tmpUser = [];
      const q = query(collection(db, "User"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data().tier === userInfo.tier && doc.data().overallAccuracy !== undefined && doc.data().overallAccuracy !== "NA")
          tmpUser.push({
            id: doc.id,
            accuracy: doc.data().overallAccuracy,
            tier: doc.data().tier,
          })
      });

      tmpUser.sort(function (a, b) {
        return a.accuracy < b.accuracy ? -1 : a.accuracy > b.accuracy ? 1 : 0;
      });

      let tmpPosition = 0;
      for(let i = 0; i<tmpUser.length; i++){
        if(tmpUser[i].id === user.id){
          tmpPosition = i + 1;
        }
      }

      let tmpRanking = tmpPosition / (tmpUser.length + 1);

      tmpLevel = userInfo.tier == "below average" ? 1 : userInfo.tier == "average" ? 5 : 9;

      tmpRanking >= 0.75 ? (tmpLevel += 3) : tmpRanking >= 0.5 ? (tmpLevel += 2) : tmpRanking >= 0.25 && (tmpLevel += 1)

    }
    let tmpIcons = []

    for (var i = 1; i < 13; i++) {
      if (i === tmpLevel) {
        tmpIcons.push(
          <Grid key={i} item xs={1} align="center">
            <p className="performanceLevelStudentIconName">{userInfo && userInfo.nickname}</p>
            <img className="performanceLevelStudentIcon" src={performanceLevelStudentIconSVG} alt="student icon" />
          </Grid>);
      }
      else {
        tmpIcons.push(
          <Grid key={i} item xs={1} align="center">
            <img className="performanceLevelIcon" src={performanceLevelIconSVG} alt="icon" />
          </Grid>);
      }
    }
    setIcons(tmpIcons);
  }


  return (
    <>
      <div className="resultTitleContainer" style={{ backgroundColor: '#AEF4F4', marginLeft: is_m?'0px' :'30px', }}>
        <p className="resultTitle">{t('PERFORMANCE_LEVEL')}</p>
      </div>
      <div className="performanceLevelContainer">
        <div className="performanceLevelIconSection">
          <Grid container spacing={0} alignItems="flex-end">
            {icons && icons}
          </Grid>
        </div>
        <Grid item xs={12} align="center">
          <img className="performanceLevelBar" src={l === "en" ? performanceLevelBarSVG : performanceLevelBarZHHKSVG} alt="performance level bar" />
        </Grid>
      </div>
    </>
  );
}

export default PerformanceLevel;
