import React from 'react';
import Modal from 'react-modal';

import '../../App.css';
import './_styles.css';

import '../../translation'
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../localStorage/Language';

import { ResumeButton } from './_components';

function ForceGoBack({
    forceGoBackModalVisibility,
    setForceGoBackModalVisibility,
    remainingTime = 0,
    resume,
}) {
    const { t } = useTranslation();
    const l = getLanguage();
    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={forceGoBackModalVisibility}
                className="pauseModalContainer"
                style={{ overlay: { backgroundColor: 'rgba(0, 0, 0, 0.3)', backdropFilter: "blur(6px)", WebkitBackdropFilter: "blur(6px)", zIndex: 1000000 } }}
            >
                <div className="pauseBackgroundImage">
                    <p className="forceGoBackConfirmText">{t("FORCE_GO_BACK_TITLE")}</p>
                    <img className="pauseContent" src={
                        l === "en" ?
                            require('../../assets/exercise/forceGoBackContent.png')
                            :
                            require('../../assets/exercise/forceGoBackContentZH-HK.png')
                    }
                        alt="resume" />
                    <p className="pauseTimeSpentText">{t("TIME_REMAIN")}: {new Date(remainingTime).toISOString().substr(11, 8)}</p>
                    <div onClick={() => {
                        setForceGoBackModalVisibility(false);
                        resume();
                    }} className="pauseButtonContainer">
                        <ResumeButton />
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ForceGoBack;
