import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import Grid from "@mui/material/Grid";
import Checkbox from '@mui/material/Checkbox';
import {db} from "../../firebase";
import {addDoc, collection, doc, getDoc, getDocs, query, where} from 'firebase/firestore';

import '../../App.css';
import './_styles.css';
import '../../translation'
import closeSVG from '../../assets/general/close.svg';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";

import questionBankPastTenseSVG from '../../assets/exercise/questionBankPastTense.svg';
import questionBankPastTensePresentTenseSVG from '../../assets/exercise/questionBankPastTensePresentTense.svg';
import questionBankPresentContinuousTenseSVG from '../../assets/exercise/questionBankPresentContinuousTense.svg';
import questionBankPresentTensePresentContinuousTenseSVG
    from '../../assets/exercise/questionBankPresentTensePresentContinuousTense.svg';
import questionBankPrepositionSVG from '../../assets/exercise/questionBankPreposition.svg';
import questionBankQuestionWordSVG from '../../assets/exercise/questionBankQuestionWord.svg';

import {getUser} from '../../localStorage/User';
import {useMediaQuery} from "react-responsive";

function QuestionBankModal({
                               questionBankModalVisibility,
                               setQuestionBankModalVisibility,
                               activeTopic
                           }) {

    const {t} = useTranslation();
    const user = getUser();
    let navigate = useNavigate();

    useEffect(() => {
        activeTopic && getTotalQuestion();
    }, [activeTopic]);

    const [difficultyBoxInfo, setDifficultyBoxInfo] = useState();
    const [skipAttemptedFlag, setSkipAttemptedFlag] = useState(true);
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    // to calculate the total questions of the type and the corresponding questions answered by user
    const getTotalQuestion = async () => {

        if (user.id !== "" && user.id !== null && user.id !== undefined
        // some user is here
        ) {
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            let userInfo = userSnapshot.data();

            // to get level 1 statics
            let levelOneCount = 0, levelOneAnsweredCount = 0;


            const qLevelOne = query(collection(db, "Question"),
                where("topic", "==", activeTopic),
                where("level", "==", "1"));
            const querySnapshotLevelOne = await getDocs(qLevelOne);


            querySnapshotLevelOne.forEach((doc) => {

                levelOneCount += 1;

                // check whether user answer this question before
                userInfo.submitedQuestion &&
                userInfo.submitedQuestion.includes(doc.id) &&
                (levelOneAnsweredCount += 1);


            });

            // to get level 2 statics
            let levelTwoCount = 0, levelTwoAnsweredCount = 0;
            const qLevelTwo = query(collection(db, "Question"),
                where("topic", "==", activeTopic),
                where("level", "==", "2"));

            const querySnapshotLevelTwo = await getDocs(qLevelTwo);
            querySnapshotLevelTwo.forEach((doc) => {

                levelTwoCount += 1;

                userInfo.submitedQuestion &&
                userInfo.submitedQuestion.includes(doc.id) &&
                (levelTwoAnsweredCount += 1);
            });

            // to get level 3 statics
            let levelThreeCount = 0, levelThreeAnsweredCount = 0;
            const qLevelThree = query(collection(db, "Question"),
                where("topic", "==", activeTopic),
                where("level", "==", "3"));

            const querySnapshotLevelThree = await getDocs(qLevelThree);

            querySnapshotLevelThree.forEach((doc) => {

                levelThreeCount += 1;

                userInfo.submitedQuestion && userInfo.submitedQuestion.includes(doc.id) && (levelThreeAnsweredCount += 1);
            });

            setDifficultyBoxInfo([
                {
                    "title": "Easy",
                    "color": "#50CC17",
                    "greyColor": "#c0dca4",
                    "answeredNum": levelOneAnsweredCount,
                    "totalNum": levelOneCount,
                },
                {
                    "title": "Normal",
                    "color": "#FDA718",
                    "greyColor": "#f0d4a4",
                    "answeredNum": levelTwoAnsweredCount,
                    "totalNum": levelTwoCount,
                },
                {
                    "title": "Hard",
                    "color": "#D63737",
                    "greyColor": "#e8b4b4",
                    "answeredNum": levelThreeAnsweredCount,
                    "totalNum": levelThreeCount,
                },
            ]);
        }
    }

    // to generate question bank practice
    const generateQuestionBankPractice = async (data, i) => {
        const userSnapshot = await getDoc(doc(db, 'User', user.id));
        let userInfo = userSnapshot.data();

        const qQuestion = query(collection(db, "Question"),
            where("topic", "==", activeTopic),
            where("level", "==", (i + 1).toString()));


        const querySnapshotQuestion = await getDocs(qQuestion);
        let tmpQuestionArray = []

        querySnapshotQuestion.forEach((doc) => {
            if (!skipAttemptedFlag) { // include all questions
                tmpQuestionArray.push(doc.id);
            } else if ((userInfo.submitedQuestion && !userInfo.submitedQuestion.includes(doc.id)) || userInfo.submitedQuestion == undefined) { // only include not answered questions
                tmpQuestionArray.push(doc.id);
            }
        });

        const tmpQuestionBankExercise = {
            date: new Date(),
            type: "questionBank",
            user: user.id,
            question: tmpQuestionArray,
            countUpTime: 0,
        };

        const docRefQuestionBankExercise = await addDoc(collection(db, "Exercise"), tmpQuestionBankExercise);
        

        navigate("/practice", {state: {exercise: tmpQuestionBankExercise, exerciseID: docRefQuestionBankExercise.id}});
    }

    return <>
        <Modal
            ariaHideApp={false}
            isOpen={questionBankModalVisibility}
            className="pauseModalContainer"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    backdropFilter: "blur(6px)",
                    WebkitBackdropFilter: "blur(6px)",
                    zIndex: 1000000
                }
            }}
        >
            <div className="questionBankModalContainer">

                {is_m ? <>


                        <img onClick={() => {
                            setQuestionBankModalVisibility(false)
                        }} className="forgotPasswordClose" src={closeSVG} alt="close"/>
                        <Grid justifyContent="center" container spacing={0}>
                            <Grid align="center" item xs={12}>
                                <div className="verticalCenter">
                                    {activeTopic == "pastTense" ?
                                        <img className="questionBankModalBook" src={questionBankPastTenseSVG} alt="book"/>
                                        : activeTopic == "pastTensePresentTense" ?
                                            <img className="questionBankModalBook"
                                                 src={questionBankPastTensePresentTenseSVG} alt="book"/>
                                            : activeTopic == "presentContinuousTense" ?
                                                <img className="questionBankModalBook"
                                                     src={questionBankPresentContinuousTenseSVG} alt="book"/>
                                                : activeTopic == "presentTensePresentContinuousTense" ?
                                                    <img className="questionBankModalBook"
                                                         src={questionBankPresentTensePresentContinuousTenseSVG}
                                                         alt="book"/>
                                                    : activeTopic == "preposition" ?
                                                        <img className="questionBankModalBook"
                                                             src={questionBankPrepositionSVG} alt="book"/>
                                                        : activeTopic == "questionWord" &&
                                                        <img className="questionBankModalBook"
                                                             src={questionBankQuestionWordSVG} alt="book"/>
                                    }
                                </div>
                            </Grid>
                            {/*<Grid align="center" item xs={1}></Grid>*/}
                            <Grid align="center" item xs={9}>
                                <div className="verticalCenter">
                                    <p className="questionBankModalDifficultyTitle">{t("CHOOSE_LEVEL")}</p>
                                    {difficultyBoxInfo && difficultyBoxInfo.map((data, i) => (
                                        <div
                                            onClick={() => !((skipAttemptedFlag && (data.answeredNum == data.totalNum)) || data.totalNum <= 0) && generateQuestionBankPractice(data, i)}
                                            key={i}
                                            className="questionBankModalDifficulty"
                                            style={{
                                                backgroundColor: (skipAttemptedFlag && (data.answeredNum == data.totalNum)) || data.totalNum <= 0 ? data.greyColor : data.color,
                                                cursor: (skipAttemptedFlag && (data.answeredNum == data.totalNum)) || data.totalNum <= 0 ? "not-allowed" : "pointer",

                                            }}
                                        >
                                            <p className="questionBankModalDifficultyText">{
                                                data.title === "Easy" ? t("EASY")
                                                    : data.title === "Normal" ? t("NORMAL")
                                                        : data.title === "Hard" && t("HARD")
                                            }</p>
                                            <p className="questionBankModalDifficultyDescription">
                                                {t("ATTEMPT_NUM")}:{" "}
                                                {data.answeredNum}/{data.totalNum}</p>
                                        </div>
                                    ))}
                                    <Checkbox defaultChecked onClick={(e) => setSkipAttemptedFlag(e.target.checked)}/>
                                    <span className="questionBankModalSkip">{t("SKIP_ATTEMPT")}</span>
                                </div>
                            </Grid>
                            {/*<Grid align="center" item xs={1}></Grid>*/}
                        </Grid></>

                    :


                    <>
                        <img onClick={() => {
                            setQuestionBankModalVisibility(false)
                        }} className="forgotPasswordClose" src={closeSVG} alt="close"/>
                        <Grid justifyContent="center" container spacing={0}>
                            <Grid align="center" item xs={3}>
                                <div className="verticalCenter">
                                    {activeTopic == "pastTense" ?
                                        <img className="questionBankModalBook" src={questionBankPastTenseSVG}
                                             alt="book"/>
                                        : activeTopic == "pastTensePresentTense" ?
                                            <img className="questionBankModalBook"
                                                 src={questionBankPastTensePresentTenseSVG} alt="book"/>
                                            : activeTopic == "presentContinuousTense" ?
                                                <img className="questionBankModalBook"
                                                     src={questionBankPresentContinuousTenseSVG} alt="book"/>
                                                : activeTopic == "presentTensePresentContinuousTense" ?
                                                    <img className="questionBankModalBook"
                                                         src={questionBankPresentTensePresentContinuousTenseSVG}
                                                         alt="book"/>
                                                    : activeTopic == "preposition" ?
                                                        <img className="questionBankModalBook"
                                                             src={questionBankPrepositionSVG} alt="book"/>
                                                        : activeTopic == "questionWord" &&
                                                        <img className="questionBankModalBook"
                                                             src={questionBankQuestionWordSVG} alt="book"/>
                                    }
                                </div>
                            </Grid>
                            <Grid align="center" item xs={1}></Grid>
                            <Grid align="center" item xs={7}>
                                <div className="verticalCenter">
                                    <p className="questionBankModalDifficultyTitle">{t("CHOOSE_LEVEL")}</p>
                                    {difficultyBoxInfo && difficultyBoxInfo.map((data, i) => (
                                        <div
                                            onClick={() => !((skipAttemptedFlag && (data.answeredNum == data.totalNum)) || data.totalNum <= 0) && generateQuestionBankPractice(data, i)}
                                            key={i}
                                            className="questionBankModalDifficulty"
                                            style={{
                                                backgroundColor: (skipAttemptedFlag && (data.answeredNum == data.totalNum)) || data.totalNum <= 0 ? data.greyColor : data.color,
                                                cursor: (skipAttemptedFlag && (data.answeredNum == data.totalNum)) || data.totalNum <= 0 ? "not-allowed" : "pointer",

                                            }}
                                        >
                                            <p className="questionBankModalDifficultyText">{
                                                data.title === "Easy" ? t("EASY")
                                                    : data.title === "Normal" ? t("NORMAL")
                                                        : data.title === "Hard" && t("HARD")
                                            }</p>
                                            <p className="questionBankModalDifficultyDescription">
                                                {t("ATTEMPT_NUM")}:{" "}
                                                {data.answeredNum}/{data.totalNum}</p>
                                        </div>
                                    ))}
                                    <Checkbox defaultChecked onClick={(e) => setSkipAttemptedFlag(e.target.checked)}/>
                                    <span className="questionBankModalSkip">{t("SKIP_ATTEMPT")}</span>
                                </div>
                            </Grid>
                            <Grid align="center" item xs={1}></Grid>
                        </Grid></>

                }

            </div>
        </Modal>
    </>;
}

export default QuestionBankModal;
