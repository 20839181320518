import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import RadioGroup from '@mui/material/RadioGroup';
import {db, NUMB} from "../../firebase";
import {addDoc, collection, doc, getDoc, getDocs, query, where} from 'firebase/firestore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import '../../App.css';
import './_styles.css';

import {ConfirmButton, ConfirmButton2, SimilarQuestionButton} from './_components';
import Remark from './Remark';
import correctSVG from '../../assets/general/correct.svg';
import incorrectSVG from '../../assets/general/incorrect.svg';
import correctElaborationSVG from '../../assets/exercise/correctElaboration.svg';
import starYellowSVG from '../../assets/exercise/starYellow.svg';
import starGreySVG from '../../assets/exercise/starGrey.svg';
import bookmarkedSVG from '../../assets/exercise/bookmarked.svg';

import '../../translation'
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {getLanguage} from '../../localStorage/Language';

import {getUser} from '../../localStorage/User';
import {useMediaQuery} from "react-responsive";
import {Box} from "@mui/material";
import {QP} from "./Practice2";

function MultipleChoice({
                            updateSubmitedQuestion,
                            answeredFlagArray,
                            setCorrectAnsweredFlagArray,
                            correctAnsweredFlagArray,
                            setAnsweredFlagArray,
                            exerciseDetail,
                            // handleSubmitAnswer,
                            setProgress,
                            progress,
                            type,
                            showEmptyMsg,
                            questionKey,
                            questionID,
                            currentQuestionNum,
                            confirmBtn = true,
                            userAnswer,
                            setUserAnswer,
                            setMode,
                            mode = "question",
                            remark,
                            setRemark,
                            pause,
                            continuousCorrectCount,
                            continuousWrongCount,
                            correctRandomIndex,
                            wrongRandomIndex,
                            setSaveDraftFlag,
                            nextQuestion,

                            highlight,
                            setHighlight
                        }) {
    console.log("mode", mode)

    const {t} = useTranslation();
    const l = getLanguage();

    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const numbering = ["A", "B", "C", "D"];

    const [value, setValue] = useState("")
    const [option, setOption] = useState();

    useEffect(() => {
            currentQuestionNum && getQuestion(questionID);
            if (type !== "calibrationTest" && type !== undefined) {

                setValue(exerciseDetail.answer ? exerciseDetail.answer[questionKey] : "");


                // if (type === "todaysPractices") {
                //     progress >= currentQuestionNum - 1 ?
                //         (setMode("answer")) :
                //         (setMode("question"));
                // }
                (type === "questionBank" || type === "answerReviewExercise") && setMode("question");
            }
        },
        [questionID, currentQuestionNum]
    )

    useEffect(() => {
        setMode("question")
        if (type === "todaysPractices") {
            progress >= currentQuestionNum - 1 ? (setMode("answer")) : (setMode("question"));
        }
    }, [progress])

    const [questionDetail, setQuestionDetail] = useState();
    const [answerIndex, setAnswerIndex] = useState();
    const getQuestion = async (id) => {

        // const questionSnapshot = await getDoc(doc(db, 'Question', id));
        // if (questionSnapshot.exists()) {
        if (1) {

            // random question by question id
            let randomIndex = [];


            let QQQQQQQ = 4
            for (let i = 0; i < QQQQQQQ; i++) {

                randomIndex.push(Math.floor(Math.random() * 1000));
            }


            // let tmpOption = questionSnapshot.data().option;
            let tmpOption = QP[currentQuestionNum - 1].slice(1);
            console.log("qq", tmpOption);


            let sortedOption = [];
            var len = randomIndex.length;
            var indices = new Array(len);
            for (var i = 0; i < len; ++i) indices[i] = i;
            indices.sort(function (a, b) {
                return randomIndex[a] < randomIndex[b] ? -1 : randomIndex[a] > randomIndex[b] ? 1 : 0;
            });

            for (let i = 0; i < QQQQQQQ; i++) {
                sortedOption.push(tmpOption[indices[i]]);
            }
            setOption(sortedOption);


            // setQuestionDetail(questionSnapshot.data());

            let PP = {
                "question": QP[currentQuestionNum - 1][0],
                // ,"option":sortedOption,
                "answer": QP[currentQuestionNum - 1][1]
            }
            setQuestionDetail(PP);


            for (let i = 0; i < QQQQQQQ; i++) {
                // sortedOption[i] == questionSnapshot.data().answer && setAnswerIndex(i); // to set the answer index
                sortedOption[i] == QP[currentQuestionNum][1] && setAnswerIndex(i); // to set the answer index
            }
        }
    }

    const userSingleAnswer = userAnswer[currentQuestionNum - 1];


    const handleSelectAnswer = (answer) => {
        if (
            (type == "calibrationTest" && mode == "question") ||
            (!(progress >= currentQuestionNum - 1) ||
                type === "questionBank")
        ) {
            let tmp = userAnswer;
            tmp[questionKey] = answer;
            setUserAnswer(tmp);
            setValue(userAnswer[questionKey]);
        }
        // setSaveDraftFlag(false);


        handleConfirmAnswer();
    }

    const [forceUpdate, setForceUpdate] = useState(1); // to force update DOM when updated remark

    const handleConfirmAnswer = () => {
        // setMode("answer");
        console.log("qqqqq")
        // updateSubmitedQuestion(questionID);
        //
        //
        let tmp = answeredFlagArray;
        tmp[questionKey] = true;
        setAnsweredFlagArray(tmp);
        let tmpCorrect = correctAnsweredFlagArray;
        tmpCorrect[questionKey] = (questionDetail.answer == value) ? "correct" : "incorrect";
        setCorrectAnsweredFlagArray(tmpCorrect);
        // setProgress(progress + 1);
        setMode("answer");
        // handleSubmitAnswer("default", questionDetail.answer == value);
    }

    let pq;

    if (questionDetail && currentQuestionNum==5){

        pq=<>
                            <span className="questionOfTheDayFrame7">Sam is feeling

                            </span> <span className="questionOfTheDayFrame75">___ .

                            </span>
            <img
                onClick={() => {
                    setHighlight(!highlight)
                    console.log(highlight)
                }}
                className="questionOfTheDayFrame-3"
                src={require('../../assets/image 67.png')}
                alt="frame"/>
        </>
    }else if (questionDetail){
        pq=<>
                            <span className="questionOfTheDayFrame7">{questionDetail.question}

                            </span>
            <img
                onClick={() => {
                    setHighlight(!highlight)
                    console.log(highlight)
                }}
                className="questionOfTheDayFrame-3"
                src={require('../../assets/image 67.png')}
                alt="frame"/>
        </>
    }

    return (
        <>
            {/*{(type === "answerReviewExercise" && (mode == "answer" || answeredFlagArray[currentQuestionNum - 1])) &&*/}
            {/*    <div className='similarQuestionContainer'>*/}
            {/*        <div onClick={toQuestionBank}>*/}
            {/*            <SimilarQuestionButton />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
            <div>

                <div>
                        <span className="questionOfTheDayFrame7">
                            {1 ?
                                "Q" + currentQuestionNum + " "
                                :
                                "第 " + currentQuestionNum + " 題"
                            }
                        </span>

                    {pq
                    }

                    {currentQuestionNum == 4 && highlight &&
                        <img
                            className="questionOfTheDayFrame-25"
                            src={require('../../assets/Group 1123.png')}
                            alt="frame"/>
                    }


                    {/*<img ></img>*/}
                </div>
                {/*{(mode == "question" && !((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) && NUMB != 2 &&*/}
                {/*    <Remark userAnswer={userAnswer} setUserAnswer={setUserAnswer} questionKey={questionKey}*/}
                {/*            remark={remark} setRemark={setRemark}*/}
                {/*            forceUpdate={forceUpdate} setForceUpdate={setForceUpdate} setValue={setValue}/>*/}
                {/*}*/}

                <Grid container spacing={is_m ? 3 : 0}>
                    <Grid item xs={12}>
                        <RadioGroup key={forceUpdate} value={value} onChange={(_, value) => {
                            handleSelectAnswer(value)
                        }}>
                            {questionDetail
                                && option
                                && option.map((data, i) => (
                                    <div key={i} onClick={() => {
                                        setValue(data);
                                        handleSelectAnswer(data)
                                    }}
                                         className="optionContainer2"

                                         style={{
                                             backgroundColor:
                                                 (remark && forceUpdate && remark[questionKey].dontKnow) ? "#E5E5E5" :
                                                     questionDetail.answer == data && (mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) ? '#BDFFB2' :
                                                         questionDetail.answer != data && (mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) && data == userSingleAnswer && '#F8A5A5'
                                         }}

                                    >
                                        {/*<FormControlLabel*/}
                                        {/*    value={data}*/}
                                        {/*    control={*/}
                                        {/*        (*/}
                                        {/*            mode != "answer" && !((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) && (remark && forceUpdate && !remark[questionKey].dontKnow) ?*/}
                                        {/*            <Radio sx={{*/}
                                        {/*                '& .MuiSvgIcon-root': {*/}
                                        {/*                    fontSize: "2.8vw",*/}
                                        {/*                },*/}
                                        {/*            }}/>*/}
                                        {/*            : (mode != "answer" && !((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) && (remark && forceUpdate && remark[questionKey].dontKnow) ?*/}
                                        {/*                <Radio disabled={true} checked={false} sx={{*/}
                                        {/*                    '& .MuiSvgIcon-root': {*/}
                                        {/*                        fontSize: "2.8vw",*/}
                                        {/*                    },*/}
                                        {/*                }}/>*/}
                                        {/*                :*/}
                                        {/*                <>*/}
                                        {/*                    <Radio checked={data == userSingleAnswer} sx={{*/}
                                        {/*                        '& .MuiSvgIcon-root': {*/}
                                        {/*                            fontSize: "2.8vw",*/}
                                        {/*                        },*/}
                                        {/*                    }}/>*/}
                                        {/*                    <div className="multipleChoiceRadioDisable"></div>*/}
                                        {/*                </>*/}
                                        {/*    }*/}
                                        {/*    label=""*/}
                                        {/*/>*/}
                                        <div className="optionTextContainer">
                                            <p className="optionText">
                                            <span
                                                className="optionAnswer2">
                                                {numbering[i]}. {data}
                                            </span>
                                            </p>
                                        </div>
                                        {/*{*/}
                                        {/*    questionDetail.answer == data*/}
                                        {/*    &&*/}
                                        {/*    (mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise")*/}
                                        {/*        &&*/}
                                        {/*        answeredFlagArray[currentQuestionNum - 1]))*/}
                                        {/*        ?*/}
                                        {/*    <img className="multipleChoiceAnswerImg" src={correctSVG} alt="correct"/>*/}
                                        {/*    :*/}
                                        {/*        questionDetail.answer != data &&*/}
                                        {/*        (mode == "answer"*/}
                                        {/*            ||*/}
                                        {/*            (*/}
                                        {/*                (type === "questionBank" || type === "answerReviewExercise") &&*/}
                                        {/*                answeredFlagArray[currentQuestionNum - 1])*/}
                                        {/*        ) &&*/}
                                        {/*        data == userSingleAnswer &&*/}
                                        {/*    <img className="multipleChoiceAnswerImg" src={incorrectSVG} alt="incorrect"/>*/}
                                        {/*}*/}
                                    </div>
                                ))}
                        </RadioGroup>
                    </Grid>
                    {/*{NUMB != 2  // in demo mode, no need to show the picture*/}
                    {/*    &&*/}
                    {/*    <Grid item xs={12} sm={5}>*/}
                    {/*        {questionDetail && <div className="questionImageExampleContainer"*/}
                    {/*                                style={{*/}
                    {/*                                    backgroundImage: "url(" + questionDetail.image + ")",*/}
                    {/*                                    ...(is_m) && {height: "30vh"}, // in mobile this line need to be added .... wtf*/}
                    {/*                                }}/>}*/}
                    {/*    </Grid>}*/}

                </Grid>
            </div>
            {/*{*/}
            {/*    (mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) &&*/}
            {/*    <>*/}
            {/*        {NUMB == 2 && is_m ?*/}
            {/*            <img className="answerContainerImg"*/}
            {/*                 src={require('../../assets/exercise/answerBanner_emptyblackboard.png')}*/}
            {/*                 alt="answer container"/>*/}
            {/*            :*/}
            {/*            is_m ?*/}
            {/*                <></>*/}
            {/*                :*/}
            {/*                continuousCorrectCount >= 3 && l === "en" && correctRandomIndex === 1 ?*/}
            {/*                    <img className="answerContainerImg"*/}
            {/*                         src={require('../../assets/exercise/answerBannerCorrect1.png')}*/}
            {/*                         alt="answer container"/>*/}
            {/*                    : continuousCorrectCount >= 3 && l === "en" && correctRandomIndex === 2 ?*/}
            {/*                        <img className="answerContainerImg"*/}
            {/*                             src={require('../../assets/exercise/answerBannerCorrect2.png')}*/}
            {/*                             alt="answer container"/>*/}
            {/*                        : continuousWrongCount >= 3 && l === "en" && wrongRandomIndex === 1 ?*/}
            {/*                            <img className="answerContainerImg"*/}
            {/*                                 src={require('../../assets/exercise/answerBannerWrong1.png')}*/}
            {/*                                 alt="answer container"/>*/}
            {/*                            : continuousWrongCount >= 3 && l === "en" && wrongRandomIndex === 2 ?*/}
            {/*                                <img className="answerContainerImg"*/}
            {/*                                     src={require('../../assets/exercise/answerBannerWrong2.png')}*/}
            {/*                                     alt="answer container"/>*/}
            {/*                                : continuousWrongCount >= 3 && l === "en" && wrongRandomIndex === 3 ?*/}
            {/*                                    <img className="answerContainerImg"*/}
            {/*                                         src={require('../../assets/exercise/answerBannerWrong3.png')}*/}
            {/*                                         alt="answer container"/>*/}
            {/*                                    : continuousCorrectCount >= 3 && l !== "en" && correctRandomIndex === 1 ?*/}
            {/*                                        <img className="answerContainerImg"*/}
            {/*                                             src={require('../../assets/exercise/answerBannerCorrect1ZH-HK.png')}*/}
            {/*                                             alt="answer container"/>*/}
            {/*                                        : continuousCorrectCount >= 3 && l !== "en" && correctRandomIndex === 2 ?*/}
            {/*                                            <img className="answerContainerImg"*/}
            {/*                                                 src={require('../../assets/exercise/answerBannerCorrect2ZH-HK.png')}*/}
            {/*                                                 alt="answer container"/>*/}
            {/*                                            : continuousWrongCount >= 3 && l !== "en" && wrongRandomIndex === 1 ?*/}
            {/*                                                <img className="answerContainerImg"*/}
            {/*                                                     src={require('../../assets/exercise/answerBannerWrong1ZH-HK.png')}*/}
            {/*                                                     alt="answer container"/>*/}
            {/*                                                : continuousWrongCount >= 3 && l !== "en" && wrongRandomIndex === 2 ?*/}
            {/*                                                    <img className="answerContainerImg"*/}
            {/*                                                         src={require('../../assets/exercise/answerBannerWrong2ZH-HK.png')}*/}
            {/*                                                         alt="answer container"/>*/}
            {/*                                                    : continuousWrongCount >= 3 && l !== "en" && wrongRandomIndex === 3 ?*/}
            {/*                                                        <img className="answerContainerImg"*/}
            {/*                                                             src={require('../../assets/exercise/answerBannerWrong3ZH-HK.png')}*/}
            {/*                                                             alt="answer container"/>*/}
            {/*                                                        :*/}
            {/*                                                        <img className="answerContainerImg"*/}
            {/*                                                             src={require('../../assets/exercise/answerBanner.png')}*/}
            {/*                                                             alt="answer container"/>*/}
            {/*        }*/}


            {/*             <Grid container spacing={0}>*/}
            {/*                <Grid item xs={10}>*/}
            {/*                    <div className="answerElaborationContainer">*/}
            {/*                        <p className="answerElaborationTitle">{t("CORRECT_ANSWER")}:*/}
            {/*                            <span*/}
            {/*                                className="answerElaborationAnswerTextOption">{numbering[answerIndex]} </span>*/}
            {/*                            <span*/}
            {/*                                className="answerElaborationAnswerTextUnderline">{questionDetail && questionDetail.answer}</span>*/}
            {/*                        </p>*/}
            {/*                        {NUMB != 2 &&*/}
            {/*                            <img className="correctElaborationImg" src={correctElaborationSVG}*/}
            {/*                                 alt="correct"/>}*/}
            {/*                        {NUMB != 2 &&*/}
            {/*                            <p className="answerElaborationDetail">{t("EXPLANATION")}:<br/>{questionDetail && questionDetail.elaboration}*/}
            {/*                            </p>}*/}
            {/*                    </div>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}


            {/*    </>*/}
            {/*}*/}
            {
                // (
                //     (confirmBtn &&
                //         mode != "answer"
                //     ) ||
                //     (
                //         (
                //             value !== ""
                //             ||
                //             (remark && remark[questionKey].dontKnow)
                //         )
                //         &&
                //         type !== "calibrationTest"
                //     )
                // ) &&
                // (
                //     !(progress >= currentQuestionNum - 1)
                //     ||
                //     correctAnsweredFlagArray[questionKey] === "notAnswered"
                // ) &&
                mode == "answer"
                &&
                <>
                    <div onClick={() => {
                        if (type !== "calibrationTest") {


                            if (highlight) {
                                setHighlight(false)
                            }

                            nextQuestion()
                            // handleConfirmAnswer();
                        }
                    }} className="questionCenterButtonContainer">
                        <div>
                            <ConfirmButton2/>
                        </div>
                    </div>

                </>


            }


        </>
    );
}

export default MultipleChoice;
