import React from 'react';
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";

import '../../App.css';
import './_styles.css';

import { BackToHomeButton } from './_components';

function FinishExercise({
    finsihExerciseModalVisibility,
    setFinishExerciseModalVisibility,
}) {
    let navigate = useNavigate();
    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={finsihExerciseModalVisibility}
                className="logoutModalContainer"
                style={{ overlay: {backgroundColor:'rgba(0, 0, 0, 0.3)', backdropFilter: "blur(6px)", WebkitBackdropFilter: "blur(6px)", zIndex: 1000000 }}}
            >
                <div className="logoutBackgroundImage">
                    <p className="logoutConfirmText">You have finished this exercises.</p>
                    <img className="confirmLeaveContent" src={require('../../assets/exercise/confirmLeaveContent.png')}
                        alt="logout confirm" />
                    <div className="logoutButtonContainer">
                        <div className="logoutDisplayInlineBlock" onClick={() => {
                            navigate('/');
                        }}><BackToHomeButton /></div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default FinishExercise;
