import React, {createRef, useEffect, useRef, useState} from 'react';
import Grid from "@mui/material/Grid";
import {db, DEBUGGGGGG, lgg} from "../../firebase";
import {collection, doc, getDoc, getDocs, query, updateDoc, where} from 'firebase/firestore';
import useCountDown from 'react-countdown-hook';

import '../../App.css';
import './_styles.css';

import Menu from '../../components/menu/Menu';
import Progress from '../../pages/exercise/Progress';
import CalibrationTestResultProgress from '../../pages/exercise/CalibrationTestResultProgress';
import MultipleChoice from '../../pages/exercise/MultipleChoice';
import FillInTheBlank from '../../pages/exercise/FillInTheBlank';

import arrowLeftSVG from '../../assets/exercise/arrowLeft.svg';
import arrowRightSVG from '../../assets/exercise/arrowRight.svg';
import {useNavigate} from 'react-router-dom';
import {getUser} from '../../localStorage/User';
import {ConfirmButton, ViewResultSummaryBtn} from './_components';

import Pause from '../../pages/exercise/Pause';
import TimesUp from '../../pages/exercise/TimesUp';
import ForceGoBack from '../../pages/exercise/ForceGoBack';
import LoadingOverlay from "react-loading-overlay";
import {useMediaQuery} from "react-responsive";


function CalibrationTest() {

    const [timerStartFlag, setTimerStartFlag] = useState(false);

    // to show time's up modal when timer is 0
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const showTimesUpModal = async () => {
        if (remainingTime == 0 && timerStartFlag && mode == 'question') {
            await delay(1000);
            setTimesUpModalVisibility(true);
        }
        await delay(1000); // run function interval
    }

    useEffect(() => {
        showTimesUpModal();
    })
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const [pauseModalVisibility, setPauseModalVisibility] = useState(false);
    const [timesUpModalVisibility, setTimesUpModalVisibility] = useState(false);
    const [forceGoBackModalVisibility, setForceGoBackModalVisibility] = useState(false);

    const initialTime = 1200 * 1000;
    const [remainingTime, {start, pause, resume}] = useCountDown(initialTime, 1000);

    useEffect(() => {
        remainingTime && remainingTime == initialTime && setTimerStartFlag(true);
    }, [remainingTime])

    const user = getUser();
    const [exerciseID, setExerciseID] = useState();
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    let navigate = useNavigate();
    useEffect(() => {
        (user.id === "" || user.id === null || user.id === undefined) && navigate("/login"); // to redirect to home page if user has logged-in
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setTimeout(() => {
            loading && getExercise()
        }, 1000);
    })

    const [elRefs, setElRefs] = useState([]);
    const scrollToQuestion = (i) => elRefs[i].current.scrollIntoView({behavior: 'smooth'});
    const [exerciseDetail, setExerciseDetail] = useState();
    const [questionType, setQuestionType] = useState();
    const [questionAnswer, setQuestionAnswer] = useState();
    const [questionTopic, setQuestionTopic] = useState();
    const [userAnswer, setUserAnswer] = useState();
    const [remark, setRemark] = useState();
    const [mode3, setMode3] = useState();
    // console.log(
    //     "_________________________________",
    //     "elRefs", elRefs,
    //     "exerciseDetail", exerciseDetail,
    //     "questionType", questionType,
    //     "questionAnswer", questionAnswer,
    //     "questionTopic", questionTopic,
    //     "userAnswer", userAnswer,
    //     "remark", remark
    // )
    // console.log("qq",exerciseID)
    const getExercise = async () => {

        let tmpQuestion = [];

        const q = query(collection(db, "Exercise"), where("user", "==", user.id), where("type", "==", "calibrationTest"));
        const querySnapshot = await getDocs(q);


        // should choose only one actually
        querySnapshot.forEach((doc) => {

            setLoading(false); // set loading to false when found exercise

            setExerciseID(doc.id);
            setExerciseDetail(doc.data()); // to get the exercise details
            tmpQuestion = doc.data().question;
            setCurrectQuestionID(doc.data().question[0]); // initial the first question

            const arrLength = doc.data().question.length;
            setElRefs((elRefs) => Array(arrLength).fill().map((_, i) => elRefs[i] || createRef()));
            // to initalise the answer and remark array
            setUserAnswer(Array.from({length: arrLength}).fill(""));
            setRemark(Array.from({length: arrLength}).fill({
                bookmark: false,
                dontKnow: false,
            }));



        });

        // to get the question type of the question list
        let tmpArray = [];
        let tmpArrayAnswer = [];
        let tmpArrayTopic = [];
        for (let i = 0; i < tmpQuestion.length; i++) {
            const docRef = doc(db, 'Question', tmpQuestion[i]);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                tmpArray.push(docSnap.data().type);
                tmpArrayAnswer.push(docSnap.data().answer)
                tmpArrayTopic.push(docSnap.data().topic)
            }
        }
        setQuestionType(tmpArray);
        setQuestionAnswer(tmpArrayAnswer);
        setQuestionTopic(tmpArrayTopic);
        setLoading2(false); // set loading to false when found exercise
    }

    const [mode, setMode] = useState("question");
    const [showEmptyMsg, setShowEmptyMsg] = useState(false);


    // to handle answer submission
    const handleSubmitAnswer = async (mode) => {

        // to check if all questions have answered by user
        let emptyAnswerFlag = false;


        let firstEmptyQuestionIndex = 0;
        for (let i = 0; i < exerciseDetail.question.length; i++) {
            if ((userAnswer[i] === '' || userAnswer[i] === undefined) && !remark[i].dontKnow) {
                !emptyAnswerFlag && (firstEmptyQuestionIndex = i);
                emptyAnswerFlag = true;
            }
        }


        if (mode !== "timesUp" && initialTime - remainingTime < 60000 && !DEBUGGGGGG) {
            // if (initialTime - remainingTime < 0) {
            setForceGoBackModalVisibility(true);
            pause();
        } else if (mode !== "timesUp" && (emptyAnswerFlag&&!DEBUGGGGGG)) {  // navigate if user has not answered all questions
            setShowEmptyMsg(true);
            scrollToQuestion(firstEmptyQuestionIndex);
        } else { // to submit the answer


            setLoading2(true);
            const userRef = doc(db, 'Exercise', exerciseID);


            // useless ?
            await updateDoc(userRef, {answer: userAnswer, remark: remark, remainingTime: remainingTime / 1000});


            lgg("start to cal");
            await calAccuracy();



            lgg("start to update finishColibrationTestFlag ");
            // set flag
            const userRefUser = doc(db, 'User', user.id);
            await updateDoc(userRefUser, {finishColibrationTestFlag: true});


            pause();
            setMode("answer");
            setLoading2(false);
        }
    }

    // to handel display calibration test answer
    const [currentQuestionNum, setCurrectQuestionNum] = useState(1);
    const [currentQuestionID, setCurrectQuestionID] = useState();
    const nextQuestion = () => {
        setCurrectQuestionID(exerciseDetail.question[currentQuestionNum]);
        setCurrectQuestionNum(currentQuestionNum + 1);
    }
    const prevQuestion = () => {
        setCurrectQuestionID(exerciseDetail.question[currentQuestionNum - 2]);
        setCurrectQuestionNum(currentQuestionNum - 1);
    }

    const toQuestion = (index) => {
        setCurrectQuestionID(exerciseDetail.question[index]);
        setCurrectQuestionNum(index + 1);
    }

    const [currentScrollQuestionNum, setScrollQuestionNum] = useState(0);
    const listInnerRef = useRef();
    // to set the on scroll function for process scroll detection
    const onScroll = () => {
        const {innerWidth: width, innerHeight: height} = window;
        if (listInnerRef.current && mode === "question") {
            const {scrollTop} = listInnerRef.current;
            for (let i = 0; i < exerciseDetail.question.length; i++) {
                const {offsetTop} = elRefs[i].current;
                const offsetTopCurrent = offsetTop;
                let offsetTopNext = 0;
                if (i != exerciseDetail.question.length - 1) {
                    const {offsetTop} = elRefs[i + 1].current;
                    offsetTopNext = offsetTop;
                }
                if (scrollTop >= offsetTopCurrent - 0.25 * height && scrollTop < offsetTopNext - 0.25 * height) {
                    setScrollQuestionNum(i);
                }
            }
        }
    };


    // to handle the accuracy when submit answer
    const [topicTotal, setTopicTotal] = useState();
    const [topicCorrectTotal, setTopicCorrectTotal] = useState();


    const calAccuracy = async() => {

        let pastTenseTotal = 0;
        let presentContinuousTenseTotal = 0;
        let prepositionTotal = 0;
        let questionWordTotal = 0;
        let pastTenseCorrectTotal = 0;
        let presentContinuousTenseCorrectTotal = 0;
        let prepositionCorrectTotal = 0;
        let questionWordCorrectTotal = 0;
        let dict11 = { };
        for (let i = 0; i < exerciseDetail.question.length; i++) {


            if (! (questionTopic[i] in dict11)){
                dict11[questionTopic[i]] = {"total": 0, "correct": 0}
            }

            dict11[questionTopic[i]]["total"] += 1;
            if (userAnswer[i] === questionAnswer[i]) {
                dict11[questionTopic[i]]["correct"] += 1;
            }


            if (questionTopic[i] === "pastTense") {
                pastTenseTotal += 1;  // total question
                questionAnswer[i] === userAnswer[i] && (pastTenseCorrectTotal += 1);
            }
            if (questionTopic[i] === "presentContinuousTense") {
                presentContinuousTenseTotal += 1;   // total question
                questionAnswer[i] === userAnswer[i] && (presentContinuousTenseCorrectTotal += 1);
            }
            if (questionTopic[i] === "preposition") {
                prepositionTotal += 1; // total question
                questionAnswer[i] === userAnswer[i] && (prepositionCorrectTotal += 1);
            }
            if (questionTopic[i] === "questionWord") {
                questionWordTotal += 1;// total question
                questionAnswer[i] === userAnswer[i] && (questionWordCorrectTotal += 1);
            }
        }
        setMode3(dict11)
        setTopicTotal({
            pastTense: pastTenseTotal,
            presentContinuousTense: presentContinuousTenseTotal,
            preposition: prepositionTotal,
            questionWord: questionWordTotal,

        });
        setTopicCorrectTotal({
            pastTense: pastTenseCorrectTotal,
            presentContinuousTense: presentContinuousTenseCorrectTotal,
            preposition: prepositionCorrectTotal,
            questionWord: questionWordCorrectTotal
        });


        // to store the result into the database       useless?
        const userRefExercise = doc(db, 'Exercise', exerciseID);
        await updateDoc(userRefExercise, {
            accuracy: {
                pastTense: pastTenseCorrectTotal / pastTenseTotal,
                presentContinuousTense: presentContinuousTenseCorrectTotal / presentContinuousTenseTotal,
                preposition: prepositionCorrectTotal / prepositionTotal,
                questionWord: questionWordCorrectTotal / questionWordTotal
            }
        });
        const userRefUser = doc(db, 'User', user.id);



        const userSnapshot = await getDoc(doc(db, 'User', user.id));
        let p56Flag = false;
        if (userSnapshot.data().formm == "p5"||userSnapshot.data().formm == "p6" ) {
            console.log("p5 or p6 pass")
            p56Flag = true

        }
        ;


        lgg("recording 1 of 3 pathway")
        // check

        // record   either
        // 1. dont do anything (p4)
        // 2. set >40 (p5 path1)
        // 3. set <40 (p5 path2)

        let topicLevel = {
            pastTense: 0,
            pastTensePresentTense: 0,
            preposition: 0,
            presentContinuousTense: 0,
            presentTensePresentContinuousTense: 0,
            questionWord: 0,
            PACT_1:0,
            PPT_1:0,
            BIxTI_1:0,
            BIxGER_1:0,
            ART_1:0,
            COM_1:0,
            CONJ_1:0
        }
        if (p56Flag

        ) {

            lgg("p56Flag pass")
            let mode33=dict11
            console.log("mode3", mode33);
            let p1031=(mode33["PACT_1"].correct + mode33["PPT_1"].correct)/(mode33["PACT_1"].total + mode33["PPT_1"].total)
            console.log("p1031", p1031);
            let below40=(p1031<=0.4)


            // var obj = {a: 1, b: 2, c: 2},
            //     keys = Object.keys(obj),
            //     largest = Math.max.apply(null, keys.map(x => obj[x]))
            // result = keys.reduce((result, key) => { if (obj[key] === largest){ result.push(key); } return result; }, []);


            let qq75={PACT_1:{correct:3},PACT_2:{correct:1},CONJ_1:{correct:66}}
            let obj=mode33
            // find lowest non tense topic
            // let tt3=Object.keys(obj).reduce((a, b) => obj[a].correct < obj[b].correct ? a : b);
            let tt3=["BIxTI_1","BIxGER_1","ART_1","COM_1","CONJ_1"].reduce((a, b) => obj[a].correct < obj[b].correct ? a : b);
            lgg("lowest non tense topic", tt3)


            let tt4=["BIxTI_1","BIxGER_1","ART_1","COM_1","CONJ_1"].reduce((a, b) => obj[a].correct > obj[b].correct ? a : b);
            lgg("highet non tense topic", tt4)

            //


            if (below40) {

                lgg("below40 pass")


                lgg("recording ")
                const userRef = doc(db, 'User', user.id);
                await updateDoc(userRef, {formmp5_path: "below40"});






                // set topic
                let firstActiveTenseTopic =
                    "presentContinuousTense"

                let firstActiveNonTenseTopic =
                    tt3

                let secondActiveTenseTopic =
                    "pastTense"

                let secondActiveNonTenseTopic =
                    tt4






                // set level
                let firstActiveTenseTopicLevel =
                    presentContinuousTenseCorrectTotal / presentContinuousTenseTotal == 1 ?
                        '3'
                        :
                        presentContinuousTenseCorrectTotal / presentContinuousTenseTotal >= 0.8 ?
                            '2'
                            :
                            '1'


                let p412=mode33[tt3].correct /mode33[tt3].total
                let firstActiveNonTenseTopicLevel =

                    p412 == 1 ?
                        '3'
                        :
                        p412 >= 0.8 ?
                            '2'
                            :
                            '1'

                let secondActiveTenseTopicLevel =
                    '1'

                let secondActiveNonTenseTopicLevel =
                    '1'


                topicLevel[firstActiveTenseTopic] = firstActiveTenseTopicLevel
                topicLevel[firstActiveNonTenseTopic] = firstActiveNonTenseTopicLevel
                topicLevel[secondActiveTenseTopic] = secondActiveTenseTopicLevel
                topicLevel[secondActiveNonTenseTopic] = secondActiveNonTenseTopicLevel

                await updateDoc(userRefUser, {
                    activeTenseTopic: firstActiveTenseTopic,
                    activeNonTenseTopic: firstActiveNonTenseTopic,
                    activeTenseTopicLevel: firstActiveTenseTopicLevel,
                    activeNonTenseTopicLevel: firstActiveNonTenseTopicLevel,
                    topicLevel: topicLevel
                });

            }else if (

                !below40
            )

            {

                let p41=mode33["PACT_1"].correct /mode33["PACT_1"].total
                let p411=mode33[tt3].correct /mode33[tt3].total
                lgg("below40 fail")

                lgg("recording ")
                const userRef = doc(db, 'User', user.id);
                await updateDoc(userRef, {formmp5_path: "!below40"});
                // set topic


                let firstActiveTenseTopic =
                    "PACT_1"

                let firstActiveNonTenseTopic =
                    tt3

                let secondActiveTenseTopic =
                    "PPT_1"

                let secondActiveNonTenseTopic =
                    tt4



                // set level
                let firstActiveTenseTopicLevel =
                    p41 == 1 ?
                        '3'
                        :
                        p41 >= 0.8 ?
                            '2'
                            :
                            '1'

                let firstActiveNonTenseTopicLevel =
                    p411 == 1 ?
                        '3'
                        :
                        p411 >= 0.8 ?
                            '2'
                            :
                            '1'

                let secondActiveTenseTopicLevel =
                    '1'

                let secondActiveNonTenseTopicLevel =
                    '1'


                topicLevel[firstActiveTenseTopic] = firstActiveTenseTopicLevel
                topicLevel[firstActiveNonTenseTopic] = firstActiveNonTenseTopicLevel
                topicLevel[secondActiveTenseTopic] = secondActiveTenseTopicLevel
                topicLevel[secondActiveNonTenseTopic] = secondActiveNonTenseTopicLevel

                await updateDoc(userRefUser, {
                    activeTenseTopic: firstActiveTenseTopic,
                    activeNonTenseTopic: firstActiveNonTenseTopic,
                    activeTenseTopicLevel: firstActiveTenseTopicLevel,
                    activeNonTenseTopicLevel: firstActiveNonTenseTopicLevel,
                    topicLevel: topicLevel
                });

            }else{
                lgg("error")
            }

        }




        else if (!p56Flag){


            // set topic
            let firstActiveTenseTopic = pastTenseCorrectTotal / pastTenseTotal >
            presentContinuousTenseCorrectTotal / presentContinuousTenseTotal ?
                "presentContinuousTense" : "pastTense";

            let firstActiveNonTenseTopic = prepositionCorrectTotal / prepositionTotal >
            questionWordCorrectTotal / questionWordTotal ?
                "questionWord" : "preposition";

            let secondActiveTenseTopic = pastTenseCorrectTotal / pastTenseTotal >
            presentContinuousTenseCorrectTotal / presentContinuousTenseTotal ?
                "pastTense" : "presentContinuousTense";

            let secondActiveNonTenseTopic = prepositionCorrectTotal / prepositionTotal >
            questionWordCorrectTotal / questionWordTotal ?
                "preposition" : "questionWord";



            // set level
            let firstActiveTenseTopicLevel =
                firstActiveTenseTopic == "pastTense" && pastTenseCorrectTotal / pastTenseTotal == 1 ?
                    '3'
                    :
                    firstActiveTenseTopic == "pastTense" && pastTenseCorrectTotal / pastTenseTotal >= 0.8 ?
                        '2'
                        :
                        firstActiveTenseTopic == "pastTense" && pastTenseCorrectTotal / pastTenseTotal >= 0.6 ?
                            '1'
                            :
                            firstActiveTenseTopic == "presentContinuousTense" && presentContinuousTenseCorrectTotal / presentContinuousTenseTotal == 1 ?
                                '3'
                                :
                                firstActiveTenseTopic == "presentContinuousTense" && presentContinuousTenseCorrectTotal / presentContinuousTenseTotal >= 0.8 ?
                                    '2'
                                    :
                                    '1'

            let firstActiveNonTenseTopicLevel =
                firstActiveNonTenseTopic == "preposition" && prepositionCorrectTotal / prepositionTotal >= 0.8 ?
                    '2'
                    :
                    firstActiveNonTenseTopic == "preposition" ?
                        '1'
                        :
                        firstActiveNonTenseTopic == "questionWord" && questionWordCorrectTotal / questionWordTotal >= 0.8 ?
                            '2'
                            :
                            '1'

            let secondActiveTenseTopicLevel =
                secondActiveTenseTopic == "pastTense" && pastTenseCorrectTotal / pastTenseTotal == 1 ?
                    '3'
                    :
                    secondActiveTenseTopic == "pastTense" && pastTenseCorrectTotal / pastTenseTotal >= 0.8 ?
                        '2'
                        :
                        secondActiveTenseTopic == "pastTense" && pastTenseCorrectTotal / pastTenseTotal >= 0.6 ?
                            '1'
                            :
                            secondActiveTenseTopic == "presentContinuousTense" && presentContinuousTenseCorrectTotal / presentContinuousTenseTotal == 1 ?
                                '3'
                                :
                                secondActiveTenseTopic == "presentContinuousTense" && presentContinuousTenseCorrectTotal / presentContinuousTenseTotal >= 0.8 ?
                                    '2'
                                    :
                                    '1'

            let secondActiveNonTenseTopicLevel =
                secondActiveNonTenseTopic == "preposition" && prepositionCorrectTotal / prepositionTotal >= 0.8 ?
                    '2'
                    :
                    secondActiveNonTenseTopic == "preposition" ?
                        '1'
                        :
                        secondActiveNonTenseTopic == "questionWord" && questionWordCorrectTotal / questionWordTotal >= 0.8 ?
                            '2'
                            :
                            '1'


            topicLevel[firstActiveTenseTopic] = firstActiveTenseTopicLevel
            topicLevel[firstActiveNonTenseTopic] = firstActiveNonTenseTopicLevel
            topicLevel[secondActiveTenseTopic] = secondActiveTenseTopicLevel
            topicLevel[secondActiveNonTenseTopic] = secondActiveNonTenseTopicLevel
            await updateDoc(userRefUser, {
                activeTenseTopic: firstActiveTenseTopic,
                activeNonTenseTopic: firstActiveNonTenseTopic,
                activeTenseTopicLevel: firstActiveTenseTopicLevel,
                activeNonTenseTopicLevel: firstActiveNonTenseTopicLevel,
                topicLevel: topicLevel
            });

        }else{
            lgg("error")
        }


    }

    function gggg6() {
        return async () => {


            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            let p5 = false;
            if (userSnapshot.data().formm == "p5") {
                console.log("true82")
                p5 = true

            }
            ;


            if (p5) {
                // navigate('/calibrationTestVideo')
                navigate('/calibrationTestResult', {
                    state: {
                        topicTotal: topicTotal,
                        topicCorrectTotal: topicCorrectTotal,
                        dict32: mode3
                    }
                })
            } else {
                navigate('/calibrationTestResult', {
                    state: {
                        topicTotal: topicTotal,
                        topicCorrectTotal: topicCorrectTotal,
                        dict32: mode3
                    }
                })
            }
        };
    }

    return <>
        <Menu/>
        <LoadingOverlay
            active={loading2}
            // spinner={<BounceLoader />}
            spinner={true}
            // text="Loading your content..."
        ></LoadingOverlay>
        <div className="exerciseBackgroundImage" onScroll={() => onScroll()} ref={listInnerRef}>
            {!loading2 &&
                <Grid container spacing={0}>
                    <Grid item xs={is_m?1:3}>
                        <div className="calibrationTestSideMenuContainer">
                            {exerciseDetail &&
                            mode == "question" ?
                                <Progress setPauseModalVisibility={setPauseModalVisibility}
                                          pauseModalVisibility={pauseModalVisibility}
                                          remainingTime={remainingTime} start={start} pause={pause} resume={resume}
                                          handleSubmitAnswer={handleSubmitAnswer}
                                          scrollToQuestion={scrollToQuestion} type={"calibrationTest"}
                                          questionTotal={exerciseDetail.question.length} mode={mode}
                                          currentQuestionNum={mode == 'question' ? currentScrollQuestionNum : currentQuestionNum}
                                          userAnswer={userAnswer} remark={remark}/>
                                :
                                <CalibrationTestResultProgress topicTotal={topicTotal}
                                                               topicCorrectTotal={topicCorrectTotal}
                                                               toQuestion={toQuestion}
                                                               questionAnswer={questionAnswer}
                                                               questionTotal={exerciseDetail && exerciseDetail.question.length}
                                                               userAnswer={userAnswer} remark={remark}
                                qq321={mode3}/>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={is_m?10:9}>
                        <div className="calibrationTestQuestionContainer">
                            {mode == "question" ?
                                <>
                                    {exerciseDetail && userAnswer && exerciseDetail.question.map((data, i) => (
                                        <div key={i} ref={elRefs[i]}
                                             className="claibrationTestQuestionInnerContainer">
                                            {questionType && questionType[i] === "multipleChoice" &&
                                                <MultipleChoice showEmptyMsg={showEmptyMsg} type={"calibrationTest"}
                                                                questionKey={i} confirmBtn={false}
                                                                currentQuestionNum={i + 1} questionID={data}
                                                                userAnswer={userAnswer}
                                                                setUserAnswer={setUserAnswer} remark={remark}
                                                                setRemark={setRemark}/>}
                                            {questionType && questionType[i] === "fillInTheBlank" &&
                                                <FillInTheBlank showEmptyMsg={showEmptyMsg} type={"calibrationTest"}
                                                                questionKey={i} confirmBtn={false}
                                                                currentQuestionNum={i + 1} questionID={data}
                                                                userAnswer={userAnswer}
                                                                setUserAnswer={setUserAnswer} remark={remark}
                                                                setRemark={setRemark}/>}
                                        </div>
                                    ))}
                                    <div onClick={() => {
                                        handleSubmitAnswer()
                                    }} className="claibrationTestCenterButtonContainer">
                                        <ConfirmButton/>
                                    </div>
                                    <div className="claibrationTestBottomMargin"></div>
                                </>
                                : mode == "answer" &&
                                <>
                                    <div className="claibrationTestBottomMargin"></div>
                                    <Grid justifyContent="center"
                                          alignItems="center"
                                          justifyContent="center"
                                          style={{minHeight: 'calc(100vh - 10vw - 60px'}}
                                          container spacing={0}>
                                        <Grid align="center" item xs={1}>
                                            {currentQuestionNum !== 1 &&
                                                <img onClick={() => prevQuestion()} className="questionArrowAnswer"
                                                     src={arrowLeftSVG} alt="left arrow"/>
                                            }
                                        </Grid>
                                        <Grid align="center" item xs={10}>
                                            <div onClick={gggg6()}>
                                                <ViewResultSummaryBtn/>
                                            </div>
                                            {exerciseDetail && questionType && questionType[currentQuestionNum - 1] === "multipleChoice" &&
                                                <MultipleChoice currentQuestionNum={currentQuestionNum}
                                                                questionID={currentQuestionID} mode={mode}
                                                                userAnswer={userAnswer} type={"calibrationTest"}/>}
                                            {exerciseDetail && questionType && questionType[currentQuestionNum - 1] === "fillInTheBlank" &&
                                                <FillInTheBlank currentQuestionNum={currentQuestionNum}
                                                                questionID={currentQuestionID} mode={mode}
                                                                userAnswer={userAnswer} type={"calibrationTest"}/>}
                                        </Grid>
                                        <Grid align="center" item xs={1}>
                                            {exerciseDetail && currentQuestionNum !== exerciseDetail.question.length &&
                                                <img onClick={() => nextQuestion()} className="questionArrowAnswer"
                                                     src={arrowRightSVG} alt="right arrow"/>
                                            }
                                        </Grid>
                                    </Grid>
                                    <div className="claibrationTestBottomMargin"></div>
                                </>
                            }
                        </div>
                    </Grid>
                </Grid>
            }
        </div>
        <Pause pauseModalVisibility={pauseModalVisibility} setPauseModalVisibility={setPauseModalVisibility}
               remainingTime={remainingTime} resume={resume}/>
        <ForceGoBack forceGoBackModalVisibility={forceGoBackModalVisibility}
                     setForceGoBackModalVisibility={setForceGoBackModalVisibility} remainingTime={remainingTime}
                     resume={resume}/>
        <TimesUp handleSubmitAnswer={handleSubmitAnswer} timesUpModalVisibility={timesUpModalVisibility}
                 setTimesUpModalVisibility={setTimesUpModalVisibility}/>
    </>;
}

export default CalibrationTest;
