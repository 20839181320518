import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import LearningVideoEmbed from './LearningVideoEmbed';
import { LearningVideoPlayerReturnButton } from './_components';

function LearningVideoPlayer() {
    const [topic, setTopic] = useState("");
    const [embedId, setEmbedId] = useState("");

    const location = useLocation();
    useEffect(() => {
        location.state && setEmbedId(location.state.embedId);
        location.state && setTopic(location.state.topic);
    })

    let navigate = useNavigate();

    return (
        <>
            <Menu />
            <SideMenu />
            <div className="responsiveContainer" style={{ marginTop: "3vw" }}>
                <Grid container spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={8}><p className="learningVideoPlayerTopic">{topic}</p></Grid>
                    <Grid item xs={1}>
                        <div className="learningVideoPlayerReturnDisplayInlineBlock" onClick={() => { navigate('/learningVideo') }}>
                            <LearningVideoPlayerReturnButton variant="contained" className="sendButton"></LearningVideoPlayerReturnButton>
                        </div>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                        <div className="learningVideoPlayerContainer">
                            <LearningVideoEmbed embedId={embedId} />
                        </div>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </div>
        </>
    );
}

export default LearningVideoPlayer;
