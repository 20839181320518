import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import RadioGroup from '@mui/material/RadioGroup';
import {db, NUMB} from "../../firebase";
import {addDoc, collection, doc, getDoc, getDocs, query, where} from 'firebase/firestore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import '../../App.css';
import './_styles.css';

import {ConfirmButton, SimilarQuestionButton} from './_components';
import Remark from './Remark';
import correctSVG from '../../assets/general/correct.svg';
import incorrectSVG from '../../assets/general/incorrect.svg';
import correctElaborationSVG from '../../assets/exercise/correctElaboration.svg';
import starYellowSVG from '../../assets/exercise/starYellow.svg';
import starGreySVG from '../../assets/exercise/starGrey.svg';
import bookmarkedSVG from '../../assets/exercise/bookmarked.svg';

import '../../translation'
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {getLanguage} from '../../localStorage/Language';

import {getUser} from '../../localStorage/User';
import {useMediaQuery} from "react-responsive";
import {Box} from "@mui/material";

function MultipleChoice({
                            updateSubmitedQuestion,
                            answeredFlagArray,
                            setCorrectAnsweredFlagArray,
                            correctAnsweredFlagArray,
                            setAnsweredFlagArray,
                            exerciseDetail,
                            handleSubmitAnswer,
                            setProgress,
                            progress,
                            type,
                            showEmptyMsg,
                            questionKey,
                            questionID,
                            currentQuestionNum,
                            confirmBtn = true,
                            userAnswer,
                            setUserAnswer,
                            setMode,
                            mode = "question",
                            remark,
                            setRemark,
                            pause,
                            continuousCorrectCount,
                            continuousWrongCount,
                            correctRandomIndex,
                            wrongRandomIndex,
                            setSaveDraftFlag
                        }) {

    const {t} = useTranslation();
    const l = getLanguage();
    const user = getUser();
    let navigate = useNavigate();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const numbering = ["A", "B", "C", "D"];


    const [value, setValue] = useState("")
    const [option, setOption] = useState();

    useEffect(() => {
        questionID && getQuestion(questionID);
        if (type !== "calibrationTest" && type !== undefined) {
            setValue(exerciseDetail.answer ? exerciseDetail.answer[questionKey] : "");
            if (type === "todaysPractices") {
                progress >= currentQuestionNum - 1 ? (setMode("answer")) : (setMode("question"));
            }
            (type === "questionBank" || type === "answerReviewExercise") && setMode("question");
        }
    }, [questionID])

    useEffect(() => {
        if (type === "todaysPractices") {
            progress >= currentQuestionNum - 1 ? (setMode("answer")) : (setMode("question"));
        }
    }, [progress])

    const [questionDetail, setQuestionDetail] = useState();
    const [answerIndex, setAnswerIndex] = useState();
    const getQuestion = async (id) => {

        const questionSnapshot = await getDoc(doc(db, 'Question', id));
        if (questionSnapshot.exists()) {

            // random question by question id
            let randomIndex = [];
            for (let i = 0; i < questionSnapshot.data().option.length; i++) {
                randomIndex.push(questionID[i]);
            }
            let tmpOption = questionSnapshot.data().option;
            let sortedOption = [];
            var len = randomIndex.length;
            var indices = new Array(len);
            for (var i = 0; i < len; ++i) indices[i] = i;
            indices.sort(function (a, b) {
                return randomIndex[a] < randomIndex[b] ? -1 : randomIndex[a] > randomIndex[b] ? 1 : 0;
            });

            for (let i = 0; i < questionSnapshot.data().option.length; i++) {
                sortedOption.push(tmpOption[indices[i]]);
            }
            setOption(sortedOption);

            setQuestionDetail(questionSnapshot.data());
            for (let i = 0; i < questionSnapshot.data().option.length; i++) {
                sortedOption[i] == questionSnapshot.data().answer && setAnswerIndex(i); // to set the answer index
            }
        }
    }
    let iiiiiiiii=questionDetail?.imagecloudinary?questionDetail.imagecloudinary:questionDetail?.image
    const userSingleAnswer = userAnswer[currentQuestionNum - 1];

    const handleSelectAnswer = (answer) => {
        if (
            (type == "calibrationTest" && mode == "question") ||
            (!(progress >= currentQuestionNum - 1) ||
                type === "questionBank")
        ) {
            let tmp = userAnswer;
            tmp[questionKey] = answer;
            setUserAnswer(tmp);
            setValue(userAnswer[questionKey]);
        }
        setSaveDraftFlag(false);
    }

    const [forceUpdate, setForceUpdate] = useState(1); // to force update DOM when updated remark

    const handleConfirmAnswer = () => {
        updateSubmitedQuestion(questionID);
        let tmp = answeredFlagArray;
        tmp[questionKey] = true;
        setAnsweredFlagArray(tmp);
        let tmpCorrect = correctAnsweredFlagArray;
        tmpCorrect[questionKey] = (questionDetail.answer == value) ? "correct" : "incorrect";
        setCorrectAnsweredFlagArray(tmpCorrect);
        setProgress(progress + 1);
        setMode("answer");
        handleSubmitAnswer("default", questionDetail.answer == value);
    }

    const toQuestionBank = async () => {
        // to generate question bank practice
        const qQuestion = query(collection(db, "Question"), where("topic", "==", questionDetail.topic), where("level", "==", (questionDetail.level).toString()));
        const querySnapshotQuestion = await getDocs(qQuestion);
        let tmpQuestionArray = []
        querySnapshotQuestion.forEach((doc) => {
            tmpQuestionArray.push(doc.id);
        });
        const tmpQuestionBankExercise = {
            date: new Date(),
            type: "questionBank",
            user: user.id,
            question: tmpQuestionArray,
            countUpTime: 0,
        };
        const docRefQuestionBankExercise = await addDoc(collection(db, "Exercise"), tmpQuestionBankExercise);
        navigate("/practice", {state: {exercise: tmpQuestionBankExercise, exerciseID: docRefQuestionBankExercise.id}});
        window.location.reload(false);
    }

    return (
        <>
            {(type === "answerReviewExercise" && (mode == "answer" || answeredFlagArray[currentQuestionNum - 1])) &&
                <div className='similarQuestionContainer'>
                    <div onClick={toQuestionBank}>
                        <SimilarQuestionButton />
                    </div>
                </div>
            }
            <div className="multipleChoiceContainer">
                <div className="multipleChoiceTopContainer">
                    <div>
                        <p className="multipleChoiceQuestionNum">
                            {l === "en" ?
                                "Question " + currentQuestionNum
                                :
                                "第 " + currentQuestionNum + " 題"
                            }
                        </p>
                        {showEmptyMsg && (userAnswer[currentQuestionNum - 1] == '' || userAnswer[currentQuestionNum - 1] == undefined) && !remark[currentQuestionNum - 1].dontKnow &&
                            <p className="multipleChoiceEmptyMsg">You have not attempted this question yet.</p>
                        }
                        {mode == "answer" &&
                            <>
                                {questionDetail &&
                                    <>
                                        <p className="multipleChoiceTopicText">
                                            {
                                                questionDetail.topic === "pastTense" ? "Past Tense" :
                                                    questionDetail.topic === "pastTensePresentTense" ? "Past Tense + Present Tense" :
                                                        questionDetail.topic === "presentContinuousTense" ? "Present Continuous Tense" :
                                                            questionDetail.topic === "presentTensePresentContinuousTense" ? "Present Tense + Present Continuous Tense" :
                                                                questionDetail.topic === "preposition" ? "Prepositions" :
                                                                    questionDetail.topic === "questionWord" && "Question Words"
                                            }
                                        </p>
                                        {NUMB != 2 && <div className="multipleChoiceDifficult">
                                            <p className="multipleChoiceDifficultText">{t("DIFFICULTY")} :</p>
                                            <img className="multipleChoiceDifficultStar" src={starYellowSVG}
                                                 alt="star"/>
                                            <img className="multipleChoiceDifficultStar" src={starGreySVG} alt="star"/>
                                            <img className="multipleChoiceDifficultStar" src={starGreySVG} alt="star"/>
                                        </div>}

                                    </>
                                }
                            </>
                        }
                        <br/>

                        {/*question , not the choice*/}
                        {questionDetail && questionDetail.question.map((data, i) => (
                            <span key={i}>
                                <p className="multipleChoiceQuestion">{data}</p>
                                {i !== questionDetail.question.length - 1 &&
                                    <span className="multipleChoiceQuestionUnderline">______________</span>
                                }
                            </span>
                        ))}
                    </div>
                    {(mode == "question" && !((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) && NUMB != 2 &&
                        <Remark userAnswer={userAnswer} setUserAnswer={setUserAnswer} questionKey={questionKey}
                                remark={remark} setRemark={setRemark}
                                forceUpdate={forceUpdate} setForceUpdate={setForceUpdate} setValue={setValue}/>
                    }
                </div>
                <Grid container spacing={is_m ? 3 : 0}>
                    <Grid item xs={12} sm={7}>
                        <RadioGroup key={forceUpdate} value={value} onChange={(_, value) => {
                            handleSelectAnswer(value)
                        }}>
                            {questionDetail && option && option.map((data, i) => (
                                <div key={i} onClick={() => {
                                    setValue(data);
                                    handleSelectAnswer(data)
                                }} className="optionContainer"
                                     style={{
                                         backgroundColor:
                                             (remark && forceUpdate && remark[questionKey].dontKnow) ? "#E5E5E5" :
                                                 questionDetail.answer == data && (mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) ? '#BDFFB2' :
                                                     questionDetail.answer != data && (mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) && data == userSingleAnswer && '#F8A5A5'
                                     }}
                                >
                                    <FormControlLabel
                                        value={data}
                                        control={
                                            (mode != "answer" && !((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) && (remark && forceUpdate && !remark[questionKey].dontKnow) ?
                                                <Radio sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: "2.8vw",
                                                    },
                                                }}/>
                                                : (mode != "answer" && !((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) && (remark && forceUpdate && remark[questionKey].dontKnow) ?
                                                    <Radio disabled={true} checked={false} sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: "2.8vw",
                                                        },
                                                    }}/>
                                                    :
                                                    <>
                                                        <Radio checked={data == userSingleAnswer} sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: "2.8vw",
                                                            },
                                                        }}/>
                                                        <div className="multipleChoiceRadioDisable"></div>
                                                    </>
                                        }
                                        label=""
                                    />
                                    <div className="optionTextContainer">
                                        <p className="optionText">{numbering[i]}.<span
                                            className="optionAnswer">{data}</span></p>
                                    </div>
                                    {questionDetail.answer == data && (mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) ?
                                        <img className="multipleChoiceAnswerImg" src={correctSVG} alt="correct"/>
                                        : questionDetail.answer != data && (mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) && data == userSingleAnswer &&
                                        <img className="multipleChoiceAnswerImg" src={incorrectSVG} alt="incorrect"/>
                                    }
                                </div>
                            ))}
                        </RadioGroup>
                    </Grid>
                    {NUMB != 2  // in demo mode, no need to show the picture
                        &&
                        <Grid item xs={12} sm={5}>
                            {questionDetail && <div className="questionImageExampleContainer"
                                                    style={{
                                                        backgroundImage: "url(" + iiiiiiiii + ")",
                                                        ...(is_m) && {height: "30vh"}, // in mobile this line need to be added .... wtf
                                                    }}/>}
                        </Grid>}

                </Grid>
            </div>
            {
                (mode == "answer" || ((type === "questionBank" || type === "answerReviewExercise") && answeredFlagArray[currentQuestionNum - 1])) &&
                <>
                    {NUMB == 2 && is_m ?
                        <img className="answerContainerImg"
                             src={require('../../assets/exercise/answerBanner_emptyblackboard.png')}
                             alt="answer container"/>
                        :
                        is_m ?
                            <></>
                            :
                            continuousCorrectCount >= 3 && l === "en" && correctRandomIndex === 1 ?
                                <img className="answerContainerImg"
                                     src={require('../../assets/exercise/answerBannerCorrect1.png')}
                                     alt="answer container"/>
                                : continuousCorrectCount >= 3 && l === "en" && correctRandomIndex === 2 ?
                                    <img className="answerContainerImg"
                                         src={require('../../assets/exercise/answerBannerCorrect2.png')}
                                         alt="answer container"/>
                                    : continuousWrongCount >= 3 && l === "en" && wrongRandomIndex === 1 ?
                                        <img className="answerContainerImg"
                                             src={require('../../assets/exercise/answerBannerWrong1.png')}
                                             alt="answer container"/>
                                        : continuousWrongCount >= 3 && l === "en" && wrongRandomIndex === 2 ?
                                            <img className="answerContainerImg"
                                                 src={require('../../assets/exercise/answerBannerWrong2.png')}
                                                 alt="answer container"/>
                                            : continuousWrongCount >= 3 && l === "en" && wrongRandomIndex === 3 ?
                                                <img className="answerContainerImg"
                                                     src={require('../../assets/exercise/answerBannerWrong3.png')}
                                                     alt="answer container"/>
                                                : continuousCorrectCount >= 3 && l !== "en" && correctRandomIndex === 1 ?
                                                    <img className="answerContainerImg"
                                                         src={require('../../assets/exercise/answerBannerCorrect1ZH-HK.png')}
                                                         alt="answer container"/>
                                                    : continuousCorrectCount >= 3 && l !== "en" && correctRandomIndex === 2 ?
                                                        <img className="answerContainerImg"
                                                             src={require('../../assets/exercise/answerBannerCorrect2ZH-HK.png')}
                                                             alt="answer container"/>
                                                        : continuousWrongCount >= 3 && l !== "en" && wrongRandomIndex === 1 ?
                                                            <img className="answerContainerImg"
                                                                 src={require('../../assets/exercise/answerBannerWrong1ZH-HK.png')}
                                                                 alt="answer container"/>
                                                            : continuousWrongCount >= 3 && l !== "en" && wrongRandomIndex === 2 ?
                                                                <img className="answerContainerImg"
                                                                     src={require('../../assets/exercise/answerBannerWrong2ZH-HK.png')}
                                                                     alt="answer container"/>
                                                                : continuousWrongCount >= 3 && l !== "en" && wrongRandomIndex === 3 ?
                                                                    <img className="answerContainerImg"
                                                                         src={require('../../assets/exercise/answerBannerWrong3ZH-HK.png')}
                                                                         alt="answer container"/>
                                                                    :
                                                                    <img className="answerContainerImg"
                                                                         src={require('../../assets/exercise/answerBanner.png')}
                                                                         alt="answer container"/>
                    }

                    {is_m ?
                        <Grid container spacing={0}>
                            <Grid item xs={12}>

                                <Box style={
                                    {
                                        backgroundImage: `url(${require('../../assets/Group423.png')})`,

                                        // background-size: `auto 100%`;
                                        // height:'300px',
                                        // marginTop: 20,
                                        // backgroundPosition: 'center',

                                        // borderRadius: '50px',
                                        backgroundSize: '100% 100%',
                                        backgroundRepeat: 'no-repeat'
                                    }
                                } sx={{
                                    // position: '',
                                    // width:`100%`, flex :1
                                    // height: ``,
                                    // flex: 1,

                                    // height:200,
                                    // minHeight: '200px',
                                    // width: 370.4,
                                    // height: 171.48,
                                }}>
                                    <div className="answerElaborationContainer">
                                        <p className="answerElaborationTitle">{t("CORRECT_ANSWER")}:
                                            <span
                                                className="answerElaborationAnswerTextOption">{numbering[answerIndex]} </span>
                                            <span
                                                className="answerElaborationAnswerTextUnderline">{questionDetail && questionDetail.answer}</span>
                                        </p>
                                        {NUMB != 2 &&
                                            <img className="correctElaborationImg" src={correctElaborationSVG}
                                                 alt="correct"/>}
                                        {NUMB != 2 &&
                                            <p className="answerElaborationDetail">{t("EXPLANATION")}:<br/>{questionDetail && questionDetail.elaboration}
                                            </p>}
                                    </div>


                                </Box>


                            </Grid>
                        </Grid>
                        : <Grid container spacing={0}>
                            <Grid item xs={10}>
                                <div className="answerElaborationContainer">
                                    <p className="answerElaborationTitle">{t("CORRECT_ANSWER")}:
                                        <span
                                            className="answerElaborationAnswerTextOption">{numbering[answerIndex]} </span>
                                        <span
                                            className="answerElaborationAnswerTextUnderline">{questionDetail && questionDetail.answer}</span>
                                    </p>
                                    {NUMB != 2 &&
                                        <img className="correctElaborationImg" src={correctElaborationSVG}
                                             alt="correct"/>}
                                    {NUMB != 2 &&
                                        <p className="answerElaborationDetail">{t("EXPLANATION")}:<br/>{questionDetail && questionDetail.elaboration}
                                        </p>}
                                </div>
                            </Grid>
                        </Grid>
                    }

                </>
            }
            {
                (
                    (confirmBtn && mode != "answer") ||
                    ((value !== "" || (remark && remark[questionKey].dontKnow)) && type !== "calibrationTest")
                ) &&
                (!(progress >= currentQuestionNum - 1) ||
                    correctAnsweredFlagArray[questionKey] === "notAnswered"
                ) &&
                <>
                    <div onClick={() => {
                        if (type !== "calibrationTest") {
                            handleConfirmAnswer();
                        }
                    }} className="questionCenterButtonContainer">
                        <div>
                            <ConfirmButton/>
                        </div>
                    </div>
                    {/*{is_m &&*/}
                    {/*// height60*/}
                    {/*    <div className="menuHeight30"/>*/}
                    {/*}*/}

                </>


            }


        </>
    );
}

export default MultipleChoice;
