
import React from 'react';
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';

import questionBankPastTenseNoWordSVG from '../../assets/exercise/questionBankPastTenseNoWord.svg';
import questionBankPastTensePresentTenseNoWordSVG from '../../assets/exercise/questionBankPastTensePresentTenseNoWord.svg';
import questionBankPresentContinuousTenseNoWordSVG from '../../assets/exercise/questionBankPresentContinuousTenseNoWord.svg';
import questionBankPresentTensePresentContinuousTenseNoWordSVG
  from '../../assets/exercise/questionBankPresentTensePresentContinuousTenseNoWord.svg';
import questionBankPrepositionNoWordSVG from '../../assets/exercise/questionBankPrepositionNoWord.svg';
import questionBankQuestionWordNoWordSVG from '../../assets/exercise/questionBankQuestionWordNoWord.svg';

function BestPerformanceTopicBox({
  data,
  masteredTopic
}) {
  return (
    <>
      {masteredTopic.includes(data.topicKey) &&
        <Grid item xs={12}>
          <div className="bestPerformanceTopicBoxContainer">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={1} align="center">
                <img className="topicBoxCover" src={
                  data &&
                  data.topicKey == "pastTense" ? questionBankPastTenseNoWordSVG :
                  data.topicKey == "pastTensePresentTense" ? questionBankPastTensePresentTenseNoWordSVG :
                  data.topicKey == "presentContinuousTense" ? questionBankPresentContinuousTenseNoWordSVG :
                  data.topicKey == "presentTensePresentContinuousTense" ? questionBankPresentTensePresentContinuousTenseNoWordSVG :
                  data.topicKey == "preposition" ? questionBankPrepositionNoWordSVG :
                  data.topicKey == "questionWord" && questionBankQuestionWordNoWordSVG
                } alt="topic cover" />
              </Grid>
              <Grid item xs={6}><p className="resultTopicBoxText">{data.topic}</p></Grid>
              <Grid item xs={3} align="center"><p className="resultTopicBoxText">{
                Math.round(data.accuracy * 100) === 100 ?
                  "Q"
                  : Math.round(data.accuracy * 100) >= 90 ?
                    "A"
                    : Math.round(data.accuracy * 100) >= 80 ?
                      "B"
                      : Math.round(data.accuracy * 100) >= 60 ?
                        "C"
                        : "F"
              }</p></Grid>
              <Grid item xs={2} align="center"><p className="resultTopicBoxText">{Math.round(data.accuracy * 100)}%</p></Grid>
            </Grid>
          </div>
        </Grid>
      }
    </>
  );
}

export default BestPerformanceTopicBox;