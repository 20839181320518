import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { db } from "../../firebase";
import { doc, getDoc } from 'firebase/firestore';
import { useTranslation } from "react-i18next";
import { getLanguage } from '../../localStorage/Language';

import '../../App.css';
import './_styles.css';
import '../../translation'

import weekSVG from '../../assets/exercise/week.svg';
import weekZHHKSVG from '../../assets/exercise/weekZH-HK.svg';
import { getUser } from '../../localStorage/User';

function Week() {

  const { t } = useTranslation();
  const l = getLanguage();

  const user = getUser();
  const [week, setWeek] = useState();
  useEffect(() => {
    getWeek()
  }, [])
  const getWeek = async () => {
    if (user.id !== "" && user.id !== null && user.id !== undefined) {
      const userSnapshot = await getDoc(doc(db, 'User', user.id));
      let userInfo = userSnapshot.data();
      let userFirstLoginDate = userInfo.firstLoginDate.toDate();
      userFirstLoginDate.setHours(0);
      userFirstLoginDate.setMinutes(0);
      userFirstLoginDate.setSeconds(0);
      userFirstLoginDate = Math.floor(userFirstLoginDate / 1000) * 1000; // to set zero for millisecond
      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today = Math.floor(today / 1000) * 1000; // to set zero for millisecond
      setWeek(Math.ceil(Math.ceil((today - userFirstLoginDate + (1000 * 60 * 60 * 24)) / (1000 * 60 * 60 * 24)) / 7));
    }
  }

  function ordinalSuffixOf(i) {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return "st";
    }
    if (j === 2 && k !== 12) {
      return "nd";
    }
    if (j === 3 && k !== 13) {
      return "rd";
    }
    return "th";
  }

  return (
    <>
      <div className="weekContainer">
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6} align="center">
            <img className="weekCover" src={l === "en" ? weekSVG : weekZHHKSVG} alt="week cover" />
          </Grid>

          {l === "en" ?
            <Grid item xs={6} align="center">
              <div className="weekUpperTextSection">
                <p className="weekUpperText1">{week}</p>
                <p className="weekUpperText2">{ordinalSuffixOf(week)}</p>
              </div>
              <p className="weekAndDayLowerText">Week</p>
            </Grid>
            :
            <Grid item xs={6} align="center">
              <div className="weekUpperTextSection">
                <p className="weekUpperText1ZH-HK">第{week}週</p>
              </div>
            </Grid>
          }

        </Grid>
      </div>
    </>
  );
}

export default Week;