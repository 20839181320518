import React, {useEffect, useState} from 'react';
import Login from "./pages/login/Login";
import ContactUs from "./pages/login/ContactUs";
import Dashboard from "./pages/dashboard/Dashboard";
import Welcome from "./pages/welcome/Welcome";
import CalibrationTest from "./pages/exercise/CalibrationTest";
import CalibrationTestResult from "./pages/exercise/CalibrationTestResult";
import CalibrationTestVideo from "./pages/exercise/CalibrationTestVideo";
import Result from "./pages/result/Result";

import LearningVideo from "./pages/video/LearningVideo";
import Exercise from "./pages/exercise/Exercise";
import Practice from "./pages/exercise/Practice";
import AccountSetting from "./pages/login/AccountSetting";
import LearningVideoPlayer from "./pages/video/LearningVideoPlayer";
import AnswerReview from "./pages/exercise/AnswerReview";
import {auth, db} from "./firebase";
import {doc, getDoc, setDoc, updateDoc} from 'firebase/firestore';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {getUser} from './localStorage/User';
import {getLanguage} from './localStorage/Language';
import {useTranslation} from "react-i18next";


import AddData from "./AddData";
import Landing from "./pages/booth/Landing";
import Landing2 from "./pages/booth/Landing2";
import Landing3 from "./pages/booth/Landing3";
import Welcom333 from "./pages/booth/Welcom333";
import Landing4 from "./pages/booth/Landing4";
import Landing5 from "./pages/booth/Landing5";
import {NUMB} from "./firebase"
import Landing6 from "./pages/booth/Landing6";
import {Route1} from "./K";
import AnswerReview2 from "./pages/exercise/AnswerReview2";
import AnswerReview3 from "./pages/exercise/AnswerReview3";
import AnswerReview4 from "./pages/exercise/AnswerReview4";
import AnswerReview5 from "./pages/exercise/AnswerReview5";
import Practice2 from "./pages/exercise/Practice2";
import AnswerReview6 from "./pages/exercise/AnswerReview6";
import Ora from "./pages/dashboard/Ora";
import {ThemeProvider, StyledEngineProvider, createTheme} from '@mui/material/styles';

const theme = createTheme();

// 404 page
function PageNotFound() {
    return (
        <div>
            <h1>404 Page Not Found</h1>
        </div>
    )
}

function App() {
    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <App2/>
                </ThemeProvider>
            </StyledEngineProvider>

        </>
    )
}

function App2() {
    console.log("(NUMB != 2 && NUMB != 3)",(NUMB != 2 && NUMB != 3))
    const [rememberMeFlag, setRememberMeFlag] = useState(true);

    window.onbeforeunload = function () {
        !rememberMeFlag && localStorage.clear();
    }

    const user = getUser();
    const language = getLanguage();
    const {i18n} = useTranslation();

    useEffect(() => {
        initAppFunction();
    }, [user])

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language])

    const initAppFunction = async () => {

        console.log("NUMB",NUMB)

        if (NUMB!=3 && NUMB!=2) {

            let today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            // to record user login date
            if (userSnapshot.data().loginDate === undefined) {
                const userRef = doc(db, 'User', user.id);
                updateDoc(userRef, {
                    loginDate: [today]
                });
            } else {
                if (Math.round((userSnapshot.data().loginDate[userSnapshot.data().loginDate.length - 1].toDate() - today) / (1000 * 60 * 60 * 24)) !== 0) {
                    let tmp = userSnapshot.data().loginDate
                    tmp.push(today);
                    const userRef = doc(db, 'User', user.id);
                    updateDoc(userRef, {
                        loginDate: tmp
                    });
                }
            }
        }else {
            console.log("skip login date")
        }
    }


    // const mainroutes=
    return (
        <>
            <div
                // className="mainContent"
            >
                <BrowserRouter>
                    <Routes>

                        {NUMB != 3 &&
                            <>
                                {/*all practice page                        */}
                                <Route exact path="/practice" element={<Practice/>}/>

                            </>


                        }

                        {/*solely for adding data*/}
                        {/*<Route exact path="/addData" element={<AddData/>}/>*/}


                        {NUMB == 3 &&


                            <>

                                <Route exact path="/" element={<Ora/>}/>
                                {/*<Route exact path="/" element={<Dashboard/>}/>*/}

                                <Route exact path="/1" element={<Dashboard/>}/>

                            </>
                        }
                        {NUMB == 2 &&
                            <Route exact path="/" element={<Landing/>}/>
                        }
                        {(NUMB == 0 || NUMB == 1) && <Route exact path="/" element={<Route1 Pp={Dashboard}/>}/>}


                        {(NUMB != 2 && NUMB != 3)&& <>
                            <Route exact path="/read" element={<Route1 Pp={AnswerReview2}/>}/>

                            <Route exact path="/read2" element={<Route1 Pp={AnswerReview3}/>}/>
                            <Route exact path="/read3" element={<Route1 Pp={AnswerReview4}/>}/>
                            <Route exact path="/read4" element={<Route1 Pp={AnswerReview5}/>}/>
                            <Route exact path="/read5" element={<Route1 Pp={Practice2}/>}/>
                            <Route exact path="/read6" element={<Route1 Pp={AnswerReview6}/>}/>

                            <Route exact path="/result" element={<Route1 Pp={Result}/>}/>

                            <Route exact path="/answerReview" element={<Route1 Pp={AnswerReview}/>}/>

                            <Route exact path="/learningVideo" element={<LearningVideo/>}/>
                            <Route exact path="/learningVideoPlayer" element={<LearningVideoPlayer/>}/>

                            <Route exact path="/exercise" element={<Route1 Pp={Exercise}/>}/>

                            <Route exact path="/accountSetting" element={<AccountSetting/>}/>

                            <Route exact path="/contactUs" element={<ContactUs/>}/>


                            {/*login page*/}
                            <Route exact path="/login" element={<Login rememberMeFlag={rememberMeFlag}
                                                                       setRememberMeFlag={setRememberMeFlag}/>}/>


                            {/*welcome page*/}
                            <Route exact path="/welcome" element={<Welcome/>}/>

                            {/*calibration page                     */}
                            <Route exact path="/calibrationTest" element={<CalibrationTest/>}/>
                            <Route exact path="/calibrationTestResult" element={<CalibrationTestResult/>}/>
                            <Route exact path="/calibrationTestVideo" element={<CalibrationTestVideo/>}/>


                        </>}


                        { NUMB == 2 &&<>    {/*booth!!!!!!!!!!!!!!!!!*/}

                            {/*start*/}
                            <Route exact path="/booth" element={<Landing/>}/>

                            {/*accuacy get*/}
                            <Route exact path="/accuracy" element={<Landing4/>}/>

                            {/*information get*/}
                            <Route exact path="/yourinfo" element={<Landing3/>}/>

                            <Route exact path="/res" element={<Landing5/>}/>

                            {/*last page*/}
                            <Route exact path="/joinus" element={<Landing2/>}/>


                            <Route exact path="/dataadmin" element={<Landing6/>}/>

                        </>}
                        <Route path="*" element={<PageNotFound/>}/>


                    </Routes>
                </BrowserRouter>
            </div>
            <div className="notSupportScreenSizeImageContainer">
                <div className="verticalCenter">
                    <img className="notSupportScreenSizeImage"
                         src={require('./assets/general/notSupportScreenSizeBanner.png')} alt="not support"/>
                </div>
            </div>
        </>
    );
}

export default App;
