import React from 'react';
import Modal from 'react-modal';
import {useNavigate} from "react-router-dom";
import '../../translation'

import '../../App.css';
import './_styles.css';

import {useTranslation} from 'react-i18next';
import {LogoutNoButton, LogoutYesButton} from './_components';

function Logout({
                    confirmLeaveModalVisibility,
                    setConfirmLeaveModalVisibility,
                    confirmLeaveModalTarget
                }) {
    let navigate = useNavigate();
    const {t} = useTranslation();
    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={confirmLeaveModalVisibility}
                className="logoutModalContainer"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        backdropFilter: "blur(6px)",
                        WebkitBackdropFilter: "blur(6px)",
                        zIndex: 1000000
                    }
                }}
            >
                <div className="logoutBackgroundImage">
                    <p className="logoutConfirmText">{t("CONFIRM_LEAVE")}</p>
                    <img className="confirmLeaveContent" src={require('../../assets/exercise/confirmLeaveContent.png')}
                         alt="logout confirm"/>
                    <div className="logoutButtonContainer">
                        <div
                            className="logoutDisplayInlineBlock"
                            onClick={() => {
                                setConfirmLeaveModalVisibility(false)
                            }}>
                            <LogoutNoButton/>
                        </div>
                        <div className="logoutButtonMargin">

                        </div>
                        <div
                            className="logoutDisplayInlineBlock"
                            onClick={() => {
                                if (confirmLeaveModalTarget) {
                                    navigate('/' + confirmLeaveModalTarget)
                                } else {
                                    navigate('/')
                                }
                            }}>
                            <LogoutYesButton/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Logout;
