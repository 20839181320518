import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { db } from "../../firebase";
import { doc, getDoc } from 'firebase/firestore';

import '../../App.css';
import './_styles.css';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import AnswerReviewTopicBox from './AnswerReviewTopicBox';
import { getUser } from '../../localStorage/User';
import { getWrongAnswer } from "../../functions/getWrongAnswer"
import { useTranslation } from 'react-i18next';
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {getLanguage} from "../../localStorage/Language";

function AnswerReview() {
    const l = getLanguage();
    const user = getUser();
    const { t } = useTranslation();

    useEffect(() => {
        getUserInfo();
    }, [])
    let navigate = useNavigate();
    // to get user information
    const [userInfo, setUserInfo] = useState();
    const getUserInfo = async () => {
        if (user.id !== "" && user.id !== null && user.id !== undefined) {
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            setUserInfo(userSnapshot.data());
        }
    }

    const [wrongQuestionIDByTopic, setWrongQuestionIDByTopic] = useState();
    useEffect(() => {
        getWrongAnswer(userInfo, setWrongQuestionIDByTopic);
    }, [userInfo])

    return <>
        <Menu />
        <SideMenu />
        <div className="responsiveContainer">




            {/*<img className="questionOfTheDayFrame" src={require('../../assets/image 49.png')}*/}
            {/*     alt="frame"/>*/}
            <div>
                {l==='en'?
                <img className="questionOfTheDayFrame" src={require('../../assets/Group 1140.png')}
                     alt="frame"/> :
                    <img className="questionOfTheDayFrame" src={require('../../assets/Group 1137.png')}
                         alt="frame"/>}



                <img
                    onClick={() => navigate('/read2')}
                    className="questionOfTheDayFrame00" src={require('../../assets/Group 1131.png')}
                     alt="frame"/>
            </div>


            {/*<Box style={*/}
            {/*    {*/}
            {/*        backgroundImage: `url(${require('../../assets/image 49.png')})`,*/}

            {/*        // background-size: `auto 100%`;*/}
            {/*        // height:'300px',*/}
            {/*        // marginTop: 20,*/}
            {/*        // backgroundPosition: 'center',*/}

            {/*        borderRadius: '50px',*/}
            {/*        backgroundSize: 'cover',*/}
            {/*        backgroundRepeat: 'no-repeat'*/}
            {/*    }*/}
            {/*}*/}
            {/*     sx={{*/}
            {/*         // position: '',*/}
            {/*         // width:`100%`, flex :1*/}
            {/*         // height: ``,*/}
            {/*         // flex: 1,*/}

            {/*         // height:200,*/}
            {/*         // minHeight: '200px',*/}
            {/*         // width: 370.4,*/}
            {/*         // height: 171.48,*/}
            {/*     }}></Box>*/}

            {/*<div className="resultTitleContainer" style={{*/}
            {/*    backgroundColor: '#FFDB70',*/}
            {/*    marginLeft: '30px',*/}
            {/*    marginBottom: '50px'*/}
            {/*}}>*/}
            {/*    <p className="resultTitle">{t('ANS_REVIEW')}</p>*/}
            {/*</div>*/}


            {/*<Grid container spacing={2} justifyContent="center">*/}

            {/*    /!*header*!/*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <div className="answerReviewTopicTitleBoxContainer">*/}
            {/*            <Grid container spacing={2} alignItems="center">*/}
            {/*                <Grid item xs={1} align="center"><p className="answerReviewLebal">{t('RANK')}</p></Grid>*/}
            {/*                <Grid item xs={6} align="center"><p className="answerReviewLebal">{t('TOPIC')}</p></Grid>*/}
            {/*                <Grid item xs={3} align="center"><p className="answerReviewLebal">{t('QUESTIONS_LEFT')}</p></Grid>*/}
            {/*                <Grid item xs={2} align="center"></Grid>*/}
            {/*            </Grid>*/}

            {/*        </div>*/}
            {/*    </Grid>*/}


            {/*    /!*rows*!/*/}
            {/*    {wrongQuestionIDByTopic && wrongQuestionIDByTopic.map((data, i) => (*/}
            {/*        <AnswerReviewTopicBox key={i} data={data} index={i} />*/}
            {/*    ))}*/}
            {/*</Grid>*/}
            {/*<div className="answerReviewBottom"></div>*/}
        </div >
    </>;
}

export default AnswerReview;
