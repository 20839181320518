import React from 'react';
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import '../../App.css';
import './_styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import nextArrowSVG from '../../assets/video/nextArrow.svg';
import prevArrowSVG from '../../assets/video/prevArrow.svg';

function LearningVideoTopicBox({ data }) {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style }}
                onClick={onClick}
                onMouseUp={onClick}
            >
                <img src={nextArrowSVG} alt="next" />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style }}
                onClick={onClick}
                onMouseUp={onClick}
            >
                <img src={prevArrowSVG} alt="prev" />
            </div>
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    var blanks = [];
    for (var i = data.videos.length; i < 3; i++) {
        blanks.push(<div key={i} />);
    }

    let navigate = useNavigate();

    return (
        <>
            <div className="learningVideoTopicContainer" style={{ backgroundColor: data.topicColor }}>
                <div className="learningVideoTopicContainerInner">
                    <p className="learningVideoTopicText">{data.topicText}</p>
                </div>
            </div>
            <div className="learningVideoSlide">
                <Slider {...settings}>
                    {data && data.videos.map((data, i) => (
                        <div key={i}>
                            <img className="learningVideoImage"
                                onClick={() => { navigate('/learningVideoPlayer', { state: { topic: data.name, embedId: data.embedId } }) }}
                                src={`https://img.youtube.com/vi/${data.embedId}/maxresdefault.jpg`}
                                alt="video cover" />
                            <p className="learningVideoName">{data.name}</p>
                        </div>
                    ))}
                    {blanks}
                </Slider>
            </div>
        </>
    );
}

export default LearningVideoTopicBox;
