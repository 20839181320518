import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import {LanguageSelect} from './_components';
import {useTranslation} from "react-i18next";
import { getLanguage, setLanguage } from '../../localStorage/Language';

const Language = () => {

    const {i18n} = useTranslation();
    const language = getLanguage();

    const changeLanguage = (l) =>{
        i18n.changeLanguage(l);
        setLanguage(l);
    }

    return (
        <LanguageSelect
            defaultValue={language}
            className="languageSelect"
        >
            <MenuItem key={'en'} value={'en'} onClick={(e) => {
                changeLanguage(e.target.dataset.value);
            }}>
                English
            </MenuItem>
            <MenuItem key={'zh-HK'} value={'zh-HK'} onClick={(e) => {
                changeLanguage(e.target.dataset.value);
            }}>
                繁體中文
            </MenuItem>
        </LanguageSelect>
    );
}

export default Language;
