import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';

import whiteArrowSVG from '../../assets/dashboard/whiteArrow.svg'
import {db} from "../../firebase";
import {collection, doc, getDoc, getDocs, query, updateDoc, where} from 'firebase/firestore';
import TodaysVideoModal from '../video/TodaysVideoModal';
import {useMediaQuery} from "react-responsive";
import Box from "@mui/material/Box";

function QuestionOfTheDay() {

    useEffect(() => {
        getVideo();
    }, [])

    const [todaysVideoModalVisibility, setTodaysVideoModalVisibility] = useState(false);

    const [video, setVideo] = useState();
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const getVideo = async () => {
        let tmpVideoArray = [];
        const q = query(collection(db, "Video"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            tmpVideoArray.push(doc.data());
        });
        var now = new Date();
        var start = new Date(now.getFullYear(), 0, 0);
        var diff = now - start;
        var oneDay = 1000 * 60 * 60 * 24;
        var day = Math.floor(diff / oneDay);
        // let randomIndex = Math.floor(Math.random() * tmpVideoArray.length);
        setVideo(tmpVideoArray[day % tmpVideoArray.length]);
    }

    return (
        <>


            {is_m ?
                <>
                    {/*<img className="questionOfTheDayFrame" src={require('../../assets/dashboard/todaysVideo.png')} alt="frame" />*/}

                    <Box style={
                        {
                            backgroundImage: `url(${require('../../assets/dashboard/todaysVideo.png')})`,

                            // background-size: `auto 100%`;
                            // height:'300px',
                            // marginTop: 20,
                            // backgroundPosition: 'center',

                            borderRadius: '0px',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                        }
                    }
                         sx={{
                             // position: '',
                             // width:`100%`, flex :1
                             // height: ``,
                             // flex: 1,

                             // height:200,
                             // minHeight: '200px',
                             // width: 370.4,
                             // height: 171.48,
                         }}>


                        {/*<div className="questionOfTheDayContainer">*/}


                        <Grid container spacing={2}>
                            <Grid item xs={4}/>
                            <Grid className="todaysVideoContainer" item xs={7}>
                                {video &&
                                    <div onClick={() => {
                                        setTodaysVideoModalVisibility(true)
                                    }}>
                                        <img className="todaysVideoArrow" src={whiteArrowSVG} alt="arrow"/>
                                        <img className="todaysVideoCover"
                                             src={`https://img.youtube.com/vi/${video.embedId}/maxresdefault.jpg`}
                                             alt="video"/>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                        {/*</div>*/}
                    </Box>
                    {video &&
                        <TodaysVideoModal video={video} todaysVideoModalVisibility={todaysVideoModalVisibility}
                                          setTodaysVideoModalVisibility={setTodaysVideoModalVisibility}/>
                    }
                </>
                :
                <>
                    <img className="questionOfTheDayFrame" src={require('../../assets/dashboard/todaysVideo.png')}
                         alt="frame"/>
                    <div className="questionOfTheDayContainer">
                        <Grid container spacing={2}>
                            <Grid item xs={4}/>
                            <Grid className="todaysVideoContainer" item xs={7}>
                                {video &&
                                    <div onClick={() => {
                                        setTodaysVideoModalVisibility(true)
                                    }}>
                                        <img className="todaysVideoArrow" src={whiteArrowSVG} alt="arrow"/>
                                        <img className="todaysVideoCover"
                                             src={`https://img.youtube.com/vi/${video.embedId}/maxresdefault.jpg`}
                                             alt="video"/>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </div>
                    {video &&
                        <TodaysVideoModal video={video} todaysVideoModalVisibility={todaysVideoModalVisibility}
                                          setTodaysVideoModalVisibility={setTodaysVideoModalVisibility}/>
                    }
                </>}
        </>
    );
}

export default QuestionOfTheDay;
