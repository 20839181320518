import { db } from "../firebase";
import { addDoc, collection, doc, getDocs, query, updateDoc, where, orderBy } from 'firebase/firestore';

export const getAccuracy = async (userInfo, userID, setTopciAccuracy, setWeeklyAccuracy, setMasteredTopic, setToBeImprovedLevel1, setToBeImprovedLevel2) => {
    if (userInfo) {

        const topic = ["pastTense", "pastTensePresentTense", "presentContinuousTense", "presentTensePresentContinuousTense", "preposition", "questionWord"];
        const topicDisplay = ["Past Tense", "Past Tense Present Tense", "Present Continuous Tense", "Present Tense Present Continuous Tense", "Preposition", "Question Words"];

        const q = query(collection(db, "Exercise"), where("user", "==", userID));
        const querySnapshot = await getDocs(q);

        // to calculate the accuracy by topic
        let topicAnsweredTotal = [0, 0, 0, 0, 0, 0];
        let topicCorrectAnswerTotal = [0, 0, 0, 0, 0, 0]
        let topicAnsweredTotalLevel1 = [0, 0, 0, 0, 0, 0];
        let topicCorrectAnswerTotalLevel1 = [0, 0, 0, 0, 0, 0]
        let topicAnsweredTotalLevel2 = [0, 0, 0, 0, 0, 0];
        let topicCorrectAnswerTotalLevel2 = [0, 0, 0, 0, 0, 0]

        // to calculate the accuracy by week
        let userFirstLoginDate = userInfo.firstLoginDate.toDate();
        userFirstLoginDate.setHours(0);
        userFirstLoginDate.setMinutes(0);
        userFirstLoginDate.setSeconds(0);
        userFirstLoginDate = Math.floor(userFirstLoginDate / 1000) * 1000; // to set zero for millisecond
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today = Math.floor(today / 1000) * 1000; // to set zero for millisecond
        let currentWeek = Math.ceil(Math.ceil((today - userFirstLoginDate + (1000 * 60 * 60 * 24)) / (1000 * 60 * 60 * 24)) / 7);

        let weeklyAnsweredTotal = Array.from({ length: currentWeek }).fill(0);
        // console.log("log",weeklyAnsweredTotal)
        let weeklyCorrectAnswerTotal = Array.from({ length: currentWeek }).fill(0);

        // to calculate the best performance topic
        let levelThreeExercise = [[], [], [], [], [], []]

        querySnapshot.forEach((doc) => { // for each exercise belong to the user
            if (doc.data().accuracy !== undefined
                && doc.data().accuracy >= 0
                && doc.data().type != "calibrationTest"
                && doc.data().type != "answerReviewExercise"
                && doc.data().type != "questionBank"
            ) {

                // to calculate the accuracy by topic
                for (let i = 0; i < topic.length; i++) {   // for each possible topic
                    if (doc.data().type == topic[i]
                        && doc.data().question.length == 5
                    ) { // for level 1 or 2 question
                        topicAnsweredTotal[i] += (doc.data().progress + 1);
                        topicCorrectAnswerTotal[i] += ((doc.data().progress + 1) * doc.data().accuracy);
                        if (doc.data().level && doc.data().level === "1") {
                            topicAnsweredTotalLevel1[i] += (doc.data().progress + 1);
                            topicCorrectAnswerTotalLevel1[i] += ((doc.data().progress + 1) * doc.data().accuracy);
                        }
                        if (doc.data().level && doc.data().level === "2") {
                            topicAnsweredTotalLevel2[i] += (doc.data().progress + 1);
                            topicCorrectAnswerTotalLevel2[i] += ((doc.data().progress + 1) * doc.data().accuracy);
                        }
                    } else if (doc.data().type == topic[i]
                        && doc.data().question.length == 1
                    ) { // for level 3 question
                        let tmpAnswer = doc.data().answer[0];
                        let tmpAnsweredQuestion = JSON.parse(tmpAnswer);
                        let tmpCount = 0;
                        for (let j = 0; j < tmpAnsweredQuestion.length; j++) {
                            tmpAnsweredQuestion[j] !== "" &&
                            tmpCount++;
                        }
                        topicAnsweredTotal[i] += tmpCount;
                        topicCorrectAnswerTotal[i] += (tmpCount * doc.data().accuracy);
                        levelThreeExercise[i].push(doc.data());
                    }
                }
                // to calculate the accuracy by week
                for (let i = 0; i < currentWeek; i++) {
                    let exerciseDate = doc.data().date.toDate();
                    exerciseDate.setHours(0);
                    exerciseDate.setMinutes(0);
                    exerciseDate.setSeconds(0);
                    exerciseDate = Math.floor(exerciseDate / 1000) * 1000; // to set zero for millisecond
                    if (exerciseDate >= userFirstLoginDate + (1000 * 60 * 60 * 24) * i * 7 &&
                        exerciseDate < userFirstLoginDate + (1000 * 60 * 60 * 24) * (i + 1) * 7 &&
                        doc.data().question.length == 5
                    ) { // for level 1 or 2 question       AND  exerciseDate is in the i week
                        weeklyAnsweredTotal[i] += (doc.data().progress + 1);
                        weeklyCorrectAnswerTotal[i] += ((doc.data().progress + 1) * doc.data().accuracy);
                    } else if (exerciseDate >= userFirstLoginDate + (1000 * 60 * 60 * 24) * i * 7 &&
                        exerciseDate < userFirstLoginDate + (1000 * 60 * 60 * 24) * (i + 1) * 7 &&
                        doc.data().question.length == 1
                    ) { // for level 3 question            AND  exerciseDate is in the i week
                        let tmpAnswer = doc.data().answer[0];
                        let tmpAnsweredQuestion = JSON.parse(tmpAnswer);
                        let tmpCount = 0;
                        for (let j = 0; j < tmpAnsweredQuestion.length; j++) {
                            tmpAnsweredQuestion[j] !== "" && tmpCount++;
                        }
                        weeklyAnsweredTotal[i] += tmpCount;
                        weeklyCorrectAnswerTotal[i] += (tmpCount * doc.data().accuracy);
                    }
                }
            }
        });

        // to calculate the best performance topic
        let tmpMasteredTopic = []
        for (let i = 0; i < topic.length; i++) { // for i th topic
            let tmpMasterTopicFlag = false;
            levelThreeExercise[i].sort(function (a, b) {
                return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
            });  // sort the array in i th element by date
            for (let j = 0; j < levelThreeExercise[i].length - 1; j++) {
                if (levelThreeExercise[i][j].accuracy != undefined &&
                    levelThreeExercise[i][j + 1].accuracy != undefined
                ) {
                    if (((levelThreeExercise[i][j].accuracy + levelThreeExercise[i][j + 1].accuracy) / 2 ) >= 0.8 && userInfo.topicLevel[topic[i]] == 3) {
                        tmpMasterTopicFlag = true;
                    }
                }
            }
            tmpMasterTopicFlag && tmpMasteredTopic.push(topic[i]);
        }
        setMasteredTopic(tmpMasteredTopic);

        // to calculate the to be improved topic
        let tmpToBeImprovedLevel1 = [];
        for (let i = 0; i < topic.length; i++) {
            tmpToBeImprovedLevel1.push({
                topic: topicDisplay[i],
                topicKey: topic[i],
                accuracy: topicAnsweredTotalLevel1[i] === 0 ? "NA" : Math.min(1, topicCorrectAnswerTotalLevel1[i] / topicAnsweredTotalLevel1[i]),
            })
        }
        setToBeImprovedLevel1(tmpToBeImprovedLevel1)
        let tmpToBeImprovedLevel2 = [];
        for (let i = 0; i < topic.length; i++) {
            tmpToBeImprovedLevel2.push({
                topic: topicDisplay[i],
                topicKey: topic[i],
                accuracy: topicAnsweredTotalLevel2[i] === 0 ? "NA" : Math.min(1, topicCorrectAnswerTotalLevel2[i] / topicAnsweredTotalLevel2[i]),
            })
        }
        setToBeImprovedLevel2(tmpToBeImprovedLevel2)

        // to set the accuracy by topic
        let tmp = [];
        for (let i = 0; i < topic.length; i++) {
            tmp.push({
                topic: topicDisplay[i],
                topicKey: topic[i],
                accuracy: topicAnsweredTotal[i] === 0 ? "NA" : Math.min(1, topicCorrectAnswerTotal[i] / topicAnsweredTotal[i]),
            })
        }
        
        // tmp.sort(function (a, b) {
        //     return a.accuracy > b.accuracy ? -1 : a.accuracy < b.accuracy ? 1 : 0;
        // });

        setTopciAccuracy(tmp);

        // to set the accuracy by week
        let tmpWeekly = [];
        for (let i = 0; i < currentWeek; i++) {
            tmpWeekly.push(weeklyAnsweredTotal[i] === 0 ? 0 : Math.min(1, weeklyCorrectAnswerTotal[i] / weeklyAnsweredTotal[i]) * 100)
        }
        setWeeklyAccuracy(tmpWeekly);
    }
}
