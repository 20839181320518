import React, { useEffect, useState } from 'react';
import { db } from "../../firebase";
import { collection, getDocs, query, where } from 'firebase/firestore';

import '../../App.css';
import './_styles.css';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import LearningVideoTopicBox from './LearningVideoTopicBox';

function LearningVideo() {
    const [videoTopics, setVideoTopics] = useState([])
    const defaultVideoTopics = [
        {
            topicText: "Past Tense",
            topic: "pastTense",
            topicColor: "#FCEB92",
            videos: [
            ]
        },
        {
            topicText: "Past Tense × Present Tense",
            topic: "pastTensePresentTense",
            topicColor: "#FECF76",
            videos: [
            ]
        },
        {
            topicText: "Present Continuous Tense",
            topic: "presentContinuousTense",
            topicColor: "#A3DCCF",
            videos: [
            ]
        },
        {
            topicText: "Present Tense × Present Continuous Tense",
            topic: "presentTensePresentContinuousTense",
            topicColor: "#FCEB92",
            videos: [
            ]
        },
        {
            topicText: "Prepositions",
            topic: "preposition",
            topicColor: "#FECF76",
            videos: [
            ]
        },
        {
            topicText: "Question Words",
            topic: "questionWord",
            topicColor: "#A3DCCF",
            videos: [
            ]
        },
    ];

    useEffect(() => {
        getLearningVideo()
    })

    const getLearningVideo = async () => {
        for (let i = 0; i < videoTopics.length; i++) {
            const q = query(collection(db, "Video"), where("topic", "==", defaultVideoTopics[i].topic));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                const obj = { 'name': doc.data().name, 'embedId': doc.data().embedId };
                defaultVideoTopics[i].videos.push(obj);
            });
        }
        setVideoTopics(defaultVideoTopics);
    }

    return (
        <>
            <Menu />
            <SideMenu />
            <div className="responsiveContainer">
                {videoTopics.map((data, i) => (
                    <div key={i}>
                        {data.videos?.length > 0 && <LearningVideoTopicBox key={i} data={data} />}
                    </div>
                ))}
                <div className="learningVideoBottomMargin"></div>
            </div>
        </>
    );
}

export default LearningVideo;
