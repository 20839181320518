import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import {db, lgg} from "../../firebase";
import {doc, getDoc, updateDoc} from 'firebase/firestore';

import '../../App.css';
import './_styles.css';

import TodaysQuestionAnswer from '../../pages/dashboard/TodaysQuestionAnswer';
import correctSVG from '../../assets/general/correct.svg';
import incorrectSVG from '../../assets/general/incorrect.svg';
import {useTranslation} from 'react-i18next'

import Box from '@mui/material/Box';
import {useMediaQuery} from "react-responsive";

function QuestionOfTheDay({questionOfTheDayID, questionOfTheDay}) {
    const [todaysQuestionAnswerVisibility, setTodaysQuestionAnswerVisibility] = useState(false);
    const numbering = ["A", "B", "C", "D"];
    useEffect(() => {
        getQuestion(questionOfTheDay[0].question[0]);
    }, [])
    const [questionDetail, setQuestionDetail] = useState();
    const [answerIndex, setAnswerIndex] = useState();
    const [option, setOption] = useState();
    const {t} = useTranslation();


    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const getQuestion = async (id) => {

        const questionSnapshot = await getDoc(doc(db, 'Question', id));
        if (questionSnapshot.exists()) {

            // random question by question id
            let randomIndex = [];
            for (let i = 0; i < questionSnapshot.data().option.length; i++) {

                // We assign a random integer.
                randomIndex.push(Math.floor(Math.random() * 1000000));



                // randomIndex.push(questionOfTheDay[0].question[0]);
            }
            let tmpOption = questionSnapshot.data().option;
            let sortedOption = [];
            var len = randomIndex.length;
            var indices = new Array(len);

            for (var i = 0; i < len; ++i) indices[i] = i;

            indices.sort(function (a, b) {
                return randomIndex[a] < randomIndex[b] ? -1 : randomIndex[a] > randomIndex[b] ? 1 : 0;
            });
            lgg("indicessss: ")
            lgg(indices)
            for (let i = 0; i < questionSnapshot.data().option.length; i++) {
                sortedOption.push(tmpOption[indices[i]]);
            }

            setOption(sortedOption);


            setQuestionDetail(questionSnapshot.data());
            for (let i = 0; i < questionSnapshot.data().option.length; i++) {
                sortedOption[i] == questionSnapshot.data().answer && setAnswerIndex(i); // to set the answer index
            }
        }
    }

    const [userAnswer, setUserAnswer] = useState(questionOfTheDay && questionOfTheDay[0].answer && (questionOfTheDay[0].answer[0] !== undefined || questionOfTheDay[0].answer[0] !== "") && questionOfTheDay[0].answer[0]);
    const [mode, setMode] = useState(questionOfTheDay && questionOfTheDay[0].answer && (questionOfTheDay[0].answer[0] !== undefined || questionOfTheDay[0].answer[0] !== "") ? "answer" : "question");
    const handleSelectAnswer = (answer) => {
        if (mode == "question") {
            setMode("answer");
            setUserAnswer(answer);
            // to submit the answer to database
            const userRef = doc(db, 'Exercise', questionOfTheDayID[0]);
            updateDoc(userRef, {answer: [answer]});
        }
    }
    const styles1 = {
        heroContainer: {
            minHeight: 200,
            maxHeight: 300,
            backgroundImage: `url(${require('../../assets/dashboard/questionOfTheDayFrame.png')})`,
            backgroundSize: 'contain',
            // backgroundPosition: 'center',
            // width: `calc(100vw)`,
            // margin: -24,
            // padding: 24,
        }
    };
    return (
        <>
            <TodaysQuestionAnswer
                todaysQuestionAnswerVisibility={todaysQuestionAnswerVisibility}
                setTodaysQuestionAnswerVisibility={setTodaysQuestionAnswerVisibility}
                questionDetail={questionDetail}
                answerIndex={answerIndex}
                option={option}
                numbering={numbering}
                questionOfTheDayID={questionOfTheDayID}
                questionOfTheDay={questionOfTheDay}
                userAnswer={userAnswer}
                setUserAnswer={setUserAnswer}
                mode={mode}
                setMode={setMode}
                handleSelectAnswer={handleSelectAnswer}
            />
            {/*<img className="questionOfTheDayFrame" src={require('../../assets/dashboard/questionOfTheDayFrame.png')}*/}
            {/*    alt="frame" />*/}


            {/*better just use the mobile code since it doesnt use a blocking photo */}
            {is_m ?
                <Box style={
                    {
                        backgroundImage: `url(${require('../../assets/questionOfday.png')})`,

                        // background-size: `auto 100%`;
                        // height:'300px',
                        // marginTop: 20,
                        // backgroundPosition: 'center',

                        borderRadius: '50px',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }
                }
                     sx={{
                         // position: '',
                         // width:`100%`, flex :1
                         // height: ``,
                         // flex: 1,

                         // height:200,
                         // minHeight: '200px',
                         // width: 370.4,
                         // height: 171.48,
                     }}>


                    {/*orignal this div is used to place the content back into the questionOfTheDayFrame because questionOfTheDayFrame block the content */}
                    {/*<div className="questionOfTheDayContainer">*/}
                    <Grid container spacing={2}
                        // style={styles1.heroContainer}

                        // style={{
                        //     marginTop: `-20vw`
                        // }}
                    >


                        <Grid item xs={4}>
                            <img style={{marginTop: 50}} className="questionOfTheDayFrame"
                                 src={require('../../assets/QualiFlylogo.png')}/>

                        </Grid>
                        <Grid item xs={7}>
                            <div className="questionOfTheDayContentContainer">
                                <p className="questionOfTheDayTitle">{t('QUESTION_OF_THE_DAY')}</p>
                                <p className="questionOfTheDayText">{t('PLEASE_CHOOSE_THE_CORRECT_ANS')}</p>
                                <p className="questionOfTheDayQuestion">
                                    {questionDetail && questionDetail.question.map((data, i) => (
                                        <span key={i}>
                                        <span>{data}</span>
                                            {i !== questionDetail.question.length - 1 &&
                                                <span className="multipleChoiceQuestionUnderline">______________</span>
                                            }
                                    </span>
                                    ))}
                                </p>
                                {/* <p className="questionOfTheDayType">({questionDetail && questionDetail.topic})</p> */}
                                <Grid container spacing={1}
                                      style={{
                                          marginBottom: 10
                                      }}>
                                    {option && option.map((data, i) => (
                                        <Grid key={i} item xs={6}>
                                            <div onClick={() => {
                                                handleSelectAnswer(data);
                                                setTodaysQuestionAnswerVisibility(true);
                                            }} className="multipleChoicesContainer"
                                                 style={{
                                                     backgroundColor:
                                                         (data === option[answerIndex] && mode === "answer") ? '#BDFFB2'
                                                             : (data === userAnswer && mode === "answer") ? '#F8A5A5'
                                                                 : 'white'
                                                 }}
                                            >
                                            <span
                                                className="questionOfTheDayModalAnswerSmall">{numbering[i] + ".\xa0\xa0" + data}</span>
                                                {data === option[answerIndex] && mode === "answer" ?
                                                    <img className="todaysQuestionAnswerCorrectSmall" src={correctSVG}
                                                         alt="correct"/>
                                                    : data === userAnswer && mode === "answer" &&
                                                    <img className="todaysQuestionAnswerCorrectSmall" src={incorrectSVG}
                                                         alt="incorrect"/>
                                                }
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    {/*</div>*/}


                </Box>


                :


                <>
                    <img className="questionOfTheDayFrame"
                         src={require('../../assets/dashboard/questionOfTheDayFrame.png')}
                         alt="frame"/>
                    <div className="questionOfTheDayContainer">
                        <Grid container spacing={2}>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={7}>
                                <div className="questionOfTheDayContentContainer">
                                    <p className="questionOfTheDayTitle">{t('QUESTION_OF_THE_DAY')}</p>
                                    <p className="questionOfTheDayText">{t('PLEASE_CHOOSE_THE_CORRECT_ANS')}</p>
                                    <p className="questionOfTheDayQuestion">
                                        {questionDetail && questionDetail.question.map((data, i) => (
                                            <span key={i}>

                                                <span>{data}</span>
                                                {i !== questionDetail.question.length - 1 &&
                                                    <span
                                                        className="multipleChoiceQuestionUnderline">______________</span>
                                                }

                                            </span>
                                        ))}
                                    </p>
                                    {/* <p className="questionOfTheDayType">({questionDetail && questionDetail.topic})</p> */}
                                    <Grid container spacing={1}>
                                        {option && option.map((data, i) => (
                                            <Grid key={i} item xs={6}>
                                                <div onClick={() => {
                                                    handleSelectAnswer(data);
                                                    setTodaysQuestionAnswerVisibility(true);
                                                }} className="multipleChoicesContainer"
                                                     style={{
                                                         backgroundColor:
                                                             (data === option[answerIndex] && mode === "answer") ? '#BDFFB2'
                                                                 : (data === userAnswer && mode === "answer") ? '#F8A5A5'
                                                                     : 'white'
                                                     }}
                                                >

                                                    <span
                                                        className="questionOfTheDayModalAnswerSmall">{numbering[i] + ".\xa0\xa0" + data}</span>
                                                    {data === option[answerIndex] && mode === "answer" ?
                                                        <img className="todaysQuestionAnswerCorrectSmall"
                                                             src={correctSVG}
                                                             alt="correct"/>
                                                        : data === userAnswer && mode === "answer" &&
                                                        <img className="todaysQuestionAnswerCorrectSmall"
                                                             src={incorrectSVG}
                                                             alt="incorrect"/>
                                                    }
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </>
            }


            {/* <div onClick={() => {
                setTodaysQuestionAnswerVisibility(true)
            }} className="checkOutTheAnswerContainer">
                <div className="checkOutTheAnswer"></div>
            </div> */}
        </>
    );
}

export default QuestionOfTheDay;
