import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";

import '../../App.css';
import './_styles.css';

import LearningVideoEmbed from '../video/LearningVideoEmbed';

import Menu from '../../components/menu/Menu';
import { GotItButton } from './_components';
import {useMediaQuery} from "react-responsive";

function CalibrationTestVideo() {

    let navigate = useNavigate();
    const [showBtn, setShowBtn] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShowBtn(true);
        }, 5000);
    })
    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })

    return (
        <>
            <Menu />
            <div className="exerciseBackgroundImage2">
                <div className="responsiveContainer"
                     style={{ marginTop: "2vw", paddingLeft: "5vw", paddingRight: "5vw" }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={8}><p className="learningVideoPlayerTopic">Tutorial Video</p></Grid>

                        {/*{!is_m&&     <Grid item xs={1}>*/}
                        {/*    {showBtn &&*/}
                        {/*        <div className="learningVideoPlayerReturnDisplayInlineBlock" onClick={() => { navigate('/') }}>*/}
                        {/*            <GotItButton variant="contained" className="sendButton"></GotItButton>*/}
                        {/*        </div>*/}
                        {/*    }*/}
                        {/*</Grid>}*/}

                        <Grid item xs={2}></Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={9}>
                            <div className="learningVideoPlayerContainer">
                                <LearningVideoEmbed embedId={"ZTFtacSqqGI"} />
                            </div>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                    {<Grid container spacing={2}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}>
                            {showBtn &&
                                <div className="learningVideoPlayerReturnDisplayInlineBlock" onClick={() => {
                                    navigate('/')
                                }}>
                                    <GotItButton variant="contained" className="sendButton"></GotItButton>
                                </div>
                            }
                        </Grid></Grid>}

                </div>
                <div className="calibrationTestVideoBotomMargin"></div>
            </div>
        </>
    );
}

export default CalibrationTestVideo;
