import Select from '@mui/material/Select';
import {styled} from "@mui/material/styles";
import {outlinedInputClasses} from "@mui/material/OutlinedInput";

export const LanguageSelect = styled(Select)(`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: rgba(0,0,0,0);
  }
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-color: rgba(0,0,0,0);
  }
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: rgba(0,0,0,0);
  }
`);
