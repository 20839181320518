import React from 'react'
import TextField from '@mui/material/TextField';
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
// import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const useStyles = makeStyles((theme: Theme) =>
  ({
    wrapForm : {
        display: "flex",
        justifyContent: "center",
        width: "95%",
        margin: `${theme.spacing(0)} auto`
    },
    wrapText  : {
        width: "100%"
    },
    button: {
        //margin: theme.spacing(1),
    },
  })
);


export const TextInput = () => {
    const classes = useStyles();
    return (
        <>
            <form className={classes.wrapForm}  noValidate autoComplete="off">
            <TextField
                id="standard-text"
                label="メッセージを入力"
                className={classes.wrapText}
                //margin="normal"
            />
            <Button variant="contained" color="primary" className={classes.button}>
                {/*<SendIcon />*/}
            </Button>
            </form>
        </>
    )
}



