import React from 'react';
import Modal from 'react-modal';

import '../../App.css';
import './_styles.css';

import '../../translation'
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../localStorage/Language';

import { ViewMyResultsButton } from './_components';

function TimesUp({
    handleSubmitAnswer,
    timesUpModalVisibility,
    setTimesUpModalVisibility,
}) {

    const { t } = useTranslation();
    const l = getLanguage();

    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={timesUpModalVisibility}
                className="pauseModalContainer"
                style={{ overlay: {backgroundColor:'rgba(0, 0, 0, 0.3)', backdropFilter: "blur(6px)", WebkitBackdropFilter: "blur(6px)", zIndex: 1000000 }}}
            >
                <div className="pauseBackgroundImage">
                    <p className="pauseConfirmText">{t("TIMES_UP")}</p>
                    <img className="timesUpContent" src={
                        l === "en" ?
                        require('../../assets/exercise/timesUpContent.png')
                        :
                        require('../../assets/exercise/timesUpContentZH-HK.png')
                    }
                        alt="time's up" />
                    <div onClick={() => {
                        setTimesUpModalVisibility(false);
                        handleSubmitAnswer("timesUp");
                    }} className="pauseButtonContainer">
                        <ViewMyResultsButton />
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default TimesUp;
