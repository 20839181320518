import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import '../../App.css';
import './_styles.css';

import '../../translation'
import {useTranslation} from 'react-i18next';
import {getLanguage} from '../../localStorage/Language';

import ConversationBoxSVG from '../../assets/welcome/conversationBox.svg';
import ConversationBoxNameSVG from '../../assets/welcome/conversationBoxName.svg';
import HappySVG from '../../assets/welcome/happy.svg';
import NerdSVG from '../../assets/welcome/nerd.svg';
import languageSVG from '../../assets/menu/language.svg';
import Language from '../../components/menu/Language';
import penSVG from '../../assets/exercise/pen.svg';
import {ConfirmButton} from './_components';
import {getUser} from '../../localStorage/User';
import useWindowDimensions, {db} from "../../firebase";
import {addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {redirect} from "../../functions/redirect"

import welcomeBanner1 from "../../assets/welcome/welcomeBanner1.png";
import welcomeBannerZHHK1 from "../../assets/welcome/welcomeBannerZH-HK1.png";
import welcomeBannerZHHK1_m from "../../assets/welcome/welcomeBannerZH-HK1_m.png";
import welcomeBanner1_m from "../../assets/welcome/welcomeBanner1_m.png";


import welcomeBanner2 from "../../assets/welcome/welcomeBanner2.png";
import welcomeBanner2_m from "../../assets/welcome/welcomeBanner2_m.png";
import welcomeBannerZHHK2 from "../../assets/welcome/welcomeBannerZH-HK2.png";
import welcomeBannerZHHK2_m from "../../assets/welcome/welcomeBannerZH-HK2_m.png";
import welcomeBanner4 from "../../assets/welcome/welcomeBanner4.png";
import welcomeBanner4_m from "../../assets/welcome/welcomeBanner4_m.png";
import welcomeBannerZHHK4 from "../../assets/welcome/welcomeBannerZH-HK4.png";
import welcomeBannerZHHK4_m from "../../assets/welcome/welcomeBannerZH-HK4_m.png";
import welcomeBanner5 from "../../assets/welcome/welcomeBanner5.png";
import welcomeBanner5_m from "../../assets/welcome/welcomeBanner5_m.png";
import welcomeBannerZHHK5 from "../../assets/welcome/welcomeBannerZH-HK5.png";
import welcomeBannerZHHK5_m from "../../assets/welcome/welcomeBannerZH-HK5_m.png";
import welcomeBanner7 from "../../assets/welcome/welcomeBanner7.png";
import welcomeBanner7_m from "../../assets/welcome/welcomeBanner7_m.png";
import welcomeBannerZHHK7 from "../../assets/welcome/welcomeBannerZH-HK7.png";
import welcomeBannerZHHK7_m from "../../assets/welcome/welcomeBannerZH-HK7_m.png";
import welcomeBanner8 from "../../assets/welcome/welcomeBanner8.png";
import welcomeBanner8_m from "../../assets/welcome/welcomeBanner8_m.png";
import welcomeBannerZHHK8 from "../../assets/welcome/welcomeBannerZH-HK8.png";
import welcomeBannerZHHK8_m from "../../assets/welcome/welcomeBannerZH-HK8_m.png";


import welcomeBanner11 from "../../assets/welcome/welcomeBanner11.png";
import welcomeBanner11_m from "../../assets/welcome/welcomeBanner11_m.png";
// import welcomeBanner11_m from "../../assets/welcome/welcomeBanner11.png";
import welcomeBannerZHHK11 from "../../assets/welcome/welcomeBannerZH-HK11.png";
import welcomeBannerZHHK11_m from "../../assets/welcome/welcomeBannerZH-HK11_m.png";
// import welcomeBannerZHHK11_m from "../../assets/welcome/welcomeBannerZH-HK11.png";


import p4 from "../../assets/welcome/p4.png";
import p5 from "../../assets/welcome/p5.png";
import p6 from "../../assets/welcome/p6.png";


import {useMediaQuery} from "react-responsive";
import Grid from "@mui/material/Grid";


import LoadingOverlay from 'react-loading-overlay';
import {SimpleDialog} from "../../K";


function Welcome() {

    const { height, width } = useWindowDimensions();

    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const {t} = useTranslation();
    const l = getLanguage();

    const user = getUser();
    let navigate = useNavigate();
    useEffect(() => {
        redirect(user, navigate);
        // eslint-disable-next-line
    }, [])

    const location = useLocation();
    useEffect(() => {
        location.state && setProcess(location.state.processInit);
    })

    const [process, setProcess] = useState(1);
    const [process2, setProcess2] = useState();

    console.log("process", process)
    const toProcess3N4 = () => {
        setProcess(3);
        // setTimeout(() => {
        //     setProcess(4)
        // }, 2000);
    }
    const toProcess6N7N4 = () => {
        setProcess(6);
        // setTimeout(() => {
        //     setProcess(7)
        // }, 2000);
        // setTimeout(() => {
        //     setProcess(4)
        // }, 4000);
    }
    const toProcess5N8 = () => {
        setProcess(5);
        // setTimeout(() => {
        //     setProcess(8)
        // }, 2000);
    }

    function titleCase(str) {
        var splitStr = str.split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    const [nickname, setNickname] = useState("");
    const updateNickname = () => {
        const userRef = doc(db, 'User', user.id);
        updateDoc(userRef, {nickname: titleCase(nickname)});
    }
    console.log("userid", user.id)
    const finishWelcome = async () => {

        setProcess2(1)
        const userRef = doc(db, 'User', user.id);
        await updateDoc(userRef, {finishWelcomeFlag: true});


        const userSnapshot = await getDoc(doc(db, 'User', user.id));


        console.log("checking")


        // to create calibration test to user if test not created
        const q = query(collection(db, "Exercise"), where("user", "==", user.id), where("type", "==", "calibrationTest"));
        const querySnapshot = await getDocs(q);
        let calibrationTestExistFlag = false;
        querySnapshot.forEach((doc) => {

            console.log("doid", doc.id);
            calibrationTestExistFlag = true
        });


        if (!userSnapshot.data().formm) {
            //error
            console.log("error")

        }


        let p5Flag = false;
        if (userSnapshot.data().formm == "p5") {
            console.log("true82")
            p5Flag = true

        }
        ;


        // p5 is reusing the question


        if (p5Flag) {
            console.log("true823")
            let qwe3;
            qwe3 = ['ENG_PACT_L1_0023',
                'ENG_PACT_L1_0033',
                'ENG_PACT_L2_0033',
                'ENG_PPT_L1_0002',
                'ENG_PPT_L1_0013',
                'ENG_PPT_L2_0001',
                'ENG_BIxTI_L1_0014',
                'ENG_BIxTI_L1_0043',
                'ENG_BIxTI_L2_0005',
                'ENG_BIxGER_L1_0005']

            let qwe4;
            qwe4 = ['ENG_BIxGER_L1_0007',
                'ENG_BIxGER_L1_0047',
                'ENG_ART_L1_0001',
                'ENG_ART_L1_0003',
                'ENG_ART_L1_0049',
                'ENG_COM_L1_0002',
                'ENG_COM_L1_0038',
                'ENG_COM_L2_0007',
                'ENG_CONJ_L1_0002',
                'ENG_CONJ_L1_0006']

            let qwe5;
            qwe5 = ['ENG_CONJ_L2_0006']


            // create calibration test IF not exist
            let tmpArray = [];


            const qMultipleChoice = query(collection(db, "Question"), where("level", "==", "calibrationTest"), where("type", "==", "multipleChoice"));
            const q00 = query(collection(db, "Question"), where('questionCode', 'in', qwe3));


            const querySnapshotMultipleChoice = await getDocs(q00);
            querySnapshotMultipleChoice.forEach((doc) => {
                tmpArray.push(doc.id)
            });


            const qFillInTheBlank = query(collection(db, "Question"), where("level", "==", "calibrationTest"), where("type", "==", "fillInTheBlank"));
            const q01 = query(collection(db, "Question"), where('questionCode', 'in', qwe4));

            const querySnapshotFillInTheBlank = await getDocs(q01);
            querySnapshotFillInTheBlank.forEach((doc) => {
                tmpArray.push(doc.id)
            });

            const q02 = query(collection(db, "Question"), where('questionCode', 'in', qwe5));

            const querySnapshotFillInTheBlank2 = await getDocs(q02);
            querySnapshotFillInTheBlank2.forEach((doc) => {
                tmpArray.push(doc.id)
            });

            console.log("true824")

            if (!calibrationTestExistFlag) {
                await addDoc(collection(db, "Exercise"), {
                    date: new Date(),
                    type: "calibrationTest",
                    user: user.id,
                    question: tmpArray,
                    remainingTime: 900,
                });
            }

        } else {


            // create calibration test IF not exist
            let tmpArray = [];
            const qMultipleChoice = query(collection(db, "Question"), where("level", "==", "calibrationTest"), where("type", "==", "multipleChoice"));
            const querySnapshotMultipleChoice = await getDocs(qMultipleChoice);
            querySnapshotMultipleChoice.forEach((doc) => {
                tmpArray.push(doc.id)
            });
            const qFillInTheBlank = query(collection(db, "Question"), where("level", "==", "calibrationTest"), where("type", "==", "fillInTheBlank"));
            const querySnapshotFillInTheBlank = await getDocs(qFillInTheBlank);
            querySnapshotFillInTheBlank.forEach((doc) => {
                tmpArray.push(doc.id)
            });
            if (!calibrationTestExistFlag) {
                await addDoc(collection(db, "Exercise"), {
                    date: new Date(),
                    type: "calibrationTest",
                    user: user.id,
                    question: tmpArray,
                    remainingTime: 900,
                });
            }


        }

        navigate('/calibrationTest')
    }

    return (
        <>
            <div className="languageContainer">
                <img className="welcomeLanguage" src={languageSVG} alt="home"/>
                <Language/>
            </div>
            {/*<SimpleDialog*/}
            {/*    // selectedValue={selectedValue}*/}
            {/*    open={process2}*/}
            {/*    // onClose={handleClose}*/}
            {/*/>*/}
            <LoadingOverlay
                active={process2}
                // spinner={<BounceLoader />}
                spinner={true}
                // text="Loading your content..."
            ></LoadingOverlay>

            {process === 1 ?
                <div className="welcomeBackgroundImage1"
                     style={{


                         backgroundImage: is_m ?
                             l === "en" ? `url(${welcomeBanner1_m})` : `url(${welcomeBannerZHHK1_m})`
                             : l === "en" ? `url(${welcomeBanner1})` : `url(${welcomeBannerZHHK1})`

                     }}>
                    <img className="conversationBoxName" src={ConversationBoxNameSVG} alt="conversation box"/>
                    <div className="conversationBoxContainer4">
                        <p className="welcomeMyNameText">{t("HELLO_NAME")}</p>
                        <TextField
                            value={nickname} onChange={(e) => {
                            setNickname(e.target.value)
                        }}
                            placeholder={t('TYPE_HERE')}
                            // placeholder={ `${height}`+`${width}`}
                            className="welcomeNameTextField"
                            InputProps={{
                                style: {
                                    fontSize: is_m ? "5vw" : "1.4vw",
                                    fontFamily: "Poppins",
                                    fontWeight: '500'
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img className="welcomeNamePen" src={penSVG} alt="pen"/>
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                        <div onClick={() => {
                            setProcess(2);
                            updateNickname()
                        }}>
                            <ConfirmButton/>
                        </div>
                    </div>
                </div>
                : process === 2 ?
                    <div className="welcomeBackgroundImage2"
                         style={{
                             backgroundImage: is_m ?
                                 l === "en" ? `url(${welcomeBanner2_m})` : `url(${welcomeBannerZHHK2_m})`
                                 :
                                 l === "en" ? `url(${welcomeBanner2})` : `url(${welcomeBannerZHHK2})`

                         }}>
                        <img className="conversationBox3" src={ConversationBoxSVG} alt="conversation box"/>
                        {!is_m&&<img className="happy" src={HappySVG} alt="happy"/>}
                        <div className="conversationBoxContainer2">
                            <p className="welcome1Text1">( {t("TRY_CONVERSATION")}! )</p>
                            <div className="conversationOptionContainer" onClick={() => {
                                toProcess3N4()
                            }}>
                                <span className="welcome1Text2">{t('NICE_MEET')}</span>
                            </div>
                            <div className="conversationOptionContainer" onClick={() => {
                                toProcess6N7N4()
                            }}>
                                <span className="welcome1Text2">{t("WHO_ARE_YOU")}</span>
                            </div>
                        </div>
                    </div>
                    : process === 3 ?
                        <>
                            <div className="welcomeBackgroundImage3" onClick={() => {
                                setProcess(4)
                            }}/>
                            <p className="welcomeContinueText">({t("CLICK_TO_CONTINUE")})</p>
                        </>
                        : process === 4 ?
                            <div className="welcomeBackgroundImage4"
                                 style={{
                                     backgroundImage: is_m ?
                                         l === "en" ? `url(${welcomeBanner4_m})` : `url(${welcomeBannerZHHK4_m})`
                                         :
                                         l === "en" ? `url(${welcomeBanner4})` : `url(${welcomeBannerZHHK4})`
                                 }}>
                                <img className="conversationBox" src={ConversationBoxSVG} alt="conversation box"/>
                                {!is_m&&<img className="nerd" src={NerdSVG} alt="nerd"/>}
                                <div className="conversationBoxContainer">
                                    <div className="welcome3Margin"></div>
                                    <div className="conversationOptionContainer" onClick={() => {
                                        toProcess5N8()
                                    }}>
                                        <span className="welcome1Text2">{t("WILL_IT_BE_DIFFICULT")}
                                        </span>
                                    </div>

                                    {is_m&&<div className="welcome3Margin"></div>}
                                    {is_m&&<div className="welcome3Margin"></div>}
                                    {is_m&&<div className="welcome3Margin"></div>}
                                    <div className="conversationOptionContainer" onClick={() => {
                                        setProcess(11)
                                    }}>
                                        <span className="welcome1Text2">{t("I_M_READY")}</span>
                                    </div>
                                </div>
                            </div>
                            : process === 5 ?
                                <>
                                    <div className="welcomeBackgroundImage5" onClick={() => {
                                        setProcess(11)
                                    }}
                                         style={{
                                             backgroundImage: is_m ?

                                                 l === "en" ? `url(${welcomeBanner5_m})` : `url(${welcomeBannerZHHK5_m})` :
                                                 l === "en" ? `url(${welcomeBanner5})` : `url(${welcomeBannerZHHK5})`

                                         }}/>
                                    <p className="welcomeContinueText">({t("CLICK_TO_CONTINUE")})</p>
                                </>
                                : process === 6 ?
                                    <>
                                        <div className="welcomeBackgroundImage6" onClick={() => {
                                            setProcess(7)
                                        }}/>
                                        <p className="welcomeContinueText">({t("CLICK_TO_CONTINUE")})</p>
                                    </>
                                    : process === 7 ?
                                        <>
                                            <div className="welcomeBackgroundImage7" onClick={() => {
                                                setProcess(4)
                                            }}
                                                 style={{
                                                     backgroundImage: is_m ?
                                                         l === "en" ? `url(${welcomeBanner7_m})` : `url(${welcomeBannerZHHK7_m})`
                                                         :
                                                         l === "en" ? `url(${welcomeBanner7})` : `url(${welcomeBannerZHHK7})`
                                                 }}/>
                                            <p className="welcomeContinueText">({t("CLICK_TO_CONTINUE")})</p>
                                        </>
                                        : process === 8 &&
                                        <div onClick={() => {
                                            finishWelcome();

                                        }} className="welcomeBackgroundImage8"
                                             style={{
                                                 backgroundImage: is_m ?
                                                     l === "en" ? `url(${welcomeBanner8_m})` : `url(${welcomeBannerZHHK8_m})`
                                                     :
                                                     l === "en" ? `url(${welcomeBanner8})` : `url(${welcomeBannerZHHK8})`
                                             }}/>
            }

            {
                process === 11 &&
                <div className="welcomeBackgroundImage4"
                     style={{
                         backgroundImage: is_m ?
                             l === "en" ? `url(${welcomeBanner11_m})` : `url(${welcomeBannerZHHK11_m})`
                             :
                             l === "en" ? `url(${welcomeBanner11})` : `url(${welcomeBannerZHHK11})`
                     }}
                >
                    <img className="conversationBox" src={ConversationBoxSVG} alt="conversation box"/>
                    {/*<img className="nerd" src={NerdSVG} alt="nerd"/>*/}
                    <div className="conversationBoxContainer5">
                        {/*<div className="welcome3Margin"></div>*/}
                        {/*<div className="conversationOptionContainer" onClick={() => {*/}
                        {/*    toProcess5N8()*/}
                        {/*}}>*/}
                        {/*                <span className="welcome1Text2">{t("WILL_IT_BE_DIFFICULT")}*/}
                        {/*                </span>*/}
                        {/*</div>*/}
                        {/*<div className="conversationOptionContainer" onClick={() => {*/}
                        {/*    setProcess(8)*/}
                        {/*}}>*/}
                        {/*    <span className="welcome1Text2">{t("I_M_READY")}</span>*/}
                        {/*</div>*/}

                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <div onClick={() => {
                                    const userRef = doc(db, 'User', user.id);
                                    updateDoc(userRef, {formm: "p4"});


                                    setProcess(8)

                                }
                                }>
                                    <img
                                        // className="p4"
                                        style={{
                                            width: '100%',
                                            backgroundRepeat: 'no-repeat'
                                        }}
                                        src={p4} alt="pen"/>

                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div onClick={() => {

                                    const userRef = doc(db, 'User', user.id);
                                    updateDoc(userRef, {formm: "p5"});


                                    setProcess(8)

                                }
                                }>
                                    <img
                                        // className="p4"
                                        style={{
                                            width: '100%',
                                            backgroundRepeat: 'no-repeat'
                                        }}
                                        src={p5} alt="pen"/>

                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div onClick={() => {
                                    const userRef = doc(db, 'User', user.id);
                                    updateDoc(userRef, {formm: "p5"});


                                    setProcess(8)

                                }
                                }>
                                    <img
                                        // className="p4"
                                        style={{
                                            width: '100%',
                                            backgroundRepeat: 'no-repeat'
                                        }}
                                        src={p6} alt="pen"/>

                                </div>
                            </Grid>
                        </Grid>


                    </div>
                </div>
            }
        </>
    );
}

export default Welcome;
