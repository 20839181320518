import React from 'react';
import Grid from "@mui/material/Grid";
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";

import '../../App.css';
import './_styles.css';

import '../../translation'
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../localStorage/Language';

import LearningVideoEmbed from './LearningVideoEmbed';
import { VideoSuggestionBackToHomeButton } from './_components';

import speakerSVG from '../../assets/video/speaker.svg';
import messageBoxSVG from '../../assets/video/messageBox.svg';
import closeSVG from '../../assets/general/close.svg';


function VideoSuggestion({
    todaysVideoModalVisibility,
    setTodaysVideoModalVisibility,
    video,
    embedId,
    practiceTopic
}) {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const l = getLanguage();
    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={todaysVideoModalVisibility}
                className="videoSuggestionModalContainer"
                style={{ overlay: { backgroundColor: 'rgba(0, 0, 0, 0.3)', backdropFilter: "blur(6px)", WebkitBackdropFilter: "blur(6px)", zIndex: 1000000 } }}
            >
                <div className="videoSuggestionBackgroundImage">
                    <img onClick={() => {
                        setTodaysVideoModalVisibility(false)
                    }} className="videoSuggestionModalClose" src={closeSVG} alt="close" />
                    <Grid container spacing={2}>
                        <Grid item xs={1}>
                            <img className="speakerImage" src={speakerSVG} alt="speaker" />
                        </Grid>
                        <Grid item xs={11} align="left">
                            <p className="videoSuggestionTopicText">{t("TODAYS_VIDEO")}</p>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="flex-end">
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                            <LearningVideoEmbed embedId={video.embedId} />
                            <div className="videoSuggestionReturnToHomeDisplayInlineBlock" onClick={() => {
                                navigate('/')
                                setTodaysVideoModalVisibility(false);
                            }}>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </>
    );
}

export default VideoSuggestion;
