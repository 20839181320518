import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {makeStyles, withStyles} from '@mui/styles';

import '../../translation'
import {useTranslation} from 'react-i18next';

export const LoginTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '100px',
                width: 'calc( 100% - 18px )',
            },
        },
    },
})(TextField);

export const LoginFailedTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '100px',
                width: 'calc( 100% - 18px )',
                borderColor: '#D63737',
            },
        },
    },
})(TextField);

export const LoginTextFieldLong = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '30px',
                width: '100%'
            },
        },
    },
})(TextField);

export const LoginFailedTextFieldLong = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '30px',
                width: '100%',
                borderColor: '#D63737',
            },
        },
    },
})(TextField);

export const LoginTextFieldSingleDigit = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '10px',
                width: '4vw',
                height: '6vw',
                borderColor: '#F6C840',
            },
        },
    },
})(TextField);

export const LoginButton = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '100px',
                width: '100%',
            },
        },
    },
})(Button);

export const SendButton = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '100px',
                width: '100%',
            },
        },
    },
})(Button);

export const AccountSettingsTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '100px',
                width: '100%',
            },
        },
    },
})(TextField);

export const AccountSettingsConfirmButton = () => {
    const { t } = useTranslation();
    const useStyles = makeStyles({
        button: {
            backgroundColor: '#3A5AFF',
            color: '#FFFFFF',
            width: '11vw',
            borderRadius: '3vw',
            fontSize: '1.2vw',
            fontFamily: 'Nunito',
            fontWeight: '800',
            letterSpacing: '0.1vw',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: '#3A5AFF',
                color: '#FFFFFF',
            },
        }
    })
    const classes = useStyles()
    return (
        <Button disableRipple className={classes.button}>
            {t("CONFIRM")}
        </Button>
    );
};

export const AccountSettingsBackToHomeButton = () => {
    const { t } = useTranslation();
    const useStyles = makeStyles({
        button: {
            backgroundColor: '#3A5AFF',
            color: '#FFFFFF',
            width: '11vw',
            borderRadius: '3vw',
            fontSize: '1vw',
            fontFamily: 'Nunito',
            fontWeight: '800',
            letterSpacing: '0.1vw',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: '#3A5AFF',
                color: '#FFFFFF',
            },
        }
    })
    const classes = useStyles()
    return (
        <Button disableRipple className={classes.button}>
            {t("UPDATED_NAME_BTN")}
        </Button>
    );
};
