import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import '../../App.css';
import './_styles.css';

import ConfirmLeave from '../../pages/exercise/ConfirmLeave';

import dashboardSVG from '../../assets/menu/dashboard.svg';
import dashboardFocusedSVG from '../../assets/menu/dashboardFocused.svg';
import resultSVG from '../../assets/menu/result.svg';
import resultFocusedSVG from '../../assets/menu/resultFocused.svg';
import answerReviewSVG from '../../assets/menu/answerReview.svg';
import answerReviewFocusedSVG from '../../assets/menu/answerReviewFocused.svg';
import learningVideoSVG from '../../assets/menu/learningVideo.svg';
import learningVideoFocusedSVG from '../../assets/menu/learningVideoFocused.svg';
import exerciseSVG from '../../assets/menu/exercise.svg';
import exerciseFocusedSVG from '../../assets/menu/exerciseFocused.svg';
import settingSVG from '../../assets/menu/setting.svg';
import settingFocusedSVG from '../../assets/menu/settingFocused.svg';
import contactUsSVG from '../../assets/menu/contactUs.svg';
import contactUsFocusedSVG from '../../assets/menu/contactUsFocused.svg';
import menuToLargeSVG from '../../assets/menu/menuToLarge.svg';
import menuToSmallSVG from '../../assets/menu/menuToSmall.svg';
import {useTranslation} from 'react-i18next'
import {useMediaQuery} from "react-responsive";
import moreSVG from "../../assets/menu/More.svg";
import Logout from "../../pages/login/Logout";
import {getUser} from "../../localStorage/User";

// import { getMenuSize, setMenuSize } from '../../localStorage/MenuSize';
import logoutSVG from '../../assets/menu/logout.svg';
function SideMenu() {
    const [menuSize, setMenuSize] = useState("small");

    const [logoutModalVisibility, setLogoutModalVisibility] = useState(false);
    const [confirmLeaveModalVisibility, setConfirmLeaveModalVisibility] = useState(false);
    const [confirmLeaveModalTarget, setConfirmLeaveModalTarget] = useState();
    let navigate = useNavigate();
    const {t} = useTranslation();
    const user = getUser();




    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })


    useEffect(() => {
        if (!is_m && menuSize === "large") {
            console.log("setting!!")
            toSmallMenu()
        }
        if (is_m && menuSize === "small") {
            console.log("setting!!")
            toSmallMenu()
        }

    },[is_m])
    const toLargeMenu = () => {
        setMenuSize("large");


        document.getElementById("menuContainer").style.width = "180px";
        document.getElementById("menuContainer").style.textAlign = "left";
        document.getElementById("menuContainer").style.paddingLeft = "28px";


        // make short part disappear
        document.getElementById("menuHomeBarContainerShort").style.opacity = "0";
        document.getElementById("menuResulBarContainerShort").style.opacity = "0";
        document.getElementById("menuAnswerReviewBarContainerShort").style.opacity = "0";
        document.getElementById("menuLearningVideoBarContainerShort").style.opacity = "0";
        document.getElementById("menuExerciseBarContainerShort").style.opacity = "0";
        document.getElementById("menuSettingBarContainerShort").style.opacity = "0";
        document.getElementById("menuContactUsBarContainerShort").style.opacity = "0";

        setTimeout(() => {
            document.getElementById("menuTitle").style.opacity = "1";
            if (document.getElementById("fordisplayNoneWhenMobileSmall")){
                document.getElementById("fordisplayNoneWhenMobileSmall").style.display = "unset";
            }


            // make 6 short part appear if it is in the dom
            document.getElementById("menuHomeBarContainer").style.opacity = "1";
            document.getElementById("menuResulBarContainer").style.opacity = "1";
            document.getElementById("menuAnswerReviewBarContainer").style.opacity = "1";
            document.getElementById("menuLearningVideoBarContainer").style.opacity = "1";
            document.getElementById("menuExerciseBarContainer").style.opacity = "1";
            document.getElementById("menuSettingBarContainer").style.opacity = "1";
            document.getElementById("menuContactUsBarContainer").style.opacity = "1";

            // make bird appear
            document.getElementById("bottomBirdContainer").style.opacity = "1";
        }, 300);
    }
    const toSmallMenu = () => {
        setMenuSize("small");


        // make the long part disappear


        if(is_m){
            document.getElementById("menuContainer").style.width = "0px";

        }else{
            document.getElementById("menuContainer").style.width = "80px";
        }

        document.getElementById("menuContainer").style.textAlign = "center";
        document.getElementById("menuContainer").style.paddingLeft = "0px";


        document.getElementById("menuTitle").style.opacity = "0";
        document.getElementById("menuHomeBarContainer").style.opacity = "0";
        document.getElementById("menuResulBarContainer").style.opacity = "0";
        document.getElementById("menuAnswerReviewBarContainer").style.opacity = "0";
        document.getElementById("menuLearningVideoBarContainer").style.opacity = "0";
        document.getElementById("menuExerciseBarContainer").style.opacity = "0";
        document.getElementById("menuSettingBarContainer").style.opacity = "0";
        document.getElementById("menuContactUsBarContainer").style.opacity = "0";

        // make bird disappear
        document.getElementById("bottomBirdContainer").style.opacity = "0";
        if (is_m) {

            document.getElementById("fordisplayNoneWhenMobileSmall").style.display = "none";
            // for mobile hide all the short part

            document.getElementById("menuHomeBarContainerShort").style.opacity = "0";
            document.getElementById("menuResulBarContainerShort").style.opacity = "0";
            document.getElementById("menuAnswerReviewBarContainerShort").style.opacity = "0";
            document.getElementById("menuLearningVideoBarContainerShort").style.opacity = "0";
            document.getElementById("menuExerciseBarContainerShort").style.opacity = "0";
            document.getElementById("menuSettingBarContainerShort").style.opacity = "0";
            document.getElementById("menuContactUsBarContainerShort").style.opacity = "0";
        }else{

            setTimeout(() => {


                document.getElementById("menuHomeBarContainerShort").style.opacity = "1";
                document.getElementById("menuResulBarContainerShort").style.opacity = "1";
                document.getElementById("menuAnswerReviewBarContainerShort").style.opacity = "1";
                document.getElementById("menuLearningVideoBarContainerShort").style.opacity = "1";
                document.getElementById("menuExerciseBarContainerShort").style.opacity = "1";
                document.getElementById("menuSettingBarContainerShort").style.opacity = "1";
                document.getElementById("menuContactUsBarContainerShort").style.opacity = "1";

            })
        }


    }
    return (
        <>

            {is_m ?


                <div
                    id="menuContainer"
                    className="sideMenuContainer"

                    style={{
                        width: menuSize == "small" ? "0%" : "100%",
                        textAlign: menuSize == "small" ? "center" : "left",
                        paddingLeft: menuSize == "small" ? 0 : 28,
                    }}>

                    <img onClick={() => {

                        if (menuSize === "small") {
                            toLargeMenu()
                        } else {
                            toSmallMenu()
                        }

                    }} className="menuLogoLeft" src={moreSVG} alt="more logo"/>


                    <div id="fordisplayNoneWhenMobileSmall">
                        <div
                            id="menuTitle"
                            style={{opacity: "0"}}
                        >
                            {false && menuSize !== "small" &&
                                <div className="sideMenuTitleContainer">
                                    <p className="sideMenuTitle">{t("MENU")}</p>
                                </div>
                            }
                        </div>



                        {/*1 home button*/}

                        {/*this is the red vertical line in small menu*/}
                        <div id="menuHomeBarContainerShort" style={{opacity: "1"}}>
                            {
                                window.location.pathname === "/" && menuSize === "small" &&

                                <div className="sideMenuDashboardBar">
                                </div>
                            }
                        </div>
                        {/*this is the red vertical line in big menu*/}
                        <div id="menuHomeBarContainer" style={{opacity: "0"}}>
                            {
                                window.location.pathname === "/" && menuSize !== "small" &&

                                <div className="sideMenuDashboardBarLong">

                                </div>
                            }
                        </div>


                        <div
                            onClick={() => {
                                if (window.location.pathname === "/practice") {
                                    setConfirmLeaveModalVisibility(true);
                                    setConfirmLeaveModalTarget("")
                                } else {
                                    navigate('/')
                                }
                            }}
                            className="sideSVGContainerShort"
                            style={
                                {height: menuSize == "small" ? "68px" : "60px"}
                            }
                        >


                            {window.location.pathname === "/" ?
                                <>
                                    {menuSize == "small" ?


                                        <img className="sideMenuSVG" src={dashboardFocusedSVG} alt="dashboard focused"/>
                                        :
                                        <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                            <img className="sideMenuSVG" src={dashboardFocusedSVG} alt="dashboard focused"/>
                                            <p className="sideMenuDashboardTextLong"
                                               style={{fontWeight: window.location.pathname === "/" && '700'}}>{t('HOME')}</p>
                                        </div>
                                    }

                                </>
                                :
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={dashboardSVG} alt="dashboard"/>
                                        :
                                        <div className="sideMenuBox">
                                            <img className="sideMenuSVG" src={dashboardSVG} alt="dashboard"/>
                                            <p className="sideMenuDashboardTextLong"
                                               style={{fontWeight: window.location.pathname === "/" && '700'}}>{t('HOME')}</p>
                                        </div>
                                    }
                                </>
                            }
                            {menuSize == "small" && <p className="sideMenuDashboardText"
                                                       style={{fontWeight: window.location.pathname === "/" && '700'}}>{t('HOME')}</p>}
                        </div>

                        {/*result button*/}
                        <div id="menuResulBarContainerShort" style={{opacity: "1"}}>
                            {window.location.pathname === "/result" && menuSize === "small" &&
                                <div className="sideMenuResultBar"></div>}
                        </div>


                        <div id="menuResulBarContainer" style={{opacity: "0"}}>
                            {window.location.pathname === "/result" && menuSize !== "small" &&
                                <div className="sideMenuResultBarLong"></div>}
                        </div>


                        <div
                            onClick={() => {
                                if (window.location.pathname === "/practice") {
                                    setConfirmLeaveModalVisibility(true);
                                    setConfirmLeaveModalTarget("result")
                                } else {
                                    navigate('/result')
                                }
                            }}
                            className="sideSVGContainerShort"
                            style={{height: menuSize == "small" ? "68px" : "60px"}}
                        >
                            {window.location.pathname === "/result" ?
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={resultFocusedSVG} alt="result focused"/>
                                        :
                                        <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                            <img className="sideMenuSVG" src={resultFocusedSVG} alt="result focused"/>
                                            <p className="sideMenuResultTextLong"
                                               style={{fontWeight: window.location.pathname === "/result" && '700'}}>{t('RESULT')}</p>
                                        </div>
                                    }

                                </>
                                :
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={resultSVG} alt="result"/>
                                        :
                                        <div className="sideMenuBox">
                                            <img className="sideMenuSVG" src={resultSVG} alt="result"/>
                                            <p className="sideMenuResultTextLong"
                                               style={{fontWeight: window.location.pathname === "/result" && '700'}}>{t('RESULT')}</p>
                                        </div>
                                    }
                                </>
                            }
                            {menuSize == "small" && <p className="sideMenuResultText"
                                                       style={{fontWeight: window.location.pathname === "/result" && '700'}}>{t('RESULT')}</p>}
                        </div>


                        {/*review button*/}

                        <div id="menuAnswerReviewBarContainerShort" style={{opacity: "1"}}>
                            {window.location.pathname === "/answerReview" && menuSize === "small" &&
                                <div className="sideMenuAsnwerResultBar"></div>}
                        </div>

                        <div id="menuAnswerReviewBarContainer" style={{opacity: "0"}}>
                            {window.location.pathname === "/answerReview" && menuSize !== "small" &&
                                <div className="sideMenuAsnwerResultBarLong"></div>}
                        </div>

                        <div
                            onClick={() => {
                                if (window.location.pathname === "/practice") {
                                    setConfirmLeaveModalVisibility(true);
                                    setConfirmLeaveModalTarget("answerReview")
                                } else {
                                    navigate('/answerReview')
                                }
                            }}
                            className="sideSVGContainerAnswerReview"
                            style={{height: menuSize == "small" ? "82px" : "60px"}}
                        >
                            {window.location.pathname === "/answerReview" ?
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={answerReviewFocusedSVG} alt="answer review"/>
                                        :
                                        <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                            <img className="sideMenuSVG" src={answerReviewFocusedSVG} alt="answer review"/>
                                            <p className="sideMenuAnswerReviewTextLong"
                                               style={{fontWeight: window.location.pathname === "/answerReview" && '700'}}>{t('ANS_REVIEW')}</p>
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={answerReviewSVG} alt="answer review"/>
                                        :
                                        <div className="sideMenuBox">
                                            <img className="sideMenuSVG" src={answerReviewSVG} alt="answer review"/>
                                            <p className="sideMenuAnswerReviewTextLong"
                                               style={{fontWeight: window.location.pathname === "/answerReview" && '700'}}>{t('ANS_REVIEW')}</p>
                                        </div>
                                    }
                                </>

                            }
                            {menuSize == "small" && <p className="sideMenuAnswerReviewText"
                                                       style={{fontWeight: window.location.pathname === "/answerReview" && '700'}}>{t('ANS_REVIEW')}</p>}
                        </div>


                        {/*learning video button*/}
                        <div id="menuLearningVideoBarContainerShort" style={{opacity: "1"}}>
                            {(window.location.pathname === "/learningVideo" || window.location.pathname === "/learningVideoPlayer") && menuSize === "small" &&
                                <div className="sideMenuLearningVideoBar"></div>}
                        </div>

                        <div id="menuLearningVideoBarContainer" style={{opacity: "0"}}>
                            {(window.location.pathname === "/learningVideo" || window.location.pathname === "/learningVideoPlayer") && menuSize !== "small" &&
                                <div className="sideMenuLearningVideoBarLong"></div>}
                        </div>

                        <div
                            onClick={() => {
                                if (window.location.pathname === "/practice") {
                                    setConfirmLeaveModalVisibility(true);
                                    setConfirmLeaveModalTarget("learningVideo")
                                } else {
                                    navigate('/learningVideo')
                                }
                            }}
                            className="sideSVGContainerLearningVideo"
                            style={{height: menuSize == "small" ? "74px" : "60px"}}
                        >
                            {(window.location.pathname === "/learningVideo" || window.location.pathname === "/learningVideoPlayer") ?
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={learningVideoFocusedSVG} alt="learning video"/>
                                        :
                                        <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                            <img className="sideMenuSVG" src={learningVideoFocusedSVG}
                                                 alt="learning video"/>
                                            <p className="sideMenuLearningVideoTextLong"
                                               style={{fontWeight: window.location.pathname === "/learningVideo" && '700'}}>{t('LEARNING_VID')}</p>
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={learningVideoSVG} alt="learning video"/>
                                        :
                                        <div className="sideMenuBox">
                                            <img className="sideMenuSVG" src={learningVideoSVG} alt="learning video"/>
                                            <p className="sideMenuLearningVideoTextLong"
                                               style={{fontWeight: window.location.pathname === "/learningVideo" && '700'}}>{t('LEARNING_VID')}</p>
                                        </div>
                                    }
                                </>
                            }
                            {menuSize == "small" && <p className="sideMenuLearningVideoText"
                                                       style={{fontWeight: (window.location.pathname === "/learningVideo" || window.location.pathname === "/learningVideoPlayer") && '700'}}>{t('LEARNING_VID')}</p>}
                        </div>


                        {/*exercise button*/}
                        <div id="menuExerciseBarContainerShort" style={{opacity: "1"}}>
                            {window.location.pathname === "/exercise" && menuSize === "small" &&
                                <div className="sideMenuExerciseBar"></div>}
                        </div>

                        <div id="menuExerciseBarContainer" style={{opacity: "0"}}>
                            {window.location.pathname === "/exercise" && menuSize !== "small" &&
                                <div className="sideMenuExerciseBarLong"></div>}
                        </div>

                        <div
                            onClick={() => {
                                if (window.location.pathname === "/practice") {
                                    setConfirmLeaveModalVisibility(true);
                                    setConfirmLeaveModalTarget("exercise")
                                } else {
                                    navigate('/exercise')
                                }
                            }}
                            className="sideSVGContainerShort"
                            style={{height: menuSize == "small" ? "68px" : "60px"}}
                        >
                            {window.location.pathname === "/exercise" ?
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={exerciseFocusedSVG} alt="exercise"/>
                                        :
                                        <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                            <img className="sideMenuSVG" src={exerciseFocusedSVG} alt="exercise"/>
                                            <p className="sideMenuExerciseTextLong"
                                               style={{fontWeight: window.location.pathname === "/exercise" && '700'}}>{t('EXERCISES')}</p>
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={exerciseSVG} alt="exercise"/>
                                        :
                                        <div className="sideMenuBox">
                                            <img className="sideMenuSVG" src={exerciseSVG} alt="exercise"/>
                                            <p className="sideMenuExerciseTextLong"
                                               style={{fontWeight: window.location.pathname === "/exercise" && '700'}}>{t('EXERCISES')}</p>
                                        </div>
                                    }
                                </>
                            }
                            {menuSize == "small" && <p className="sideMenuExerciseText"
                                                       style={{fontWeight: window.location.pathname === "/exercise" && '700'}}>{t('EXERCISES')}</p>}
                        </div>

                        {/*account setting button*/}
                        <div id="menuSettingBarContainerShort" style={{opacity: "1"}}>
                            {window.location.pathname === "/accountSetting" && menuSize === "small" &&
                                <div className="sideMenuAccountSettingBar"></div>}
                        </div>

                        <div id="menuSettingBarContainer" style={{opacity: "0"}}>
                            {window.location.pathname === "/accountSetting" && menuSize !== "small" &&
                                <div className="sideMenuAccountSettingBarLong"></div>}
                        </div>

                        <div
                            onClick={() => {
                                if (window.location.pathname === "/practice") {
                                    setConfirmLeaveModalVisibility(true);
                                    setConfirmLeaveModalTarget("accountSetting")
                                } else {
                                    navigate('/accountSetting')
                                }
                            }}
                            className="sideSVGContainerLong"
                            style={{height: menuSize == "small" ? "78px" : "60px"}}
                        >
                            {window.location.pathname === "/accountSetting" ?
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={settingFocusedSVG} alt="setting"/>
                                        :
                                        <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                            <img className="sideMenuSVG" src={settingFocusedSVG} alt="setting"/>
                                            <p className="sideMenuSettingTextLong"
                                               style={{fontWeight: window.location.pathname === "/accountSetting" && '700'}}>{t('ACC_SETTING')}</p>
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={settingSVG} alt="setting"/>
                                        :
                                        <div className="sideMenuBox">
                                            <img className="sideMenuSVG" src={settingSVG} alt="setting"/>
                                            <p className="sideMenuSettingTextLong"
                                               style={{fontWeight: window.location.pathname === "/accountSetting" && '700'}}>{t('ACC_SETTING')}</p>
                                        </div>
                                    }
                                </>
                            }
                            {menuSize == "small" && <p className="sideMenuSettingText"
                                                       style={{fontWeight: window.location.pathname === "/accountSetting" && '700'}}>{t('ACC_SETTING')}</p>}
                        </div>


                        {/*contact us button*/}
                        <div id="menuContactUsBarContainerShort" style={{opacity: "1"}}>
                            {window.location.pathname === "/contactUs" && menuSize === "small" &&
                                <div className="sideMenuContactUsBar"></div>}
                        </div>

                        <div id="menuContactUsBarContainer" style={{opacity: "0"}}>
                            {window.location.pathname === "/contactUs" && menuSize !== "small" &&
                                <div className="sideMenuContactUsBarLong"></div>}
                        </div>

                        <div
                            onClick={() => {
                                if (window.location.pathname === "/practice") {
                                    setConfirmLeaveModalVisibility(true);
                                    setConfirmLeaveModalTarget("contactUs")
                                } else {
                                    navigate('/contactUs')
                                }
                            }}
                            className="sideSVGContainerLong"
                            style={{height: menuSize == "small" ? "78px" : "60px"}}
                        >
                            {window.location.pathname === "/contactUs" ?
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={contactUsFocusedSVG} alt="contact us"/>
                                        :
                                        <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                            <img className="sideMenuSVG" src={contactUsFocusedSVG} alt="contact us"/>
                                            <p className="sideMenuContactUsTextLong"
                                               style={{fontWeight: window.location.pathname === "/contactUs" && '700'}}>{t('CONTACT_US')}</p>
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    {menuSize == "small" ?
                                        <img className="sideMenuSVG" src={contactUsSVG} alt="contact us"/>
                                        :
                                        <div className="sideMenuBox">
                                            <img className="sideMenuSVG" src={contactUsSVG} alt="contact us"/>
                                            <p className="sideMenuContactUsTextLong"
                                               style={{fontWeight: window.location.pathname === "/contactUs" && '700'}}>{t('CONTACT_US')}</p>
                                        </div>
                                    }
                                </>
                            }
                            {menuSize == "small" && <p className="sideMenuContactUsText"
                                                       style={{fontWeight: window.location.pathname === "/contactUs" && '700'}}>{t('CONTACT_US')}</p>}
                        </div>


                        {/*bird picture*/}
                        <div id="bottomBirdContainer" style={{opacity: "0"}}>
                            {menuSize !== "small" &&
                                <img id="bottomBird"
                                     className="sideMenuBottomImg"
                                     src={require(`../../assets/menu/sideMenuBottom_m.png`)}
                                     alt="bird"
                                />
                            }
                            {/* <img onMouseEnter={() => { toSmallMenu(); }} className="menuToSmall" src={menuToSmallSVG} alt="to small menu" /> */}
                        </div>



                        <div id="forlogout">
                            {(user.id !== "" && user.id !== null && user.id !== undefined) &&
                                <div className="logoutContainerSideBar" onClick={() => {
                                    setLogoutModalVisibility(true)
                                }}>
                                    <img className="menuLogoutSideBar" src={logoutSVG} alt="logout"/>
                                    <p className="logoutTextSideBar">{t('LOGOUT')}</p>
                                </div>
                            }
                            <Logout logoutModalVisibility={logoutModalVisibility} setLogoutModalVisibility={setLogoutModalVisibility}/>


                        </div>
                    </div>
                </div>


                :


                <div
                    id="menuContainer"
                    className="sideMenuContainer"
                    onMouseEnter={() => {
                        toLargeMenu();
                    }}
                    onMouseLeave={() => {
                        toSmallMenu();
                    }} style={{
                    width: menuSize == "small" ? 80 : 180,
                    textAlign: menuSize == "small" ? "center" : "left",
                    paddingLeft: menuSize == "small" ? 0 : 28,
                }}>
                    <div
                        id="menuTitle"
                        style={{opacity: "0"}}
                    >
                        {menuSize !== "small" &&
                            <div className="sideMenuTitleContainer">
                                <p className="sideMenuTitle">{t("MENU")}</p>
                            </div>
                        }
                    </div>


                    {/*this is the red vertical line in small menu*/}
                    <div id="menuHomeBarContainerShort" style={{opacity: "1"}}>
                        {
                            window.location.pathname === "/" && menuSize === "small" &&

                            <div className="sideMenuDashboardBar">
                            </div>
                        }
                    </div>


                    {/*this is the red vertical line in big menu*/}
                    <div id="menuHomeBarContainer" style={{opacity: "0"}}>
                        {
                            window.location.pathname === "/" && menuSize !== "small" &&

                            <div className="sideMenuDashboardBarLong">

                            </div>
                        }
                    </div>

                    {/*home button*/}
                    <div
                        onClick={() => {
                            if (window.location.pathname === "/practice") {
                                setConfirmLeaveModalVisibility(true);
                                setConfirmLeaveModalTarget("")
                            } else {
                                navigate('/')
                            }
                        }}
                        className="sideSVGContainerShort"
                        style={
                            {height: menuSize == "small" ? "68px" : "60px"}
                        }
                    >


                        {window.location.pathname === "/" ?
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={dashboardFocusedSVG} alt="dashboard focused"/>
                                    :
                                    <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                        <img className="sideMenuSVG" src={dashboardFocusedSVG} alt="dashboard focused"/>
                                        <p className="sideMenuDashboardTextLong"
                                           style={{fontWeight: window.location.pathname === "/" && '700'}}>{t('HOME')}</p>
                                    </div>
                                }

                            </>
                            :
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={dashboardSVG} alt="dashboard"/>
                                    :
                                    <div className="sideMenuBox">
                                        <img className="sideMenuSVG" src={dashboardSVG} alt="dashboard"/>
                                        <p className="sideMenuDashboardTextLong"
                                           style={{fontWeight: window.location.pathname === "/" && '700'}}>{t('HOME')}</p>
                                    </div>
                                }
                            </>
                        }
                        {menuSize == "small" && <p className="sideMenuDashboardText"
                                                   style={{fontWeight: window.location.pathname === "/" && '700'}}>{t('HOME')}</p>}
                    </div>

                    {/*result button*/}
                    <div id="menuResulBarContainerShort" style={{opacity: "1"}}>
                        {window.location.pathname === "/result" && menuSize === "small" &&
                            <div className="sideMenuResultBar"></div>}
                    </div>


                    <div id="menuResulBarContainer" style={{opacity: "0"}}>
                        {window.location.pathname === "/result" && menuSize !== "small" &&
                            <div className="sideMenuResultBarLong"></div>}
                    </div>


                    <div
                        onClick={() => {
                            if (window.location.pathname === "/practice") {
                                setConfirmLeaveModalVisibility(true);
                                setConfirmLeaveModalTarget("result")
                            } else {
                                navigate('/result')
                            }
                        }}
                        className="sideSVGContainerShort"
                        style={{height: menuSize == "small" ? "68px" : "60px"}}
                    >
                        {window.location.pathname === "/result" ?
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={resultFocusedSVG} alt="result focused"/>
                                    :
                                    <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                        <img className="sideMenuSVG" src={resultFocusedSVG} alt="result focused"/>
                                        <p className="sideMenuResultTextLong"
                                           style={{fontWeight: window.location.pathname === "/result" && '700'}}>{t('RESULT')}</p>
                                    </div>
                                }

                            </>
                            :
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={resultSVG} alt="result"/>
                                    :
                                    <div className="sideMenuBox">
                                        <img className="sideMenuSVG" src={resultSVG} alt="result"/>
                                        <p className="sideMenuResultTextLong"
                                           style={{fontWeight: window.location.pathname === "/result" && '700'}}>{t('RESULT')}</p>
                                    </div>
                                }
                            </>
                        }
                        {menuSize == "small" && <p className="sideMenuResultText"
                                                   style={{fontWeight: window.location.pathname === "/result" && '700'}}>{t('RESULT')}</p>}
                    </div>


                    {/*review button*/}

                    <div id="menuAnswerReviewBarContainerShort" style={{opacity: "1"}}>
                        {window.location.pathname === "/answerReview" && menuSize === "small" &&
                            <div className="sideMenuAsnwerResultBar"></div>}
                    </div>

                    <div id="menuAnswerReviewBarContainer" style={{opacity: "0"}}>
                        {window.location.pathname === "/answerReview" && menuSize !== "small" &&
                            <div className="sideMenuAsnwerResultBarLong"></div>}
                    </div>

                    <div
                        onClick={() => {
                            if (window.location.pathname === "/practice") {
                                setConfirmLeaveModalVisibility(true);
                                setConfirmLeaveModalTarget("answerReview")
                            } else {
                                navigate('/answerReview')
                            }
                        }}
                        className="sideSVGContainerAnswerReview"
                        style={{height: menuSize == "small" ? "82px" : "60px"}}
                    >
                        {window.location.pathname === "/answerReview" ?
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={answerReviewFocusedSVG} alt="answer review"/>
                                    :
                                    <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                        <img className="sideMenuSVG" src={answerReviewFocusedSVG} alt="answer review"/>
                                        <p className="sideMenuAnswerReviewTextLong"
                                           style={{fontWeight: window.location.pathname === "/answerReview" && '700'}}>{t('ANS_REVIEW')}</p>
                                    </div>
                                }
                            </>
                            :
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={answerReviewSVG} alt="answer review"/>
                                    :
                                    <div className="sideMenuBox">
                                        <img className="sideMenuSVG" src={answerReviewSVG} alt="answer review"/>
                                        <p className="sideMenuAnswerReviewTextLong"
                                           style={{fontWeight: window.location.pathname === "/answerReview" && '700'}}>{t('ANS_REVIEW')}</p>
                                    </div>
                                }
                            </>

                        }
                        {menuSize == "small" && <p className="sideMenuAnswerReviewText"
                                                   style={{fontWeight: window.location.pathname === "/answerReview" && '700'}}>{t('ANS_REVIEW')}</p>}
                    </div>


                    {/*learning video button*/}
                    <div id="menuLearningVideoBarContainerShort" style={{opacity: "1"}}>
                        {(window.location.pathname === "/learningVideo" || window.location.pathname === "/learningVideoPlayer") && menuSize === "small" &&
                            <div className="sideMenuLearningVideoBar"></div>}
                    </div>

                    <div id="menuLearningVideoBarContainer" style={{opacity: "0"}}>
                        {(window.location.pathname === "/learningVideo" || window.location.pathname === "/learningVideoPlayer") && menuSize !== "small" &&
                            <div className="sideMenuLearningVideoBarLong"></div>}
                    </div>

                    <div
                        onClick={() => {
                            if (window.location.pathname === "/practice") {
                                setConfirmLeaveModalVisibility(true);
                                setConfirmLeaveModalTarget("learningVideo")
                            } else {
                                navigate('/learningVideo')
                            }
                        }}
                        className="sideSVGContainerLearningVideo"
                        style={{height: menuSize == "small" ? "74px" : "60px"}}
                    >
                        {(window.location.pathname === "/learningVideo" || window.location.pathname === "/learningVideoPlayer") ?
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={learningVideoFocusedSVG} alt="learning video"/>
                                    :
                                    <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                        <img className="sideMenuSVG" src={learningVideoFocusedSVG}
                                             alt="learning video"/>
                                        <p className="sideMenuLearningVideoTextLong"
                                           style={{fontWeight: window.location.pathname === "/learningVideo" && '700'}}>{t('LEARNING_VID')}</p>
                                    </div>
                                }
                            </>
                            :
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={learningVideoSVG} alt="learning video"/>
                                    :
                                    <div className="sideMenuBox">
                                        <img className="sideMenuSVG" src={learningVideoSVG} alt="learning video"/>
                                        <p className="sideMenuLearningVideoTextLong"
                                           style={{fontWeight: window.location.pathname === "/learningVideo" && '700'}}>{t('LEARNING_VID')}</p>
                                    </div>
                                }
                            </>
                        }
                        {menuSize == "small" && <p className="sideMenuLearningVideoText"
                                                   style={{fontWeight: (window.location.pathname === "/learningVideo" || window.location.pathname === "/learningVideoPlayer") && '700'}}>{t('LEARNING_VID')}</p>}
                    </div>


                    {/*exercise button*/}
                    <div id="menuExerciseBarContainerShort" style={{opacity: "1"}}>
                        {window.location.pathname === "/exercise" && menuSize === "small" &&
                            <div className="sideMenuExerciseBar"></div>}
                    </div>

                    <div id="menuExerciseBarContainer" style={{opacity: "0"}}>
                        {window.location.pathname === "/exercise" && menuSize !== "small" &&
                            <div className="sideMenuExerciseBarLong"></div>}
                    </div>

                    <div
                        onClick={() => {
                            if (window.location.pathname === "/practice") {
                                setConfirmLeaveModalVisibility(true);
                                setConfirmLeaveModalTarget("exercise")
                            } else {
                                navigate('/exercise')
                            }
                        }}
                        className="sideSVGContainerShort"
                        style={{height: menuSize == "small" ? "68px" : "60px"}}
                    >
                        {window.location.pathname === "/exercise" ?
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={exerciseFocusedSVG} alt="exercise"/>
                                    :
                                    <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                        <img className="sideMenuSVG" src={exerciseFocusedSVG} alt="exercise"/>
                                        <p className="sideMenuExerciseTextLong"
                                           style={{fontWeight: window.location.pathname === "/exercise" && '700'}}>{t('EXERCISES')}</p>
                                    </div>
                                }
                            </>
                            :
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={exerciseSVG} alt="exercise"/>
                                    :
                                    <div className="sideMenuBox">
                                        <img className="sideMenuSVG" src={exerciseSVG} alt="exercise"/>
                                        <p className="sideMenuExerciseTextLong"
                                           style={{fontWeight: window.location.pathname === "/exercise" && '700'}}>{t('EXERCISES')}</p>
                                    </div>
                                }
                            </>
                        }
                        {menuSize == "small" && <p className="sideMenuExerciseText"
                                                   style={{fontWeight: window.location.pathname === "/exercise" && '700'}}>{t('EXERCISES')}</p>}
                    </div>

                    {/*account setting button*/}
                    <div id="menuSettingBarContainerShort" style={{opacity: "1"}}>
                        {window.location.pathname === "/accountSetting" && menuSize === "small" &&
                            <div className="sideMenuAccountSettingBar"></div>}
                    </div>

                    <div id="menuSettingBarContainer" style={{opacity: "0"}}>
                        {window.location.pathname === "/accountSetting" && menuSize !== "small" &&
                            <div className="sideMenuAccountSettingBarLong"></div>}
                    </div>

                    <div
                        onClick={() => {
                            if (window.location.pathname === "/practice") {
                                setConfirmLeaveModalVisibility(true);
                                setConfirmLeaveModalTarget("accountSetting")
                            } else {
                                navigate('/accountSetting')
                            }
                        }}
                        className="sideSVGContainerLong"
                        style={{height: menuSize == "small" ? "78px" : "60px"}}
                    >
                        {window.location.pathname === "/accountSetting" ?
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={settingFocusedSVG} alt="setting"/>
                                    :
                                    <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                        <img className="sideMenuSVG" src={settingFocusedSVG} alt="setting"/>
                                        <p className="sideMenuSettingTextLong"
                                           style={{fontWeight: window.location.pathname === "/accountSetting" && '700'}}>{t('ACC_SETTING')}</p>
                                    </div>
                                }
                            </>
                            :
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={settingSVG} alt="setting"/>
                                    :
                                    <div className="sideMenuBox">
                                        <img className="sideMenuSVG" src={settingSVG} alt="setting"/>
                                        <p className="sideMenuSettingTextLong"
                                           style={{fontWeight: window.location.pathname === "/accountSetting" && '700'}}>{t('ACC_SETTING')}</p>
                                    </div>
                                }
                            </>
                        }
                        {menuSize == "small" && <p className="sideMenuSettingText"
                                                   style={{fontWeight: window.location.pathname === "/accountSetting" && '700'}}>{t('ACC_SETTING')}</p>}
                    </div>


                    {/*contact us button*/}
                    <div id="menuContactUsBarContainerShort" style={{opacity: "1"}}>
                        {window.location.pathname === "/contactUs" && menuSize === "small" &&
                            <div className="sideMenuContactUsBar"></div>}
                    </div>

                    <div id="menuContactUsBarContainer" style={{opacity: "0"}}>
                        {window.location.pathname === "/contactUs" && menuSize !== "small" &&
                            <div className="sideMenuContactUsBarLong"></div>}
                    </div>

                    <div
                        onClick={() => {
                            if (window.location.pathname === "/practice") {
                                setConfirmLeaveModalVisibility(true);
                                setConfirmLeaveModalTarget("contactUs")
                            } else {
                                navigate('/contactUs')
                            }
                        }}
                        className="sideSVGContainerLong"
                        style={{height: menuSize == "small" ? "78px" : "60px"}}
                    >
                        {window.location.pathname === "/contactUs" ?
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={contactUsFocusedSVG} alt="contact us"/>
                                    :
                                    <div className="sideMenuBox" style={{backgroundColor: "white"}}>
                                        <img className="sideMenuSVG" src={contactUsFocusedSVG} alt="contact us"/>
                                        <p className="sideMenuContactUsTextLong"
                                           style={{fontWeight: window.location.pathname === "/contactUs" && '700'}}>{t('CONTACT_US')}</p>
                                    </div>
                                }
                            </>
                            :
                            <>
                                {menuSize == "small" ?
                                    <img className="sideMenuSVG" src={contactUsSVG} alt="contact us"/>
                                    :
                                    <div className="sideMenuBox">
                                        <img className="sideMenuSVG" src={contactUsSVG} alt="contact us"/>
                                        <p className="sideMenuContactUsTextLong"
                                           style={{fontWeight: window.location.pathname === "/contactUs" && '700'}}>{t('CONTACT_US')}</p>
                                    </div>
                                }
                            </>
                        }
                        {menuSize == "small" && <p className="sideMenuContactUsText"
                                                   style={{fontWeight: window.location.pathname === "/contactUs" && '700'}}>{t('CONTACT_US')}</p>}
                    </div>


                    {/*bird picture*/}
                    <div id="bottomBirdContainer" style={{opacity: "0"}}>
                        {menuSize !== "small" &&
                            <img id="bottomBird"
                                 className="sideMenuBottomImg"
                                 src={require(`../../assets/menu/sideMenuBottom.png`)}
                                 alt="bird"
                            />
                        }
                        {/* <img onMouseEnter={() => { toSmallMenu(); }} className="menuToSmall" src={menuToSmallSVG} alt="to small menu" /> */}
                    </div>
                </div>}


            {/* <img onMouseEnter={() => { toLargeMenu(); }} className="menuToLarge" src={menuToLargeSVG} alt="to large menu" /> */}
            <ConfirmLeave confirmLeaveModalTarget={confirmLeaveModalTarget}
                          confirmLeaveModalVisibility={confirmLeaveModalVisibility}
                          setConfirmLeaveModalVisibility={setConfirmLeaveModalVisibility}/>
        </>
    );
}

export default SideMenu;
