const LANGUAGE= "language"

const getLanguage = () => {
    let language = "en";
    try {
        const data = localStorage.getItem(LANGUAGE)
        if (data) {
            language = data
        }
    } catch (e) {
        console.error(e.message)
    }
    return language
}

const setLanguage = (value) => {
    try {
        localStorage.setItem(LANGUAGE, value)
    } catch (e) {
    }
}

export {setLanguage, getLanguage}
