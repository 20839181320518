import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import {useNavigate} from "react-router-dom";

import '../../App.css';
import './_styles.css';
import '../../translation'

import SearchWhiteSVG from '../../assets/dashboard/searchWhite.svg';
import SearchYellowSVG from '../../assets/dashboard/searchYellow.svg';


import {useTranslation} from 'react-i18next';
import {useMediaQuery} from "react-responsive";
import TextField from "@mui/material/TextField";





// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const TextField1 = styled(TextField)(({ theme }) => ({

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '100px',
                width: 'calc(100% - 20px)',
            },
        },



    [theme.breakpoints.down('sm')]: {
        borderRadius: '100px',
        width: 'calc(100% - 2px)',
    },
}));



function Search() {

    let navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [focusSearch, setFocusSearch] = useState(false)
    const {t} = useTranslation();

    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })

    return (
        (<>
            <Grid item xs={12} sm={6}>
                <TextField1
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    fullWidth
                    onClick={() => (setFocusSearch(true))}
                    onBlur={() => (setTimeout(() => {
                        setFocusSearch(false)
                    }, 200))}
                    className={search == "" ? 'searchTextField' : 'searchSuggestionTextField'}
                    autoComplete='off'
                    placeholder={t('SEARCH_EXERCISE')}
                    InputProps={{
                        style: {
                            fontSize: is_m?"18px":"1.2vw",  // this control font size of placeholder
                            paddingLeft: "1.2vw",
                            fontFamily: "Nunito",
                            fontWeight: '600',
                            minHeight: "4vw"
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                {search == "" ?
                                    <div className="searchBtnContainer">
                                        <img className="searchWhite" src={SearchWhiteSVG} alt="search"/>
                                    </div>
                                    :
                                    <div className="searchSuggestionBtnContainer">
                                        <img className="searchWhite" src={SearchYellowSVG} alt="search"/>
                                    </div>
                                }
                            </InputAdornment>
                        )
                    }}
                  />
                {search != "" && focusSearch &&
                    <div className="searchSuggestionBottomContainer">
                        {"past tense".includes(search.toLowerCase()) &&
                            <p className="searchSuggestionBottomText" onClick={() => {
                                navigate("/exercise", {state: {topic: "pastTense"}});
                            }}>past tense</p>}
                        {"past tense & present tense".includes(search.toLowerCase()) &&
                            <p className="searchSuggestionBottomText" onClick={() => {
                                navigate("/exercise", {state: {topic: "pastTensePresentTense"}});
                            }}>past tense & present tense</p>}
                        {"present continuous tense".includes(search.toLowerCase()) &&
                            <p className="searchSuggestionBottomText" onClick={() => {
                                navigate("/exercise", {state: {topic: "presentContinuousTense"}});
                            }}>present continuous tense</p>}
                        {"present tensee & present continuous tense".includes(search.toLowerCase()) &&
                            <p className="searchSuggestionBottomText" onClick={() => {
                                navigate("/exercise", {state: {topic: "presentTensePresentContinuousTense"}});
                            }}>present tensee & present continuous tense</p>}
                        {"prepositions".includes(search.toLowerCase()) &&
                            <p className="searchSuggestionBottomText" onClick={() => {
                                navigate("/exercise", {state: {topic: "preposition"}});
                            }}>prepositions</p>}
                        {"question words".includes(search.toLowerCase()) &&
                            <p className="searchSuggestionBottomText" onClick={() => {
                                navigate("/exercise", {state: {topic: "questionWord"}});
                            }}>question words</p>}
                    </div>
                }
            </Grid>
            <Grid item xs={2}
                  sx={{
                      display: {xs: 'none', sm: 'unset'},
                  }}
            >
                <div className="searchBoxFirstContainer" onClick={() => {
                    navigate("/exercise", {state: {topic: "pastTense"}});
                }}>
                    <div className="searchBoxFirstContainerInner">
                        <p className="searchBoxText">Past Tense</p>
                    </div>
                </div>
            </Grid>
            <Grid item xs={2}
                  sx={{
                      display: {xs: 'none', sm: 'unset'},
                  }}

            >
                <div className="searchBoxSecondContainer" onClick={() => {
                    navigate("/exercise", {state: {topic: "pastTensePresentTense"}});
                }}>
                    <div className="searchBoxFirstContainerInner">
                        <p className="searchBoxText">Past Tense × <br/>Present Tense</p>
                    </div>
                </div>
            </Grid>
            <Grid item xs={2}
                  sx={{
                      display: {xs: 'none', sm: 'unset'},
                  }}
            >
                <div className="searchBoxThirdContainer" onClick={() => {
                    navigate("/exercise", {state: {topic: "presentContinuousTense"}});
                }}>
                    <div className="searchBoxFirstContainerInner">
                        <p className="searchBoxText">Present <br/>Continuous Tense</p>
                    </div>
                </div>
            </Grid>
        </>)
    );
}

export default Search;
