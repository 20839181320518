import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";

import '../../App.css';
import './_styles.css';

import {db} from "../../firebase";
import {doc, getDoc} from 'firebase/firestore';

import Menu from '../../components/menu/Menu';
import SideMenu from '../../components/menu/SideMenu';
import PerformanceTrend from './PerformanceTrend';
import BestPerformances from './BestPerformances';
import Improvements from './Improvements';
import WeekAndDay from './WeekAndDay';
import PerformanceLevel from './PerformanceLevel';
import {getAccuracy} from "../../functions/getAccuracy"
import {redirect} from "../../functions/redirect"
import {getUser} from '../../localStorage/User';
import {useNavigate} from 'react-router-dom';

import '../../translation'
import {useTranslation} from 'react-i18next';
import {getLanguage} from '../../localStorage/Language';
import {useMediaQuery} from "react-responsive";

function Result() {

    const is_m = useMediaQuery({
        query: '(max-width: 600px)'
    })
    const user = getUser();
    let navigate = useNavigate();
    const {t} = useTranslation();
    const l = getLanguage();
    useEffect(() => {
        // redirect(user, navigate);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getUserInfo();
    }, [])

    // to get user information
    const [userInfo, setUserInfo] = useState();
    const getUserInfo = async () => {
        if (user.id !== "" && user.id !== null && user.id !== undefined) {
            const userSnapshot = await getDoc(doc(db, 'User', user.id));
            setUserInfo(userSnapshot.data());
        }
    }

    const [topciAccuracy, setTopciAccuracy] = useState();
    const [weeklyAccuracy, setWeeklyAccuracy] = useState();
    const [masteredTopic, setMasteredTopic] = useState();
    const [toBeImprovedLevel1, setToBeImprovedLevel1] = useState();
    const [toBeImprovedLevel2, setToBeImprovedLevel2] = useState();

    const [randomIndex, setRandomIndex] = useState();

    useEffect(() => {
        if (userInfo && userInfo.tier) {
            (userInfo.tier === "below average" || userInfo.tier === "average") ?
                setRandomIndex(Math.floor(Math.random() * 3) + 1)
                :
                setRandomIndex(Math.floor(Math.random() * 2) + 1)
            ;
        } else {
            setRandomIndex(Math.floor(Math.random() * 2) + 1)
        }
    }, [userInfo])

    useEffect(() => {
        getAccuracy(userInfo, user.id, setTopciAccuracy, setWeeklyAccuracy, setMasteredTopic, setToBeImprovedLevel1, setToBeImprovedLevel2);
    }, [userInfo])

    return (
        <>
            <Menu/>
            <SideMenu/>
            <div className="responsiveContainer">
                <Grid container spacing={2}>
                    {l === "en" ?
                        <Grid item xs={12} sm={7}>
                            {userInfo && randomIndex && <img className="wellDoneBird" src={
                                userInfo.tier === "below average" ?
                                    require(`../../assets/result/belowAverageText` + randomIndex + `.png`)
                                    : userInfo.tier === "average" ?
                                        require(`../../assets/result/averageText` + randomIndex + `.png`)
                                        : require(`../../assets/result/aboveAverageText` + randomIndex + `.png`)
                            } alt="well done"/>}
                        </Grid>
                        :
                        <Grid item xs={12} sm={7}>
                            {userInfo && randomIndex && <img className="wellDoneBird" src={
                                userInfo.tier === "below average" ?
                                    require(`../../assets/result/belowAverageTextZH-HK` + randomIndex + `.png`)
                                    : userInfo.tier === "average" ?
                                        require(`../../assets/result/averageTextZH-HK` + randomIndex + `.png`)
                                        : require(`../../assets/result/aboveAverageTextZH-HK` + randomIndex + `.png`)
                            } alt="well done"/>}
                        </Grid>
                    }
                    <Grid item xs={12} sm={5}>
                        <WeekAndDay/>
                    </Grid>
                </Grid>

                {is_m ?
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={5}>
                            <PerformanceTrend weeklyAccuracy={weeklyAccuracy}/>
                            <PerformanceLevel/>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <BestPerformances topciAccuracy={topciAccuracy} masteredTopic={masteredTopic}/>
                            <Improvements topciAccuracy={topciAccuracy} toBeImprovedLevel1={toBeImprovedLevel1}
                                          toBeImprovedLevel2={toBeImprovedLevel2}/>
                        </Grid>
                    </Grid> : <Grid container spacing={4}>
                        <Grid item xs={12} sm={5}>
                            <PerformanceTrend weeklyAccuracy={weeklyAccuracy}/>
                            <PerformanceLevel/>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <BestPerformances topciAccuracy={topciAccuracy} masteredTopic={masteredTopic}/>
                            <Improvements topciAccuracy={topciAccuracy} toBeImprovedLevel1={toBeImprovedLevel1}
                                          toBeImprovedLevel2={toBeImprovedLevel2}/>
                        </Grid>
                    </Grid>}

            </div>
            <div className="answerReviewBottom"></div>
        </>
    );
}

export default Result;
